import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Query, TableStructure } from '../../../components';
import { Loading, Text } from '../../../core-ui';
import {
  GET_COUNT_NEW_ASSIGNMENTS,
  GetOrderListResult,
  GetOrderListVariables,
  Order,
} from '../../../graphql/queries';
import { formatNumberDate, formatThousandSeparator } from '../../../helpers';
import { getOrderStatusString, tipeNamaTotal, tipeTotalFunc } from '../helpers';

import { ApolloQueryResult } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { COPY_ASSIGNMENT } from '../../../graphql/mutations';
import './styleAssign.css';

export const TABLE_STRUCTURE = (
  refetchData: (
    variables?: GetOrderListVariables | undefined,
  ) => Promise<ApolloQueryResult<GetOrderListResult>>,
): TableStructure<Order> => ({
  createdAt: {
    headerTitle: 'Tanggal Order',
    render: ({ createdAt }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {formatNumberDate(new Date(createdAt))}
      </Text>
    ),
  },
  assignment: {
    headerTitle: 'No Assignment',
    render: (data) => {
      if (data.assignedDeliveryDrivers.length === 0) {
        return (
          <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
            -
          </Text>
        );
      }
      if (data.invoiceNumber != null) {
        return (
          <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
            {data.assignedDeliveryDrivers[0].assignedOrder.id
              ? `${data.assignedDeliveryDrivers[0].assignedOrder.id}`
              : '-'}
          </Text>
        );
      }
      return (
        <Mutation<unknown, { id: string }> mutation={COPY_ASSIGNMENT}>
          {(copyAssignment, { loading }) => (
            <Query query={GET_COUNT_NEW_ASSIGNMENTS}>
              {({ refetch }) => (
                <CopyToClipboard
                  text={data.assignedDeliveryDrivers[0].assignedOrder.id}
                  onCopy={async () => {
                    if (data.assignedDeliveryDrivers[0].assignedOrder.id) {
                      await copyAssignment({
                        variables: {
                          id: data.assignedDeliveryDrivers[0].assignedOrder.id,
                        },
                      });

                      await refetch();
                      await refetchData();
                    }

                    toast.success(
                      `${data.assignedDeliveryDrivers[0].assignedOrder.id} berhasil di salin`,
                    );
                  }}
                >
                  <div className="copyClip">
                    <Text
                      size="small"
                      weight="reg"
                      style={{ letterSpacing: 1.5 }}
                      color={data.isCopied ? 'green' : 'black'}
                    >
                      {loading ? (
                        <Loading size="small" />
                      ) : data.assignedDeliveryDrivers[0].assignedOrder.id ? (
                        `${data.assignedDeliveryDrivers[0].assignedOrder.id}`
                      ) : (
                        '-'
                      )}
                    </Text>
                  </div>
                </CopyToClipboard>
              )}
            </Query>
          )}
        </Mutation>
      );
    },
  },
  poNumber: {
    headerTitle: 'No PO',
    render: ({ poNumber, invoiceNumber }) => {
      if (invoiceNumber != null) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            {poNumber}
          </Text>
        );
      }
      return (
        <CopyToClipboard
          text={poNumber}
          onCopy={() => toast.success(`${poNumber} berhasil di salin`)}
        >
          <div className="copyClip">
            <Text
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
            >
              {poNumber}
            </Text>
          </div>
        </CopyToClipboard>
      );
    },
  },
  invoiceNumber: {
    headerTitle: 'Invoice',
    render: ({ invoiceNumber }) => {
      if (invoiceNumber == null) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            -
          </Text>
        );
      }
      if (invoiceNumber != null) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            {invoiceNumber}
          </Text>
        );
      }
      return (
        <CopyToClipboard
          text={invoiceNumber}
          onCopy={() => toast.success(`${invoiceNumber} berhasil di salin`)}
        >
          <div className="copyClip">
            <Text
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
            >
              {invoiceNumber}
            </Text>
          </div>
        </CopyToClipboard>
      );
    },
  },
  kodePelanggan: {
    headerTitle: 'KODE PELANGGAN',
    render: ({ user, invoiceNumber }) => {
      if (invoiceNumber != null) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            {user.szID}
          </Text>
        );
      }
      return (
        <CopyToClipboard
          text={user.szID}
          onCopy={() => toast.success(`${user.szID} berhasil di salin`)}
        >
          <div className="copyClip">
            <Text
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
            >
              {user.szID}
            </Text>
          </div>
        </CopyToClipboard>
      );
    },
  },
  store: {
    headerTitle: 'Nama Toko',
    render: ({ user }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {user.storeName}
      </Text>
    ),
  },
  productList: {
    headerTitle: 'daftar produk',
    render: ({ transactionItems }) => (
      <div>
        {transactionItems.map((item, index) => (
          <p key={index}>
            <Text
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
            >
              {index + 1 + '. '}
              {item.additionalProducts.length > 0
                ? item.quantity + ' - ' + item.additionalProducts[0].name
                : item.products?.[0]?.productData.productID +
                  ' - ' +
                  item.quantity +
                  ' - ' +
                  item.products?.[0]?.productData.nickname}
            </Text>
          </p>
        ))}
      </div>
    ),
  },
  realPriceTotal: {
    headerTitle: 'Total Harga Asli',
    render: ({ transactionItems, bottleGuarantee }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {`Rp. ${formatThousandSeparator(
          tipeTotalFunc(
            transactionItems,
            tipeNamaTotal.totalHargaAsali,
            0,
            bottleGuarantee,
          ),
        )}`}
      </Text>
    ),
  },
  jaminanBotol: {
    headerTitle: 'Jaminan Botol',
    render: ({ bottleGuarantee }) => {
      const botolArray: Array<number> = bottleGuarantee.map(
        (item) => item.pricePerBottle * item.qty,
      );

      const totalBotolJaminan: number = botolArray.reduce(
        (total, num) => total + num,
        0,
      );

      return (
        <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
          {`Rp. ${formatThousandSeparator(totalBotolJaminan)}`}
        </Text>
      );
    },
  },
  coupon: {
    headerTitle: 'coupon',
    render: ({ coupon, isCouponUsed }) => {
      const tampilCoupon = coupon ? coupon.couponRewardValue : 0;
      const tipeCoupon = coupon ? coupon.couponType : '';

      return (
        <Text
          size="small"
          weight="reg"
          style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
        >
          {`Rp. ${formatThousandSeparator(tampilCoupon)} - ${tipeCoupon} ${
            coupon && !isCouponUsed ? ' - (Dikembalikan)' : ''
          }`}
        </Text>
      );
    },
  },
  discount: {
    headerTitle: 'Total Discount',
    render: ({ transactionItems }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {`Rp. ${formatThousandSeparator(
          tipeTotalFunc(transactionItems, tipeNamaTotal.totalDiskon),
        )}`}
      </Text>
    ),
  },
  creditPointUse: {
    headerTitle: 'credit Point',
    render: ({ creditPointUse }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {`Rp. ${formatThousandSeparator(creditPointUse)}`}
      </Text>
    ),
  },
  totalPotongan: {
    headerTitle: 'Total Potongan Harga',
    render: ({ transactionItems, creditPointUse }) => {
      // const totalDiskonArray: number[] = transactionItems.map((item) => {
      //   return item.priceCut * item.quantity;
      // });

      // const totalDiskon: number = totalDiskonArray.reduce((total, num) => {
      //   return total + num;
      // }, 0);

      const hasil =
        tipeTotalFunc(transactionItems, tipeNamaTotal.totalDiskon) +
        creditPointUse;

      return (
        <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
          {`Rp. ${formatThousandSeparator(hasil)}`}
        </Text>
      );
    },
  },
  priceTotal: {
    headerTitle: 'Total Pembayaran',
    render: ({
      transactionItems,
      creditPointUse,
      bottleGuarantee,
      canceledOrders,
      coupon,
      isCouponUsed,
    }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {`Rp. ${formatThousandSeparator(
          tipeTotalFunc(
            transactionItems,
            tipeNamaTotal.totalHargaSetelahDiskon,
            creditPointUse,
            bottleGuarantee,
            canceledOrders.flatMap((order) =>
              order.deliveryItems.map(
                (item) =>
                  (item.transactionItem.price - item.transactionItem.priceCut) *
                  item.quantityDelivery,
              ),
            ),
            coupon && coupon.couponType === 'POTONGAN' && isCouponUsed
              ? coupon.couponRewardValue
              : 0,
          ),
        )}`}
      </Text>
    ),
  },
  kodeDriver: {
    headerTitle: 'KODE driver',
    render: (data) => {
      if (data.assignedDeliveryDrivers.length === 0) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            -
          </Text>
        );
      }
      if (data.invoiceNumber != null) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            {data.assignedDeliveryDrivers[0].driver.driverCode
              ? `${data.assignedDeliveryDrivers[0].driver.driverCode}`
              : '-'}
          </Text>
        );
      }
      return (
        <CopyToClipboard
          text={data.assignedDeliveryDrivers[0].driver.driverCode}
          onCopy={() =>
            toast.success(
              `${data.assignedDeliveryDrivers[0].driver.driverCode} berhasil di salin`,
            )
          }
        >
          <div className="copyClip">
            <Text
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
            >
              {data.assignedDeliveryDrivers[0].driver.driverCode
                ? `${data.assignedDeliveryDrivers[0].driver.driverCode}`
                : '-'}
            </Text>
          </div>
        </CopyToClipboard>
      );
    },
  },
  driver: {
    headerTitle: 'Nama Driver',
    render: (data) => {
      if (data.assignedDeliveryDrivers.length === 0) {
        return (
          <Text
            size="small"
            weight="reg"
            style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
          >
            -
          </Text>
        );
      }
      return (
        <Text
          size="small"
          weight="reg"
          style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
        >
          {data.assignedDeliveryDrivers[0].driver.driverName
            ? data.assignedDeliveryDrivers[0].driver.driverName
            : '-'}
        </Text>
      );
    },
  },
  depot: { headerTitle: 'Depo', alias: 'depot.title' },
  status: {
    headerTitle: 'Status',
    render: ({ status }) => (
      <Text
        size="small"
        weight="reg"
        style={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
      >
        {getOrderStatusString(status)}
      </Text>
    ),
  },
});
