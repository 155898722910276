export function formatShortMonth(input: Date | string, showYear = true) {
  const fullDate = new Date(input);
  const year = showYear ? 'numeric' : undefined;
  return fullDate.toLocaleDateString('id', {
    year,
    month: 'short',
    day: 'numeric',
  });
}

export function formatFullDate(input: Date, showYear = true) {
  const year = showYear ? 'numeric' : undefined;
  return input.toLocaleDateString('id', {
    year,
    month: 'long',
    day: 'numeric',
  });
}

export function formatNumberDate(
  input: Date,
  shortYear = false,
  showTime = true,
) {
  const year = shortYear ? '2-digit' : 'numeric';
  const time = showTime
    ? {
        hour: '2-digit',
        minute: '2-digit',
      }
    : {};
  return input.toLocaleDateString('id', {
    year,
    month: '2-digit',
    day: '2-digit',
    ...time,
  });
}

export function formatTime(input: Date | string) {
  const date = new Date(input);

  return date.toLocaleTimeString('id', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatTime12H(d: Date | string) {
  const date = new Date(d);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours >= 12 ? hours % 12 : hours;

  const stringisizedHours =
    hours < 10 ? `0${hours.toString()}` : hours.toString();
  return `${stringisizedHours}:${`0${minutes}`.slice(-2)} ${meridiem}`;
}

export function formatDateTimeWithoutTimezone(
  input: Date | number,
  shortYear = false,
  showTime = true,
) {
  const year = shortYear ? '2-digit' : 'numeric';
  const time = showTime
    ? {
        hour: '2-digit' as const,
        minute: '2-digit' as const,
      }
    : {};

  return Intl.DateTimeFormat('id-ID', {
    timeZone: 'UTC',
    year,
    month: '2-digit',
    day: '2-digit',
    ...time,
  }).format(input);
}
