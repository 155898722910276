import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import { Query } from '../../../components';
import { DARK_GRAY } from '../../../constants/colors';
import { Modal, Separator, Text } from '../../../core-ui';
import {
  GET_PRODUCT_LIST,
  GetProductListResult,
  GetProductListVariables,
  Product,
} from '../../../graphql/queries';
import { fetchMoreItems } from '../../../helpers';

import { ProductList, SearchField } from '../components';

type OwnProps = {
  priceSegmentCode?: string;
  selectedProduct: Nullable<Product>;
  onClose: () => void;
  onChangeSelected: (selectedProduct: Nullable<Product>) => void;
};

type Props = OwnProps;

type State = {
  resetPage: boolean;
  page: number;
  selectedProduct: Nullable<Product>;
  searchKey: string;
};

class LoyaltyProductModal extends Component<Props, State> {
  state = {
    resetPage: false,
    page: 0,
    selectedProduct: this.props.selectedProduct,
    searchKey: '',
  };

  render() {
    const { priceSegmentCode, onClose } = this.props;
    const { selectedProduct, searchKey, resetPage } = this.state;
    return (
      <Modal
        maxHeight
        title="Pilih Jenis Barang"
        isVisible
        onClose={onClose}
        buttonText="Pilih"
        onSubmit={() => this._onSubmit()}
      >
        <View style={{ width: 500 }}>
          <Text color={DARK_GRAY} style={styles.bottomPadding}>
            Tentukan produk yang akan dijadikan sebagai reward.
          </Text>
          <SearchField
            placeholder="Cari produk..."
            value={searchKey}
            onChangeText={this._onSearch}
          />
          <Separator />
          <Query<GetProductListResult, GetProductListVariables>
            query={GET_PRODUCT_LIST}
            variables={{
              where: { searchKeyword: searchKey.toLowerCase() },
              first: 10,
              skip: 0,
            }}
            keyData="products"
            fetchPolicy="network-only"
          >
            {({ data, loading, fetchMore }) =>
              data && (
                <ProductList
                  priceSegmentCode={priceSegmentCode}
                  resetPage={resetPage}
                  setResetPage={(isReset) =>
                    this.setState({ resetPage: isReset })
                  }
                  isLoading={loading}
                  data={data.products}
                  dataCount={data.count}
                  selectedProduct={selectedProduct}
                  onChangeSelected={this._onChangeSelected}
                  searchKey={searchKey}
                  loadMore={({ first, skip, searchInput }) => {
                    fetchMoreItems<
                      GetProductListResult,
                      GetProductListVariables
                    >(fetchMore, {
                      query: GET_PRODUCT_LIST,
                      variables: {
                        where: {
                          searchKeyword: searchInput
                            ? searchInput.toLowerCase()
                            : '',
                        },
                        first,
                        skip,
                      },
                      dataKey: 'products',
                    });
                  }}
                />
              )
            }
          </Query>
        </View>
      </Modal>
    );
  }

  _onSearch = (searchKey: string) => {
    this.setState({ searchKey, resetPage: true });
  };

  _onChangeSelected = (selectedProduct: Nullable<Product>) => {
    this.setState({ selectedProduct });
  };

  _onSubmit = () => {
    const { onChangeSelected, onClose } = this.props;
    const { selectedProduct } = this.state;
    onChangeSelected(selectedProduct);
    onClose();
  };
}

const styles = StyleSheet.create({
  bottomPadding: { paddingBottom: 30 },
  button: {
    marginTop: 50,
    alignSelf: 'flex-end',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LoyaltyProductModal;
