import React from 'react';
import { View, ViewStyle } from 'react-native';
import { useDropzone, DropEvent } from 'react-dropzone';

import { Icon } from '../core-ui';
import { PRIMARY, GRAY } from '../constants/colors';

type Props = {
  disabled?: boolean;
  onAccept: <T extends File>(files: Array<T>, event: DropEvent) => void;
};

function UploadCsv(props: Props) {
  const { disabled, onAccept } = props;
  const { getInputProps, open } = useDropzone({
    accept: 'text/csv',
    onDropAccepted: onAccept,
  });
  return (
    <View>
      <input {...getInputProps()} />
      <Icon
        isCustomSVG
        name="uploadCSV"
        onPress={open}
        disabled={disabled}
        color={disabled ? GRAY : PRIMARY}
      />
    </View>
  );
}

export default UploadCsv;
