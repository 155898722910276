import React, { Component, Fragment } from 'react';

import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  StyleProp,
  ViewStyle,
  TextStyle,
  LayoutChangeEvent,
  TouchableWithoutFeedback,
} from 'react-native';

import { Text, Icon, Calendar } from '.';
import { MONTHS } from '../constants/calendar';
import { DROPDOWN, WHITE, BLACK, DARK_GRAY } from '../constants/colors';

type Props = {
  minDate?: Date;
  style?: StyleProp<ViewStyle>;
  placeholder: string;
  selectedDate: Nullable<Date>;
  label?: string;
  labelHorizontal?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  isOpen?: boolean;
  isFilter?: boolean;
  onFieldPress?: () => void;
  onChange: (selectedDate: Date) => void;
};

type State = {
  isCalendarOpened: boolean;
  fieldWidth: number;
  hoveredIndex: Nullable<number>;
};

export default class DatePickerField extends Component<Props, State> {
  _inputRef?: TextInput;

  state = {
    isCalendarOpened: false,
    fieldWidth: 0,
    hoveredIndex: null,
  };

  render() {
    const {
      style,
      isOpen,
      label,
      labelHorizontal,
      isFilter,
      ...otherProps
    } = this.props;
    const { isCalendarOpened } = this.state;
    const shouldRenderCalendar = isOpen != null ? isOpen : isCalendarOpened;
    return (
      <View
        style={[
          styles.root,
          labelHorizontal && styles.labelHorizontal,
          isFilter && { width: 240 },
          style,
        ]}
        {...otherProps}
      >
        {label && this._renderLabel()}
        <View onLayout={this._setLayout} style={labelHorizontal && { flex: 2 }}>
          <TouchableOpacity onPress={this._onDropdownPress}>
            {this._renderField(shouldRenderCalendar)}
          </TouchableOpacity>
          {shouldRenderCalendar && this._renderCalendar()}
        </View>
      </View>
    );
  }

  _renderLabel() {
    const { label, labelHorizontal, labelStyle } = this.props;
    return (
      <View
        style={
          labelHorizontal ? styles.labelWrapperHorizontal : styles.labelWrapper
        }
      >
        <Text size="small" style={[styles.label, labelStyle]}>
          {label}
        </Text>
      </View>
    );
  }

  _renderField(isDropdownOpened: boolean) {
    const { selectedDate, placeholder, isFilter } = this.props;
    return (
      <>
        <View
          style={[
            styles.dropdownButton,
            isFilter && { backgroundColor: WHITE },
            isDropdownOpened && styles.dropdownOpened,
          ]}
        >
          <Text
            size="small"
            color={selectedDate ? BLACK : DROPDOWN.PLACEHOLDER}
          >
            {selectedDate ? this._getDisplayedValue(selectedDate) : placeholder}
          </Text>
          <Icon
            onPress={this._onDropdownPress}
            size="small"
            name="date_range"
            color={isDropdownOpened ? DROPDOWN.ACTIVE : DARK_GRAY}
          />
        </View>
        <TextInput
          onBlur={this._onCloseModal}
          ref={this._setRef}
          style={StyleSheet.flatten(styles.dummyInput)}
        />
      </>
    );
  }

  _renderCalendar() {
    const { selectedDate, minDate, isOpen } = this.props;
    const { fieldWidth } = this.state;
    return (
      <>
        {isOpen == null && (
          <TouchableWithoutFeedback onPress={this._onCloseModal}>
            <View style={styles.dismiss} />
          </TouchableWithoutFeedback>
        )}
        <View style={[styles.calendarContainer, { width: fieldWidth }]}>
          <Calendar
            minDate={minDate}
            value={selectedDate || new Date()}
            onChange={this._onChange}
          />
        </View>
      </>
    );
  }

  _getDisplayedValue = (selectedDate: Date) =>
    `${selectedDate.getDate()} ${
      MONTHS[selectedDate.getMonth()]
    } ${selectedDate.getFullYear()}`;

  _setLayout = (e: LayoutChangeEvent) => {
    this.setState({ fieldWidth: e.nativeEvent.layout.width });
  };

  _setRef = (inputRef: TextInput) => {
    this._inputRef = inputRef;
  };

  _onDropdownPress = () => {
    const { onFieldPress } = this.props;
    onFieldPress && onFieldPress();
    if (this.state.isCalendarOpened) {
      this._onCloseModal();
    } else {
      this.setState({ isCalendarOpened: true });
    }
  };

  _onChange = (date: Date | Array<Date>) => {
    this._onCloseModal();
    this.props.onChange(date as Date);
  };

  _onCloseModal = () => this.setState({ isCalendarOpened: false });
}

const HEIGHT = 33;

let styles = StyleSheet.create({
  root: {
    zIndex: 1,
  },
  labelHorizontal: {
    flexDirection: 'row',
    marginBottom: 32,
  },
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: DROPDOWN.BACKGROUND,
    paddingRight: 5,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT,
  },
  calendarContainer: {
    position: 'absolute',
    top: 35,
  },
  dummyInput: {
    position: 'relative',
    borderWidth: 0,
    fontSize: 'inherit',
    width: 1,
    height: 0,
    outline: '0',
    padding: 0,
    opacity: 0,
  },
  dropdownOpened: {
    backgroundColor: DROPDOWN.BACKGROUND,
    borderColor: DROPDOWN.ACTIVE,
    borderWidth: 1,
  },
  label: {
    color: DROPDOWN.LABEL,
  },
  dismiss: {
    cursor: 'default',
    height: '100vh',
    width: '100vw',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  labelWrapper: {
    paddingVertical: 10,
  },
  labelWrapperHorizontal: {
    flex: 1,
    justifyContent: 'center',
  },
});
