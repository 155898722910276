import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { Text } from '../../../core-ui';
import { DROPDOWN } from '../../../constants/colors';

type Props = {
  children: ReactNode;
  label: string;
  style?: StyleProp<ViewStyle>;
};

export default function AdvanceField(props: Props) {
  const { children, label, style } = props;
  return (
    <View style={[styles.customeFieldContainer, style]}>
      <View style={styles.customeFieldLabel}>
        <Text size="small" color={DROPDOWN.LABEL}>
          {label}
        </Text>
      </View>
      <View style={styles.customeFieldcontent}>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  customeFieldContainer: {
    flexDirection: 'row',
    marginBottom: 32,
  },
  customeFieldLabel: {
    flex: 1,
    justifyContent: 'center',
  },
  customeFieldcontent: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
