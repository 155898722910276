import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';

import { Text } from '.';
import { GRAY30, INPUT } from '../constants/colors';
import { Button } from '../core-ui';

type Props = {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  labelHorizontal?: boolean;
  label?: string;
  labelStyle?: StyleProp<TextStyle>;
  inverted?: boolean;
  buttonText?: string;
  trailingText?: string;
  trailingTextStyle?: StyleProp<TextStyle>;
};

export default class ButtonField extends Component<Props> {
  render() {
    const {
      children,
      label,
      labelHorizontal,
      labelStyle,
      style,
      onPress,
      buttonText,
      inverted,
      trailingText,
      trailingTextStyle,
      ...otherProps
    } = this.props;

    const trailingTextComponent = (
      <Text size="small" style={[styles.trailingText, trailingTextStyle]}>
        {trailingText}
      </Text>
    );

    const buttonComponent = (
      <View style={styles.button}>
        <Button inverted={inverted} onPress={onPress}>
          {buttonText}
        </Button>
        {trailingTextComponent}
      </View>
    );

    const labelComponent = (
      <View
        style={[styles.labelWrapper, labelHorizontal && styles.labelHorizontal]}
      >
        <Text size="small" style={[styles.label, labelStyle]}>
          {label}
        </Text>
      </View>
    );

    return (
      <View style={[labelHorizontal && { marginBottom: 32 }]} {...otherProps}>
        <View style={labelHorizontal && styles.row}>
          {label && labelComponent}
          {buttonComponent}
        </View>
      </View>
    );
  }
}

const PADDING = 7;
const PADDING_HORIZONTAL = 10;

let styles = StyleSheet.create({
  button: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  trailingText: {
    paddingLeft: 10,
    color: GRAY30,
  },
  labelWrapper: {
    paddingBottom: 10,
  },
  row: {
    flexDirection: 'row',
  },
  childrenStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: PADDING,
    paddingHorizontal: PADDING_HORIZONTAL,
  },
  label: {
    color: INPUT.LABEL,
  },
  labelHorizontal: {
    flex: 1,
    paddingVertical: 8,
  },
});
