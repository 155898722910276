import gql from 'graphql-tag';

import { MEMBERS_FRAGMENT, couponAdditionalProducts, couponProducts } from '../relationalFragments';
import { Product } from './productQuery';

export type CouponType = 'CASHBACK' | 'POTONGAN' | 'PRODUK';

export type CouponProduct = {
  id?: string;
  product: Product;
  quantity: number;
};

export type AdditionalProduct = {
  id?: string;
  name: string;
  quantity: number;
};

export type Coupon = {
  id: string;
  title: string;
  description: string;
  couponImage: string;
  couponType: CouponType;
  couponRewardValue: number;
  couponPrice: number;
  couponLimit: number;
  couponValidDays: number;
  couponClaimCount?: number;
  startDate: string; // NOTE: date ISO string
  endDate: string; // NOTE: date ISO string
  createdAt: string; // NOTE: date ISO string
  active: boolean;
  products: Array<CouponProduct>;
  additionalProducts: Array<AdditionalProduct>;
};

export const COUPON_FRAGMENT = gql`
  fragment CouponFragment on Coupon {
    id
    title
    description
    couponImage
    couponType
    couponRewardValue
    couponPrice
    couponLimit
    couponClaimCount
    couponValidDays
    startDate
    endDate
    createdAt
    active
    ${couponProducts}
    ${couponAdditionalProducts}
  }
`;

export const COUPON_WITH_MEMBERS_FRAGMENT = gql`
  fragment CouponWithMebersFragment on Coupon {
    ...CouponFragment
    members {
      ...MemberData
    }
  }
  ${COUPON_FRAGMENT}
  ${MEMBERS_FRAGMENT}
`;

export type GetCouponsResult = {
  count: number;
  coupons: Array<Coupon>;
};

export const GET_COUPONS = gql`
  query GetCoupons($searchKeyword: String, $first: Int, $skip: Int) {
    count: dataCount(
      node: COUPON
      couponWhere: { title_contains: $searchKeyword }
    )
    coupons(
      where: { title_contains: $searchKeyword }
      first: $first
      skip: $skip
    ) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

export type GetCouponParams = { id: string };
export type GetCouponResult = {
  coupon: Coupon;
};

export const GET_COUPON = gql`
  query GetCoupon($id: ID!) {
    coupon(id: $id) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;
