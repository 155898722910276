import gql from 'graphql-tag';

import { COUPON_FRAGMENT, CouponType } from '../queries/couponQuery';

export type CouponProductsParam = {
  create?: Array<{
    product: { connect: { id: string } };
    quantity: number;
  }>;
  updateMany?: Array<{
    where: {
      id: string;
    };
    data: {
      quantity: number;
    };
  }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};

export type AdditionalProductsParam = {
  create?: Array<{
    name: string;
    quantity: number;
  }>;
}

export type CreateCouponVars = {
  data: {
    title: string;
    description: string;
    couponImage?: string;
    couponType: CouponType;
    couponRewardValue: number;
    couponPrice: number;
    couponLimit: number;
    couponClaimCount?: number;
    couponValidDays?: number;
    startDate?: string; // NOTE: ISO format of Date
    endDate?: string; // NOTE: ISO format of Date
    active?: boolean;
    products?: CouponProductsParam;
    additionalProducts?: AdditionalProductsParam;
  };
};

export type DeleteCouponVars = {
  id: string;
};

export type UpdateCouponVars = DeleteCouponVars & {
  data: {
    title: Nullable<string>;
    description: Nullable<string>;
    couponImage: Nullable<string>;
    couponType: Nullable<CouponType>;
    couponRewardValue: Nullable<number>;
    couponPrice: Nullable<number>;
    couponLimit: Nullable<number>;
    startDate: Nullable<string>; // NOTE: ISO format of Date
    endDate: Nullable<string>; // NOTE: ISO format of Date
    active: Nullable<boolean>;
    products: Nullable<CouponProductsParam>;
    additionalProducts: Nullable<AdditionalProductsParam>;
  };
};

export const CREATE_COUPON = gql`
  mutation createCoupon($data: CouponCreateInput!) {
    createCoupon(data: $data) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon($id: ID!, $data: CouponUpdateInput!) {
    updateCoupon(where: { id: $id }, data: $data) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($id: ID!) {
    deleteCoupon(where: { id: $id }) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;
