import gql from 'graphql-tag';

export const GET_TABLE_SELECTED_STATE = gql`
  query {
    selectedState @client {
      selectedArray
    }
  }
`;

export const UPDATE_TABLE_SELECTED = gql`
  mutation setTableSelectedArray($selectedArray: Array) {
    setTableSelectedArray(selectedArray: $selectedArray) @client
  }
`;

export const GET_MODAL_SELECTED_STATE = gql`
  query {
    modalState @client {
      selectedArray
    }
  }
`;

export const UPDATE_MODAL_SELECTED = gql`
  mutation setModalSelectedArray($selectedArray: Array) {
    setModalSelectedArray(selectedArray: $selectedArray) @client
  }
`;
