import * as RadixAccordion from '@radix-ui/react-accordion';
import React, { useState } from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { GRAY } from '../constants/colors';
import './Accordion.css';
import Icon from './Icon';
import Separator from './Separator';
import Text from './Text';

export type AccordionProps = {
  items: Array<{
    id: string;
    title: string;
    headerRight?: React.ReactNode;
    children: React.ReactNode;
    headerLeft?: React.ReactNode;
  }>;
  triggerStyle?: ViewStyle;
};

export default function Accordion({ items, triggerStyle }: AccordionProps) {
  const [value, setValue] = useState<string[]>([]);

  const onOpen = (id: string) => {
    setValue((prevValue) => {
      if (prevValue.includes(id)) {
        return prevValue.filter((item) => item !== id);
      }

      return [...prevValue, id];
    });
  };

  return (
    <RadixAccordion.Root type="multiple" value={value} onValueChange={setValue}>
      {items.map((item) => (
        <RadixAccordion.Item
          key={item.id}
          value={item.id}
          className="AccordionItem"
          aria-label={item.title}
        >
          <div className="AccordionTrigger">
            {item.headerLeft}
            <TouchableOpacity
              style={{
                borderRadius: 4,
                borderColor: GRAY,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
                ...triggerStyle,
              }}
              onPress={() => onOpen(item.id)}
            >
              <Text>{item.title}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {item.headerRight}
                <div className="AccordionArrow">
                  <Icon name="expand_more" color={GRAY} />
                </div>
              </View>
            </TouchableOpacity>
          </div>
          <RadixAccordion.Content className="AccordionContent">
            <Separator style={{ marginVertical: 0 }} />
            <View style={{ padding: 20 }}>{item.children}</View>
          </RadixAccordion.Content>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
}
