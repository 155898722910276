import React, { Component } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, TextField } from '.';
import { TextFieldProps } from './TextField';
import { DARK_GRAY } from '../constants/colors';

type Props = TextFieldProps & {
  phoneNumber: string;
  isError: boolean;
  label?: string;
  placeholder?: string;
  onPhoneChange: (text: string) => void;
  style?: StyleProp<ViewStyle>;
};

export default class PhoneField extends Component<Props, {}> {
  render() {
    const {
      phoneNumber,
      isError,
      label,
      placeholder,
      style: containerStyle,
      ...otherProps
    } = this.props;
    const contactError = isError ? 'Nomor HP salah.' : undefined;
    return (
      <TextField
        keyboardType="numeric"
        maxLength={12}
        childrenPosition="left"
        label={label || 'Nomor HP'}
        placeholder={placeholder || 'Nomor HP'}
        style={{
          container: containerStyle,
          children: {
            paddingLeft: 0,
            marginHorizontal: 0,
          },
        }}
        onChangeText={this._handlePhoneChange}
        value={phoneNumber || ''}
        error={contactError}
        {...otherProps}
      >
        <Text size="small" color={DARK_GRAY} style={{ paddingLeft: 10 }}>
          {'+62 | '}
        </Text>
      </TextField>
    );
  }

  _handlePhoneChange = (input: string) => {
    const { onPhoneChange } = this.props;
    if (input.length <= 12 && onPhoneChange) {
      const cleanNumber = input.replace(/^0/g, '');
      onPhoneChange(cleanNumber);
    }
  };
}
