import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { asyncStorage } from '../helpers';

import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink, split } from 'apollo-link';
import { API_URI, WS_URI } from '../constants/api';
import { defaultState } from './localState';
import {
  loginSuccess,
  logout,
  updateModalState,
  updateSearchState,
  updateSelectedState,
} from './resolvers';

const cache = new InMemoryCache();
const stateLink = withClientState({
  defaults: defaultState,
  resolvers: {
    Mutation: {
      setTableSelectedArray: updateSelectedState,
      setModalSelectedArray: updateModalState,
      updateSearch: updateSearchState,
      loginSuccess,
      logout,
    },
  },
  cache,
});
const authLink = setContext(async (_, { headers }) => {
  const token = await asyncStorage.getToken();
  return {
    headers: {
      ...headers,
      'auth-token': token || '',
    },
  };
});
const httpLink = createUploadLink({
  uri: API_URI,
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink(new SubscriptionClient(WS_URI));
const link = ApolloLink.from([stateLink, authLink, httpLink]);
export const client = new ApolloClient({
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    link,
  ),
  cache,
});

client.onResetStore(() => {
  stateLink.writeDefaults();
  return Promise.resolve();
});
