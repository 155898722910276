export const TOP_TABLE_STRUCTURE = {
  szID: {
    headerTitle: 'Kode Pelanggan',
  },
  name: {
    headerTitle: 'Nama Pelanggan',
  },
  storeName: {
    headerTitle: 'Nama Toko',
  },
  phone: {
    headerTitle: 'Kontak',
  },
};
