import React, { Component } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { graphql } from 'react-apollo';

import { GET_SEGMENTS_TREE, SegmentTreeListData } from '../../graphql/queries';

import { ChosenProps, Picker, Option } from '.';

type SegmentTreeListProps = { segmentQuery: SegmentTreeListData };
type OwnProps = {
  isFilter?: boolean;
  selectedSegment: Nullable<string>;
  selectedSubSegment: Nullable<string>;
  onSegmentChange?: (option: Nullable<Option>) => void;
  onSubSegmentChange?: (option: Nullable<Option>) => void;
  containerStyle?: StyleProp<ViewStyle>;
} & Pick<ChosenProps, 'style'>;
type Props = SegmentTreeListProps & OwnProps;

type State = {
  segmentID: Nullable<string>;
};

export class SegmentPicker extends Component<Props, State> {
  state: State = { segmentID: null };

  render() {
    const {
      segmentQuery,
      style,
      selectedSegment,
      selectedSubSegment: propsSubSegment,
      onSegmentChange,
      onSubSegmentChange,
      containerStyle,
      ...otherProps
    } = this.props;
    const { segmentID: stateSegmentID } = this.state;
    const segmentID = stateSegmentID || selectedSegment;
    let selectedSubSegment = null;

    let segmentOptions: Array<Option> = [];
    let subSegmentOptions: Array<Option> = [];

    if (segmentQuery.segments) {
      segmentOptions = segmentQuery.segments.map(({ id, title }) => ({
        value: id,
        label: title,
      }));

      const selectedSegmentObject =
        segmentID &&
        segmentQuery.segments.find((segment) => segment.id === segmentID);

      if (selectedSegmentObject) {
        subSegmentOptions = selectedSegmentObject.subSegments.map(
          ({ id, title }) => {
            if (id === propsSubSegment) {
              selectedSubSegment = id;
            }
            return {
              value: id,
              label: title.toUpperCase(), // NOTE: to display data as we received from TVIP API
            };
          },
        );
      }
    }

    return (
      <View style={containerStyle}>
        <Picker
          label="Segmentasi"
          placeholder="Segmentasi"
          style={[{ zIndex: 2 }, style]}
          selectedOption={selectedSegment}
          options={segmentOptions}
          onChange={(selected) => {
            this.setState({
              segmentID: selected ? selected.value : selected,
            });
            onSegmentChange && onSegmentChange(selected);
          }}
          {...otherProps}
        />
        <Picker
          label="Sub Segmentasi"
          placeholder="Sub Segmentasi"
          style={style}
          selectedOption={selectedSubSegment}
          options={subSegmentOptions}
          onChange={(selected) => {
            onSubSegmentChange && onSubSegmentChange(selected);
          }}
          {...otherProps}
        />
      </View>
    );
  }
}

export default graphql<OwnProps, SegmentTreeListData, {}, SegmentTreeListProps>(
  GET_SEGMENTS_TREE,
  { name: 'segmentQuery' },
)(SegmentPicker);
