import React, { Fragment } from 'react';
import { View, StyleSheet } from 'react-native';

import { Separator, Text } from '../../../core-ui';
import { DARK_GRAY } from '../../../constants/colors';
import {
  formatThousandSeparator,
  formatFullDate,
  convertPascalCase,
} from '../../../helpers';
import {
  ClusterPart,
  Product,
  ClusterGroup,
  Cluster,
} from '../../../graphql/queries';

type Props = {
  clusterDetail: ClusterPart;
  pointPrice: number;
};

const DETAIL_FIELDS: ObjectKey = {
  segment: 'Segmentasi',
  subSegment: 'Sub Segmentasi',
  products: 'Produk',
  validDate: 'Tanggal Mulai',
  expiredDate: 'Tanggal Berakhir',
  pointPrice: 'Rupiah Per Poin',
};

const CLUSTER_FIELD: ObjectKey = {
  name: 'Nama Cluster',
  rangeOrder: 'Range Pembelian',
  unitMultiplier: 'Kelipatan Pembelian',
  pointMultiplier: 'Poin',
};

export default class ClusterDetail extends React.Component<Props> {
  _checkContent = (key: string) => {
    const { clusterDetail, pointPrice } = this.props;

    switch (key) {
      case 'pointPrice':
        return `Rp. ${formatThousandSeparator(pointPrice)} /Poin`;
      case 'segment':
        return clusterDetail.segment.title;
      case 'subSegment':
        return clusterDetail.subSegment.title.toUpperCase();
      case 'validDate':
        return formatFullDate(new Date(clusterDetail.validDate));
      case 'expiredDate':
        return formatFullDate(new Date(clusterDetail.expiredDate));
      default:
        return clusterDetail[key as keyof ClusterPart];
    }
  };

  render() {
    const { clusterDetail } = this.props;
    const detailFieldsKey = Object.keys(DETAIL_FIELDS);
    const clusterGroup = Object.keys(clusterDetail.clusterGroup);
    const clusterGroupKey = clusterGroup.slice(0, clusterGroup.length - 1);
    const clusterFieldsKey = Object.keys(CLUSTER_FIELD);
    const detailField = detailFieldsKey.map((key, index) => {
      const label = DETAIL_FIELDS[key];
      const content = this._checkContent(key);

      return (
        <View key={index} style={styles.boxDetail}>
          <View style={styles.flex}>
            <Text size="xsmall" color={DARK_GRAY}>
              {label}
            </Text>
          </View>
          <View style={styles.infoSeparator}>
            <Text size="xsmall" color={DARK_GRAY}>
              :
            </Text>
          </View>
          <View style={styles.infoValue}>
            {key === 'products' ? (
              clusterDetail[key].length <= 0 ? (
                <Text size="small">-</Text>
              ) : (
                clusterDetail[key].map(
                  (item: Product, indexCluster: number) => (
                    <View key={indexCluster} style={styles.productList}>
                      <Text size="small" style={styles.number}>
                        {indexCluster + 1}.{' '}
                      </Text>
                      <Text size="small">{convertPascalCase(item.title)}</Text>
                    </View>
                  ),
                )
              )
            ) : (
              <Text size="small">{content}</Text>
            )}
          </View>
        </View>
      );
    });
    const clusterInfo = clusterGroupKey.map((keyClusterGroup, indexCluster) => {
      const clusterInfoDetail = clusterFieldsKey.map((key, index) => {
        const label = CLUSTER_FIELD[key];
        let content;
        const { unitMultiplier, pointMultiplier } = clusterDetail.clusterGroup[
          keyClusterGroup as keyof ClusterGroup
        ];
        if (key === 'rangeOrder') {
          const title = keyClusterGroup.toLowerCase();
          content = this._getContent(
            title,
            clusterDetail.clusterGroup[keyClusterGroup as keyof ClusterGroup],
          );
        } else if (key === 'unitMultiplier') {
          content = `${unitMultiplier} Unit`;
        } else if (key === 'pointMultiplier') {
          content = `${pointMultiplier} Poin`;
        } else {
          content =
            clusterDetail.clusterGroup[keyClusterGroup as keyof ClusterGroup][
              key as keyof Cluster
            ];
        }
        return (
          <View key={index} style={styles.boxDetail}>
            <View style={styles.flex}>
              <Text size="xsmall" color={DARK_GRAY}>
                {label}
              </Text>
            </View>
            <View style={styles.infoSeparator}>
              <Text size="xsmall" color={DARK_GRAY}>
                :
              </Text>
            </View>
            <View style={styles.infoValue}>
              <Text size="small">{content}</Text>
            </View>
          </View>
        );
      });
      return (
        <>
          {clusterInfoDetail}
          {indexCluster !== clusterGroupKey.length - 1 && <Separator />}
        </>
      );
    });

    return (
      <>
        {detailField}
        <Separator secondary />
        {clusterInfo}
      </>
    );
  }

  _getContent = (title: string, item: Cluster) => {
    switch (title) {
      case 'diamond':
        return `> ${item.minRange} Unit`;
      case 'bronze':
        return `< ${item.maxRange} Unit`;
      default:
        return `${item.minRange} - ${item.maxRange} Unit`;
    }
  };
}

const styles = StyleSheet.create({
  flex: {
    flex: 1.2,
  },
  boxDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  infoSeparator: {
    paddingLeft: 10,
    paddingRight: 30,
  },
  infoValue: {
    flex: 2,
    justifyContent: 'flex-end',
  },
  productList: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  number: {
    marginRight: 3,
  },
});
