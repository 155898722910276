import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';

import { SubSegment, SubSegmentWithMembers } from './subSegmentQuery';
import {
  SEGMENT_FRAGMENT,
  SUB_SEGMENT_FRAGMENT,
  MEMBERS_FRAGMENT,
} from '../relationalFragments';

export type Segment = {
  id: string;
  segmentID: string;
  title: string;
};
export type SegmentData = DataValue<{ segment: Segment }>;
export const GET_SEGMENT = gql`
  query getSegments($id: ID!) {
    segment(where: { id: $id }) {
      ...SegmentData
    }
  }
  ${SEGMENT_FRAGMENT}
`;

export type SegmentTree = Segment & { subSegments: Array<SubSegment> };
export type SegmentTreeVariables = { id: string };
export type SegmentTreeData = DataValue<
  { segment: SegmentTree },
  SegmentTreeVariables
>;
export const GET_SEGMENT_TREE = gql`
  query getSegments($id: ID!) {
    segment(where: { id: $id }) {
      ...SegmentData
      subSegments {
        ...SubSegmentData
      }
    }
  }
  ${SEGMENT_FRAGMENT}
  ${SUB_SEGMENT_FRAGMENT}
`;

export type SegmentList = { segments: Array<Segment> };
export type SegmentListData = DataValue<SegmentList>;
export const GET_SEGMENTS = gql`
  query getSegments {
    segments {
      ...SegmentData
    }
  }
  ${SEGMENT_FRAGMENT}
`;

export type SegmentTreeList = { segments: Array<SegmentTree> };
export type SegmentTreeListData = DataValue<SegmentTreeList>;
export const GET_SEGMENTS_TREE = gql`
  query getSegmentsTree {
    segments {
      ...SegmentData
      subSegments {
        ...SubSegmentData
      }
    }
  }
  ${SEGMENT_FRAGMENT}
  ${SUB_SEGMENT_FRAGMENT}
`;

export type SegmentTreeWithMembers = Segment & {
  subSegments: Array<SubSegmentWithMembers>;
};
export type SegmentTreeWithMembersList = {
  segments: Array<SegmentTreeWithMembers>;
};
export type SegmentTreeWithMembersListData = DataValue<
  SegmentTreeWithMembersList
>;
export const GET_SEGMENTS_TREE_WITH_MEMBERS = gql`
  query getSegmentsTreeWithMembers {
    segments {
      ...SegmentData
      subSegments {
        ...SubSegmentData
        members {
          ...MemberData
        }
      }
    }
  }
  ${SEGMENT_FRAGMENT}
  ${SUB_SEGMENT_FRAGMENT}
  ${MEMBERS_FRAGMENT}
`;
