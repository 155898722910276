import React from 'react';
import { Redirect } from 'react-router';
import { NAVIGATION_ITEMS } from '../components/Navbar/navbarUtils';

function getFirstNav(filteredRoutes: Array<any>) {
  for (const { route } of NAVIGATION_ITEMS) {
    if (filteredRoutes.find(({ path }) => path === route)) {
      return route;
    }
  }
  return '/settings';
}

export default function Dashboard(props: any) {
  const { filteredRoutes } = props;
  if (filteredRoutes.length) {
    const firstNav = getFirstNav(filteredRoutes);
    return <Redirect from="/" to="/settings" />;
  }
  return null;
}
