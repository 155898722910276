import React from 'react';
import { graphql, NamedProps, MutationFn } from 'react-apollo';
import { SearchField } from '.';

import { UPDATE_SEARCH_STATE } from '../graphql/queries';

type UpdateSearchStateResponse = { updateSearchState: MutationFn };

type OwnProps = {
  onChangeText: (text: string) => void;
  value: string;
  placeholder?: string;
};

type Props = OwnProps & {
  updateSearchState?: MutationFn;
};

function StatefulSearchField(props: Props) {
  const { updateSearchState, onChangeText, ...otherProps } = props;
  const _onChangeText = (text: string) => {
    onChangeText(text);
    if (updateSearchState) {
      updateSearchState({
        variables: {
          searchedString: text,
        },
      });
    }
  };
  return <SearchField onChangeText={_onChangeText} {...otherProps} />;
}

export default graphql<OwnProps, UpdateSearchStateResponse, {}, Props>(
  UPDATE_SEARCH_STATE,
  {
    name: 'updateSearchState',
    props: ({
      updateSearchState,
      ownProps,
    }: NamedProps<{ updateSearchState?: MutationFn }, OwnProps>) => ({
      ...ownProps,
      ...(updateSearchState ? { updateSearchState } : {}),
    }),
  },
)(StatefulSearchField);
