import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class CustomerServiceScene extends Component<RouteComponentProps> {
  componentDidMount() {
    // window.open('https://tviptest.zendesk.com/', '_blank');
    window.open('https://wa.me/628170776680/?text=', '_blank');
    this.props.history.goBack();
  }

  render() {
    return null;
  }
}

export default withRouter(CustomerServiceScene);
