import { ApolloCache } from 'apollo-cache';

import { GET_MODAL_SELECTED_STATE } from '../queries';
import { ModalState } from '../localState';

type SelectedParams = {
  selectedArray: Array<any>;
};

export const updateModalState = (
  _: ObjectKey,
  { selectedArray }: SelectedParams,
  { cache }: { cache: ApolloCache<any> },
) => {
  const previousState: Nullable<ModalState> = cache.readQuery({
    query: GET_MODAL_SELECTED_STATE,
  });
  if (previousState) {
    const data: ModalState = {
      modalState: {
        ...previousState.modalState,
        selectedArray,
      },
    };
    cache.writeQuery({ query: GET_MODAL_SELECTED_STATE, data });
  }
  return null;
};
