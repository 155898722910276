import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import { DARK_GRAY, GRAY, GRAY70 } from '../../../constants/colors';
import { CurrencyField, IncrementField, Modal, Text } from '../../../core-ui';
import { GiftType, ProgramUser, User } from '../../../graphql/queries';

export type LoyaltyTerm = {
  id: string;
  customer: User;
  minimalPurchase: number;
  rewardQty: number;
  programTermId?: string;
  state?: 'unchanged' | 'new' | 'modified';
};

type Props = {
  loyaltyType: Nullable<'CASHBACK' | 'TVIP_PRODUCT' | GiftType>;
  selectedTerms: Array<LoyaltyTerm>;
  selectedCustomers: Array<ProgramUser>;
  onClose: () => void;
  onChangeSelected: (selectedTerms: Array<LoyaltyTerm>) => void;
};

type State = {
  termsList: Array<LoyaltyTerm>;
};

export class LoyaltyTermsModal extends Component<Props> {
  state = {
    termsList: this.props.selectedTerms,
  };

  componentDidMount() {
    const { selectedCustomers, selectedTerms } = this.props;
    const newSelectedTerms = selectedCustomers.map((customer) => {
      const customerTerm = selectedTerms.filter(
        (term) => term.customer.id === customer.user.id,
      );
      if (customerTerm.length > 0) {
        return customerTerm[0];
      }
      return {
        id: '',
        programTermId: '',
        customer: customer.user,
        rewardQty: 0,
        minimalPurchase: 0,
        state: 'new',
      };
    });
    this.setState({ termsList: newSelectedTerms });
  }

  render() {
    const { onClose, loyaltyType } = this.props;
    const { termsList } = this.state;

    return (
      loyaltyType && (
        <Modal
          maxHeight
          title="Tentukan Syarat"
          isVisible
          onClose={onClose}
          buttonText="Simpan"
          onSubmit={() => this._onSubmit()}
        >
          <Text color={DARK_GRAY} style={styles.bottomPadding}>
            Tentukan syarat-syarat yang berlaku untuk mendapatkan program ini.
          </Text>
          {termsList.map((term, index) => (
            <TermInput
              key={index}
              term={term}
              loyaltyType={loyaltyType}
              onUpdate={(newTermValue) => this._updateTerm(index, newTermValue)}
            />
          ))}
        </Modal>
      )
    );
  }

  _onChangeText = <T extends keyof State>(key: T, value: State[T]) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _updateTerm = (
    selectedIndex: number,
    newTermValue: Pick<LoyaltyTerm, 'minimalPurchase' | 'rewardQty'>,
  ) => {
    this.setState((prevState: State) => {
      const { termsList } = prevState;
      const newTermsList = termsList.map(({ state, ...term }, index) =>
        index === selectedIndex
          ? {
              ...term,
              ...newTermValue,
              state: state === 'unchanged' ? 'modified' : state,
            }
          : {
              ...term,
              state,
            },
      );
      return {
        termsList: newTermsList,
      };
    });
  };

  _onSubmit = () => {
    const { onChangeSelected, onClose } = this.props;
    const { termsList } = this.state;
    onChangeSelected(termsList);
    onClose();
  };
}

type LoyaltyTermInputProps = {
  term: LoyaltyTerm;
  loyaltyType: Nullable<'CASHBACK' | 'TVIP_PRODUCT' | GiftType>;
  onUpdate: (
    newTermValue: Pick<LoyaltyTerm, 'minimalPurchase' | 'rewardQty'>,
  ) => void;
};

type LoyaltyTermInputState = {
  minimalPurchase: number;
  rewardQty: number;
};

class TermInput extends Component<
  LoyaltyTermInputProps,
  LoyaltyTermInputState
> {
  state = {
    minimalPurchase: this.props.term.minimalPurchase,
    rewardQty: this.props.term.rewardQty,
  };

  render() {
    const { term, loyaltyType } = this.props;
    const { minimalPurchase, rewardQty } = this.state;
    return (
      loyaltyType && (
        <View style={styles.inputRoot}>
          <Text weight="bold" style={styles.title}>
            {term.customer.storeName}
          </Text>
          <View style={styles.rowContainer}>
            <View style={[styles.flexOne, { paddingRight: 10 }]}>
              <Text size="small" style={styles.label}>
                Cluster
              </Text>
              <View style={styles.centerTextContainer}>
                <Text size="small">
                  {term.customer.cluster
                    ? term.customer.cluster.currentCluster
                    : '-'}
                </Text>
              </View>
            </View>
            <View style={[styles.flexOne, { paddingHorizontal: 5 }]}>
              <IncrementField
                stretch
                label="Target (Unit)"
                value={minimalPurchase}
                onChangeValue={(value) =>
                  this._onChangeText('minimalPurchase', value)
                }
                onButtonPress={(value) =>
                  this.setState({ minimalPurchase: value }, () =>
                    this._onBlur(),
                  )
                }
                onBlur={this._onBlur}
                style={{ width: 150 }}
              />
            </View>
            <View style={[styles.flexOne, { paddingLeft: 10 }]}>
              {loyaltyType === 'CASHBACK' ? (
                <CurrencyField
                  stretch
                  label="Cashback/Unit"
                  currencyValue={rewardQty}
                  onCurrencyChange={(value) =>
                    this._onChangeText('rewardQty', value)
                  }
                  onBlur={this._onBlur}
                />
              ) : (
                <IncrementField
                  stretch
                  label={
                    loyaltyType === 'GOLD' ? 'Gram' : 'Jumlah Hadiah (Unit)'
                  }
                  value={rewardQty}
                  onChangeValue={(value) =>
                    this._onChangeText('rewardQty', value)
                  }
                  onButtonPress={(value) =>
                    this.setState({ rewardQty: value }, () => this._onBlur())
                  }
                  onBlur={this._onBlur}
                  style={{ width: 150 }}
                />
              )}
            </View>
          </View>
        </View>
      )
    );
  }

  _onChangeText = <T extends keyof LoyaltyTermInputState>(
    key: T,
    value: LoyaltyTermInputState[T],
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _onBlur = () => {
    const { minimalPurchase, rewardQty } = this.state;
    const { onUpdate } = this.props;
    onUpdate({ minimalPurchase, rewardQty });
  };
}

const styles = StyleSheet.create({
  bottomPadding: { paddingBottom: 30 },
  padding: { padding: 40 },
  dropdown: {
    marginBottom: 0,
    zIndex: 2,
  },
  button: {
    marginTop: 50,
    alignSelf: 'flex-end',
  },
  addButton: {
    alignSelf: 'flex-start',
    borderWidth: 0,
  },
  flexOne: { flex: 1 },
  halfFlex: { flex: 0.5 },
  inputRoot: {
    paddingBottom: 25,
    marginBottom: 25,
    borderBottomColor: GRAY,
    borderBottomWidth: 2,
    borderBottomStyle: 'dashed',
  },
  title: {
    paddingBottom: 10,
  },
  label: {
    paddingBottom: 10,
    color: GRAY70,
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  centerTextContainer: { flex: 1, justifyContent: 'center' },
});
