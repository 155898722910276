import { DataValue } from 'react-apollo';

type Params<T> = {
  query: string;
  variables: T;
  dataKey: string;
};

export default function fetchMoreItems<
  TData extends ObjectKey = {},
  TVariables extends ObjectKey = {}
>(
  fetchMoreFn: (args: any) => void,
  { query, variables, dataKey }: Params<TVariables>,
) {
  fetchMoreFn({
    query,
    variables,
    updateQuery: (
      prev: DataValue<TData>,
      { fetchMoreResult }: { fetchMoreResult: DataValue<TData, {}> },
    ) => {
      if (!fetchMoreResult) {
        return prev;
      }

      const items = prev[dataKey] || [];
      const nextItems = fetchMoreResult[dataKey] || [];
      return {
        ...prev,
        [dataKey]: [...items, ...nextItems],
      };
    },
  });
}
