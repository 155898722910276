import React, { Component, createRef, RefObject } from 'react';
import {
  View,
  StyleSheet,
  TextInput,
  StyleProp,
  TextStyle,
  TextInputProps,
  ViewStyle,
} from 'react-native';

import { Text } from '.';
import { WHITE, INPUT } from '../constants/colors';

export type TextFieldProps = TextInputProps & {
  childrenPosition?: 'left' | 'right';
  error?: Nullable<string>;
  disabled?: boolean;
  forwardedRef?: RefObject<TextInput>;
  label?: string;
  labelHorizontal?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  onBlur?: () => any;
  onFocus?: () => any;
  stretch?: boolean;
  showErrorMessage?: boolean;
  style?: {
    children?: StyleProp<ViewStyle>;
    textField?: StyleProp<TextStyle>;
    container?: StyleProp<ViewStyle>;
    inputContainer?: StyleProp<ViewStyle>;
  };
};

type State = {
  isFocus: boolean;
};

export default class TextField extends Component<TextFieldProps, State> {
  state = {
    isFocus: false,
  };

  _focus = () => {
    this.setState(
      { isFocus: true },
      this.props.onFocus && this.props.onFocus(),
    );
  };

  _blur = () => {
    this.setState({ isFocus: false }, this.props.onBlur && this.props.onBlur());
  };

  render() {
    const {
      children,
      childrenPosition,
      disabled,
      editable,
      label,
      labelHorizontal,
      labelStyle,
      showErrorMessage = true,
      error,
      multiline,
      numberOfLines,
      onBlur,
      onFocus,
      stretch,
      style,
      value,
      forwardedRef,
      ...otherProps
    } = this.props;

    const textFieldStyle = [
      !disabled && styles.textFieldStyle,
      labelHorizontal && { flex: 2 },
      children ? styles.row : {},
      this.state.isFocus && editable !== false && styles.focus,
      error ? { borderColor: INPUT.ERROR } : {},
    ];
    const childrenStyle = [
      styles.childrenStyle,
      style && style.children,
      childrenPosition === 'left' && styles.noRightPadding,
      childrenPosition === 'right' && styles.noLeftPadding,
    ];

    const wrappedChildren = <View style={childrenStyle}>{children}</View>;
    const textInput = (
      <View style={styles.textFieldWrapper}>
        <TextInput
          ref={forwardedRef || createRef()}
          editable={editable}
          multiline={multiline}
          numberOfLines={numberOfLines || 5}
          placeholderTextColor={INPUT.PLACEHOLDER}
          style={[
            styles.textInput,
            editable === false && styles.disabledText,
            style && style.textField,
          ]}
          onFocus={this._focus}
          onBlur={this._blur}
          value={value || ''}
          {...otherProps}
        />
      </View>
    );
    const inputComponent =
      childrenPosition === 'left' ? (
        <View style={[textFieldStyle, style && style.inputContainer]}>
          {children && !multiline && wrappedChildren}
          {textInput}
        </View>
      ) : (
        <View style={[textFieldStyle, style && style.inputContainer]}>
          {textInput}
          {children && !multiline && wrappedChildren}
        </View>
      );
    const labelComponent = (
      <View
        style={[styles.labelWrapper, labelHorizontal && styles.labelHorizontal]}
      >
        <Text
          size="small"
          style={[
            styles.label,
            labelStyle,
            error ? { color: INPUT.ERROR } : null,
          ]}
        >
          {label}
        </Text>
      </View>
    );
    const errorText = showErrorMessage && (
      <Text
        size="xsmall"
        weight="light"
        style={styles.error}
        data-testid="inputErrorText"
      >
        {error}
      </Text>
    );

    return (
      <View
        style={[
          !stretch && { alignItems: 'flex-start' },
          labelHorizontal && { marginBottom: 32 },
          style && style.container,
        ]}
      >
        <View style={labelHorizontal && styles.row}>
          {label && labelComponent}
          {inputComponent}
        </View>
        {error && errorText}
      </View>
    );
  }
}

const PADDING = 10;
const PADDING_HORIZONTAL = 10;

let styles = StyleSheet.create({
  labelWrapper: {
    paddingBottom: 10,
  },
  textFieldWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  textFieldStyle: {
    backgroundColor: INPUT.BACKGROUND,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: INPUT.BORDER,
  },
  childrenStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: PADDING,
    paddingHorizontal: PADDING_HORIZONTAL,
  },
  textInput: {
    outline: 'none',
    paddingVertical: PADDING,
    paddingHorizontal: PADDING_HORIZONTAL,
    fontFamily: 'Rubik-Regular',
  },
  disabledText: {
    color: INPUT.TEXT_DISABLED,
  },
  noRightPadding: {
    paddingRight: 0,
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
  focus: {
    backgroundColor: WHITE,
    borderColor: INPUT.FOCUSED,
    borderWidth: 1,
  },
  label: {
    color: INPUT.LABEL,
  },
  labelHorizontal: {
    flex: 1,
    paddingVertical: 8,
  },
  error: {
    color: INPUT.ERROR,
    paddingTop: 5,
    fontStyle: 'italic',
  },
});
