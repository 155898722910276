import { ClickAwayListener } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MutationFn, Subscription, compose, graphql } from 'react-apollo';
import {
  FlatList,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { logo1, logo2 } from '../../assets';
import {
  DARK_GRAY,
  GRAY,
  PRIMARY,
  RED_MARKER,
  WHITE,
} from '../../constants/colors';
import { Icon, Loading, Modal, Text } from '../../core-ui';
import { client } from '../../graphql/client';
import {
  GET_NAVBAR_COUNTER,
  GET_NEW_ASSIGNMENT_NOTIFICATION,
  GET_NEW_ORDER_NOTIFICATION,
  GET_NEW_REJECTED_ORDER_NOTIFICATION,
  IS_CMS_AUTHENTICATED,
  PageAccess,
  USER_LOGOUT,
} from '../../graphql/queries';
import { asyncStorage } from '../../helpers';
import { getPageAccess } from '../../routes/PageAccess';
import { RouteItem } from '../../routes/routeUtils';
import Query from '../Query';
import { NAVIGATION_ITEMS } from './navbarUtils';

export type NavigationMenu = {
  logo: IconKey;
  label: string;
  route: string;
};

type NavbarProps = RouteComponentProps & {
  routes: Array<RouteItem & { access: PageAccess }>;
  pageAccesses: Array<PageAccess>;
  role: string;
  isFormOpened: boolean;
  userLogout?: MutationFn;
};

export function Navbar({
  location,
  routes,
  pageAccesses,
  role,
  history,
  isFormOpened,
  userLogout,
}: NavbarProps) {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(location.pathname);
  const [token, setToken] = useState('');

  const navigationListWithPageAccess = useMemo(() => {
    return NAVIGATION_ITEMS.filter(
      (nav) => routes.findIndex(({ path }) => path === nav.route) !== -1,
    )
      .map((nav) => ({
        ...nav,
        ...getPageAccess(pageAccesses, role, nav.label),
      }))
      .filter(
        ({ label, read }) =>
          read ||
          (label === 'Pemesanan' &&
            getPageAccess(pageAccesses, role, 'Jaminan Botol').read),
      );
  }, [pageAccesses, role, routes]);

  const navigateTo = (route?: string, params?: ObjectKey) => {
    history.push(route || routeToNavigate, params);
  };

  const onModalSubmit = () => {
    setIsModalVisible(false);
    navigateTo();
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  const onNavbarClickAway = () => {
    if (isDrawerOpened) {
      setIsDrawerOpened((prevOpened) => !prevOpened);
    }
  };

  const expandDrawer = () => {
    setIsDrawerOpened((prevOpened) => !prevOpened);
  };

  const handleOnClick = (route: string) => {
    if (isFormOpened) {
      setIsModalVisible(true);
    } else {
      navigateTo(route);
    }
  };

  const onNavItemClick = (route: string) => {
    setRouteToNavigate(route);
    handleOnClick(route);
  };

  const renderFooterItem = useCallback(
    (text: string, iconName: IconKey, action: () => void, route?: string) => {
      const path = isModalVisible ? window.location.pathname : routeToNavigate;
      const isCurrentlySelected = route && path.startsWith(route);
      const selectedColor = isCurrentlySelected ? PRIMARY : DARK_GRAY;
      return (
        <TouchableOpacity onPress={action} style={{ flexDirection: 'row' }}>
          <View style={[styles.menuItem, styles.footerItem]}>
            <Icon
              name={iconName}
              color={selectedColor}
              style={{
                marginRight: 20,
              }}
            />
            <Text
              weight="bold"
              size="small"
              color={selectedColor}
              style={[styles.text, isDrawerOpened && styles.textTransition]}
            >
              {text}
            </Text>
          </View>
        </TouchableOpacity>
      );
    },
    [isDrawerOpened, isModalVisible, routeToNavigate],
  );

  const handleLogout = async () => {
    await userLogout?.();
    await client.query({ query: IS_CMS_AUTHENTICATED });
    navigateTo('/');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      const navRoutes = navigationListWithPageAccess;
      setRouteToNavigate(navRoutes[0].route);
    }
  }, [location.pathname, navigationListWithPageAccess]);

  useEffect(() => {
    (async () => {
      const token = await asyncStorage.getToken();
      setToken(token || '');
    })();
  }, []);

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        buttonText="OK"
        title="Abaikan Perubahan?"
        description="Apakah anda yakin ingin pindah ke halaman lain? Semua perubahan anda tidak akan disimpan."
        onSubmit={onModalSubmit}
        onClose={onModalClose}
      />
      <ClickAwayListener onClickAway={onNavbarClickAway}>
        <View style={[styles.root, isDrawerOpened && styles.rootOpened]}>
          <View>
            {isDrawerOpened ? (
              <View style={{ flexDirection: 'row' }}>
                <Icon
                  name="close"
                  color={DARK_GRAY}
                  style={
                    isDrawerOpened
                      ? styles.arrowIconOpened
                      : styles.arrowIconClosed
                  }
                  onPress={expandDrawer}
                />
                {/* TODO: remove link when we don't need it anymore */}
                <Link to="/">
                  <Image
                    source={{ uri: logo1 }}
                    style={styles.logo}
                    resizeMode="contain"
                  />
                </Link>
                <Image
                  source={{ uri: logo2 }}
                  style={[styles.logo, styles.logo2]}
                  resizeMode="contain"
                />
              </View>
            ) : (
              <Icon
                name="menu"
                color={DARK_GRAY}
                style={
                  isDrawerOpened ? styles.menuIconOpened : styles.menuIconClosed
                }
                onPress={expandDrawer}
              />
            )}
          </View>
          <FlatList
            data={navigationListWithPageAccess}
            extraData={{
              isDrawerOpened,
              isModalVisible,
              routeToNavigate,
            }}
            keyExtractor={(item) => item.label}
            renderItem={({ item }) => {
              const { logo, label, route } = item;
              const path = isModalVisible ? location.pathname : routeToNavigate;
              const isCurrentlySelected = path.startsWith(route);
              const selectedColor = isCurrentlySelected ? PRIMARY : DARK_GRAY;
              return (
                <TouchableOpacity
                  onPress={() => onNavItemClick(route)}
                  accessibilityRole="button"
                >
                  <Query<{ orderCount: number; assignmentCount: number }>
                    query={GET_NAVBAR_COUNTER}
                    disableLoading
                    variables={{
                      whereData: '',
                      whereDepot: '',
                      whereSubDistrict: '',
                      whereStatus: ['WAITING'],
                    }}
                  >
                    {({ data, refetch, loading }) => {
                      const renderCounter = (route: string) => {
                        switch (route) {
                          case '/purchase-orders':
                            return data?.orderCount || 0;
                          default:
                            return data?.assignmentCount || 0;
                        }
                      };

                      return (
                        <>
                          <Subscription
                            subscription={GET_NEW_ORDER_NOTIFICATION}
                            onSubscriptionData={() => refetch()}
                            variables={{
                              token: role === 'SUPER_ADMIN' ? '' : token,
                            }}
                          />
                          <Subscription
                            subscription={GET_NEW_REJECTED_ORDER_NOTIFICATION}
                            onSubscriptionData={() => refetch()}
                          />
                          <Subscription
                            subscription={GET_NEW_ASSIGNMENT_NOTIFICATION}
                            onSubscriptionData={() => refetch()}
                          />
                          <View style={styles.menuItem}>
                            <View
                              style={{
                                position: 'relative',
                              }}
                            >
                              <Icon
                                isCustomSVG
                                name={logo}
                                color={selectedColor}
                                size="xlarge"
                                style={styles.menuIcon}
                              />
                              {route === '/purchase-orders' ||
                              route === '/assignment'
                                ? !isDrawerOpened &&
                                  renderCounter(route) > 0 && (
                                    <View
                                      style={{
                                        width: 20,
                                        height: 20,
                                        top: 0,
                                        borderRadius: 9999,
                                        backgroundColor: RED_MARKER,
                                        position: 'absolute',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        right: 10,
                                      }}
                                    >
                                      {loading ? (
                                        <Loading size="small" />
                                      ) : (
                                        <Text
                                          style={{
                                            fontSize:
                                              renderCounter(route) >= 100
                                                ? 10
                                                : 12,
                                          }}
                                          color="white"
                                        >
                                          {renderCounter(route)}
                                        </Text>
                                      )}
                                    </View>
                                  )
                                : null}
                            </View>
                            <Text
                              weight="bold"
                              size="small"
                              color={selectedColor}
                              style={[
                                styles.text,
                                isDrawerOpened && styles.textTransition,
                              ]}
                            >
                              {route === '/purchase-orders' ||
                              route === '/assignment' ? (
                                <View
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Text>{label}</Text>
                                  {renderCounter(route) > 0 && (
                                    <View
                                      style={{
                                        paddingVertical: 4,
                                        paddingHorizontal: 8,
                                        backgroundColor: RED_MARKER,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 999999,
                                        marginLeft: 5,
                                      }}
                                    >
                                      <Text color="white" size="small">
                                        {renderCounter(route)}
                                      </Text>
                                    </View>
                                  )}
                                </View>
                              ) : (
                                label
                              )}
                            </Text>
                          </View>
                        </>
                      );
                    }}
                  </Query>
                </TouchableOpacity>
              );
            }}
            ListFooterComponent={
              <View>
                <View style={styles.separatorFooter} />
                {renderFooterItem(
                  'Pengaturan',
                  'settings',
                  () => onNavItemClick('/settings'),
                  '/settings',
                )}
                {renderFooterItem('Keluar', 'input', handleLogout)}
              </View>
            }
          />
        </View>
      </ClickAwayListener>
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    backgroundColor: WHITE,
    borderColor: GRAY,
    borderWidth: 0.3,
    height: '100%',
    overflow: 'hidden',
    width: 80,
    boxShadow: `1px 5px 15px ${DARK_GRAY}`,
    transitionProperty: 'width',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
  },
  rootOpened: {
    width: 240,
    transitionProperty: 'width',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'ease',
  },
  text: {
    transitionProperty: 'opacity',
    transitionDuration: '0.2s',
    opacity: 0,
  },
  menuIconOpened: {
    transitionProperty: 'opacity',
    transitionDuration: '0.1s',
    opacity: 0,
    paddingLeft: 25,
    paddingTop: 30,
    marginBottom: 30,
  },
  menuIconClosed: {
    transitionProperty: 'opacity',
    transitionDuration: '0.2s',
    opacity: 1,
    paddingTop: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  arrowIconOpened: {
    transitionProperty: 'margin',
    transitionTimingFunction: 'ease-out',
    transitionDuration: '0.2s',
    opacity: 1,
    paddingLeft: 25,
    paddingTop: 30,
    marginBottom: 30,
  },
  arrowIconClosed: {
    transitionProperty: 'opacity, margin',
    transitionDuration: '0.2s, 0.2s',
    transitionTimingFunction: 'ease',
    marginLeft: 0,
    opacity: 0,
    paddingLeft: 30,
    paddingTop: 30,
    marginBottom: 30,
  },
  textTransition: {
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
    opacity: 1,
  },
  logo: {
    width: 60,
    height: 62,
    marginLeft: 15,
    marginTop: 15,
  },
  logo2: {
    marginLeft: 10,
  },
  menuItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 6,
  },
  menuIcon: { marginRight: 30, outline: 'none' },
  separatorFooter: {
    borderWidth: 0.5,
    borderColor: GRAY,
    marginHorizontal: 15,
  },
  footerItem: {
    height: 64,
    marginLeft: 15,
  },
});

export default compose(
  withRouter,
  graphql(USER_LOGOUT, { name: 'userLogout' }),
)(Navbar);
