import React from 'react';
import { graphql } from 'react-apollo';

import { GET_CITY_NAME_LIST, CityNamelListData } from '../../graphql/queries';

import { ChosenProps, Picker } from '.';

type LocationParams = {
  province?: Nullable<string>;
};

type CitiesListProps = { citiesQuery: CityNamelListData };
type OwnProps = { isFilter?: boolean; disabled?: boolean } & ChosenProps &
  LocationParams;
type CitiesPickerProps = CitiesListProps & OwnProps;

function CitiesPicker(props: CitiesPickerProps) {
  const { disabled, citiesQuery, style, ...otherProps } = props;
  return (
    <Picker
      label="Kota"
      placeholder="Kota"
      style={style}
      isLoading={citiesQuery.loading}
      options={
        disabled || !(citiesQuery && citiesQuery.cities)
          ? []
          : citiesQuery.cities.map(({ id, name }) => ({
              value: id,
              label: name.toUpperCase(),
            }))
      }
      {...otherProps}
    />
  );
}

export default graphql<
  OwnProps,
  CitiesListProps,
  Record<string, unknown>,
  CitiesListProps
>(GET_CITY_NAME_LIST, {
  name: 'citiesQuery',
  options: ({ province }) => ({
    variables: province ? { province } : {},
  }),
})(CitiesPicker);
