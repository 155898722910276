import React, { ComponentClass, useEffect, useState } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { Query } from '../../../components';
import { DARK_GRAY } from '../../../constants/colors';
import { Modal, Separator, Text } from '../../../core-ui';
import { ModalState } from '../../../graphql/localState';
import {
  GET_MODAL_SELECTED_STATE,
  GET_PRODUCT_LIST,
  GetProductListResult,
  GetProductListVariables,
  Product,
  UPDATE_MODAL_SELECTED,
} from '../../../graphql/queries';

import { InputField } from '../components';
import SearchField from '../components/SearchField';
import Table from '../components/Table';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

export type BundleProduct = {
  programTermId?: string;
  id?: string;
  product: Product;
  rewardQty: number;
  minimalPurchase: number;
  state?: 'new' | 'modified' | 'unchanged';
};

type OwnProps = {
  selectedProducts: Array<BundleProduct>;
  onClose: () => void;
  onChangeSelected: (selectedProducts: Array<BundleProduct>) => void;
  isVisible: boolean;
};

type Props = OwnProps & UpdateSelectedData & SelectedStateProps;

function BundlingProductSelectionModalComponent({
  isVisible,
  onClose,
  onChangeSelected,
  selectedStateQuery: { modalState },
  updateMultiTable,
  selectedProducts: initialProducts,
}: Props) {
  const [selectedProducts, setSelectedProducts] = useState<BundleProduct[]>(
    initialProducts,
  );
  const [searchKey, setSearchKey] = useState('');
  const [resetPage, setResetPage] = useState(false);

  const selectedUom =
    modalState && modalState.selectedArray.length > 0
      ? modalState.selectedArray[0].uom
      : undefined;

  const onSubmit = () => {
    if (modalState && modalState.selectedArray) {
      const newSelectedProducts: Array<BundleProduct> = [];
      modalState.selectedArray.forEach((datum) => {
        const isSelected = selectedProducts.filter(
          (bundleProduct) => bundleProduct.product.id === datum.id,
        );
        if (isSelected.length === 0) {
          const { rewardQty, minimalPurchase, state, ...product } = datum;
          newSelectedProducts.push({
            product,
            minimalPurchase: 0,
            rewardQty: 0,
            state,
          });
        } else {
          newSelectedProducts.push({ ...isSelected[0] });
        }
      });
      onChangeSelected(newSelectedProducts);
      onClose();
    }
  };

  const onSearch = (text: string) => {
    setSearchKey(text);
  };

  const onUpdateValue = (
    newProduct: Product,
    newValue: number,
    type: 'minimalPurchase' | 'rewardQty',
  ) => {
    setSelectedProducts((prevState) => {
      const selectedProducts = prevState;
      let newSelected = [...selectedProducts];
      if (
        selectedProducts.filter(
          (bundleProduct) => bundleProduct.product.id === newProduct.id,
        ).length > 0
      ) {
        if (type === 'minimalPurchase') {
          newSelected = selectedProducts.map(({ state, ...bundleProduct }) =>
            bundleProduct.product.id === newProduct.id
              ? {
                  ...bundleProduct,
                  minimalPurchase: newValue,
                  state: state === 'unchanged' ? 'modified' : state,
                }
              : { ...bundleProduct, state },
          );
        } else {
          newSelected = selectedProducts.map(({ state, ...bundleProduct }) =>
            bundleProduct.product.id === newProduct.id
              ? {
                  ...bundleProduct,
                  rewardQty: newValue,
                  state: state === 'unchanged' ? 'modified' : state,
                }
              : { ...bundleProduct, state },
          );
        }
      } else if (type === 'minimalPurchase') {
        newSelected.push({
          product: newProduct,
          minimalPurchase: newValue,
          rewardQty: 0,
          state: 'new',
        });
      } else {
        newSelected.push({
          product: newProduct,
          minimalPurchase: 0,
          rewardQty: newValue,
          state: 'new',
        });
      }
      return newSelected;
    });
  };

  useEffect(() => {
    updateMultiTable({
      variables: {
        selectedArray: selectedProducts
          ? selectedProducts.map((product) => product.product)
          : [],
      },
    });

    return () => {
      // Remove selected state
      updateMultiTable({
        variables: {
          selectedArray: [],
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMultiTable]);

  return (
    <Modal
      maxHeight
      isVisible={isVisible}
      title="Pilih Produk"
      onClose={onClose}
      buttonText="Pilih"
      onSubmit={onSubmit}
    >
      <View>
        <Text color={DARK_GRAY} style={styles.paddingBottom}>
          Tentukan produk yang dapat dibeli menggunakan jenis program ini.
        </Text>
        <SearchField
          placeholder="Cari produk..."
          value={searchKey}
          onChangeText={onSearch}
        />
        <Separator style={styles.separator} />
        <Query<GetProductListResult, GetProductListVariables>
          query={GET_PRODUCT_LIST}
          variables={{
            where: {
              searchKeyword: searchKey.toLowerCase(),
              uom: selectedUom,
            },
            first: 100,
            skip: 0,
          }}
          keyData="products"
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ data, loading }) => {
            if (data && data.products) {
              const { products } = data;
              const displayProducts = products.map((product: Product) => {
                const isSelected = selectedProducts.filter(
                  (bundleProduct) => bundleProduct.product.id === product.id,
                );
                if (isSelected.length > 0) {
                  return {
                    ...product,
                    rewardQty: isSelected[0].rewardQty,
                    minimalPurchase: isSelected[0].minimalPurchase,
                  };
                }
                return { ...product, rewardQty: 0, minimalPurchase: 0 };
              });

              return (
                <Table
                  resetPage={resetPage}
                  setResetPage={(isReset) => setResetPage(isReset)}
                  isLoading={loading}
                  showCheckboxes
                  searchKey={searchKey}
                  data={displayProducts}
                  dataCount={data.count}
                  hidePagination
                  rowPerPage={displayProducts.length}
                  hideSelectAll
                  structure={{
                    title: {
                      headerTitle: 'Nama Produk',
                    },
                    rewardQty: {
                      headerTitle: 'Potongan Harga/Unit',
                      render: (datum, _, selected) => (
                        <InputField
                          initialValue={datum.rewardQty}
                          selected={selected}
                          type="currency"
                          onUpdate={(value) => {
                            onUpdateValue(datum as Product, value, 'rewardQty');
                          }}
                        />
                      ),
                    },
                    unit: {
                      headerTitle: 'Jumlah (Unit)',
                      render: (datum, _, selected) => (
                        <InputField
                          initialValue={datum.minimalPurchase}
                          selected={selected}
                          type="increment"
                          onUpdate={(value) =>
                            onUpdateValue(
                              datum as Product,
                              value,
                              'minimalPurchase',
                            )
                          }
                        />
                      ),
                    },
                  }}
                  loadMore={() => {}}
                />
              );
            }
            return null;
          }}
        </Query>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingBottom: { paddingBottom: 20 },
  separator: { marginVertical: 30 },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
)(BundlingProductSelectionModalComponent) as ComponentClass<OwnProps>;
