import React, { Fragment, useMemo, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  StyleProp,
  ViewStyle,
  TextStyle,
  LayoutChangeEvent,
  FlatList,
} from 'react-native';

import { Text, Icon, Loading } from '.';
import { WHITE, BLACK, DROPDOWN, DARK_GRAY, GRAY } from '../constants/colors';
import BOX_SHADOW from '../constants/boxShadow';
import { useOutsideClick } from '../hooks';

export type Option<T = string> = { value: T; label: string };

export type DropdownProps<T = string> = {
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  options: Array<Option<T>>;
  placeholder: string;
  selectedOption: Nullable<T>;
  searchable?: boolean;
  label?: string;
  labelHorizontal?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  isLoading?: boolean;
  onChange: (selectedOption: Nullable<Option<T>>) => void;
};

export function Dropdown<TData>({
  labelHorizontal,
  style,
  isLoading,
  searchable,
  label,
  labelStyle,
  backgroundColor,
  placeholder,
  onChange,
  selectedOption,
  options,
  ...otherProps
}: DropdownProps<TData>) {
  const inputRef = useRef<TextInput>(null);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState<Nullable<number>>(null);
  const [inputText, setInputText] = useState('');

  const hasOptions = !!options.length && options.length > 0;

  const wrapperRef = useOutsideClick<View>(() => {
    setIsDropdownOpened(false);
  });

  const onFocus = () => {
    if (inputRef) {
      inputRef.current?.focus();
    }
  };

  const onDropdownPress = () => {
    if (!searchable) {
      onFocus();
    }
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
    if (!isLoading && !isDropdownOpened) {
      setIsDropdownOpened(true);
    }
  };

  const onLayout = (event: LayoutChangeEvent) => {
    setDropdownWidth(event.nativeEvent.layout.width);
  };

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputText.toLowerCase()),
    );
  }, [options, inputText]);

  const displayedValue = useMemo(() => {
    if (selectedOption != null && options.length > 0) {
      const selectedElement = options.find(
        (option) => option.value === selectedOption,
      );
      if (selectedElement) {
        return selectedElement.label;
      }
    }
    return inputText || '';
  }, [inputText, selectedOption, options]);

  const onMouseOver = (index: Nullable<number>) => setHoveredIndex(index);

  const onCloseModal = () => setIsDropdownOpened(false);

  return (
    <View
      style={[
        labelHorizontal && { flexDirection: 'row', marginBottom: 32 },
        style,
      ]}
      ref={wrapperRef}
      {...otherProps}
    >
      {label && (
        <View
          style={
            labelHorizontal
              ? styles.labelWrapperHorizontal
              : styles.labelWrapper
          }
        >
          <Text size="small" style={[styles.label, labelStyle]}>
            {label}
          </Text>
        </View>
      )}
      <View onLayout={onLayout} style={labelHorizontal && { flex: 2 }}>
        <TouchableOpacity onPress={onDropdownPress}>
          {isLoading ? (
            <Loading size="small" style={styles.loading} />
          ) : searchable ? (
            <TextInput
              placeholder={placeholder}
              placeholderTextColor={DROPDOWN.PLACEHOLDER}
              style={[
                styles.textInput,
                backgroundColor ? { backgroundColor } : {},
                isDropdownOpened && {
                  borderWidth: 1,
                  borderColor: DROPDOWN.ACTIVE,
                },
              ]}
              value={displayedValue}
              onChangeText={(text) => {
                onChange(null);
                setInputText(text);
              }}
            />
          ) : (
            <>
              <View
                style={[
                  styles.dropdownButton,
                  backgroundColor ? { backgroundColor } : {},
                  isDropdownOpened && styles.dropdownOpened,
                ]}
              >
                <Text
                  size="small"
                  color={selectedOption != null ? BLACK : DROPDOWN.PLACEHOLDER}
                  numberOfLines={1}
                >
                  {selectedOption != null ? displayedValue : placeholder}
                </Text>
                <Icon
                  name="expand_more"
                  size="small"
                  containerStyle={
                    isDropdownOpened ? styles.iconOpened : styles.iconClosed
                  }
                  color={isDropdownOpened ? DROPDOWN.ACTIVE : DARK_GRAY}
                />
              </View>
              <TextInput
                ref={inputRef}
                style={StyleSheet.flatten(styles.dummyInput)}
              />
            </>
          )}
        </TouchableOpacity>
        {isDropdownOpened && (
          <FlatList
            style={[
              styles.optionsWrapper,
              { width: dropdownWidth },
              searchable &&
                inputText === '' &&
                !hasOptions && { borderWidth: 0 },
            ]}
            data={filteredOptions}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item, index }) => {
              const { label, value } = item;
              const isCurrentlyHovered = index === hoveredIndex;

              return (
                <View
                  key={index}
                  style={[
                    styles.option,
                    isCurrentlyHovered && styles.optionHovered,
                  ]}
                  onMouseDown={() => {
                    onChange(item);
                    onCloseModal();
                  }}
                  onMouseOver={() => onMouseOver(index)}
                  onMouseLeave={() => onMouseOver(null)}
                >
                  <Text size="small" color={value === null ? GRAY : BLACK}>
                    {label}
                  </Text>
                  {value && value === selectedOption && (
                    <Icon name="check" size="small" color={DROPDOWN.ACTIVE} />
                  )}
                </View>
              );
            }}
            ListEmptyComponent={
              <View style={styles.option}>
                <Text size="small" color={BLACK}>
                  Data tidak tersedia.
                </Text>
              </View>
            }
          />
        )}
      </View>
    </View>
  );
}

const HEIGHT = 33;

let styles = StyleSheet.create({
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: DROPDOWN.BACKGROUND,
    paddingRight: 5,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT,
  },
  optionsWrapper: {
    position: 'absolute',
    borderRadius: 4,
    borderColor: DROPDOWN.ACTIVE,
    top: 35,
    boxShadow: BOX_SHADOW,
    backgroundColor: WHITE,
    maxHeight: 170,
    borderWidth: 1,
  },
  option: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingVertical: 10,
    cursor: 'pointer',
  },
  optionHovered: {
    backgroundColor: DROPDOWN.HOVERED,
  },
  dummyInput: {
    position: 'relative',
    borderWidth: 0,
    fontSize: 'inherit',
    width: 1,
    height: 0,
    outline: '0',
    padding: 0,
    opacity: 0,
  },
  textInput: {
    backgroundColor: DROPDOWN.BACKGROUND,
    padding: 10,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT,
    outline: '0',
  },
  dropdownOpened: {
    borderColor: DROPDOWN.ACTIVE,
    borderWidth: 1,
  },
  label: { color: DROPDOWN.LABEL },
  iconOpened: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    transform: [{ rotate: '180deg' }],
    transitionProperty: 'transform',
  },
  iconClosed: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease',
    transform: [{ rotate: '0deg' }],
    transitionProperty: 'transform',
  },
  labelWrapper: {
    paddingVertical: 10,
  },
  labelWrapperHorizontal: {
    flex: 1,
    justifyContent: 'center',
  },
  loading: {
    paddingVertical: 6.5,
  },
  removeSelection: {},
});
