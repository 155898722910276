import React, { Component } from 'react';
import { Image, StyleSheet } from 'react-native';

import { Box } from '@material-ui/core';
import { notFound } from '../assets';

export default class NotFoundPage extends Component<{}> {
  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        data-testid="not-found-page"
      >
        <Image
          source={{ uri: notFound }}
          style={styles.image}
          resizeMode="contain"
        />
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  root: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  image: {
    width: '50%',
    height: '50%',
  },
});
