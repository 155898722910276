import React, { Component } from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { Icon, TextField } from '.';
import { GRAY, PRIMARY } from '../constants/colors';
import { formatThousandSeparator, parseInteger } from '../helpers';
import { sanitizeNumericInput } from '../helpers/formatNumber';
import { TextFieldProps } from './TextField';

type Props = Omit<TextFieldProps, 'value'> & {
  value: number;
  onChangeValue?: (value: number) => void;
  onButtonPress?: (value: number) => void;
  style?: StyleProp<ViewStyle>;
  minValue?: number;
  maxValue?: number;
};

const styles = StyleSheet.create({
  children: {
    paddingVertical: 0,
    alignItems: 'center',
    marginHorizontal: 0,
    paddingLeft: 0,
  },
  rotateIcon: { transform: [{ rotate: '90deg' }] },
});

export default class IncrementField extends Component<Props> {
  static defaultProps = {
    editable: true,
  };

  render() {
    const {
      value,
      onChangeValue,
      onButtonPress,
      style: containerStyle,
      editable,
      minValue = 0,
      maxValue,
      ...otherProps
    } = this.props;

    return (
      <TextField
        keyboardType="numbers-and-punctuation"
        onChangeText={(newValue) =>
          this._onChangeText(sanitizeNumericInput(newValue))
        }
        childrenPosition="right"
        style={{
          container: containerStyle,
          children: styles.children,
        }}
        value={formatThousandSeparator(value)}
        editable={editable}
        {...otherProps}
      >
        {editable && (
          <>
            <TouchableOpacity
              onPress={this._incrementValue}
              disabled={value === maxValue}
            >
              <Icon
                name="chevron_left"
                color={value === maxValue ? GRAY : PRIMARY}
                size="xsmall"
                containerStyle={styles.rotateIcon}
                disabled={value === maxValue}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this._decrementValue}
              disabled={value === minValue}
            >
              <Icon
                name="chevron_right"
                color={value === minValue ? GRAY : PRIMARY}
                size="xsmall"
                containerStyle={styles.rotateIcon}
                disabled={value === minValue}
              />
            </TouchableOpacity>
          </>
        )}
      </TextField>
    );
  }

  _onChangeText = (updatedValue: string) => {
    const { onChangeValue } = this.props;
    const newCurrencyValue = parseInteger(updatedValue);
    onChangeValue && onChangeValue(newCurrencyValue);
  };

  _incrementValue = () => {
    const { value, onButtonPress } = this.props;
    onButtonPress && onButtonPress(value + 1);
  };

  _decrementValue = () => {
    const { value, onButtonPress } = this.props;
    onButtonPress && onButtonPress(value - 1);
  };
}
