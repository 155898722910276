import { ApolloCache } from 'apollo-cache';
import { asyncStorage } from '../../helpers';
import { client } from '../client';

import { UserContent } from '../localState';

export const loginSuccess = async (
  _obj: ObjectKey,
  props: UserContent,
  { cache }: { cache: ApolloCache<any> },
) => {
  const { userID, email, name, role, token } = props;
  const data = {
    userState: {
      __typename: 'userState',
      userID,
      email,
      token,
      role,
      name,
    },
  };
  await Promise.all([
    asyncStorage.saveUserID(userID),
    asyncStorage.saveName(name),
    asyncStorage.saveToken(token),
    asyncStorage.saveRole(role),
  ]);
  cache.writeData({ data });
  return null;
};

export const logout = async (_obj: ObjectKey) => {
  await Promise.all([
    asyncStorage.removeUserID(),
    asyncStorage.removeName(),
    asyncStorage.removeToken(),
    asyncStorage.removeRole(),
  ]);
  client.resetStore();
  return null;
};
