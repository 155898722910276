import { Order } from '../../../graphql/queries';

export function calculatePoValue(transaction: Order) {
  let poValue = 0;
  const { transactionItems, bottleGuarantee } = transaction;
  for (const { price, quantity, priceCut } of transactionItems) {
    poValue += (price - priceCut) * quantity;
  }
  if (bottleGuarantee) {
    for (const { pricePerBottle, qty } of bottleGuarantee) {
      poValue += pricePerBottle * qty;
    }
  }
  return poValue;
}
