export type ModalType = 'KTP' | 'REJECT' | 'APPROVE' | 'REVISION' | 'DETAIL';

export type UserKTP = {
  name: string;
  ktpNumber: string;
  ktpUrl: string;
  birthdate: string;
  birthplace: string;
  storeAddress: string;
};

export type User = {
  id: string;
  depotName: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  status: string;
  storeName: string;
  storeAddress: string;
  storeTelephone: string;
  storePhoto: string;
  province: string;
  city: string;
  district: string;
  subDistrict: string;
  postalCode: string;
  map: { latitude: number; longitude: number };
};

export type ModalState = {
  modalType: ModalType;
  ktpData: Nullable<UserKTP>;
  userDetail: Nullable<User>;
  userId: Nullable<string>;
  isOpen: boolean;
  onSuccess: () => void;
};

export type ModalAction =
  | {
      type: 'OPEN_KTP';
      ktpData: Nullable<UserKTP>;
    }
  | {
      type: 'OPEN_REJECT';
      userId: string;
      onSuccess: () => void;
    }
  | {
      type: 'OPEN_REVISION';
      userId: string;
    }
  | {
      type: 'OPEN_APPROVE';
      userId: string;
      onSuccess: () => void;
    }
  | {
      type: 'OPEN_DETAIL';
      userDetail: Nullable<User>;
    }
  | {
      type: 'CLOSE';
    };

function modalReducer(state: ModalState, action: ModalAction): ModalState {
  switch (action.type) {
    case 'OPEN_KTP': {
      return {
        ...state,
        modalType: 'KTP',
        ktpData: action.ktpData,
        userDetail: null,
        isOpen: true,
      };
    }
    case 'OPEN_REJECT': {
      return {
        ...state,
        modalType: 'REJECT',
        userId: action.userId,
        ktpData: null,
        userDetail: null,
        isOpen: true,
        onSuccess: action.onSuccess,
      };
    }
    case 'OPEN_REVISION': {
      return {
        ...state,
        modalType: 'REVISION',
        userId: action.userId,
        ktpData: null,
        userDetail: null,
        isOpen: true,
      };
    }
    case 'OPEN_APPROVE': {
      return {
        ...state,
        modalType: 'APPROVE',
        userId: action.userId,
        ktpData: null,
        userDetail: null,
        isOpen: true,
        onSuccess: action.onSuccess,
      };
    }
    case 'OPEN_DETAIL': {
      return {
        ...state,
        modalType: 'DETAIL',
        ktpData: null,
        userDetail: action.userDetail,
        isOpen: true,
      };
    }
    case 'CLOSE': {
      return {
        ...state,
        ktpData: null,
        userDetail: null,
        isOpen: false,
      };
    }
    default: {
      return state;
    }
  }
}

export { modalReducer };
