import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { RouteComponentProps } from 'react-router';
import { compose, graphql } from 'react-apollo';

import NotFoundPage from '../NotFoundPage';

import { Card, Separator, Text, Icon } from '../../core-ui';
import { FormPage, CheckBoxText, Query } from '../../components';
import { PRIMARY, GRAY30, GRAY80 } from '../../constants/colors';
import { capitalCase } from '../../helpers';
import withToast, { ToastContextProps } from '../../helpers/withToast';

import {
  PageAccess,
  RoleListData,
  GET_ROLES,
  UPDATE_PAGE_ACCESS,
  UpdatePageAccessMutationFn,
  UpdatePageAccessVariable,
  GET_ACCESS_PRIVILEGES,
  PageAccessData,
  AccessProps,
} from '../../graphql/queries/roleQuery';

type MatchParams = { id: string };
type RolesProps = { rolesQuery: RoleListData };
type UpdatePageAccessProps = { updatePageAccess: UpdatePageAccessMutationFn };
type Props = RouteComponentProps<MatchParams> &
  UpdatePageAccessProps &
  ToastContextProps &
  RolesProps & {
    roles: Array<string>;
    pageAccesses: Array<PageAccess>;
  };

type PageAccessWithOrder = PageAccess & { order: number };

type Operation = 'create' | 'update' | 'delete' | 'read';

type State = {
  isDirty: boolean;
  originData: Array<PageAccess>;
  pageAccessMap: Map<string, PageAccessWithOrder>;
  isLoading: boolean;
};

const Helpers = {
  setOrders: (pageAccesses: Array<PageAccess>) =>
    pageAccesses.map((pageAccess, order) => ({
      ...pageAccess,
      order,
    })),
  groupByPageName: (pageAccesses: Array<PageAccessWithOrder>) => {
    const result = new Map();
    for (const pa of pageAccesses) {
      result.set(pa.page, pa);
    }
    return result;
  },
  getAvailableOperations: (page: string) => {
    let availableOperations: Array<Operation> = [
      'create',
      'delete',
      'read',
      'update',
    ];
    switch (page) {
      case 'Segmentasi':
      case 'Layanan Pelanggan':
      case 'Penilaian': {
        availableOperations = ['read'];
        break;
      }
      case 'Tutup Penjualan': {
        availableOperations = ['read', 'create'];
        break;
      }
      case 'Daftar Peran':
      case 'Daftar Produk':
      case 'Pemesanan':
      case 'Jaminan Botol': {
        availableOperations = ['read', 'update'];
        break;
      }
      case 'Profil Pelanggan':
      case 'Cluster': {
        availableOperations = ['read', 'create', 'update'];
      }
    }
    return availableOperations;
  },
};

const CRUDS: Array<{
  icon: IconKey;
  operation: Operation;
}> = [
  { icon: 'visibility', operation: 'read' },
  { icon: 'add', operation: 'create' },
  { icon: 'edit', operation: 'update' },
  { icon: 'delete', operation: 'delete' },
];

export class AdminRoleFormScene extends Component<Props, State> {
  state: State = {
    isDirty: false,
    originData: this.props.pageAccesses,
    pageAccessMap: Helpers.groupByPageName(
      Helpers.setOrders(this.props.pageAccesses),
    ),
    isLoading: false,
  };

  render() {
    return this._renderForm(
      `Ubah Ketentuan Peran ${capitalCase(this.props.match.params.id)}`,
    );
  }

  _isAllAccess = () => {
    const { pageAccessMap } = this.state;
    for (const pa of Array.from(pageAccessMap.values())) {
      const { create, read, update, delete: del } = pa;
      if (!(create && read && update && del)) {
        return false;
      }
    }

    return true;
  };

  _renderAccessMenu = () => (
    <View style={styles.allAccessStyle}>
      <Text color={GRAY80} size="small">
        Akses Menu
      </Text>
      <CheckBoxText
        text="Semua Akses Menu"
        style={styles.description}
        isSelected={this._isAllAccess()}
        onPress={this._setAllAccess}
      />
    </View>
  );

  _sortByOrder = (a: PageAccessWithOrder, b: PageAccessWithOrder) =>
    a.order - b.order;

  _reset = () =>
    this.setState({
      isDirty: false,
      pageAccessMap: Helpers.groupByPageName(
        Helpers.setOrders(this.state.originData),
      ),
    });

  _renderForm = (title: string) => {
    const { history } = this.props;
    const { pageAccessMap, isDirty, isLoading } = this.state;

    return (
      <FormPage
        showBackButton
        isSubmitLoading={isLoading}
        isDirty={isDirty}
        isValid
        submitTitle="Simpan"
        handleDiscard={this._reset}
        handleGoBack={history.goBack}
        handleSubmit={this._onSubmitForm}
      >
        <Card title={title}>
          {this._renderAccessMenu()}
          <View style={[styles.row, styles.flex]}>
            <View>
              {Array.from(pageAccessMap.values())
                .sort(this._sortByOrder)
                .map((pageAccess) => {
                  const { page } = pageAccess;
                  const availableOperations = Helpers.getAvailableOperations(
                    page,
                  );
                  const isSelected =
                    availableOperations.filter((op) => pageAccess[op])
                      .length === availableOperations.length;
                  return (
                    <CheckBoxText
                      key={page}
                      text={page}
                      style={styles.checkBox}
                      isSelected={isSelected}
                      onPress={() => this._onClickCheckboxRoot(page)}
                    />
                  );
                })}
            </View>
            <View style={styles.separatorMargin}>
              <Separator isVertical />
            </View>
            {this._renderAccessRows()}
          </View>
          {this._renderDescription()}
        </Card>
      </FormPage>
    );
  };

  _renderDescription = () => (
    <View>
      <Separator />
      <Text>Keterangan</Text>
      <View style={[styles.row, styles.description]}>
        {CRUDS.map(({ icon, operation }, i) => (
          <View style={[styles.row, styles.descriptionItem]} key={i}>
            <View style={styles.accessItem}>
              <Icon name={icon} color={PRIMARY} size="xsmall" />
            </View>
            <Text style={styles.descriptionText} size="small">
              {this._getTranslatedOperation(operation)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );

  _getTranslatedOperation = (operation: Operation) => {
    switch (operation) {
      case 'create': {
        return 'Tambah';
      }
      case 'delete': {
        return 'Hapus';
      }
      case 'read': {
        return 'Lihat';
      }
      case 'update': {
        return 'Ubah';
      }
    }
  };

  _renderAccessRows = () => {
    const { pageAccessMap } = this.state;

    return (
      <View style={styles.flex}>
        {Array.from(pageAccessMap.values())
          .sort(this._sortByOrder)
          .map((pageAccess) => {
            const { page } = pageAccess;
            const availableOperations = Helpers.getAvailableOperations(page);
            return (
              <View style={[styles.row, styles.accessRow]}>
                {CRUDS.map(({ icon, operation }, i) => {
                  if (!availableOperations.includes(operation)) {
                    return <View style={styles.emptyAccessItem} />;
                  }
                  const color = pageAccess[operation] ? PRIMARY : GRAY30;
                  return (
                    <View
                      style={[styles.accessItem, { borderColor: color }]}
                      key={i}
                    >
                      <Icon
                        size="xsmall"
                        name={icon}
                        color={color}
                        onPress={() =>
                          this._onClickCheckboxCrud(page, operation)
                        }
                      />
                    </View>
                  );
                })}
              </View>
            );
          })}
      </View>
    );
  };

  _onClickCheckboxCrud = (pageName: string, operation: Operation) => {
    const { pageAccessMap } = this.state;
    const pageAccess = pageAccessMap.get(pageName);

    if (pageAccess) {
      this.setState({
        isDirty: true,
        pageAccessMap: pageAccessMap.set(pageName, {
          ...pageAccess,
          [operation]: !pageAccess[operation],
        }),
      });
    }
  };

  _onClickCheckboxRoot = (pageName: string) => {
    const { pageAccessMap } = this.state;

    const pageAccess = pageAccessMap.get(pageName);

    if (pageAccess) {
      const { create, read, update, delete: del } = pageAccess;
      const operationBool = !(create && read && update && del);

      this.setState({
        isDirty: true,
        pageAccessMap: pageAccessMap.set(pageName, {
          ...pageAccess,
          create: operationBool,
          read: operationBool,
          update: operationBool,
          delete: operationBool,
        }),
      });
    }
  };

  _setAllAccess = () => {
    const { pageAccessMap } = this.state;

    const isAllAccess = this._isAllAccess();

    const operationBool = !isAllAccess;

    const result = new Map();
    for (const pageAccess of Array.from(pageAccessMap.values())) {
      const { page } = pageAccess;
      result.set(page, {
        ...pageAccess,
        create: operationBool,
        read: operationBool,
        update: operationBool,
        delete: operationBool,
      });
    }

    this.setState({
      isDirty: true,
      pageAccessMap: result,
    });
  };

  _onSubmitForm = async () => {
    const { isDirty, pageAccessMap } = this.state;
    const { updatePageAccess, openToast } = this.props;
    if (isDirty) {
      this.setState({ isLoading: true });
      const result = await Promise.all(
        Array.from(pageAccessMap.values()).map(
          async ({ id, create, read, update, delete: del }) =>
            updatePageAccess({
              variables: {
                id,
                create,
                read,
                update,
                delete: del,
              },
            }),
        ),
      );

      const errorMessages = result.some((item) => !!item && !!item.errors);
      if (errorMessages) {
        openToast && openToast('fail', 'Akses Peran gagal diperbaharui');
      } else {
        openToast && openToast('success', 'Akses Peran berhasil diperbaharui');
        this._handleGoBack();
      }

      this.setState(() => ({
        isDirty: false,
        isLoading: false,
      }));
    }
  };

  _handleGoBack = () => {
    const { history } = this.props;
    history && history.goBack();
  };
}

export function RootScene(
  props: AccessProps &
    RouteComponentProps<MatchParams> &
    UpdatePageAccessProps &
    RolesProps &
    ToastContextProps,
) {
  const { rolesQuery, match, access } = props;

  const role = match.params.id;
  let isPathCorrect;

  if (rolesQuery.loading || rolesQuery.error || !rolesQuery.roles) {
    return null;
  }

  for (const r of rolesQuery.roles) {
    if (r === match.params.id) {
      isPathCorrect = true;
    }
  }

  if (!isPathCorrect || !access.read || !access.update) {
    return <NotFoundPage />;
  }

  return (
    <Query<PageAccessData>
      query={GET_ACCESS_PRIVILEGES}
      skip={!role}
      variables={{ role }}
      fetchPolicy="network-only"
    >
      {({ data }) => (
        <AdminRoleFormScene
          access={access}
          pageAccesses={data && data.pageAccesses ? data.pageAccesses : []}
          roles={rolesQuery.roles || []}
          {...props}
        />
      )}
    </Query>
  );
}

export default compose(
  graphql<{}, RolesProps>(GET_ROLES, {
    name: 'rolesQuery',
  }),
  graphql<
    {},
    UpdatePageAccessProps,
    UpdatePageAccessVariable,
    UpdatePageAccessProps
  >(UPDATE_PAGE_ACCESS, {
    name: 'updatePageAccess',
  }),
  withToast,
)(RootScene);

const styles = StyleSheet.create({
  flex: { flex: 1 },
  allAccessStyle: { marginBottom: 35 },
  separatorMargin: { marginLeft: 10, marginRight: 10 },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  accessRow: { justifyContent: 'space-around', paddingBottom: 10 },
  emptyAccessItem: { width: 29 },
  accessItem: {
    borderWidth: 1,
    borderRadius: 100,
    padding: 5,
    borderColor: PRIMARY,
  },
  checkBox: { alignItems: 'center', height: 39, paddingBottom: 10 },
  description: { paddingTop: 15 },
  descriptionItem: { alignItems: 'center' },
  descriptionText: { paddingLeft: 5 },
});
