import React, { Component } from 'react';
import { ScrollView, View, StyleSheet, Image } from 'react-native';

import { User } from '../../graphql/queries';
import { Modal, Tab, Tabs, Text } from '../../core-ui';
import { MapView } from '../../components';
import { BLACK, DARK_GRAY } from '../../constants/colors';
import { formatThousandSeparator, convertPascalCase } from '../../helpers';
import { MONTHS } from '../../constants/calendar';
import { TOP_OPTIONS, INVOICE_OPTIONS } from '../top/SelectAsTopScene';

type Props = {
  isVisible: boolean;
  data: Array<User>;
  dataID: Nullable<string>;
  onClose: () => void;
};
type State = { selectedDetailTab: number };

const PERSONAL_FIELDS = {
  'depot.title': 'Nama Depo',
  szID: 'Kode Pelanggan',
  name: 'Nama Pelanggan',
  storeName: 'Nama Toko',
  active: 'Status Pelanggan',
  phone: 'Nomor HP Pelanggan',
  email: 'Email Pelanggan',
  // address: 'Alamat Pelanggan',
};

const STORE_FIELDS = {
  storeName: 'Nama Toko',
  storeAddress: 'Alamat Toko',
  storePhoto: 'Foto Toko',
  storeTelephone: 'Nomor Telepon Toko',
  'location.province.name': 'Provinsi',
  'location.city.name': 'Kota',
  'location.district.name': 'Kelurahan',
  'location.subDistrict.name': 'Kecamatan',
  'location.postalCode': 'Kode Pos',
  map: 'Peta Lokasi',
};

const SEGMENT_FIELDS = {
  'segment.title': 'Segmentasi',
  'subSegment.title': 'Sub Segmentasi',
  'cluster.currentCluster': 'Cluster',
  'priceSegment.title': 'Segmentasi Harga',
};

const TOP_FIELDS = {
  'top.contractNumber': 'Nomor Kontrak',
  'top.contractStart': 'Tanggal Kontrak',
  'top.details': 'Keterangan Kontrak',
  'top.paymentPeriod': 'Tempo Pembayaran',
  'top.isCredit': 'Kredit',
  'top.creditLimit': 'Plafon Kredit',
  'top.invoiceType': 'Pemrosesan Invoice',
};

export default class UserDetailModal extends Component<Props, State> {
  state = { selectedDetailTab: 0 };

  render() {
    const { isVisible, data, onClose } = this.props;
    const { selectedDetailTab } = this.state;
    const currentData = this._getCurrentData(data);
    return (
      <Modal
        maxHeight
        title="Informasi Pelanggan"
        isVisible={isVisible}
        onClose={onClose}
      >
        {currentData && (
          <Tabs
            selectedIndex={selectedDetailTab}
            onChange={(_e, index) =>
              this.setState({ selectedDetailTab: index })
            }
          >
            <Tab label="Data Pelanggan">
              {this._renderTabDetailContent(PERSONAL_FIELDS, currentData)}
            </Tab>
            <Tab label="Data Toko">
              {this._renderTabDetailContent(STORE_FIELDS, currentData)}
            </Tab>
            <Tab label="Segmentasi">
              {this._renderTabDetailContent(SEGMENT_FIELDS, currentData)}
            </Tab>
            {currentData.top && (
              <Tab label="TOP">
                {this._renderTabDetailContent(TOP_FIELDS, currentData)}
              </Tab>
            )}
          </Tabs>
        )}
      </Modal>
    );
  }

  _renderTabDetailContent = (fields: ObjectKey, data: User) => (
    <ScrollView contentContainerStyle={styles.modalBody}>
      <View>{this._renderInfoFields(Object.values(fields))}</View>
      <View style={styles.infoSeparator}>
        {this._renderInfoSeparators(Object.keys(fields))}
      </View>
      <View style={styles.infoValue}>
        {this._renderInfoValues(data, Object.keys(fields))}
      </View>
    </ScrollView>
  );

  _renderInfoFields = (fields: Array<string>) =>
    fields.map((field, index) => (
      <View
        style={[
          styles.infoWrapper,
          ['Foto Toko', 'Peta Lokasi'].includes(field) && { height: 200 },
        ]}
        key={index}
      >
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          {field}
        </Text>
      </View>
    ));

  _renderInfoSeparators = (fields: Array<string>) =>
    fields.map((field, idx) => (
      <View
        style={[
          styles.infoWrapper,
          ['storePhoto', 'map'].includes(field) && { height: 200 },
        ]}
        key={idx}
      >
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          :
        </Text>
      </View>
    ));

  _renderInfoValues = (data: User, dataKeys: Array<string>) =>
    dataKeys.map((dataKey, index) => {
      let content;
      switch (dataKey) {
        case 'storePhoto': {
          content = (
            <Image
              source={{ uri: data.userUpload.storePhoto }}
              // TODO: adjust size as necessary
              resizeMethod="auto"
              resizeMode="contain"
              style={{ width: 200, height: 200 }}
            />
          );
          break;
        }
        case 'map': {
          const { latitude, longitude } = data.location;
          content = (
            <View>
              <MapView
                markerPosition={{
                  lat: Number(latitude || -6.1753124),
                  lng: Number(longitude || 106.8260692),
                }}
              />
              <View style={{ marginTop: 5 }}>
                <Text size="small" style={styles.infoText}>
                  Longitude: {longitude || '-'}
                </Text>
                <Text size="small" style={styles.infoText}>
                  Latitude: {latitude || '-'}
                </Text>
              </View>
            </View>
          );
          break;
        }
        default: {
          let processor: (val: string) => string = (val) => val;
          const toPascalCase = [
            'name',
            'depot.title',
            'storeName',
            'location.province.name',
            'location.city.name',
            'location.district.name',
            'location.subDistrict.name',
          ];

          const toUpperCase = ['subSegment.title'];

          if (toPascalCase.includes(dataKey)) {
            processor = (val) => convertPascalCase(val);
          } else if (toUpperCase.includes(dataKey)) {
            processor = (val) => val.toUpperCase();
          } else {
            switch (dataKey) {
              case 'top.contractStart': {
                processor = (val) => {
                  const date = new Date(val);
                  return `${date.getDate()} ${
                    MONTHS[date.getMonth()]
                  } ${date.getFullYear()}`;
                };
                break;
              }
              case 'top.isCredit': {
                processor = (val) => (val ? 'Kredit' : 'Tidak Kredit');
                break;
              }
              case 'top.creditLimit': {
                processor = (val) => formatThousandSeparator(Number(val));
                break;
              }
              case 'top.paymentPeriod': {
                processor = (val) => {
                  const paymentPeriod = TOP_OPTIONS.find(
                    ({ value }) => value === val,
                  );
                  if (paymentPeriod) {
                    return paymentPeriod.label;
                  }
                  return '';
                };
                break;
              }
              case 'top.invoiceType': {
                processor = (val) => {
                  const invoice = INVOICE_OPTIONS.find(
                    ({ value }) => value === val,
                  );
                  if (invoice) {
                    return invoice.label;
                  }
                  return '';
                };
                break;
              }
            }
          }
          content = (
            <Text size="small" color={BLACK} style={styles.infoText}>
              {processor(this._getValue(data, dataKey))}
            </Text>
          );
        }
      }
      return (
        <View style={styles.infoWrapper} key={index}>
          {content}
        </View>
      );
    });

  _getValue = (data: User, dataKey: string) => {
    const nestedKey = dataKey.split('.');
    let tmpData: ObjectKey = data;
    for (const key of nestedKey) {
      if (!tmpData[key]) {
        break; // NOTE: either tmpData[key] is null or tmpData has no such key break away from the loop
      } else if (typeof tmpData[key] === 'object') {
        tmpData = tmpData[key]; // NOTE: if tmpData[key] is an object, traverse deeper
      } else {
        if (typeof tmpData[key] === 'boolean') {
          // NOTE: if value is boolean (user's active property), return appropriate text
          return tmpData[key] ? 'Aktif' : 'Tidak Aktif';
        }
        return tmpData[key]; // NOTE: supposed to be the value, may be string or number
      }
    }
    return ''; // NOTE: defaultly returns an empty string
  };

  _getCurrentData = (data: Array<User>) =>
    data.find(({ id }) => id === this.props.dataID);
}

const styles = StyleSheet.create({
  modalBody: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
  },
  infoWrapper: {
    minHeight: 40,
    justifyContent: 'flex-start',
  },
  infoText: {
    height: 22,
  },
  infoSeparator: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  infoValue: { flex: 1 },
});
