import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from 'react-native';

import Icon, { IconSize } from './Icon';
import {
  WHITE,
  NAVY_BLUE,
  GRAY,
  GRAY80,
  TRANSPARENT,
} from '../constants/colors';

export type Props = {
  isSelected?: boolean;
  disabled?: boolean;
  iconStyle?: StyleProp<ViewStyle>;
  iconColor?: string;
  iconSize?: IconSize;
  backgroundStyle?: StyleProp<ViewStyle>;
  backgroundColor?: string;
  onPress?: () => void;
};

export default class CheckBox extends Component<Props> {
  render() {
    const DEFAULT_SIZE: IconSize = 'xsmall';
    let {
      iconColor = WHITE,
      backgroundColor = NAVY_BLUE,
      disabled = false,
      onPress,
      iconSize = DEFAULT_SIZE,
      isSelected,
    } = this.props;
    isSelected
      ? (backgroundColor = backgroundColor)
      : (backgroundColor = WHITE);

    disabled ? (backgroundColor = GRAY80) : (backgroundColor = backgroundColor);

    return (
      <View
        style={[
          styles.icon,
          { backgroundColor },
          isSelected && !disabled && { borderColor: NAVY_BLUE },
        ]}
      >
        <TouchableOpacity onPress={onPress}>
          {!disabled ? (
            <Icon
              name="check"
              size={iconSize}
              color={iconColor}
              style={styles.transparent}
            />
          ) : null}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    borderWidth: 2,
    width: 18,
    height: 18,
    borderRadius: 2,
    backgroundColor: WHITE,
    borderColor: GRAY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  transparent: { backgroundColor: TRANSPARENT },
});
