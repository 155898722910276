import gql from 'graphql-tag';
import {
  ClaimType,
  GiftType,
  MaximalQuantityProgramType,
  ProgramType,
  RewardType,
} from '../queries';

export type TermParams = {
  programType: Nullable<ProgramType>;
  product?: { connect: { id: string } };
  user?: { connect: { id: string } };
  programTermProducts?: {
    create: Array<{
      product: { connect: { id: string } };
    }>;
  };
  terms: {
    create: Array<{
      minimalPurchase: number;
      rewardQty: number;
    }>;
  };
};

export type ProgramDepotParams = {
  create: Array<{
    depot: { connect: { id: string } };
    maxQty: number;
    limitType: Nullable<MaximalQuantityProgramType>;
  }>;
  updateMany?: Array<{
    where: { id: string };
    data: { maxQty: number; limitType: Nullable<MaximalQuantityProgramType> };
  }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};

export type ProgramSegmentParams = {
  create: Array<{ segment: { connect: { id: string } } }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};

export type ProgramSubSegmentParams = {
  create: Array<{ subSegment: { connect: { id: string } } }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};

export type ProgramUserParams = {
  create: Array<{
    user: { connect: { id: string } };
    outletMaxQty: number;
    limitType: Nullable<MaximalQuantityProgramType>;
  }>;
  updateMany?: Array<{
    where: { id: string };
    data: {
      outletMaxQty: number;
      limitType: Nullable<MaximalQuantityProgramType>;
    };
  }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};
export type ProgramTermParams = {
  create?: Array<TermParams>;
  update?: Array<{
    where: {
      id: string;
    };
    data: {
      terms: {
        update?: Array<{
          where: {
            id: string;
          };
          data: {
            minimalPurchase: number;
            rewardQty: number;
          };
        }>;
        create?: Array<{
          minimalPurchase: number;
          rewardQty: number;
        }>;
        deleteMany?: {
          id_in: Array<string>;
        };
      };
    };
  }>;
  deleteMany?: {
    id_in: Array<string>;
  };
};

export type ProgramFocProductParams = {
  create: Array<{ name: string; quantity: number }>;
};

export type ProgramParams = {
  claimLimit: number;
  programType: Nullable<ProgramType>;
  rewardType: Nullable<RewardType>;
  title: string;
  description?: string;
  startDate: string;
  endDate: string;
  titleNotification: string;
  bodyNotification: string;
  programDepot: ProgramDepotParams;
  programSegment: ProgramSegmentParams;
  programSubSegment: ProgramSubSegmentParams;
  giftType: Nullable<GiftType>;
  terms: ProgramTermParams;
  products: { connect: Array<{ id: string }> };
  claimType: Nullable<ClaimType>;
  giftName: Nullable<string>;
  giftPrice: Nullable<number>;
  programUser: ProgramUserParams;
  // programFocProduct: ProgramFocProductParams;
};

export type CreateProgramParams = {
  data: ProgramParams;
};

export const CREATE_PROGRAM = gql`
  mutation createProgramMultiple($data: ProgramCreateInput!) {
    createProgram(data: $data) {
      id
    }
  }
`;

export type EditProgramParams = {
  id: string;
  data: ProgramParams;
};

export const EDIT_PROGRAM = gql`
  mutation editProgramMultiple($id: ID!, $data: ProgramUpdateInput!) {
    editProgramMultiple(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export type DeleteProgramParams = {
  id: string;
};

export const DELETE_PROGRAM = gql`
  mutation deleteProgram($id: ID) {
    deleteProgram(where: { id: $id }) {
      id
    }
  }
`;
