import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import { Text } from '../../../core-ui';
import { SegmentTree } from '../../../graphql/queries';
import { GRAY } from '../../../constants/colors';

type OwnProps = {
  data: Array<SegmentTree>;
};

type Props = WithStyles<typeof styles> & OwnProps;

class SubsegmentList extends Component<Props, {}> {
  render() {
    const { classes, data } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>
              <Text size="small" weight="bold">
                SEGMENTASI
              </Text>
            </TableCell>
            <TableCell className={classes.header}>
              <Text size="small" weight="bold">
                SUBSEGMENT
              </Text>
            </TableCell>
            <TableCell className={classes.header} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((segmentData) => {
            return segmentData.subSegments.map((subSegmentData) => {
              return (
                <TableRow key={subSegmentData.id}>
                  <TableCell className={classNames(classes.cell)}>
                    <Text
                      size="xsmall"
                      weight="reg"
                      style={{ letterSpacing: 1.5 }}
                    >
                      {segmentData.title.toUpperCase()}
                    </Text>
                  </TableCell>
                  <TableCell className={classNames(classes.cell)}>
                    <Text
                      size="xsmall"
                      weight="reg"
                      style={{ letterSpacing: 1.5 }}
                    >
                      {subSegmentData.title.toUpperCase()}
                    </Text>
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    );
  }
}

const styles = createStyles({
  header: {
    padding: '12px 18px 0px 16px',
    borderBottom: 0,
  },
  cell: {
    padding: '4px 18px 4px 16px',
    borderBottom: `1px dashed ${GRAY}`,
  },
});

export default withStyles(styles)(SubsegmentList);
