import React, { Component, ComponentClass } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { Query } from '../../../components';
import { DARK_GRAY, GRAY, GRAY70 } from '../../../constants/colors';
import { Button, Modal, Separator, Tab, Tabs, Text } from '../../../core-ui';
import { formatThousandSeparator } from '../../../helpers';

import { ModalState } from '../../../graphql/localState';
import {
  GET_MODAL_SELECTED_STATE,
  GET_PRODUCT_LIST,
  GetProductListResult,
  GetProductListVariables,
  PRICE_SEGMENT_QUERY,
  PriceSegmentResult,
  PriceSegmentVariables,
  Product,
  Term,
  UPDATE_MODAL_SELECTED,
} from '../../../graphql/queries';

import SearchField from '../components/SearchField';
import Table from '../components/Table';

import withToast, { ToastContextProps } from 'helpers/withToast';
import { RETAIL } from '../../../constants/priceSegment';
import getProductPrice from '../../../helpers/getProductPrice';
import { TermInput } from '../components';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type OwnProps = {
  priceSegmentCode?: string;
  selectedProducts: Array<Product>;
  selectedTerms: Map<string, Array<Term>>;
  onClose: () => void;
  onChangeSelected: (
    selectedProducts: Array<Product>,
    selectedTerms: Map<string, Array<Term>>,
  ) => void;
  isVisible: boolean;
};

type Props = OwnProps &
  UpdateSelectedData &
  SelectedStateProps &
  ToastContextProps;

type State = {
  resetPage: boolean;
  searchKey: string;
  selectedTab: number;
  terms: Map<string, Array<Term>>;
};

const EMPTY_TERM: Pick<Term, 'minimalPurchase' | 'rewardQty'> = {
  minimalPurchase: 0,
  rewardQty: 0,
};

class FlashSaleModal extends Component<Props, State> {
  state: State = {
    resetPage: false,
    searchKey: '',
    selectedTab: 0,
    terms: this.props.selectedTerms,
  };

  componentDidMount() {
    const { terms } = this.state;
    const { selectedProducts, updateMultiTable } = this.props;

    // Show checked for selected products
    updateMultiTable({
      variables: {
        selectedArray: selectedProducts || [],
      },
    });

    const newTerms = new Map(terms);
    selectedProducts.forEach((datum) => {
      if (!terms.has(datum.id)) {
        newTerms.set(datum.id, [
          { __typename: 'Term', id: datum.id, state: 'new', ...EMPTY_TERM },
        ]);
      }
    });
    this.setState({ terms: newTerms });
  }

  componentWillUnmount() {
    const { updateMultiTable } = this.props;
    // Remove selected state
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  }

  render() {
    const { selectedTab } = this.state;
    const { isVisible } = this.props;

    return (
      <Modal
        maxHeight
        isVisible={isVisible}
        title={selectedTab === 0 ? 'Pilih Produk' : 'Tentukan Syarat'}
        onClose={this._closeModal}
        buttonText={selectedTab === 0 ? 'Lanjut' : 'Simpan'}
        onSubmit={() =>
          selectedTab === 0 ? this._onContinue() : this._onSubmit()
        }
      >
        {this._renderTabs()}
      </Modal>
    );
  }

  _renderTabs() {
    const { selectedTab } = this.state;
    return (
      <Tabs
        selectedIndex={selectedTab}
        onChange={(_e, index) => this._onContinue(index)}
      >
        <Tab label="Produk">{this._renderProduct()}</Tab>
        <Tab label="Syarat">{this._renderTerms()}</Tab>
      </Tabs>
    );
  }

  _renderProduct() {
    const { searchKey, resetPage } = this.state;
    const { priceSegmentCode } = this.props;
    return (
      <View style={styles.topPadding}>
        <Text color={DARK_GRAY} style={{ paddingBottom: 20 }}>
          Tentukan produk yang dapat dibeli menggunakan jenis program ini.
        </Text>
        <SearchField
          placeholder="Cari produk..."
          value={searchKey}
          onChangeText={this._onSearch}
        />
        <Separator style={{ marginVertical: 30 }} />
        <Query<GetProductListResult, GetProductListVariables>
          query={GET_PRODUCT_LIST}
          variables={{
            where: { searchKeyword: searchKey.toLowerCase() },
            first: 100,
            skip: 0,
          }}
          keyData="products"
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ data, loading }) =>
            data && (
              <Query<PriceSegmentResult, PriceSegmentVariables>
                query={PRICE_SEGMENT_QUERY}
                variables={{ priceSegmentCode: RETAIL }}
              >
                {({ data: retailPriceList }) =>
                  !!retailPriceList && (
                    <Query<PriceSegmentResult, PriceSegmentVariables>
                      query={PRICE_SEGMENT_QUERY}
                      // it will not be empty because as a variable because the Query will be skipped if it is empty
                      variables={{ priceSegmentCode: priceSegmentCode! }}
                      skip={!priceSegmentCode}
                    >
                      {({ data: segmentPriceList }) => (
                        <Table
                          resetPage={resetPage}
                          setResetPage={(isReset) =>
                            this.setState({ resetPage: isReset })
                          }
                          isLoading={loading}
                          showCheckboxes
                          searchKey={searchKey}
                          data={data.products}
                          dataCount={data.count}
                          rowPerPage={data.products.length}
                          hidePagination
                          structure={{
                            title: {
                              headerTitle: 'Nama Produk',
                            },
                            price: {
                              headerTitle: 'Harga',
                              render: ({ id, defaultPrice }) => {
                                const price = getProductPrice(
                                  id,
                                  retailPriceList,
                                  defaultPrice,
                                  segmentPriceList,
                                );

                                return (
                                  <Text
                                    size="small"
                                    weight="reg"
                                    numberOfLines={1}
                                    style={{ letterSpacing: 1.5 }}
                                  >
                                    Rp. {formatThousandSeparator(price)}
                                  </Text>
                                );
                              },
                            },
                          }}
                          loadMore={() => {}}
                        />
                      )}
                    </Query>
                  )
                }
              </Query>
            )
          }
        </Query>
      </View>
    );
  }

  _renderTerms() {
    const {
      selectedStateQuery: { modalState },
    } = this.props;
    return (
      <View style={styles.topPadding}>
        <Text color={DARK_GRAY} style={styles.bottomPadding}>
          Tentukan syarat-syarat yang berlaku untuk mendapatkan program ini.
        </Text>
        <Separator />
        {modalState &&
          modalState.selectedArray.map((datum) => this._renderTermList(datum))}
      </View>
    );
  }

  _closeModal = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  _onSearch = (searchKey: string) => {
    this.setState({ searchKey, resetPage: true });
  };

  _renderTermList = (datum: ObjectKey) => {
    const { terms } = this.state;
    const productTitle = datum.title;
    const price =
      (datum &&
        datum.pricePerSegments &&
        datum.pricePerSegments[0] &&
        datum.pricePerSegments[0].price) ||
      0;

    const title = `${productTitle} - Rp.${formatThousandSeparator(price)}`;

    const termInput = terms.get(datum.id);

    return (
      <View key={datum.id}>
        <Text style={styles.bottomPadding}>{title}</Text>
        {termInput &&
          termInput
            .filter((term) => term.state !== 'deleted')
            .map((term, index) => (
              <TermInput
                key={term.id}
                initialValue={term}
                onUpdate={(newTermValue) =>
                  this._updateTerm(index, newTermValue, datum.id)
                }
                onDelete={() => this._deleteTerm(term.id, datum.id)}
              />
            ))}
        <Button
          text="Tambah Syarat"
          icon="add"
          inverted
          style={styles.addButton}
          onPress={() => this._addTerm(datum.id)}
        />
        <Separator />
      </View>
    );
  };

  _onContinue = (newTab?: number) => {
    const {
      selectedStateQuery: { modalState },
    } = this.props;

    if (modalState && modalState.selectedArray.length > 0) {
      // Set initial state
      const { terms } = this.state;
      const newTerms = new Map(terms);
      modalState.selectedArray.forEach((datum) => {
        if (!terms.has(datum.id)) {
          newTerms.set(datum.id, [
            { __typename: 'Term', id: datum.id, state: 'new', ...EMPTY_TERM },
          ]);
        }
      });

      this.setState({
        selectedTab: typeof newTab === 'number' ? newTab : 1,
        terms: newTerms,
      });
    }
  };

  _addTerm = (id: string) => {
    const { terms } = this.state;
    const termsByID = terms.get(id);
    if (termsByID) {
      const newTermsByID: Array<Term> = [
        ...termsByID,
        {
          __typename: 'Term',
          id,
          state: 'new',
          programTermId: termsByID[0].programTermId,
          ...EMPTY_TERM,
        },
      ];

      const newTerms = new Map(terms);
      newTerms.set(id, newTermsByID);
      this.setState({ terms: newTerms });
    }
  };

  _updateTerm = (selectedIndex: number, newTermValue: Term, id: string) => {
    const { terms } = this.state;
    const termsByID = terms.get(id);
    if (termsByID) {
      const newTermsByID: Array<Term> = termsByID.map((term, index) => {
        const ret = index === selectedIndex ? newTermValue : term;
        const { __typename: _, id: __, ...restRet } = ret;
        const { id: oldId, state, __typename } = term;
        return {
          __typename,
          id: oldId,
          state: state === 'unchanged' ? 'modified' : state,
          ...restRet,
        };
      });
      const newTerms = new Map(terms);
      newTerms.set(id, newTermsByID);
      this.setState({ terms: newTerms });
    }
  };

  _deleteTerm = (termId: string, id: string) => {
    const { terms } = this.state;
    const termsByID = terms.get(id);
    if (termsByID) {
      const newTermsByID = termsByID.map((term) => {
        return {
          ...term,
          state: termId === term.id ? 'deleted' : term.state,
        };
      });
      const newTerms = new Map(terms);
      newTerms.set(id, newTermsByID);
      this.setState({ terms: newTerms });
    }
  };

  _onChangeText = <T extends keyof State>(key: T, value: State[T]) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _onSubmit = () => {
    const {
      onChangeSelected,
      selectedStateQuery: { modalState },
      onClose,
      openToast,
    } = this.props;
    const { terms } = this.state;
    if (modalState) {
      onChangeSelected(modalState.selectedArray, terms);

      const isHaveZeroTerms = Array.from(terms)
        .flatMap((term) => term[1])
        .some((term) => term.minimalPurchase === 0 || term.rewardQty === 0);

      if (isHaveZeroTerms) {
        return openToast('fail', 'Syarat tidak boleh kosong!');
      }

      onClose();
    }
  };
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  padding: { padding: 40 },
  dropdown: {
    marginBottom: 0,
    zIndex: 2,
  },
  button: {
    marginTop: 50,
    alignSelf: 'flex-end',
  },
  addButton: {
    alignSelf: 'flex-start',
    borderWidth: 0,
  },
  flexOne: { flex: 1 },
  halfFlex: { flex: 0.5 },
  rangeTermInputRoot: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  label: {
    color: GRAY70,
    paddingBottom: 10,
  },
  text: {
    paddingHorizontal: 10,
  },
  leftColumn: {
    flex: 1,
    paddingRight: 20,
  },
  rightColumn: {
    flex: 1,
    paddingLeft: 20,
  },
  discountContainer: {
    flex: 2,
    marginRight: 20,
  },
  deleteButtonContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  deleteButton: {
    borderWidth: 1,
    width: 30,
    height: 30,
    borderRadius: 4,
    borderColor: GRAY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topPadding: { paddingTop: 20 },
  bottomPadding: { paddingBottom: 20 },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
  withToast,
)(FlashSaleModal) as ComponentClass<OwnProps>;
