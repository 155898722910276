import { Order, TransactionItem } from '../../../../graphql/queries';

export type TipeTotal = {
  totalDiscount: string;
  totalRealPrice: string;
  totalPriceAfterDiscount: string;
};

export const tipeTotalName: TipeTotal = {
  totalDiscount: 'totalDiscount',
  totalRealPrice: 'totalRealPrice',
  totalPriceAfterDiscount: 'totalPriceAfterDiscount',
};

export const tipeTotalFunc = (
  transaction: TransactionItem[],
  tipe: string,
  creditPoint?: number,
  bottle?: Array<{
    qty: number;
    pricePerBottle: number;
  }>,
  canceledOrderPrices: Array<number> = [],
  couponDiscount = 0,
  returnedOrder = 0,
): number => {
  let totalPriceArray: Array<number> = [];
  let totalRealPrice = 0;

  let totalBottleArray: Array<number> = [];
  let totalRealBottle = 0;

  // untuk total diskon
  if (tipe === tipeTotalName.totalDiscount) {
    totalPriceArray = transaction.map((item) => item.priceCut * item.quantity);

    totalRealPrice = totalPriceArray.reduce((total, num) => total + num, 0);
  }

  // untuk total harga asli
  if (tipe === tipeTotalName.totalRealPrice) {
    totalPriceArray = transaction.map((item) => item.price * item.quantity);

    totalRealPrice = totalPriceArray.reduce((total, num) => total + num, 0);
  }

  // untuk total harga diskon
  if (tipe === tipeTotalName.totalPriceAfterDiscount) {
    totalPriceArray = transaction.map(
      (item) => (item.price - item.priceCut) * item.quantity,
    );

    totalRealPrice =
      totalPriceArray.reduce((total, num) => total + num, 0) - returnedOrder;
  }

  if (bottle) {
    totalBottleArray = bottle.map((item) => item.pricePerBottle * item.qty);

    totalRealBottle = totalBottleArray.reduce((total, num) => total + num, 0);
  }

  // untuk canceled orders
  if (canceledOrderPrices.length > 0) {
    const totalCanceledOrderPrice = canceledOrderPrices.reduce(
      (total, num) => total + num,
      0,
    );
    totalRealPrice -= totalCanceledOrderPrice;
  }

  if (couponDiscount) {
    totalRealPrice -= couponDiscount;
  }

  totalRealPrice = bottle ? totalRealPrice + totalRealBottle : totalRealPrice;

  // untuk credit point
  totalRealPrice = creditPoint ? totalRealPrice - creditPoint : totalRealPrice;

  return totalRealPrice < 0 ? 0 : totalRealPrice;
};

export function calculatePoValue(transaction: Order) {
  let poValue = 0;
  const { transactionItems, bottleGuarantee } = transaction;
  for (const { price, quantity, priceCut } of transactionItems) {
    poValue += (price - priceCut) * quantity;
  }
  if (bottleGuarantee) {
    for (const { pricePerBottle, qty } of bottleGuarantee) {
      poValue += pricePerBottle * qty;
    }
  }
  return poValue;
}

export function calculateCreditPointUse(
  orderCreditPointUse: number,
  assignedDeliveryDrivers: Order['assignedDeliveryDrivers'],
) {
  const canceledDriverCreditPointUse = assignedDeliveryDrivers
    .filter(({ assignedOrder }) =>
      [
        'CANCELED_BY_ADMIN',
        'CANCELED_BY_DRIVER',
        'CANCELED_BY_CUSTOMER',
      ].includes(assignedOrder.status),
    )
    .map(({ assignedOrder }) => assignedOrder.creditPointUse)
    .reduce((acc, curr) => acc + curr, 0);
  const creditPointUse = orderCreditPointUse - canceledDriverCreditPointUse;

  return creditPointUse;
}
