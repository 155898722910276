import gql from 'graphql-tag';

export const SEGMENT_SYNC = gql`
  mutation segmentSync {
    segmentSync {
      id
      active
    }
  }
`;

export const PRODUCT_SYNC = gql`
  mutation productSync {
    productSync {
      created
      updated
    }
  }
`;

export const ORDER_SYNC = gql`
  mutation orderSync {
    orderSync {
      count
    }
  }
`;
