import jwt from 'jsonwebtoken';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { Navbar as OriginNavbar, Query } from '../components';
import { ALICE_BLUE } from '../constants/colors';
import {
  IS_CMS_AUTHENTICATED,
  IsCmsAuthenticatedData,
} from '../graphql/queries';
import { SetNewPasswordScene } from '../scenes';
import { PageAccessQuery, getPageAccess } from './PageAccess';
import {
  AUTHENTICATED_ROUTES,
  Access,
  DEFAULT_ROUTES,
  RouteItem,
} from './routeUtils';

type PageAccess = Omit<Access, 'role'>;

const styles = StyleSheet.create({
  flex: { flex: 1 },
  rootContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  pageFrame: {
    paddingLeft: 80,
    flex: 1,
    backgroundColor: ALICE_BLUE,
  },
  navbarContainer: {
    zIndex: 100,
  },
});

function PageFrame({ content }: { content: JSX.Element }) {
  return (
    <View style={styles.pageFrame}>
      <Box
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {content}
      </Box>
    </View>
  );
}

function Navbar(props: {
  routes: Array<RouteItem & { access: PageAccess }>;
  pageAccesses: Array<PageAccess>;
  role: string;
}) {
  return (
    <View style={styles.navbarContainer}>
      <OriginNavbar {...props} />
    </View>
  );
}

export default function MainRoute() {
  const _renderRoutes = (
    filteredRoutes: Array<RouteItem & { access: PageAccess }>,
    bottlePageAccess?: PageAccess,
  ) => {
    return (
      <Switch>
        {filteredRoutes.map(
          ({ access, nickname, component: C, ...routeInfo }, i) => (
            <Route
              exact
              key={i}
              render={(props) => (
                <C
                  {...props}
                  filteredRoutes={routeInfo.path === '/' && filteredRoutes}
                  nickname={nickname}
                  access={access}
                  bottleAccess={nickname === 'Pemesanan' && bottlePageAccess}
                />
              )}
              {...routeInfo}
            />
          ),
        )}
      </Switch>
    );
  };

  const _renderRoutesWithNavbar = (
    filteredRoutes: Array<RouteItem & { access: PageAccess }>,
    bottlePageAccess: PageAccess,
    pageAccesses: Array<PageAccess>,
    role: string,
  ) => (
    <View style={styles.rootContainer}>
      <Navbar routes={filteredRoutes} pageAccesses={pageAccesses} role={role} />
      <PageFrame content={_renderRoutes(filteredRoutes, bottlePageAccess)} />
    </View>
  );

  const _renderRoutesResetPassword = () => (
    <Route exact path="/:token" component={SetNewPasswordScene} />
  );

  const _checkPathName = () => {
    const getToken = window.location.pathname.slice(1);
    try {
      const resultToken: any = jwt.decode(getToken);
      return resultToken && resultToken.page === 'reset-password';
    } catch (error) {
      return false;
    }
  };

  return (
    <Query<IsCmsAuthenticatedData> query={IS_CMS_AUTHENTICATED}>
      {({ data, refetch }) => {
        const isResetPasswordToken = _checkPathName();

        const isCmsAuthenticated = data && data.isCmsAuthenticated;

        const routeList = isCmsAuthenticated
          ? AUTHENTICATED_ROUTES
          : DEFAULT_ROUTES;
        return (
          <PageAccessQuery
            render={({ pageAccesses, role }) => {
              const bottlePageAccess = getPageAccess(
                pageAccesses,
                role,
                'Jaminan Botol',
              );

              const filteredRoutes = routeList
                .map(({ nickname, ...routeInfo }) => {
                  const access = getPageAccess(pageAccesses, role, nickname);
                  return {
                    ...routeInfo,
                    nickname,
                    access,
                  };
                })
                .filter(
                  ({ nickname, access }) =>
                    access.read ||
                    (nickname === 'Pemesanan' && bottlePageAccess.read),
                );

              return (
                <AuthenticatedContext.Provider value={refetch}>
                  <BrowserRouter>
                    {isCmsAuthenticated
                      ? _renderRoutesWithNavbar(
                          filteredRoutes,
                          bottlePageAccess,
                          pageAccesses,
                          role || '',
                        )
                      : isResetPasswordToken
                      ? _renderRoutesResetPassword()
                      : _renderRoutes(filteredRoutes, bottlePageAccess)}
                  </BrowserRouter>
                </AuthenticatedContext.Provider>
              );
            }}
          />
        );
      }}
    </Query>
  );
}

export const AuthenticatedContext = React.createContext(() => {});
