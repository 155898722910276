import {
  AccessProps,
  ClaimType,
  GiftType,
  MaximalQuantityProgramType,
  Product,
  Program,
  ProgramSegment,
  ProgramSubSegment,
  ProgramTerm,
  ProgramType,
  ProgramUser,
  RewardType,
  Term,
} from 'graphql/queries';

import { BundleProduct, LoyaltyTerm } from '../modals';

import {
  CREATE_PROGRAM,
  CreateProgramParams,
  EDIT_PROGRAM,
  EditProgramParams,
} from 'graphql/mutations';
import { formatTime, isObjectEqual } from 'helpers';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Mutation } from 'react-apollo';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { DepotSelectionItem } from '../modals/DepoSelectionModal';
import { Form } from './components';
import { calculateBudget } from './components/Form/helpers';
import { FormProvider } from './contexts';
import { isFormValid } from './helpers';
import { getProgramData } from './helpers/getProgramData';

export type FormState = {
  id: string;
  programType: Nullable<ProgramType>;
  rewardType: Nullable<RewardType>;
  title: string;
  description: string;
  startDate: string;
  startDateTime: string;
  endDate: string;
  endDateTime: string;
  totalPrice: number;
  // Hadiah Barang
  giftType: Nullable<GiftType>;
  giftName: string;
  giftPrice: number;
  // Loyalty Produk TVIP
  tvipProduct: Nullable<Product>;
  // Trade Promo
  claimType: ClaimType;
  claimLimit: string;
  // Kuantitas Maksimal
  maximalQuantity: number;
  // Product Selection
  selectedProducts: Array<Product>;
  // Depot Selection
  selectedDepots: Array<DepotSelectionItem>;
  // Segment Selection
  selectedSegments: Array<ProgramSegment>;
  // Subsegment Selection
  selectedSubSegments: Array<ProgramSubSegment>;
  // Customer Selection
  selectedCustomers: Array<ProgramUser>;
  // Terms
  selectedTerms: Array<Term>;
  // Loyalty Term
  selectedLoyaltyTerms: Array<LoyaltyTerm>;
  // Bundle Product
  selectedBundleProducts: Array<BundleProduct>;
  // Flash Sale Terms
  selectedFlashSaleTerms: Map<string, Array<Term>>;
  titleNotification: string;
  bodyNotification: string;
  programUsers: Array<ProgramUser>;
  maximalQuantityProgram: MaximalQuantityProgramType;
  maximalQuantityLimit: MaximalQuantityProgramType;
  programTerms: Array<ProgramTerm>;
};

type NewProgramFormSceneProps = AccessProps &
  RouteComponentProps<{}, StaticContext, { programData: Program }> &
  ToastContextProps;

export const DEFAULT_FORM_STATE: FormState = {
  id: '',
  programType: null,
  rewardType: null,
  title: '',
  description: '',
  startDate: new Date().toISOString(),
  startDateTime: '00:00',
  endDate: new Date().toISOString(),
  endDateTime: '00:00',
  totalPrice: 0,
  giftType: null,
  giftName: '',
  giftPrice: 0,
  tvipProduct: null,
  claimType: 'WHOLE',
  claimLimit: '',
  maximalQuantity: 0,
  selectedProducts: [],
  selectedDepots: [],
  selectedSegments: [],
  selectedSubSegments: [],
  selectedCustomers: [],
  selectedTerms: [],
  selectedLoyaltyTerms: [],
  selectedBundleProducts: [],
  selectedFlashSaleTerms: new Map() as Map<string, Array<Term>>,
  titleNotification: '',
  bodyNotification: '',
  programUsers: [],
  maximalQuantityProgram: 'LIMITED',
  maximalQuantityLimit: 'LIMITED',
  programTerms: [],
};

function NewProgramFormSceneComponent({
  openToast,
  location,
  history,
}: NewProgramFormSceneProps) {
  const [formState, setFormState] = useState<FormState>(DEFAULT_FORM_STATE);

  const isEdit = !(
    location.pathname === '/programs/new' ||
    location.pathname === '/programs/duplicate'
  );

  const isDuplicate = location.pathname === '/programs/duplicate';

  const {
    programType,
    claimLimit,
    claimType,
    selectedBundleProducts,
    selectedCustomers,
    selectedFlashSaleTerms,
    selectedLoyaltyTerms,
    selectedTerms,
  } = formState;

  const totalPrice = useMemo(() => {
    return calculateBudget({
      programType,
      claimLimit,
      claimType,
      selectedBundleProducts,
      selectedCustomers,
      selectedFlashSaleTerms,
      selectedLoyaltyTerms,
      selectedTerms,
    });
  }, [
    programType,
    claimLimit,
    claimType,
    selectedBundleProducts,
    selectedCustomers,
    selectedFlashSaleTerms,
    selectedLoyaltyTerms,
    selectedTerms,
  ]);

  const initialData = useMemo<FormState>(() => {
    const programData = location?.state?.programData;

    if (!programData) {
      return DEFAULT_FORM_STATE;
    }

    const {
      programType,
      products,
      customers,
      programDepot,
      programSegment,
      programSubSegment,
      terms: programTerms,
      claimLimit,
      programUser,
      ...restProgramData
    } = programData;

    const selectedProducts: Product[] =
      programType !== 'BUNDLE' ? products : [];
    const selectedBundleProducts: BundleProduct[] =
      programType === 'BUNDLE'
        ? programTerms.map(({ id: programTermId, product, terms }) => ({
            programTermId,
            product,
            id: terms[0].id,
            minimalPurchase: terms[0].minimalPurchase,
            rewardQty: terms[0].rewardQty,
            state: isDuplicate ? 'new' : 'unchanged',
          }))
        : [];

    const selectedFlashSaleTerms: Map<string, Array<Term>> =
      programType === 'FLASH_SALE'
        ? new Map(
            programTerms.map((programTerm) => [
              programTerm.product.id,
              programTerm.terms.map((term) => ({
                ...term,
                programTermId: programTerm.id,
                state: isDuplicate ? 'new' : 'unchanged',
              })),
            ]),
          )
        : new Map();
    const selectedLoyaltyTerms: LoyaltyTerm[] =
      programType === 'LOYALTY'
        ? programTerms.map(({ user, terms, id }) => ({
            id: terms[0].id,
            state: isDuplicate ? 'new' : 'unchanged',
            programTermId: id,
            customer: user,
            minimalPurchase: terms[0].minimalPurchase,
            rewardQty: terms[0].rewardQty,
          }))
        : [];
    const selectedTerms: Term[] = programTerms.flatMap(
      ({ id: programTermId, terms }) => {
        return terms.map((term) => ({
          __typename: term.__typename,
          programTermId,
          id: term.id,
          minimalPurchase: term.minimalPurchase,
          rewardQty: term.rewardQty,
          state: isDuplicate ? 'new' : 'unchanged',
        }));
      },
    );
    const selectedSegments: ProgramSegment[] = programSegment;
    const selectedSubSegments: ProgramSubSegment[] = programSubSegment;
    // TODO: Figuring out this line
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectedCustomers: ProgramUser[] = programUser;
    const selectedDepots: DepotSelectionItem[] = programDepot.map((depot) => ({
      id: depot.id,
      depot: depot.depot,
      qty: depot.maxQty,
    }));

    return {
      ...DEFAULT_FORM_STATE,
      ...restProgramData,
      programType,
      claimLimit: claimLimit.toString(),
      selectedDepots,
      selectedSubSegments,
      selectedSegments,
      selectedProducts,
      selectedBundleProducts,
      selectedCustomers: programUser,
      selectedFlashSaleTerms,
      selectedLoyaltyTerms,
      selectedTerms,
      endDateTime: formatTime(restProgramData.endDate),
      startDateTime: formatTime(restProgramData.startDate),
      maximalQuantity: programUser[0]?.outletMaxQty || 0,
      programUsers: programUser,
      maximalQuantityLimit: programDepot[0]?.limitType || 'LIMITED',
      maximalQuantityProgram: programUser[0]?.limitType || 'LIMITED',
      programTerms,
    };
  }, [location?.state]);

  const currentFormState = {
    ...formState,
    totalPrice,
  };

  const isDirty = !isObjectEqual(initialData, formState);
  const isValid = isFormValid(formState);
  const onDiscard = () => {
    setFormState(initialData);
  };

  const onValuesChange = useCallback((values: Partial<FormState>) => {
    setFormState((prevState) => ({
      ...prevState,
      ...values,
    }));
  }, []);

  useEffect(() => {
    if (isEdit && (!location.state || !location.state.programData)) {
      history.push('/programs');
    }

    setFormState(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider formState={currentFormState} onValuesChange={onValuesChange}>
      <Mutation<{ id: string }, EditProgramParams | CreateProgramParams>
        mutation={isEdit ? EDIT_PROGRAM : CREATE_PROGRAM}
        onCompleted={() => {
          openToast('success', 'Program baru telah ditambahkan');
          history.push('/programs');
        }}
        onError={() => openToast('fail', 'Program baru gagal ditambahkan')}
      >
        {(submitProgram, { loading }) => (
          <Form
            isDirty={isDirty}
            isLoading={loading}
            isValid={isValid}
            onDiscard={onDiscard}
            onSubmit={() =>
              submitProgram({
                variables: {
                  data: getProgramData(
                    currentFormState,
                    isDuplicate ? DEFAULT_FORM_STATE : initialData,
                  ),
                  id: isEdit ? currentFormState.id : undefined,
                },
              })
            }
            isEdit={isEdit}
            goBack={history.goBack}
          />
        )}
      </Mutation>
    </FormProvider>
  );
}

export const NewProgramFormScene = withToast(
  NewProgramFormSceneComponent,
) as ComponentType;
