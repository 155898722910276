import classNames from 'classnames';
import React, { ChangeEvent, Component, ReactElement, ReactNode } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { NoDataPlaceholder } from '..';
import {
  ALICE_BLUE,
  BACKDROP_TRANSPARENT,
  BLACK,
  GRAY,
  PRIMARY,
  WHITE,
} from '../../constants/colors';
import { Button, Icon, Loading, Text } from '../../core-ui';
import { getSorting } from '../../helpers';
import TablePaginationAction from './TablePagination';

import { SearchState, SelectedState } from '../../graphql/localState';
import {
  GET_SEARCH_STATE,
  GET_TABLE_SELECTED_STATE,
  UPDATE_TABLE_SELECTED,
} from '../../graphql/queries';

export const DEFAULT_ROWS_PER_PAGE = 10;

export type RowsPerPage = 5 | 10 | 25 | 50;

type SelectedStateProps = {
  selectedStateQuery: DataValue<SelectedState, {}>;
};

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type StructureStyle = Array<
  'grey' | 'alignCenter' | 'narrowNumberColumn' | 'alignRight' | ObjectKey
>;

type StructureElement<T extends ObjectKey = any> = {
  alias?: string;
  render?: (data: T, index: number) => ReactNode;
  isOrder?: boolean;
  style?: StructureStyle;
  headerTitle?: string;
  noHeaderName?: boolean;
  headerCenter?: boolean;
  processor?: (data: string) => string;
};

export type TableStructure<T extends ObjectKey = any> = {
  [key: string]: StructureElement<T> & {
    width?: number;
  };
};

export type LoadMoreParams = {
  skip: number;
  first: number;
  searchInput?: string;
};

type State = {
  orderBy: Optional<string>;
  activeOrder: number;
  isDescending: boolean;
  clicked?: number;
};

type OwnProps<T extends ObjectKey = any> = {
  buttonText?: string;
  data: Array<T>;
  dataCount: number;
  isLoading?: boolean;
  noDataPlaceholder?: string;
  resetPage?: boolean;
  rowsPerPage: RowsPerPage;
  showCheckboxes?: boolean;
  structure: TableStructure<T>;
  loadMore: (params: LoadMoreParams) => void;
  multiSelectAction?: () => void;
  onChangeRowsPerPage: (newRowsPerPage: RowsPerPage) => void;
  setResetPage?: (isReset: boolean) => void;
  showFooter?: boolean;
  narrowNumberColumn?: boolean;
  extraStyles?: ObjectKey<string>;
  page: number;
  onChangePage: (nextPage: number) => void;
};

type Props<T extends ObjectKey = any> = OwnProps<T> &
  WithStyles<typeof styles> &
  SelectedStateProps &
  SearchStateProps &
  UpdateSelectedData;

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export class CustomizedTable<T extends ObjectKey = any> extends Component<
  Props<T>,
  State
> {
  state = {
    orderBy: null,
    activeOrder: 0,
    isDescending: false,
    clicked: -1,
  };

  componentDidUpdate() {
    const { resetPage, isLoading, setResetPage } = this.props;
    if (resetPage && isLoading) {
      // hacky way to reset page to 0 on search due to pagination
      setResetPage && setResetPage(false);
      this.props.onChangePage(0);
    }
  }

  render() {
    const {
      isLoading,
      noDataPlaceholder = 'Tidak ada data',
      searchStateQuery: { searchState },
      data,
      selectedStateQuery: { selectedState },
      showFooter = true,
      classes,
    } = this.props;

    const loading = isLoading != null ? isLoading : true;

    if (!data && !Array.isArray(data) && loading) {
      return <Loading />;
    }
    if ((data && data.length < 1) || !data) {
      if (searchState && searchState.searchedString.length > 0) {
        const notFoundSearch = `'${searchState.searchedString}' tidak ditemukan`;
        return <NoDataPlaceholder text={notFoundSearch} />;
      }
      return <NoDataPlaceholder text={noDataPlaceholder} />;
    }
    return (
      <Paper square elevation={0} className={classes.root}>
        {selectedState &&
        selectedState.selectedArray &&
        selectedState.selectedArray.length > 0
          ? this._renderCheckboxIndicator(selectedState.selectedArray)
          : null}
        <Paper elevation={0} className={classes.bodyRoot}>
          <Table>
            {!loading && this._renderTableBody()}
            {selectedState &&
            selectedState.selectedArray &&
            selectedState.selectedArray.length > 0
              ? null
              : this._renderTableHead()}
          </Table>
          {loading && (
            <View style={nativeStyles.emptyContainer}>
              <Loading />
            </View>
          )}
        </Paper>
        {showFooter && this._renderPagination()}
      </Paper>
    );
  }

  _styleProcessor = (inputStyles?: StructureStyle) => {
    const { classes } = this.props;
    if (inputStyles) {
      const stylesArr = inputStyles.map((style) => {
        if (typeof style === 'string') {
          return classes[style];
        }
        // NOTE: to handle parent style inject
        // but still not working
        return StyleSheet.flatten(style);
      });
      return classNames(...stylesArr);
    }
    return undefined;
  };

  _renderCheckboxAll = (selectedCount: number) => {
    const { data, classes } = this.props;
    const rowCount = data.length;
    return (
      <Checkbox
        indeterminate={selectedCount > 0 && selectedCount <= rowCount}
        indeterminateIcon={
          <Icon name="indeterminate_check_box" color={PRIMARY} size="small" />
        }
        checked={selectedCount === rowCount}
        onChange={(event) => this._handleSelectAll(event, selectedCount, data)}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
      />
    );
  };

  _renderCheckbox = (rowId: number) => {
    const {
      classes,
      selectedStateQuery: { selectedState },
    } = this.props;
    let isSelected;
    if (selectedState) {
      const { selectedArray } = selectedState;
      isSelected = (id: string) => selectedArray.includes(id);
    }
    return (
      <Checkbox
        checked={isSelected && isSelected(String(rowId))}
        onChange={(_) => this._handleSelected(String(rowId))}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
      />
    );
  };

  _renderCheckboxIndicator = (selectedArray: Array<string>) => {
    const { data, buttonText, multiSelectAction } = this.props;
    return (
      <View style={nativeStyles.checkboxContainer}>
        <View style={nativeStyles.checkboxAll}>
          {this._renderCheckboxAll(selectedArray.length)}
        </View>
        <View style={nativeStyles.checkboxSelection}>
          <Text style={nativeStyles.checkboxSelectionText}>
            Selected: {selectedArray.length} out of {data.length}
          </Text>
          <Button
            text={buttonText || 'Suspend Account'}
            inverted
            onPress={() => {
              if (multiSelectAction) {
                multiSelectAction();
              }
              this._clearSelected();
            }}
          />
        </View>
      </View>
    );
  };

  _handleOrderPressed(orderIndex: number, orderBy: string) {
    const { activeOrder, isDescending } = this.state;
    this.setState({
      orderBy,
      activeOrder: orderIndex,
      isDescending: activeOrder === orderIndex ? !isDescending : false,
    });
  }

  _renderTableHead = () => {
    const { activeOrder, isDescending } = this.state;
    const {
      structure,
      classes,
      showCheckboxes,
      narrowNumberColumn,
    } = this.props;
    const structures = Object.entries(structure);
    const transitionTiming = isDescending ? 'ease-out' : 'ease';
    const headerContent = structures.map(([headerName, options], index) => {
      const {
        style,
        noHeaderName,
        headerTitle,
        alias,
        isOrder,
        headerCenter,
      } = structure[headerName];
      const headerStyle: StyleProp<ViewStyle> = headerCenter
        ? {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {
            flexDirection: 'row',
            alignItems: 'center',
          };
      const degreeRotate =
        isDescending && index === activeOrder ? '180deg' : '0deg';
      const headerChild = (
        <View style={[headerStyle]}>
          <Text
            size="xsmall"
            weight="bold"
            color={isOrder && index === activeOrder ? BLACK : undefined}
          >
            {headerTitle ? headerTitle.toUpperCase() : headerName.toUpperCase()}
          </Text>
          {isOrder && (
            <Icon
              size="small"
              color={index === activeOrder ? BLACK : undefined}
              containerStyle={
                ({
                  transform: [{ rotate: degreeRotate }],
                  transitionDuration: '0.5s',
                  transitionProperty: 'transform',
                  transitionTimingFunction: transitionTiming,
                } as unknown) as ViewStyle // NOTE: this is necessary as transitions are part of CSS styles, not ViewStyle
              }
              name="arrow_drop_down"
              onPress={() =>
                this._handleOrderPressed(
                  index,
                  isOrder && alias ? alias : headerName,
                )
              }
            />
          )}
        </View>
      );
      return (
        <TableCell
          className={classNames(
            classes.cellRoot,
            this._styleProcessor([
              classes,
              'grey',
              narrowNumberColumn ? 'narrowNumberColumn' : {},
            ]),
          )}
          key={index}
        >
          {noHeaderName ? null : isOrder ? (
            <TouchableOpacity
              onPress={() =>
                this._handleOrderPressed(index, alias || headerName)
              }
            >
              {headerChild}
            </TouchableOpacity>
          ) : (
            headerChild
          )}
        </TableCell>
      );
    });
    return (
      <TableHead className={classes.headerRow}>
        <TableRow>
          {showCheckboxes ? (
            <TableCell>{this._renderCheckboxAll(0)}</TableCell>
          ) : null}
          <TableCell
            className={classNames(
              classes.cellRoot,
              this._styleProcessor([
                'grey',
                narrowNumberColumn ? 'narrowNumberColumn' : {},
                'alignCenter',
              ]),
            )}
          >
            <Text size="xsmall" weight="bold">
              NO
            </Text>
          </TableCell>
          {headerContent}
        </TableRow>
      </TableHead>
    );
  };

  _renderTableRow = (currentRow: T, parentIndex: number) => {
    const {
      structure,
      classes,
      showCheckboxes,
      narrowNumberColumn,
      extraStyles = {},
    } = this.props;
    const cells = Object.keys(structure);
    const id = 'id' as keyof T;

    return (
      <TableRow
        className={`${classes.row} ${extraStyles.row}`}
        key={parentIndex}
      >
        {showCheckboxes ? (
          <TableCell>{this._renderCheckbox(Number(currentRow[id]))}</TableCell>
        ) : null}
        <TableCell
          onClick={() => {
            this.setState({
              clicked: parentIndex,
            });
          }}
          style={{
            backgroundColor:
              this.state.clicked === parentIndex ? ALICE_BLUE : '',
          }}
          className={classNames(
            classes.cellRoot,
            this._styleProcessor([
              narrowNumberColumn ? 'narrowNumberColumn' : {},
              'alignCenter',
            ]),
          )}
        >
          <Text size="small">{parentIndex + 1}</Text>
        </TableCell>
        {cells.map((headerName, index) => {
          const { style, render, alias, processor } = structure[headerName];
          const dataRow = alias
            ? this._aliasResolver(currentRow, alias)
            : currentRow[headerName as keyof T];
          return (
            <TableCell
              onClick={() => {
                this.setState({
                  clicked: parentIndex,
                });
              }}
              style={{
                backgroundColor:
                  this.state.clicked === parentIndex ? ALICE_BLUE : '',
              }}
              className={classNames(
                classes.body,
                extraStyles.cell,
                classes.cellRoot,
                this._styleProcessor(style),
              )}
              key={index}
            >
              {render ? (
                render(currentRow, parentIndex)
              ) : (
                <Text size="small" style={nativeStyles.cellText}>
                  {dataRow != null
                    ? processor
                      ? processor(String(dataRow))
                      : String(dataRow).toUpperCase()
                    : '-'}
                </Text>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  _getSortedData() {
    const { data, structure, page } = this.props;
    const { isDescending, orderBy } = this.state;
    const { rowsPerPage } = this.props;
    const fields = Object.keys(structure);
    const beginIndex = page * rowsPerPage;
    const endIndex = page * rowsPerPage + rowsPerPage;
    let initialSortedField = '' as keyof T;
    fields.forEach((field) => {
      const { alias, isOrder } = structure[field];
      if (isOrder) {
        initialSortedField = (alias || field) as keyof T;
      }
    });
    const orderField = orderBy || initialSortedField;
    const dataToDisplay = data.slice(beginIndex, endIndex);
    return {
      sortedData: dataToDisplay.sort(
        getSorting(isDescending, orderField as keyof T),
      ),
      beginIndex,
      endIndex,
    };
  }

  _renderTableBody = () => {
    const { sortedData, beginIndex } = this._getSortedData();
    const { classes } = this.props;

    return (
      <TableBody className={classes.body}>
        {sortedData.map((currentRow, parentIndex) =>
          this._renderTableRow(currentRow, parentIndex + beginIndex),
        )}
      </TableBody>
    );
  };

  _renderPagination = () => {
    const { classes, dataCount, rowsPerPage, page } = this.props;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              colSpan={8}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={this._handleChangePage}
              onRowsPerPageChange={this._handleChangeRowsPerPage}
              ActionsComponent={TablePaginationAction}
              labelDisplayedRows={({ to, count }) => (
                <Text size="small">
                  {to} dari {count}
                </Text>
              )}
              labelRowsPerPage={<Text size="small">Baris per halaman</Text>}
              classes={{
                root: classes.caption,
                select: classes.select,
                caption: classes.caption,
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  _handleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    selectedCount: number,
    data: Array<ObjectKey>,
  ) => {
    const { updateMultiTable } = this.props;
    if (event.target.checked && selectedCount === 0) {
      updateMultiTable({
        variables: {
          selectedArray: data.map((datum) => String(datum.id)),
        },
      });
      return;
    }
    this._clearSelected();
  };

  _handleSelected = (id: string) => {
    const {
      selectedStateQuery: { selectedState },
      updateMultiTable,
    } = this.props;
    if (selectedState) {
      const { selectedArray } = selectedState;
      const isSelectedIndex = selectedArray.includes(id);
      const selectedSet = new Set(selectedArray);

      if (isSelectedIndex) {
        selectedSet.delete(id);
      } else {
        selectedSet.add(id);
      }
      const selectedFinalArray = Array.from(selectedSet);
      updateMultiTable({
        variables: {
          selectedArray: selectedFinalArray,
        },
      });
    }
  };

  _clearSelected = () => {
    const { updateMultiTable } = this.props;
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  };

  _aliasResolver = (data: ObjectKey, alias: string, index = 0): string => {
    const keys = alias.split('.');
    const temp = data ? data[keys[index]] : '';
    if (typeof temp === 'string' || typeof temp === 'number') {
      return String(temp);
    }
    return this._aliasResolver(temp, alias, ++index);
  };

  _handleChangePage = (_: CustomMouseEvent, nextPage: number) => {
    const {
      data,
      loadMore,
      dataCount,
      searchStateQuery: { searchState },
      rowsPerPage,
      page,
    } = this.props;
    const isNextPagePressed = nextPage === page + 1;
    const totalPage = Math.ceil(dataCount / rowsPerPage);
    const nextPageIsLastPage = nextPage + 1 === totalPage;
    if (nextPageIsLastPage && data.length < dataCount) {
      loadMore({
        skip: data.length,
        first: dataCount - data.length,
        searchInput: searchState && searchState.searchedString,
      });
    } else if (
      !nextPageIsLastPage &&
      isNextPagePressed &&
      data.length < rowsPerPage * (nextPage + 1)
    ) {
      loadMore({
        skip: data.length,
        first: rowsPerPage,
        searchInput: searchState && searchState.searchedString,
      });
    }
    this.props.onChangePage(nextPage);
  };

  _handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { rowsPerPage } = this.props;
    const newRowsPerPage = Number(event.target.value) as RowsPerPage;
    if (newRowsPerPage !== rowsPerPage) {
      this.props.onChangeRowsPerPage(newRowsPerPage);
    }
  };
}

const nativeStyles = StyleSheet.create({
  checkboxContainer: { flexDirection: 'row' },
  checkboxAll: { paddingHorizontal: 20 },
  checkboxSelection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 10,
  },
  checkboxSelectionText: { alignSelf: 'center' },
  cellText: { letterSpacing: 1.5 },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 150,
  },
});

const styles = createStyles({
  root: { backgroundColor: 'transparent' },
  cellRoot: {
    padding: '4px 18px 4px 16px',
  },
  head: {
    maxHeight: 17,
    color: BACKDROP_TRANSPARENT,
    letterSpacing: 2,
    border: 'none',
    padding: '0px 20px 0px 20px',
  },
  bodyRoot: {
    overflowX: 'auto',
    backgroundColor: 'transparent',
    paddingRight: 1, // NOTE: this is necessary to counter-balance the right border of last-child
  },
  body: {
    '&:last-child': {
      borderRight: `1px solid ${GRAY}`,
    },
    backgroundColor: WHITE,
    fontSize: 15,
    fontFamily: 'Rubik-Regular',
  },
  checkboxRoot: {
    '&$checked': {
      color: PRIMARY,
    },
  },
  checked: {},
  alignCenter: {
    textAlign: 'center',
  },
  narrowNumberColumn: {
    width: 50,
  },
  alignRight: {
    textAlign: 'right',
  },
  grey: {
    color: BACKDROP_TRANSPARENT,
  },
  caption: {
    borderStyle: 'none',
    fontSize: 15,
    color: BACKDROP_TRANSPARENT,
    fontFamily: 'Rubik-Regular',
  },
  select: {
    padding: '7px 20px 7px 0px',
  },
  row: {
    height: 60,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY}`,
  },
  headerRow: {
    color: BACKDROP_TRANSPARENT,
  },
});

export default compose(
  graphql<OwnProps, SelectedState, {}, SelectedStateProps>(
    GET_TABLE_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_TABLE_SELECTED,
    { name: 'updateMultiTable' },
  ),
  graphql<OwnProps, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(withStyles(styles)(CustomizedTable)) as <T extends ObjectKey = any>(
  props: OwnProps<T>,
) => ReactElement;
