import React, { Fragment } from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  ScrollView,
  StyleProp,
  ViewStyle,
} from 'react-native';

import { Icon, Text } from '.';
import { DROPDOWN, WHITE, DARK_GRAY } from '../constants/colors';
import BOX_SHADOW from '../constants/boxShadow';
import { HEIGHT_TEXT_INPUT } from '../constants/boxSize';

type State = {
  isOpen: boolean;
  hoveredIndex: Nullable<number>;
  hoveredID: Nullable<string>;
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  style?: StyleProp<ViewStyle>;
  showTime?: boolean;
};

const hour = Array(24)
  .fill(null)
  .map((_, index) => String(`0${index}`).slice(-2));

const minutes = Array(60)
  .fill(null)
  .map((_, index) => String(`0${index}`).slice(-2));

const WIDTH_BOX = 130;

export default class TimePickerComponent extends React.Component<Props, State> {
  state = {
    isOpen: false,
    hoveredIndex: null,
    hoveredID: null,
  };

  render() {
    const { style, value, showTime = false } = this.props;
    const { isOpen, hoveredIndex } = this.state;
    // TODO : Enhance with indicator of the selected values #190 comment
    return (
      <View style={[styles.container, style]}>
        <TouchableOpacity onPress={() => this.setState({ isOpen: !isOpen })}>
          <>
            <View
              style={[styles.fieldWrapper, isOpen && styles.timepickerOpened]}
            >
              <TextInput
                value={value}
                editable={false}
                onBlur={() =>
                  hoveredIndex === null && this.setState({ isOpen: false })
                }
                style={styles.textInput}
              />
              <Icon
                size="small"
                name="access_time"
                containerStyle={isOpen ? styles.iconOpened : styles.iconClosed}
                color={isOpen ? DROPDOWN.ACTIVE : DARK_GRAY}
              />
            </View>
          </>
        </TouchableOpacity>
        {isOpen && this._renderTimeBox(showTime)}
      </View>
    );
  }

  _renderTimeBox = (showTime: boolean) => (
    <View style={styles.optionsWrapper}>
      <ScrollView style={styles.timeWrapper}>
        {hour.map((item, index) => this._renderOption(item, index, 'hour'))}
      </ScrollView>
      {showTime && (
        <ScrollView style={styles.timeWrapper}>
          {minutes.map((item, index) =>
            this._renderOption(item, index, 'minutes'),
          )}
        </ScrollView>
      )}
    </View>
  );

  _renderOption = (item: string, index: number, id: string) => {
    const { hoveredIndex, hoveredID } = this.state;
    const isCurrentlyHovered = index === hoveredIndex && id === hoveredID;
    return (
      <TouchableOpacity
        onPress={() => this._onOptionPress({ item, id })}
        key={item}
      >
        <View
          onMouseOver={() => this._onMouseOver(index, id)}
          onMouseLeave={() => this._onMouseOver(null, id)}
          style={[styles.option, isCurrentlyHovered && styles.optionHovered]}
        >
          <Text>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  _onOptionPress = ({ item, id }: { item: string; id: string }) => {
    const { onChange, value } = this.props;
    let newValue = value;

    if (id === 'hour') {
      newValue = value.replace(/^.{2}/g, item);
    } else if (id === 'minutes') {
      newValue = value.replace(/.{2}$/, item);
    }

    onChange(newValue);
  };

  _onMouseOver = (index: Nullable<number>, id: string) =>
    this.setState({ hoveredIndex: index, hoveredID: id });
}

const styles = StyleSheet.create({
  container: {
    width: WIDTH_BOX,
  },
  textInput: {
    width: WIDTH_BOX - 40,
  },
  fieldWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: DROPDOWN.BACKGROUND,
    paddingRight: 5,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT_TEXT_INPUT,
  },
  timepickerOpened: {
    backgroundColor: DROPDOWN.BACKGROUND,
    borderColor: DROPDOWN.ACTIVE,
    borderWidth: 1,
  },
  iconOpened: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    transform: [{ rotate: '180deg' }],
    transitionProperty: 'transform',
  },
  iconClosed: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease',
    transform: [{ rotate: '0deg' }],
    transitionProperty: 'transform',
  },
  optionsWrapper: {
    position: 'absolute',
    borderRadius: 4,
    borderColor: DROPDOWN.ACTIVE,
    top: 35,
    boxShadow: BOX_SHADOW,
    backgroundColor: WHITE,
    maxHeight: 170,
    borderWidth: 1,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  timeWrapper: {
    paddingVertical: 15,
    borderRightWidth: 1,
  },
  timeFormatWrapper: {
    paddingVertical: 15,
  },
  option: {
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  optionHovered: {
    backgroundColor: DROPDOWN.HOVERED,
  },
});
