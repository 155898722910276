import React, { ReactNode } from 'react';
import { OperationVariables, ApolloQueryResult } from 'apollo-client';
import { Query } from '../components';
import {
  PageAccess,
  GET_ACCESS_PRIVILEGES,
  PageAccessData,
} from '../graphql/queries';
import { asyncStorage } from '../helpers';

export type RefetchFn<TData = any, TVars = OperationVariables> = (
  variables?: TVars | undefined,
) => Promise<ApolloQueryResult<TData>>;

type State = {
  role: Nullable<string>;
};

type Props = {
  render: (props: {
    pageAccesses: Array<PageAccess>;
    role: Nullable<string>;
    isLoading: boolean;
    refetch: RefetchFn;
  }) => ReactNode;
  pollInterval?: number;
  disableLoading?: boolean;
  disableError?: boolean;
};

export class PageAccessQuery extends React.Component<Props, State> {
  state: State = {
    role: null,
  };

  _setRole = async () => {
    this.setState({
      role: await asyncStorage.getRole(),
    });
  };

  componentDidMount() {
    this._setRole();
  }

  render() {
    const { render } = this.props;
    const { role } = this.state;

    return (
      <Query<PageAccessData>
        pollInterval={role ? 600000 : 0}
        query={GET_ACCESS_PRIVILEGES}
        skip={!role}
        variables={{ role }}
        {...this.props}
      >
        {({ data, loading: isPageAccessLoading, refetch: refetchPageAccess }) =>
          render({
            pageAccesses: data && data.pageAccesses ? data.pageAccesses : [],
            role,
            isLoading: isPageAccessLoading,
            refetch: refetchPageAccess,
          })
        }
      </Query>
    );
  }
}

export function getPageAccess(
  pageAccesses: Array<PageAccess>,
  role: Nullable<string>,
  pageName?: string,
) {
  const defaultAccess = {
    create: true,
    read: true,
    update: true,
    delete: true,
    page: '',
    role,
  };

  return (
    pageAccesses.find(({ page }: { page: string }) => page === pageName) ||
    defaultAccess
  );
}
