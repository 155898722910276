import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT, Product } from './productQuery';

/**
 * NOTE: we could simplify these 4 types into 2 types, making products in PriceSegment and priceSegment in ProductPrice optional
 *       but that comes with a drawback: we have to check for their existence when we want to access them
 */
export type PriceSegmentWithoutProducts = {
  id: string;
  title: string;
  priceSegmentCode: string;
};
export type PriceSegment = {
  id: string;
  title: string;
  priceSegmentCode: string;
  products: Array<ProductPriceSegmentWithoutPriceSegment>;
};

export type ProductPriceSegmentWithoutPriceSegment = {
  id: string;
  price: number;
  product: Product;
};
export type ProductPriceSegment = {
  id: string;
  price: number;
  product: Product;
  priceSegment: PriceSegmentWithoutProducts;
};

export const PRICE_SEGMENT_WITHOUT_PRODUCTS_FRAGMENT = gql`
  fragment PriceSegmentWithoutProducts on PriceSegment {
    id
    title
    priceSegmentCode
  }
`;

export const PRODUCT_PRICE_WITHOUT_PRICE_SEGMENT_FRAGMENT = gql`
  fragment ProductPriceWithoutPriceSegment on ProductPriceSegment {
    id
    price
    product {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const PRODUCT_PRICE_SEGMENT_FRAGMENT = gql`
  fragment ProductPriceSegment on ProductPriceSegment {
    ...ProductPriceWithoutPriceSegment
    priceSegment {
      ...PriceSegmentWithoutProducts
    }
  }
  ${PRODUCT_PRICE_WITHOUT_PRICE_SEGMENT_FRAGMENT}
  ${PRICE_SEGMENT_WITHOUT_PRODUCTS_FRAGMENT}
`;

export const PRICE_SEGMENT_FRAGMENT = gql`
  fragment PriceSegment on PriceSegment {
    ...PriceSegmentWithoutProducts
    products {
      ...ProductPriceWithoutPriceSegment
    }
  }
  ${PRICE_SEGMENT_WITHOUT_PRODUCTS_FRAGMENT}
  ${PRODUCT_PRICE_WITHOUT_PRICE_SEGMENT_FRAGMENT}
`;

/**
 * NOTE: productGqlID means it is the product's ID, NOT product's productID
 *       this is used to get all price segments of a given product ID
 */
export type ProductPriceSegmentsResult = {
  productPriceSegments: Array<ProductPriceSegment>;
};
export type ProductPriceSegmentVariables = { productGqlID: string };
export const PRODUCT_PRICE_SEGMENTS_QUERY = gql`
  query productPriceSegments($productGqlID: ID!, $priceSegmentCode: String!) {
    productPriceSegments(
      where: {
        product: { id: $productGqlID }
        priceSegment: { priceSegmentCode_starts_with: $priceSegmentCode }
      }
    ) {
      ...ProductPriceSegment
    }
  }
  ${PRODUCT_PRICE_SEGMENT_FRAGMENT}
`;

/**
 * NOTE: this is used to get all price segments
 */
export type PriceSegmentsWithoutProductsResult = {
  priceSegments: Array<PriceSegment>;
  priceSegmentCode?: string;
};
export const PRICE_SEGMENTS_WITHOUT_PRODUCTS_QUERY = gql`
  query priceSegments($where: PriceSegmentWhereInput) {
    priceSegments(where: $where) {
      ...PriceSegmentWithoutProducts
    }
  }
  ${PRICE_SEGMENT_WITHOUT_PRODUCTS_FRAGMENT}
`;

/**
 * NOTE: this is used to get all products of a given price segment ID
 *       mainly used to get TVIP-C price segment
 */
export type PriceSegmentResult = { priceSegment: PriceSegment };
export type PriceSegmentVariables = { id?: string; priceSegmentCode: string };
export const PRICE_SEGMENT_QUERY = gql`
  query priceSegment($id: ID, $priceSegmentCode: String!) {
    priceSegment(id: $id, priceSegmentCode: $priceSegmentCode) {
      ...PriceSegment
    }
  }
  ${PRICE_SEGMENT_FRAGMENT}
`;
