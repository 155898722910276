/**
 * Return new Date from given 24h format
 */

export default function getTimeFrom24HourFormat(
  time: string,
  date: string | Date,
) {
  const copyOfDate = new Date(date);
  copyOfDate.setHours(parseInt(time.substring(0, 2)));
  copyOfDate.setMinutes(parseInt(time.substring(time.length - 2)));
  return copyOfDate;
}
