import gql from 'graphql-tag';
import { MutationFunc, DataValue } from 'react-apollo';

import { depot } from '../relationalFragments';
import { DepotComplete } from '.';

export type ProviderRole =
  | 'SUPER_ADMIN'
  | 'SUPERVISOR'
  | 'ADMIN_DEPO'
  | 'DISPATCHER'
  | 'CASHIER';
export type Provider = {
  id: string;
  email: string;
  name: string;
  telephone: string;
  role: ProviderRole;
  depot: DepotComplete;
};

export type ProviderListResult = { providers: Array<Provider>; count: number };
export type ProviderListParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};
export type ProviderListData = DataValue<
  ProviderListResult,
  ProviderListParams
>;

export type ProviderResult = { provider: Provider };
export type ProviderVars = { id: string };
export type ProviderData = DataValue<ProviderResult, ProviderVars>;

export type ProviderChangePasswordVars = {
  id: string;
  email: string;
  oldPassword: string;
  password: string;
};

type ProviderChangePasswordResult = { id: string; email: string };
export type ProviderChangePasswordMutation = MutationFunc<
  ProviderChangePasswordResult,
  ProviderChangePasswordVars
>;

export type CreateProviderResult = { id: string };
export type CreateProviderVars = {
  data: Omit<Provider, 'id' | 'depot'> & {
    depot?: { connect: { id: string } };
  };
};
export type CreateProviderMutation = MutationFunc<
  CreateProviderResult,
  CreateProviderVars
>;

export type EditProviderResult = CreateProviderResult;
export type EditProviderVars = CreateProviderVars & { id: string };
export type EditProviderMutation = MutationFunc<
  EditProviderResult,
  EditProviderVars
>;

export type DeleteProviderResult = { id: string };
export type DeleteProviderMutation = MutationFunc<
  DeleteProviderResult,
  ProviderVars
>;

export const GET_PROVIDER_INFO = gql`
  query MyProviderInfo {
    info: myProviderInfo {
      id
      email
      name
      telephone
      role
      ${depot}
    }
  }
`;

export const PROVIDER_CHANGE_PASSWORD = gql`
  mutation CmsEditProvider($id: ID, $oldPassword: String, $password: String) {
    cmsEditProvider(
      where: { id: $id }
      data: { oldPassword: $oldPassword, password: $password }
    ) {
      id
      email
    }
  }
`;

const providerFragment = gql`
  fragment ProviderFragment on Provider {
    id
    email
    name
    telephone
    role
    ${depot}
  }
`;

export const GET_PROVIDERS = gql`
  query getProviders($where: ProviderWhereInput, $skip: Int, $first: Int) {
    providers(where: $where, skip: $skip, first: $first) {
      ...ProviderFragment
    }
    count: dataCount(node: PROVIDER, providerWhere: $where)
  }
  ${providerFragment}
`;

export const GET_PROVIDER_BY_ID = gql`
  query getProviderByID($id: ID!) {
    provider(where: { id: $id }) {
      ...ProviderFragment
    }
  }
  ${providerFragment}
`;

export const CREATE_PROVIDER = gql`
  mutation createProvider($data: ProviderCreateInput!) {
    cmsCreateProvider(data: $data) {
      id
      email
    }
  }
`;

export const EDIT_PROVIDER = gql`
  mutation editProvider($id: ID!, $data: ProviderUpdateInput!) {
    cmsEditProvider(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export const DELETE_PROVIDER = gql`
  mutation deleteProvider($id: ID!) {
    cmsDeleteProvider(where: { id: $id }) {
      id
    }
  }
`;
