import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  Component,
  ComponentClass,
  ReactNode,
} from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import { LoadMoreParams, NoDataPlaceholder } from '../../../components';
import {
  BACKDROP_TRANSPARENT,
  BLACK,
  GRAY,
  PRIMARY,
  WHITE,
} from '../../../constants/colors';
import { Icon, Loading, Text } from '../../../core-ui';
import { getSorting } from '../../../helpers';

import { ModalState } from '../../../graphql/localState';
import {
  GET_MODAL_SELECTED_STATE,
  UPDATE_MODAL_SELECTED,
} from '../../../graphql/queries';

import TablePaginationAction from '../../../components/Table/TablePagination';

const DEFAULT_ROWS_PER_PAGE = 10;

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type StructureStyle = Array<'grey' | 'alignCenter' | 'alignRight' | ObjectKey>;

type StructureElement = {
  alias?: string;
  render?: (data: RowData, index: number, selected: boolean) => ReactNode;
  isOrder?: boolean;
  style?: StructureStyle;
  headerTitle?: string;
  noHeaderName?: boolean;
  headerCenter?: boolean;
  width?: number;
};

export type TableStructure = { [key: string]: StructureElement };

type RowData = ObjectKey;

type State = {
  page: number;
  orderBy: Optional<string>;
  activeOrder: number;
  isDescending: boolean;
};

type OwnProps = {
  data: Array<RowData>;
  dataCount: number;
  searchKey: string;
  isLoading?: boolean;
  noDataPlaceholder?: string;
  resetPage?: boolean;
  showCheckboxes?: boolean;
  structure: TableStructure;
  loadMore: (params: LoadMoreParams) => void;
  multiSelectAction?: () => void;
  setResetPage?: (isReset: boolean) => void;
  rowPerPage?: number;
  hidePagination?: boolean;
  hideSelectAll?: boolean;
};

type Props = OwnProps &
  WithStyles<typeof styles> &
  SelectedStateProps &
  UpdateSelectedData;

export class CustomizedTable extends Component<Props, State> {
  state = {
    page: 0,
    orderBy: null,
    activeOrder: 0,
    isDescending: false,
    rowPerPage: this.props.rowPerPage || DEFAULT_ROWS_PER_PAGE,
  };

  componentDidUpdate() {
    const { resetPage, isLoading, setResetPage } = this.props;
    if (resetPage && isLoading) {
      // hacky way to reset page to 0 on search due to pagination
      setResetPage && setResetPage(false);
      this.setState({ page: 0 });
    }
  }

  render() {
    const {
      isLoading,
      noDataPlaceholder = 'No data available',
      searchKey,
      data,
    } = this.props;
    if ((!data && !Array.isArray(data)) || isLoading) {
      return <Loading />;
    }
    if ((data && data.length < 1) || !data) {
      if (searchKey && searchKey.length > 0) {
        const notFoundSearch = `'${searchKey}' tidak ditemukan`;
        return <NoDataPlaceholder text={notFoundSearch} />;
      }
      return <NoDataPlaceholder text={noDataPlaceholder} />;
    }

    return (
      <Paper square elevation={0} style={styles.root}>
        <Paper square elevation={0} style={styles.bodyRoot}>
          <Table>
            {this._renderTableBody()}
            {this._renderTableHead()}
          </Table>
        </Paper>
        {!this.props.hidePagination && this._renderPagination()}
      </Paper>
    );
  }

  _styleProcessor = (inputStyles?: StructureStyle) => {
    const { classes } = this.props;
    if (inputStyles) {
      const stylesArr = inputStyles.map((style) => {
        if (typeof style === 'string') {
          return classes[style];
        }
        // NOTE: to handle parent style inject
        // but still not working
        return StyleSheet.flatten(style);
      });
      return classNames(...stylesArr);
    }
    return undefined;
  };

  _renderCheckboxAll = (selectedCount: number) => {
    const { data, classes, hideSelectAll } = this.props;
    const rowCount = data.length;

    if (hideSelectAll) return;

    return (
      <Checkbox
        indeterminate={selectedCount > 0 && selectedCount <= rowCount}
        icon={
          <Icon name="check_box_outline_blank" color="inherit" size="small" />
        }
        checkedIcon={<Icon name="check_box" color={PRIMARY} size="small" />}
        indeterminateIcon={
          <Icon name="indeterminate_check_box" color={PRIMARY} size="small" />
        }
        checked={selectedCount === rowCount}
        onChange={(event) => this._handleSelectAll(event, selectedCount, data)}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
      />
    );
  };

  _renderCheckbox = (rowData: ObjectKey<any>) => {
    const {
      classes,
      selectedStateQuery: { modalState },
    } = this.props;
    let isSelected;
    if (modalState) {
      const { selectedArray } = modalState;
      isSelected = (id: string) =>
        selectedArray.filter((datum) => datum.id === id).length !== 0;
    }
    return (
      <Checkbox
        checked={isSelected && isSelected(String(rowData.id))}
        onChange={(_) => this._handleSelected(rowData)}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
      />
    );
  };

  _handleOrderPressed(orderIndex: number, orderBy: string) {
    const { activeOrder, isDescending } = this.state;
    this.setState({
      orderBy,
      activeOrder: orderIndex,
      isDescending: activeOrder === orderIndex ? !isDescending : false,
    });
  }

  _renderTableHead = () => {
    const { activeOrder, isDescending } = this.state;
    const {
      structure,
      classes,
      showCheckboxes,
      selectedStateQuery: { modalState },
    } = this.props;
    const cells = Object.keys(structure);
    const transitionTiming = isDescending ? 'ease-out' : 'ease';
    const headerContent = cells.map((headerName, index) => {
      const {
        style,
        noHeaderName,
        headerTitle,
        alias,
        isOrder,
        headerCenter,
        width,
      } = structure[headerName];
      const headerStyle: StyleProp<ViewStyle> = headerCenter
        ? {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {
            flexDirection: 'row',
            alignItems: 'center',
          };
      const degreeRotate =
        isDescending && index === activeOrder ? '180deg' : '0deg';
      const headerChild = (
        <View
          style={[
            headerStyle,
            {
              width,
            },
          ]}
        >
          <Text
            size="xsmall"
            weight="bold"
            color={isOrder && index === activeOrder ? BLACK : undefined}
          >
            {headerTitle ? headerTitle.toUpperCase() : headerName.toUpperCase()}
          </Text>
          {isOrder && (
            <Icon
              size="small"
              color={index === activeOrder ? BLACK : undefined}
              containerStyle={
                ({
                  transform: [{ rotate: degreeRotate }],
                  transitionDuration: '0.5s',
                  transitionProperty: 'transform',
                  transitionTimingFunction: transitionTiming,
                } as unknown) as ViewStyle // NOTE: this is necessary as transitions are part of CSS styles, not ViewStyle
              }
              name="arrow_drop_down"
              onPress={() =>
                this._handleOrderPressed(
                  index,
                  isOrder && alias ? alias : headerName,
                )
              }
            />
          )}
        </View>
      );
      return (
        <TableCell
          className={classNames(
            classes.cellHeader,
            this._styleProcessor(style),
          )}
          key={index}
        >
          {noHeaderName ? null : isOrder ? (
            <TouchableOpacity
              onPress={() =>
                this._handleOrderPressed(index, alias || headerName)
              }
            >
              {headerChild}
            </TouchableOpacity>
          ) : (
            headerChild
          )}
        </TableCell>
      );
    });

    return (
      <TableHead className={classes.headerRow}>
        <TableRow>
          {showCheckboxes ? (
            <TableCell className={classes.cellCheckboxHeader}>
              {modalState &&
              modalState.selectedArray &&
              modalState.selectedArray.length > 0
                ? this._renderCheckboxAll(modalState.selectedArray.length)
                : this._renderCheckboxAll(0)}
            </TableCell>
          ) : null}
          {headerContent}
        </TableRow>
      </TableHead>
    );
  };

  _renderTableRow = (currentRow: RowData, parentIndex: number) => {
    const {
      structure,
      classes,
      showCheckboxes,
      selectedStateQuery: { modalState },
    } = this.props;
    const cells = Object.keys(structure);

    let isSelected: (id: string) => boolean;
    if (modalState) {
      const { selectedArray } = modalState;
      isSelected = (id: string) =>
        selectedArray.filter((datum) => datum.id === id).length !== 0;
    }

    return (
      <TableRow className={classes.row} key={parentIndex}>
        {showCheckboxes ? (
          <TableCell className={classes.cellCheckboxRoot}>
            {this._renderCheckbox(currentRow)}
          </TableCell>
        ) : null}
        {cells.map((headerName, index) => {
          const { style, render, alias } = structure[headerName];
          const dataRow = alias
            ? this._aliasResolver(currentRow, alias)
            : currentRow[headerName];
          return (
            <TableCell
              className={classNames(
                classes.body,
                classes.cellRoot,
                this._styleProcessor(style),
              )}
              key={index}
            >
              {render ? (
                render(
                  currentRow,
                  parentIndex,
                  isSelected ? isSelected(currentRow.id) : false,
                )
              ) : (
                <Text size="small" style={nativeStyles.cellText}>
                  {dataRow != null ? String(dataRow).toUpperCase() : '-'}
                </Text>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  _getSortedData() {
    const { data, structure } = this.props;
    const { page, isDescending, orderBy, rowPerPage } = this.state;
    const fields = Object.keys(structure);
    const beginIndex = page * rowPerPage;
    const endIndex = page * rowPerPage + rowPerPage;
    let initialSortedField = '';
    fields.forEach((field) => {
      const { alias, isOrder } = structure[field];
      if (isOrder) {
        initialSortedField = alias || field;
      }
    });
    const orderField = orderBy || initialSortedField;
    const dataToDisplay = data.slice(beginIndex, endIndex);
    return {
      sortedData: dataToDisplay.sort(getSorting(isDescending, orderField)),
      beginIndex,
      endIndex,
    };
  }

  _renderTableBody = () => {
    const { sortedData, beginIndex } = this._getSortedData();
    return (
      <TableBody style={styles.body}>
        {sortedData.map((currentRow: RowData, parentIndex: number) =>
          this._renderTableRow(currentRow, parentIndex + beginIndex),
        )}
      </TableBody>
    );
  };

  _renderPagination = () => {
    const { page, rowPerPage } = this.state;
    const { classes, dataCount } = this.props;

    return (
      <Table>
        <TableRow>
          <TablePagination
            colSpan={8}
            count={dataCount}
            rowsPerPage={rowPerPage}
            page={page}
            onPageChange={this._handleChangePage}
            ActionsComponent={TablePaginationAction}
            rowsPerPageOptions={[]}
            classes={{
              root: classes.caption,
              select: classes.select,
              caption: classes.caption,
            }}
          />
        </TableRow>
      </Table>
    );
  };

  _handleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    selectedCount: number,
    data: Array<ObjectKey>,
  ) => {
    const { updateMultiTable } = this.props;
    if (event.target.checked && selectedCount === 0) {
      updateMultiTable({
        variables: {
          selectedArray: data,
        },
      });
      return;
    }
    this._clearSelected();
  };

  _handleSelected = (rowData: ObjectKey<any>) => {
    const {
      selectedStateQuery: { modalState },
      updateMultiTable,
    } = this.props;
    if (modalState) {
      const { id } = rowData;
      const { selectedArray } = modalState;
      const isSelectedIndex =
        selectedArray.filter((datum) => datum.id === id).length > 0;
      let newSelectedArray = [];
      newSelectedArray = isSelectedIndex
        ? selectedArray.filter((datum) => datum.id !== id)
        : [...selectedArray, rowData];

      updateMultiTable({
        variables: {
          selectedArray: newSelectedArray,
        },
      });
    }
  };

  _clearSelected = () => {
    const { updateMultiTable } = this.props;
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  };

  _aliasResolver = (data: ObjectKey, alias: string, index = 0): string => {
    const keys = alias.split('.');
    const temp = data ? data[keys[index]] : '';
    if (typeof temp === 'string') {
      return temp;
    }
    return this._aliasResolver(temp, alias, ++index);
  };

  _handleChangePage = (_: CustomMouseEvent, nextPage: number) => {
    const { data, loadMore, dataCount, searchKey } = this.props;
    const { page, rowPerPage } = this.state;
    const isNextPagePressed = nextPage === page + 1;
    const totalPage = Math.ceil(dataCount / rowPerPage);
    const nextPageIsLastPage = nextPage + 1 === totalPage;
    if (nextPageIsLastPage && data.length < dataCount) {
      loadMore({
        skip: data.length,
        first: dataCount - data.length,
        searchInput: searchKey,
      });
    } else if (
      !nextPageIsLastPage &&
      isNextPagePressed &&
      data.length < rowPerPage * (nextPage + 1)
    ) {
      loadMore({
        skip: data.length,
        first: rowPerPage,
        searchInput: searchKey,
      });
    }
    this.setState({ page: nextPage });
  };
}

const nativeStyles = StyleSheet.create({
  cellText: { letterSpacing: 1.5 },
});

const styles = createStyles({
  root: { backgroundColor: 'transparent' },
  cellHeader: {
    padding: '0px 18px 0px 16px',
    borderBottomWidth: 0,
  },
  cellRoot: {
    padding: '4px 18px 4px 16px',
    borderBottom: `1px dashed ${GRAY}`,
  },
  cellCheckboxHeader: {
    padding: '0px 4px',
    borderBottomWidth: 0,
  },
  cellCheckboxRoot: {
    padding: 4,
    borderBottom: `1px dashed ${GRAY}`,
  },
  bodyRoot: {
    overflowX: 'auto',
    backgroundColor: 'transparent',
    paddingRight: 1, // NOTE: this is necessary to counter-balance the right border of last-child
  },
  body: {
    backgroundColor: WHITE,
  },
  checkboxRoot: {
    '&$checked': {
      color: PRIMARY,
    },
  },
  checked: {
    color: PRIMARY,
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  grey: {
    color: BACKDROP_TRANSPARENT,
  },
  caption: {
    borderStyle: 'none',
    fontSize: 15,
    color: BACKDROP_TRANSPARENT,
    fontFamily: 'Rubik-Regular',
  },
  select: {
    padding: '7px 20px 7px 0px',
  },
  row: {
    height: 60,
    backgroundColor: WHITE,
  },
  headerRow: {
    color: BACKDROP_TRANSPARENT,
  },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
)(withStyles(styles)(CustomizedTable)) as ComponentClass<OwnProps>;
