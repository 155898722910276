import React, { Component } from 'react';
import { View, StyleSheet, Image } from 'react-native';

import ProductPrice from './ProductPrice';

import { Text, Modal } from '../../core-ui';
import { convertPascalCase } from '../../helpers';
import { BLACK, DARK_GRAY } from '../../constants/colors';
import { Product } from '../../graphql/queries';

type Props = {
  product: Nullable<Product>;
  onClose: () => void;
};

const FIELDS = {
  productID: 'ID Produk',
  title: 'Nama Produk',
  nickname: 'Kategori Produk',
  uom: 'Informasi Isi Kemasan',
  dimension: 'Dimensi Produk',
  productStock: 'Stock Onhand',
  price: 'Harga Produk',
  description: 'Deskripsi Produk',
  photo: 'Foto Produk',
};

type KeyProduct = Exclude<
  keyof Product,
  'id' | 'active' | 'sku' | 'defaultPrice' | 'stock' | 'minimumStock'
>;

export default class ProductDetailModal extends Component<Props> {
  render() {
    const { product, onClose } = this.props;
    return (
      product && (
        <Modal
          fullWidth
          maxWidth="sm"
          title="Informasi Produk"
          isVisible
          onClose={() => onClose()}
        >
          <View style={styles.detailWrapper}>
            {Object.keys(FIELDS).map((item, index) => {
              const property = item === 'price' ? 'id' : item;
              let value = product && product[property as KeyProduct];
              const title = FIELDS[item as KeyProduct];
              if (item === 'productStock') {
                value =
                  product && product.stock ? product.stock.toString() : '0';
              }
              return (
                <View
                  style={[
                    styles.fieldWrapper,
                    item === 'price' && styles.priceStyle,
                  ]}
                  key={index}
                >
                  <View style={styles.flex}>
                    <Text size="xsmall" color={DARK_GRAY}>
                      {title}
                    </Text>
                  </View>
                  <View style={styles.colon}>
                    <Text size="xsmall" color={DARK_GRAY}>
                      :
                    </Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    {this._renderItemValue(
                      item as KeyProduct,
                      value as Product[KeyProduct],
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        </Modal>
      )
    );
  }

  _renderItemValue = (
    key: KeyProduct | 'price',
    value: Product[KeyProduct] | string,
  ) => {
    switch (key) {
      case 'price': {
        return <ProductPrice productID={String(value)} />;
      }
      case 'photo': {
        return (
          <Image
            resizeMethod="auto"
            resizeMode="contain"
            source={{ uri: String(value) }}
            style={styles.productImage}
          />
        );
      }
      default: {
        return (
          <Text size="small" color={BLACK}>
            {convertPascalCase(String(value || '-'))}
          </Text>
        );
      }
    }
  };

  _closeDetail = () => this.setState({ isDetailVisible: false });
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  priceSeparator: {
    width: 40,
  },
  directionRow: {
    flexDirection: 'row',
  },
  modalContent: {
    padding: 40,
  },
  modalHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContent: {
    letterSpacing: 1.5,
  },
  detailWrapper: {
    paddingTop: 30,
    flex: 1,
  },
  fieldWrapper: {
    flexDirection: 'row',
    marginBottom: 15,
  },
  colon: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  productImage: {
    width: 100,
    height: 100,
  },
  separator: {
    marginVertical: 5,
  },
  priceStyle: { zIndex: 2 },
});
