import React, { ReactNode } from 'react';
import {
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { DialogProps } from '@material-ui/core/Dialog';
import { Button, Icon, Text } from '.';
import { MODAL, WHITE } from '../constants/colors';

const INNER_PADDING = 45;

type HeaderStyle = {
  title?: StyleProp<TextStyle>;
  description?: StyleProp<TextStyle>;
};

export type OwnProps = {
  isVisible: boolean;
  title: string;
  description?: ReactNode;
  hideHeaderClose?: boolean;
  closeButtonText?: string;
  buttonText?: string;
  invisible?: boolean;
  transitionTime?: number;
  transitionType?: 'fade' | 'slide';
  children?: ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  maxHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  headerStyle?: HeaderStyle;
};
export type Props = Pick<DialogProps, Exclude<keyof DialogProps, 'open'>> &
  WithStyles<typeof materialStyles> &
  OwnProps;

function Transition(props: { [key: string]: string }) {
  return <Slide direction="up" {...props} />;
}

export function Modal(props: Props) {
  const {
    children,
    isVisible,
    title,
    description,
    hideHeaderClose,
    closeButtonText,
    buttonText,
    invisible,
    transitionType,
    transitionTime,
    onClose,
    onSubmit,
    submitDisabled,
    submitLoading,
    maxHeight,
    fullWidth,
    maxWidth,
    containerStyle,
    contentStyle,
    headerStyle,
    classes,
    ...otherProps
  } = props;
  let transition = {};
  if (transitionType === 'slide') {
    transition = { ...transition, TransitionComponent: Transition };
  }
  if (transitionTime) {
    transition = { ...transition, transitionDuration: transitionTime };
  }
  return isVisible ? (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={isVisible}
      onClose={typeof window.orientation !== 'undefined' ? () => {} : onClose}
      BackdropProps={{ invisible }}
      classes={maxHeight ? { paperScrollPaper: classes.maxHeightModal } : {}}
      {...transition}
      {...otherProps}
    >
      <View
        style={[
          styles.container,
          maxHeight && styles.maxHeight,
          containerStyle,
        ]}
      >
        <Header
          title={title}
          desc={description}
          hideClose={hideHeaderClose}
          onClose={onClose}
          classes={{ root: classes.removePadding }}
          headerStyle={headerStyle}
        />
        {children && (
          <DialogContent classes={{ root: classes.removePadding }}>
            <View style={[maxHeight && styles.maxHeight, contentStyle]}>
              {children}
            </View>
          </DialogContent>
        )}
        <DialogActions classes={{ root: classes.removeMargin }}>
          <View style={styles.formButton}>
            {closeButtonText && onClose && (
              <Button
                inverted
                color="secondary"
                text={closeButtonText}
                onPress={onClose}
                style={styles.rightMargin}
              />
            )}
            {buttonText && onSubmit && (
              <Button
                color="primary"
                text={buttonText}
                onPress={onSubmit}
                disabled={submitDisabled}
                isLoading={submitLoading}
                loadingColor={WHITE}
              />
            )}
          </View>
        </DialogActions>
      </View>
    </Dialog>
  ) : null;
}

type HeaderProps = {
  desc?: ReactNode;
  classes?: ObjectKey;
  hideClose?: boolean;
  title: string;
  onClose: () => void;
  headerStyle?: HeaderStyle;
};

function Header(props: HeaderProps) {
  const { desc, title, hideClose, onClose, classes, headerStyle } = props;
  return (
    <View style={styles.bottomSpace}>
      <DialogTitle classes={classes}>
        <View style={styles.titleRow}>
          <Text
            size="large"
            color={MODAL.TITLE}
            style={headerStyle && headerStyle.title}
          >
            {title}
          </Text>
          {!hideClose && <Icon size="small" name="close" onPress={onClose} />}
        </View>
      </DialogTitle>
      {desc && (
        <Text
          size="small"
          color={MODAL.DESCRIPTION}
          style={[styles.topSpace, headerStyle && headerStyle.description]}
        >
          {desc}
        </Text>
      )}
    </View>
  );
}

const materialStyles = createStyles({
  maxHeightModal: { height: '100%' },
  removePadding: { padding: 0 },
  removeMargin: { margin: 0, paddingTop: 20 },
});

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    minHeight: 200,
    padding: INNER_PADDING,
    backgroundColor: WHITE,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightMargin: { marginRight: 20 },
  bottomSpace: { paddingBottom: 30 },
  topSpace: { paddingTop: 30 },
  formButton: { flexDirection: 'row', justifyContent: 'flex-end' },
  maxHeight: { height: '100%' },
});

export default withStyles(materialStyles)(Modal);
