import gql from 'graphql-tag';

import { DepotComplete, Product, User } from '.';
import { products } from '../relationalFragments';

export type ProgramType = 'LOYALTY' | 'FLASH_SALE' | 'BUNDLE' | 'TRADE_PROMO';
export type GiftType = 'GOLD' | 'ANOTHER_PRODUCT';
export type ClaimType = 'WHOLE' | 'PER_STORE';
export type MaximalQuantityProgramType = 'UNLIMITED' | 'LIMITED';

export type RewardType = 'DISCOUNT' | 'CASHBACK' | 'TVIP_PRODUCT' | 'PRESENT';

export type Term = {
  __typename: 'Term';
  programTermId?: string;
  id: string;
  minimalPurchase: number;
  rewardQty: number;
  state?: 'new' | 'modified' | 'unchanged' | 'deleted';
  // otherProducts?: {
  //   productName: string,
  //   qty: number
  // }[];
};

export type ProgramTerm = {
  id: string;
  product: Product;
  user: User;
  terms: Array<Term>;
  programType: ProgramType;
  program: Program;
};

export type ProgramSegment = {
  id?: string;
  segment: { id: string; title?: string };
};

export type ProgramSubSegment = {
  id?: string;
  subSegment: { id: string; title?: string };
};

export type ProgramDepot = {
  id: string;
  depot: DepotComplete;
  maxQty: number;
  limitType?: MaximalQuantityProgramType;
};

export type ProgramUser = {
  id?: string;
  user: User;
  outletMaxQty?: number;
  limitType?: MaximalQuantityProgramType;
};

export type Program = {
  id: string;
  title: string;
  description: string;
  programType: ProgramType;
  rewardType: RewardType;
  giftType: GiftType;
  startDate: string;
  endDate: string;
  active: boolean;
  programDepot: Array<ProgramDepot>;
  customers: Array<User>;
  products: Array<Product>;
  terms: Array<ProgramTerm>;
  programSegment: Array<ProgramSegment>;
  programSubSegment: Array<ProgramSubSegment>;
  claimType: ClaimType;
  claimLimit: number;
  giftName: string;
  giftPrice: number;
  depot: DepotComplete;
  programUser: Array<ProgramUser>;
};

export type GetProgramsResult = {
  programsMultiple: Array<Program>;
  count: number;
};
export type GetProgramsParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

export const GET_PROGRAMS = gql`
  query getPrograms($where: ProgramWhereInput, $first: Int, $skip: Int) {
    count: dataCount(node: PROGRAM, programWhere: $where)
    programsMultiple(where: $where, first: $first, skip: $skip) {
      id
      title
      description
      programType
      rewardType
      giftType
      startDate
      endDate
      active
      programDepot {
        id
        depot {
          id
          title
        }
        maxQty
        limitType
      }
      depot {
        id
        title
        description
      }
      programSegment {
        id
        segment {
          id
          title
        }
      }
      programSubSegment {
        id
        subSegment {
          id
          title
        }
      }
      customers {
        szID
        id
        email
        name
        storeName
        depot {
          id
          title
        }
      }
      ${products}
      terms {
          id
          product {
            id
            title
          }
          user {
            id
            name
            storeName
          }
          terms {
            id
            minimalPurchase
            rewardQty
          }
      }
      claimLimit
      claimType
      giftName
      giftPrice
      titleNotification
      bodyNotification
      programUser {
        id
        user {
          id
          name
          storeName
          depot {
            id
            title
          }
        }
        outletMaxQty
        limitType
      }
    }
  }
`;
