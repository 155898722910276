import React, { Component } from 'react';
import { View, Text } from 'react-native';

import { Link } from 'react-router-dom';

export default class HomePage extends Component<{}> {
  render() {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Home Page</Text>
        <Link to={{ pathname: '/dashboard' }}>
          <Text>Go to dashboard</Text>
        </Link>
      </View>
    );
  }
}
