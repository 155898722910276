import React, { Component, ComponentClass } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { Query } from '../../../components';
import { Modal, Separator } from '../../../core-ui';
import { ModalState } from '../../../graphql/localState';
import {
  Depot,
  DepotList,
  GET_DEPOT_LISTS,
  GET_MODAL_SELECTED_STATE,
  UPDATE_MODAL_SELECTED,
} from '../../../graphql/queries';

import withToast, { ToastContextProps } from 'helpers/withToast';
import { InputField } from '../components';
import Table from '../components/Table';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

export type DepotSelectionItem = {
  id?: string;
  depot: Depot;
  qty: number;
};

type OwnProps = {
  selectedItems: Array<DepotSelectionItem>;
  onClose: () => void;
  onChangeSelected: (selectedItems: Array<DepotSelectionItem>) => void;
  isVisible: boolean;
};

type Props = OwnProps &
  UpdateSelectedData &
  SelectedStateProps &
  ToastContextProps;

type State = {
  resetPage: boolean;
  selectedItems: Array<DepotSelectionItem>;
};

class DepoSelectionModal extends Component<Props, State> {
  state = {
    resetPage: false,
    selectedItems: this.props.selectedItems || [],
  };

  componentDidMount() {
    const { selectedItems, updateMultiTable } = this.props;

    // Show checked for selected products
    updateMultiTable({
      variables: {
        selectedArray: selectedItems
          ? selectedItems.map((datum) => datum.depot)
          : [],
      },
    });
  }

  componentWillUnmount() {
    const { updateMultiTable } = this.props;
    // Remove selected state
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  }

  render() {
    const { resetPage } = this.state;
    const { isVisible } = this.props;

    return (
      <Modal
        maxHeight
        isVisible={isVisible}
        title="Pilih Depo"
        onClose={this._closeModal}
        buttonText="Pilih"
        onSubmit={() => this._onSubmit()}
      >
        <View>
          <Separator style={styles.separator} />
          <Query<DepotList>
            query={GET_DEPOT_LISTS}
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
          >
            {({ data, loading }) => {
              if (data && data.depots) {
                const { depots } = data;
                return (
                  <Table
                    resetPage={resetPage}
                    setResetPage={(isReset) =>
                      this.setState({ resetPage: isReset })
                    }
                    isLoading={loading}
                    showCheckboxes
                    searchKey={''}
                    data={depots}
                    rowPerPage={depots.length}
                    dataCount={depots.length}
                    hidePagination
                    structure={{
                      title: {
                        headerTitle: 'Nama Depo',
                      },
                      quantityMax: {
                        headerTitle: 'Kuantitas Maksimal',
                        width: 150,
                        render: (data, _, selected) => {
                          const qty = this._findSelectedDepot(data as Depot)
                            ?.qty;
                          return (
                            <InputField
                              initialValue={qty ?? 0}
                              selected={selected}
                              type="increment"
                              width={150}
                              onUpdate={(value) => {
                                if (value >= 0) {
                                  this._updateValue(data as Depot, value);
                                }
                              }}
                            />
                          );
                        },
                      },
                    }}
                    loadMore={() => {}}
                  />
                );
              }
              return null;
            }}
          </Query>
        </View>
      </Modal>
    );
  }

  _findSelectedDepot = (depot: Depot): DepotSelectionItem | null => {
    const { id } = depot;
    const { selectedItems } = this.state;
    const isSelected = selectedItems.filter((item) => item.depot.id === id);
    if (isSelected.length > 0) {
      return isSelected[0];
    } else {
      return null;
    }
  };

  _updateValue = (newItem: Depot, newValue: number) => {
    this.setState((prevState) => {
      const { selectedItems } = prevState;
      let newSelected = [...selectedItems];
      if (
        selectedItems.filter(
          (bundleProduct) => bundleProduct.depot.id === newItem.id,
        ).length > 0
      ) {
        newSelected = selectedItems.map((bundleProduct) =>
          bundleProduct.depot.id === newItem.id
            ? { ...bundleProduct, qty: newValue }
            : bundleProduct,
        );
      } else {
        newSelected.push({
          depot: newItem,
          qty: newValue,
        });
      }
      return { selectedItems: newSelected };
    });
  };

  _onSubmit = () => {
    const {
      onChangeSelected,
      selectedStateQuery: { modalState },
      onClose,
    } = this.props;
    if (modalState && modalState.selectedArray) {
      const { selectedItems } = this.state;
      const { openToast } = this.props;

      const newSelectedItems: Array<DepotSelectionItem> = [];
      modalState.selectedArray.forEach((datum: Depot) => {
        const isSelected = selectedItems.filter(
          (item) => item.depot.id === datum.id,
        );
        if (isSelected.length === 0) {
          newSelectedItems.push({
            depot: datum,
            qty: 0,
          });
        } else {
          newSelectedItems.push({ ...isSelected[0] });
        }
      });

      if (newSelectedItems.filter((item) => item.qty <= 0).length > 0) {
        return openToast(
          'fail',
          'Kuantitas maksimal depo tidak boleh kurang dari 0',
        );
      }

      onChangeSelected(newSelectedItems);
      onClose();
    }
  };

  _closeModal = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingBottom: { paddingBottom: 20 },
  separator: { marginVertical: 30 },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
  withToast,
)(DepoSelectionModal) as ComponentClass<OwnProps>;
