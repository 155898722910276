import React, { ComponentClass, useEffect, useState } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { View } from 'react-native';

import { Query } from '..';
import { DARK_GRAY } from '../../constants/colors';
import { Modal, Separator, Text } from '../../core-ui';
import { ModalState } from '../../graphql/localState';
import {
  GET_MODAL_SELECTED_STATE,
  GET_PRODUCT_LIST,
  GetProductListResult,
  GetProductListVariables,
  PRICE_SEGMENT_QUERY,
  PriceSegmentResult,
  PriceSegmentVariables,
  Product,
  UPDATE_MODAL_SELECTED,
} from '../../graphql/queries';
import { formatThousandSeparator } from '../../helpers';

import { RETAIL } from '../../constants/priceSegment';
import getProductPrice from '../../helpers/getProductPrice';
import SearchField from '../../scenes/program/components/SearchField';
import Table from '../../scenes/program/components/Table';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState>;
};

type UpdateSelectedVariables = { selectedArray: Array<Product> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type OwnProps = {
  priceSegmentCode?: string;
  selectedProducts: Array<Product>;
  onClose: () => void;
  onChangeSelected: (selectedProducts: Array<Product>) => void;
  bodyText?: string;
  withPrice?: boolean;
  isVisible?: boolean;
};

type ProductSelectionModalProps = OwnProps &
  UpdateSelectedData &
  SelectedStateProps;

function ProductSelectionModal({
  selectedProducts,
  updateMultiTable,
  bodyText,
  withPrice,
  priceSegmentCode,
  onClose,
  onChangeSelected,
  selectedStateQuery,
  isVisible = true,
}: ProductSelectionModalProps) {
  const [resetPage, setResetPage] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const closeModal = () => {
    onClose?.();
  };

  const onSearch = (search: string) => {
    setResetPage(true);
    setSearchKey(search);
  };

  const onSubmit = () => {
    const { modalState } = selectedStateQuery;

    if (modalState) {
      onChangeSelected(modalState.selectedArray);
      onClose();
    }
  };

  useEffect(() => {
    // Show checked for selected products
    updateMultiTable({
      variables: {
        selectedArray: selectedProducts,
      },
    });

    return () => {
      // Remove selected state
      updateMultiTable({
        variables: {
          selectedArray: [],
        },
      });
    };
  }, [selectedProducts, updateMultiTable]);

  return (
    <Modal
      maxHeight
      isVisible={isVisible}
      title="Pilih Produk"
      onClose={closeModal}
      buttonText="Pilih"
      onSubmit={onSubmit}
    >
      <View style={{ width: 500 }}>
        <Text color={DARK_GRAY} style={{ paddingBottom: 20 }}>
          {bodyText}
        </Text>
        <SearchField
          placeholder="Cari produk..."
          value={searchKey}
          onChangeText={onSearch}
        />
        <Separator style={{ marginVertical: 30 }} />
        <Query<GetProductListResult, GetProductListVariables>
          query={GET_PRODUCT_LIST}
          variables={{
            where: { searchKeyword: searchKey.toLowerCase() },
            first: 100,
            skip: 0,
          }}
          keyData="products"
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ data, loading }) =>
            data && (
              <Query<PriceSegmentResult, PriceSegmentVariables>
                query={PRICE_SEGMENT_QUERY}
                variables={{ priceSegmentCode: RETAIL }}
                notifyOnNetworkStatusChange
              >
                {({ data: retailPriceList }) =>
                  !!retailPriceList && (
                    <Query<PriceSegmentResult, PriceSegmentVariables>
                      query={PRICE_SEGMENT_QUERY}
                      // it will not be empty because as a variable because the Query will be skipped if it is empty
                      variables={{
                        priceSegmentCode: priceSegmentCode as string,
                      }}
                      skip={!priceSegmentCode}
                    >
                      {({ data: segmentPriceList }) => (
                        <Table
                          resetPage={resetPage}
                          setResetPage={setResetPage}
                          isLoading={loading}
                          showCheckboxes
                          searchKey={searchKey}
                          data={data.products}
                          dataCount={data.count}
                          rowPerPage={data.products.length}
                          hidePagination
                          structure={
                            withPrice
                              ? {
                                  title: {
                                    headerTitle: 'Nama Produk',
                                  },
                                  price: {
                                    headerTitle: 'Harga',
                                    render: ({ id, defaultPrice }) => {
                                      const price = getProductPrice(
                                        id,
                                        retailPriceList,
                                        defaultPrice,
                                        segmentPriceList,
                                      );

                                      return (
                                        <Text
                                          size="small"
                                          weight="reg"
                                          numberOfLines={1}
                                          style={{ letterSpacing: 1.5 }}
                                        >
                                          Rp. {formatThousandSeparator(price)}
                                        </Text>
                                      );
                                    },
                                  },
                                }
                              : {
                                  title: {
                                    headerTitle: 'Nama Produk',
                                  },
                                }
                          }
                          loadMore={() => {}}
                        />
                      )}
                    </Query>
                  )
                }
              </Query>
            )
          }
        </Query>
      </View>
    </Modal>
  );
}

export default compose(
  graphql<OwnProps, ModalState, Record<string, unknown>, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<
    OwnProps,
    UpdateSelectedData,
    Record<string, unknown>,
    OwnProps & UpdateSelectedData
  >(UPDATE_MODAL_SELECTED, { name: 'updateMultiTable' }),
)(ProductSelectionModal) as ComponentClass<OwnProps>;
