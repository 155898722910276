import gql from 'graphql-tag';
import { CouponProduct } from './couponQuery';

export type Product = {
  id: string;
  productID: string;
  nickname: string;
  title: string;
  description: string;
  stock: number;
  photo: string;
  dimension: string;
  uom: string;
  sku: string;
  minimumStock: number;
  defaultPrice: number;
};

export type GetProductCouponListResult = { products: Array<CouponProduct>; count: number };

export type GetProductListResult = { products: Array<Product>; count: number };
export type GetProductListVariables = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

// NOTE: productStock fragment yet to be included here
export const PRODUCT_FRAGMENT = gql`
  fragment Product on Product {
    id
    productID
    title
    nickname
    description
    stock
    photo
    dimension
    uom
    sku
    minimumStock
    stock
    defaultPrice
  }
`;

export const GET_PRODUCT_LIST = gql`
  query getProductList($where: ProductsInput, $first: Int, $skip: Int) {
    products(where: $where, first: $first, skip: $skip) {
      ...Product
    }
    count: dataCount(node: PRODUCT, productWhere: $where)
  }
  ${PRODUCT_FRAGMENT}
`;

export type GetProductResult = { product: Product };
export type GetProductVariables = { id: string };

export const GET_PRODUCT_BY_ID = gql`
  query getProduct($id: ID!) {
    product(id: $id) {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`;
