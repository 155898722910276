import React, { Component, ComponentClass } from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { LoadMoreParams, Query } from '../../../components';
import { DARK_GRAY } from '../../../constants/colors';
import { Modal, Separator, Text } from '../../../core-ui';
import { ModalState } from '../../../graphql/localState';
import {
  GET_CUSTOMER_USERS,
  GET_MODAL_SELECTED_STATE,
  GetUsersParams,
  GetUsersResult,
  UPDATE_MODAL_SELECTED,
  User,
} from '../../../graphql/queries';

import { fetchMoreItems } from '../../../helpers';
import SearchField from '../components/SearchField';
import Table from '../components/Table';
import { DepotSelectionItem } from './DepoSelectionModal';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type OwnProps = {
  selectedCustomers: Array<User>;
  selectedSubsegments: Array<string>;
  selectedDepots: Array<DepotSelectionItem>;
  onClose: () => void;
  onChangeSelected: (selectedCustomers: Array<User>) => void;
  isVisible: boolean;
};

type Props = OwnProps & UpdateSelectedData & SelectedStateProps;

type State = {
  resetPage: boolean;
  searchKey: string;
};

class CustomerSelectionModal extends Component<Props, State> {
  state: State = {
    resetPage: false,
    searchKey: '',
  };

  componentDidMount() {
    const { selectedCustomers, updateMultiTable } = this.props;
    // Show checked for selected customers
    updateMultiTable({
      variables: {
        selectedArray: selectedCustomers || [],
      },
    });
  }

  componentWillUnmount() {
    const { updateMultiTable } = this.props;
    // Remove selected state
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  }

  render() {
    const { selectedDepots, selectedSubsegments, isVisible } = this.props;
    const { searchKey, resetPage } = this.state;

    return (
      <Modal
        maxHeight
        isVisible={isVisible}
        maxWidth="xl"
        title="Pilih Pelanggan"
        onClose={this._closeModal}
        buttonText="Pilih"
        onSubmit={() => this._onSubmit()}
      >
        <View>
          <Text color={DARK_GRAY} style={{ paddingBottom: 20 }}>
            Berikut adalah daftar pelanggan sesuai segmentasi yang Anda pilih.
          </Text>
          <SearchField
            placeholder="Cari nama pelanggan, kode pelanggan, sub segment atau nama depo."
            value={searchKey}
            onChangeText={this._onSearch}
          />
          <Separator style={{ marginVertical: 30 }} />
          <Query<GetUsersResult, GetUsersParams>
            query={GET_CUSTOMER_USERS}
            keyData="users"
            fetchPolicy="network-only"
            variables={{
              first: 1000,
              skip: 0,
              where: {
                approvalKtp: 'APPROVED',
                subSegment: {
                  id_in: selectedSubsegments,
                },
                depot: {
                  id_in: selectedDepots.map((depot) => depot.depot.id),
                },
                OR: [
                  { szID_contains: searchKey.toLowerCase() },
                  { name_contains: searchKey.toLowerCase() },
                  { depot: { title_contains: searchKey.toUpperCase() } },
                  { subSegment: { title_contains: searchKey.toLowerCase() } },
                ],
              },
            }}
            notifyOnNetworkStatusChange
          >
            {({ data, loading, fetchMore }) => (
              <Table
                resetPage={resetPage}
                setResetPage={(isReset) =>
                  this.setState({ resetPage: isReset })
                }
                isLoading={loading}
                showCheckboxes
                searchKey={searchKey}
                data={data?.users ?? []}
                dataCount={data?.users.length ?? 0}
                hidePagination={false}
                structure={{
                  szID: {
                    headerTitle: 'Kode Pelanggan',
                    render: ({ szID }) => (
                      <Text
                        size="small"
                        weight="reg"
                        style={styles.customerCode}
                      >
                        {szID ? szID.toUpperCase() : '-'}
                      </Text>
                    ),
                  },
                  depot: {
                    headerTitle: 'Nama Depo',
                    alias: 'depot.title',
                  },
                  subSegment: {
                    headerTitle: 'sub Segment',
                    render: ({ subSegment }) => (
                      <Text
                        size="small"
                        weight="reg"
                        style={styles.customerCode}
                      >
                        {subSegment.title
                          ? subSegment.title.toUpperCase()
                          : '-'}
                      </Text>
                    ),
                  },
                  storeName: {
                    headerTitle: 'Nama Toko',
                  },
                  name: {
                    headerTitle: 'Nama Pelanggan',
                  },
                }}
                loadMore={({ skip, first, searchInput }: LoadMoreParams) => {
                  fetchMoreItems<GetUsersResult, GetUsersParams>(fetchMore, {
                    dataKey: 'users',
                    query: GET_CUSTOMER_USERS,
                    variables: {
                      first,
                      skip,
                      where: {
                        subSegment: { id_in: selectedSubsegments },
                        OR: [
                          {
                            id_contains: searchInput
                              ? searchInput.toLowerCase()
                              : '',
                          },
                          {
                            name_contains: searchInput
                              ? searchInput.toLowerCase()
                              : '',
                          },
                          {
                            depot: {
                              title_contains: searchInput
                                ? searchInput.toLowerCase()
                                : '',
                            },
                          },
                        ],
                      },
                    },
                  });
                }}
              />
            )}
          </Query>
        </View>
      </Modal>
    );
  }

  _closeModal = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  _onSearch = (searchKey: string) => {
    this.setState({ searchKey, resetPage: true });
  };

  _onSubmit = () => {
    const {
      onChangeSelected,
      selectedStateQuery: { modalState },
      onClose,
    } = this.props;
    if (modalState) {
      onChangeSelected(modalState.selectedArray);
      onClose();
    }
  };
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  customerCode: {
    letterSpacing: 1.5,
    wordBreak: 'break-all',
  },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
)(CustomerSelectionModal) as ComponentClass<OwnProps>;
