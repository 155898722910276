import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';

import { SegmentTreeWithMembers, SegmentTree } from './segmentQuery';
import {
  CHANNEL_FRAGMENT,
  SEGMENT_FRAGMENT,
  SUB_SEGMENT_FRAGMENT,
  MEMBERS_FRAGMENT,
} from '../relationalFragments';

export type Channel = {
  id: string;
  title: string;
};
export type ChannelList = { channels: Array<Channel> };
export type ChannelListData = DataValue<ChannelList>;

export const GET_CHANNELS = gql`
  query getChannels {
    channels {
      ...ChannelData
    }
  }
  ${CHANNEL_FRAGMENT}
`;

export type ChannelTree = Channel & { segments: Array<SegmentTree> };
export type ChannelTreeList = { channels: Array<ChannelTree> };
export type ChannelTreeListData = DataValue<ChannelTreeList>;

export const GET_CHANNELS_TREE = gql`
  query getChannels {
    channels {
      ...ChannelData
      segments {
        ...SegmentData
        subSegments {
          ...SubSegmentData
        }
      }
    }
  }
  ${CHANNEL_FRAGMENT}
  ${SEGMENT_FRAGMENT}
  ${SUB_SEGMENT_FRAGMENT}
`;

export type ChannelTreeWithMembers = Pick<Channel, 'id' | 'title'> & {
  segments: Array<SegmentTreeWithMembers>;
};
export type ChannelTreeWithMembersList = {
  channels: Array<ChannelTreeWithMembers>;
};
export type ChannelTreeWithMembersListData = DataValue<
  ChannelTreeWithMembersList
>;

export const GET_CHANNELS_TREE_WITH_MEMBERS = gql`
  query getChannels {
    channels {
      ...ChannelData
      segments {
        ...SegmentData
        subSegments {
          ...SubSegmentData
          members {
            ...MemberData
          }
        }
      }
    }
  }
  ${CHANNEL_FRAGMENT}
  ${SEGMENT_FRAGMENT}
  ${SUB_SEGMENT_FRAGMENT}
  ${MEMBERS_FRAGMENT}
`;
