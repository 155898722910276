import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { Component } from 'react';

import { LoadMoreParams, Query } from '../../../components';
import TablePaginationAction from '../../../components/Table/TablePagination';
import {
  GHOST_WHITE,
  GRAY,
  PRIMARY,
  TRANSPARENT,
} from '../../../constants/colors';
import { RETAIL } from '../../../constants/priceSegment';
import { Icon, Text } from '../../../core-ui';
import {
  PRICE_SEGMENT_QUERY,
  PriceSegmentResult,
  PriceSegmentVariables,
  Product,
} from '../../../graphql/queries';
import { formatThousandSeparator } from '../../../helpers';
import getProductPrice from '../../../helpers/getProductPrice';

const DEFAULT_ROWS_PER_PAGE = 10;

type OwnProps = {
  priceSegmentCode?: string;
  isLoading?: boolean;
  resetPage?: boolean;
  data: Array<Product>;
  dataCount: number;
  selectedProduct?: Nullable<Product>;
  onChangeSelected?: (selectedProduct: Nullable<Product>) => void;
  searchKey?: string;
  loadMore: (params: LoadMoreParams) => void;
  disableSelect?: boolean;
  hidePagination?: boolean;
  setResetPage?: (isReset: boolean) => void;
};

type Props = WithStyles<typeof styles> & OwnProps;

type State = {
  page: number;
};

class ProductList extends Component<Props, State> {
  state = {
    page: 0,
  };

  componentDidUpdate() {
    const { resetPage, isLoading, setResetPage } = this.props;
    if (resetPage && isLoading) {
      // hacky way to reset page to 0 on search due to pagination
      setResetPage && setResetPage(false);
      this.setState({ page: 0 });
    }
  }

  render() {
    const {
      classes,
      data,
      selectedProduct,
      disableSelect,
      hidePagination,
      dataCount,
      priceSegmentCode,
    } = this.props;
    const { page } = this.state;
    const displayData = hidePagination ? data : this._getSortedData();
    return (
      <Query<PriceSegmentResult, PriceSegmentVariables>
        query={PRICE_SEGMENT_QUERY}
        variables={{ priceSegmentCode: RETAIL }}
      >
        {({ data: retailPriceList }) =>
          !!retailPriceList && (
            <Query<PriceSegmentResult, PriceSegmentVariables>
              query={PRICE_SEGMENT_QUERY}
              // it will not be empty because as a variable because the Query will be skipped if it is empty
              variables={{ priceSegmentCode: priceSegmentCode! }}
              skip={!priceSegmentCode}
            >
              {({ data: segmentPriceList }) => (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>
                        <Text size="xsmall" weight="bold">
                          NAMA PRODUK
                        </Text>
                      </TableCell>
                      <TableCell className={classes.header}>
                        <Text size="xsmall" weight="bold">
                          HARGA
                        </Text>
                      </TableCell>
                      <TableCell className={classes.header} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayData.map((datum) => {
                      const price = getProductPrice(
                        datum.id,
                        retailPriceList,
                        datum.defaultPrice,
                        segmentPriceList,
                      );

                      return (
                        <TableRow
                          key={datum.id}
                          onClick={(_) =>
                            !disableSelect && this._handleSelected(datum)
                          }
                        >
                          <TableCell
                            className={classNames(
                              classes.cell,
                              selectedProduct &&
                                selectedProduct.id === datum.id &&
                                classes.selectedCell,
                            )}
                          >
                            <Text
                              size="small"
                              weight="reg"
                              style={{ letterSpacing: 1.5 }}
                            >
                              {datum.title.toUpperCase()}
                            </Text>
                          </TableCell>
                          <TableCell
                            className={classNames(
                              classes.cell,
                              selectedProduct &&
                                selectedProduct.id === datum.id &&
                                classes.selectedCell,
                            )}
                          >
                            <Text
                              size="small"
                              weight="reg"
                              style={{ letterSpacing: 1.5 }}
                              numberOfLines={1}
                            >
                              Rp. {formatThousandSeparator(price)}
                            </Text>
                          </TableCell>
                          <TableCell
                            className={classNames(
                              classes.cell,
                              selectedProduct &&
                                selectedProduct.id === datum.id &&
                                classes.selectedCell,
                            )}
                          >
                            <Icon
                              name="check"
                              size="small"
                              color={
                                selectedProduct &&
                                selectedProduct.id === datum.id
                                  ? PRIMARY
                                  : TRANSPARENT
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {!hidePagination && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={8}
                          count={dataCount}
                          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
                          page={page}
                          onChangePage={this._handleChangePage}
                          ActionsComponent={TablePaginationAction}
                          rowsPerPageOptions={[]}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              )}
            </Query>
          )
        }
      </Query>
    );
  }

  _getSortedData() {
    const { data } = this.props;
    const { page } = this.state;

    const beginIndex = page * DEFAULT_ROWS_PER_PAGE;
    const endIndex = page * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE;

    const dataToDisplay = data.slice(beginIndex, endIndex);
    return dataToDisplay;
  }

  _handleSelected = (selectedProduct: Product) => {
    const { onChangeSelected } = this.props;
    onChangeSelected && onChangeSelected(selectedProduct);
  };

  _handleChangePage = (_: CustomMouseEvent, nextPage: number) => {
    const { loadMore, searchKey, data, dataCount } = this.props;
    const { page } = this.state;
    const isNextPagePressed = nextPage === page + 1;
    const totalPage = Math.ceil(dataCount / DEFAULT_ROWS_PER_PAGE);
    const nextPageIsLastPage = nextPage + 1 === totalPage;
    if (nextPageIsLastPage && data.length < dataCount) {
      loadMore({
        skip: data.length,
        first: dataCount - data.length,
        searchInput: searchKey,
      });
    } else if (
      !nextPageIsLastPage &&
      isNextPagePressed &&
      data.length < DEFAULT_ROWS_PER_PAGE * (nextPage + 1)
    ) {
      loadMore({
        skip: data.length,
        first: DEFAULT_ROWS_PER_PAGE,
        searchInput: searchKey,
      });
    }
    this.setState({ page: nextPage });
  };
}

const styles = createStyles({
  header: {
    padding: '4px 18px 4px 16px',
    borderBottom: 0,
  },
  cell: {
    padding: '4px 18px 4px 16px',
    borderBottom: `1px dashed ${GRAY}`,
  },
  selectedCell: {
    backgroundColor: GHOST_WHITE,
  },
});

export default withStyles(styles)(ProductList);
