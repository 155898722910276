import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import { LIGHT_GRAY } from '../../../constants/colors';
import { Icon } from '../../../core-ui';

const styles = StyleSheet.create({
  imageContainer: { backgroundColor: LIGHT_GRAY, borderRadius: 5 },
  zoomInIcon: {
    bottom: 0,
    alignSelf: 'flex-end',
    position: 'absolute',
    zIndex: 100,
  },
});

export default function ImagePlaceholder({
  size,
  imageUrl,
}: {
  size?: 'small' | 'large';
  imageUrl: Nullable<string>;
}) {
  let widthHeight = {};

  switch (size) {
    case 'large': {
      widthHeight = {
        width: 460,
        height: 280,
      };
      break;
    }
    default: {
      widthHeight = {
        width: 120,
        height: 100,
      };
      break;
    }
  }

  const source = { uri: imageUrl || '' };

  return (
    <View style={[widthHeight, styles.imageContainer]}>
      <Image resizeMode="contain" style={{ ...widthHeight }} source={source} />
      {size === 'small' && <Icon style={styles.zoomInIcon} name="crop_free" />}
    </View>
  );
}
