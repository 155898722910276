import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Text, Separator, Icon } from '../../core-ui';
import { Table, RowsPerPage, Query } from '../../components';
import { GRAY } from '../../constants/colors';

import { GET_ROLES, RoleListResult, AccessProps } from '../../graphql/queries';

export type RoleType = {
  enum: string;
  name: string;
};

function RenderTable(props: Props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { access } = props;
  let roles: Array<RoleType> = [];

  return (
    <Query<RoleListResult> query={GET_ROLES} notifyOnNetworkStatusChange>
      {({ data, loading }) => {
        if (data) {
          roles = data.roles.map((role) => ({
            enum: role,
            name: role.replace('_', ' '),
          }));
          return (
            <Table
              showFooter={false}
              data={roles}
              dataCount={roles.length}
              page={page}
              isLoading={loading}
              onChangePage={(nextPage) => setPage(nextPage)}
              narrowNumberColumn
              onChangeRowsPerPage={(num) => {
                setRowsPerPage(num);
                setPage(0);
              }}
              loadMore={() => {}}
              structure={{
                name: {
                  headerTitle: 'Nama Peran',
                },
                action: {
                  noHeaderName: true,
                  render: (d) => (
                    <View style={styles.icon}>
                      {access.update && (
                        <Link to={`/admin-roles/${d.enum}`}>
                          <Icon
                            name="edit"
                            size="small"
                            color={GRAY}
                            style={{ height: 30 }}
                          />
                        </Link>
                      )}
                    </View>
                  ),
                },
              }}
              rowsPerPage={rowsPerPage as RowsPerPage}
            />
          );
        }
        return null;
      }}
    </Query>
  );
}

type Props = AccessProps & RouteComponentProps;

export default function AdminRoleScene(props: Props) {
  const renderHeader = (
    <View style={styles.header}>
      <Text size="xlarge">Daftar Peran</Text>
    </View>
  );

  return (
    <View style={styles.root}>
      {renderHeader}
      <Separator />
      <RenderTable {...props} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  emptyScene: { flex: 1, alignItems: 'center', justifyContent: 'center' },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  icon: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
