const AVATAR_SIZES = {
  small: 20,
  medium: 40,
  large: 60,
  xlarge: 100,
};

const TITLE_SIZES = {
  small: 10,
  medium: 20,
  large: 30,
  xlarge: 50,
};

const TITLE_WEIGHTS = {
  small: 100,
  medium: 300,
  large: 400,
  xlarge: 400,
};

export { AVATAR_SIZES, TITLE_SIZES, TITLE_WEIGHTS };
