import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose, graphql, DataValue } from 'react-apollo';
import GoogleMapReact from 'google-map-react';

import {
  GET_USER_BY_ID,
  GetUserResult,
  User,
  GetUserVariable,
  UPDATE_USER,
  UpdateUserVariable,
  UpdateUserMutationFn,
  AccessProps,
  PRICE_SEGMENTS_WITHOUT_PRODUCTS_QUERY,
  PriceSegmentsWithoutProductsResult,
} from '../../graphql/queries';

import {
  FormPage,
  ErrorScreen,
  ProvincePicker,
  CitiesPicker,
  DistrictPicker,
  SubDistrictPicker,
  Query,
  Picker,
} from '../../components';
import {
  Card,
  TextField,
  Loading,
  Text,
  Dropzone,
  Icon,
  Separator,
} from '../../core-ui';

import {
  validateEmail,
  isObjectEqual,
  convertPascalCase,
  graphqlErrorRemover,
} from '../../helpers';
import { INPUT, GRAY70, RED_MARKER } from '../../constants/colors';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import { NotFoundPage } from '..';
import { sanitizeNumericInput } from '../../helpers/formatNumber';
import { DEFAULT_LOCATION } from '../../constants/defaultLocation';

type MatchParams = { id: string };
type RouteProps = RouteComponentProps<MatchParams>;

type GetUserProps = { userQuery: DataValue<GetUserResult, GetUserVariable> };

type UpdateUserProps = { updateUser: UpdateUserMutationFn };

type Props = AccessProps &
  RouteComponentProps &
  GetUserProps &
  UpdateUserProps &
  AccessProps &
  ToastContextProps;

type State = {
  depoName: Nullable<string>;
  id: string;
  name: string;
  status: string;
  telephone: string;
  email: string;
  address: string;
  storeName: string;
  storeAddress: string;
  storePhoto: string;
  storePhone: string;
  province: Nullable<string>;
  city: Nullable<string>;
  district: Nullable<string>;
  subDistrict: Nullable<string>;
  postalCode: string;
  location: {
    lat: number;
    lng: number;
  };
  segment: Nullable<string>;
  subSegment: Nullable<string>;
  cluster: Nullable<string>;
  photoFile?: File;
  isLoading: boolean;
  selectedPriceSegment: Nullable<string>;
};

class EditProfileScene extends Component<Props, State> {
  state: State = {
    depoName: '',
    id: '',
    name: '',
    status: '',
    telephone: '',
    email: '',
    address: '',
    storeName: '',
    storeAddress: '',
    storePhoto: '',
    storePhone: '',
    province: null,
    city: null,
    district: null,
    subDistrict: null,
    postalCode: '',
    location: {
      lat: DEFAULT_LOCATION.lat,
      lng: DEFAULT_LOCATION.lng,
    },
    segment: '',
    subSegment: '',
    cluster: null,
    isLoading: false,
    selectedPriceSegment: null,
  };

  componentDidMount() {
    const { userQuery } = this.props;
    if (userQuery.user) {
      this._saveData(userQuery.user);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { userQuery: prevQuery } = prevProps;
    const { userQuery: currQuery } = this.props;
    if (prevQuery.loading && !currQuery.loading && currQuery.user) {
      this._saveData(currQuery.user);
    }
  }

  render() {
    const {
      depoName,
      id,
      name,
      status,
      email,
      telephone,
      storeName,
      segment,
      storePhone,
      province,
      city,
      district,
      subDistrict,
      storeAddress,
      postalCode,
      subSegment,
      address,
      storePhoto,
      cluster,
      location: { lat, lng },
      isLoading,
      selectedPriceSegment,
    } = this.state;
    const { location, access, userQuery } = this.props;

    if (!location.state || !access.read || !access.update) {
      return <NotFoundPage />;
    }

    if (userQuery.loading) {
      return (
        <View style={styles.emptyScene}>
          <Loading />
        </View>
      );
    }
    if (userQuery.error) {
      return (
        <View style={styles.emptyScene}>
          <ErrorScreen
            detailMessage={graphqlErrorRemover(userQuery.error.message)}
          />
        </View>
      );
    }
    return (
      userQuery.user && (
        <FormPage
          isLoading={userQuery.loading}
          isSubmitLoading={isLoading}
          isDirty={this._isFormDirty()}
          isValid={this._isFormValid()}
          handleDiscard={() =>
            userQuery.user && this._handleDiscard(userQuery.user)
          }
          showBackButton
          handleGoBack={this._handleGoBack}
          handleSubmit={() => (userQuery.user ? this._handleSubmit() : {})}
        >
          <Card title="Ubah Informasi Pelanggan">
            <Header label="Data Pelanggan" />
            <TextForm label="Nama Depo" value={depoName || '-'} />
            <TextForm label="Kode Pelanggan" value={id || '-'} />
            <TextField
              stretch
              labelHorizontal
              label="Nama Pelanggan"
              placeholder="Nama Pelanggan"
              value={name}
              onChangeText={(value: string) =>
                this._onValueChange(value, 'name')
              }
            />
            <TextField
              stretch
              editable={false}
              labelHorizontal
              label="Status Pelanggan"
              placeholder="Status Pelanggan"
              value={status}
            />
            <TextField
              stretch
              labelHorizontal
              label="No HP Pelanggan"
              placeholder="No HP Pelanggan"
              value={telephone}
              onChangeText={(value: string) =>
                this._onValueChange(sanitizeNumericInput(value), 'telephone')
              }
            />
            <TextField
              stretch
              labelHorizontal
              label="Email Pelanggan"
              placeholder="Email Pelanggan"
              value={email}
              onChangeText={(value: string) =>
                this._onValueChange(value, 'email')
              }
            />
            <TextField
              stretch
              labelHorizontal
              label="Alamat Pelanggan"
              placeholder="Alamat Pelanggan"
              value={address}
              multiline
              onChangeText={(value: string) =>
                this._onValueChange(value, 'address')
              }
            />
            <Separator secondary style={styles.separator} />
            <Header label="Data Toko" />
            <TextField
              stretch
              labelHorizontal
              label="Nama Toko"
              placeholder="Nama Toko"
              value={storeName}
              onChangeText={(value: string) =>
                this._onValueChange(value, 'storeName')
              }
            />
            <TextField
              stretch
              labelHorizontal
              label="Alamat Toko"
              placeholder="Alamat Toko"
              value={storeAddress}
              multiline
              onChangeText={(value: string) =>
                this._onValueChange(value, 'storeAddress')
              }
            />
            <View style={styles.imageField}>
              <Text
                size="small"
                weight="reg"
                color={GRAY70}
                style={styles.imageFieldTitle}
              >
                Foto Toko
              </Text>
              <View style={styles.dropzone}>
                <Dropzone
                  multiple={false}
                  getPreview={(previews) =>
                    this.setState({
                      storePhoto: previews[0].preview,
                      photoFile: previews[0].file,
                    })
                  }
                  source={storePhoto ? { uri: storePhoto } : undefined}
                />
              </View>
            </View>
            <TextField
              stretch
              labelHorizontal
              label="No Telp Toko"
              placeholder="No Telp Toko"
              value={storePhone}
              onChangeText={(value: string) =>
                this._onValueChange(sanitizeNumericInput(value), 'storePhone')
              }
            />
            <ProvincePicker
              selectedOption={province}
              onChange={(selectedOption) => {
                this.setState({
                  province: selectedOption && selectedOption.value,
                  city: null,
                  district: null,
                  subDistrict: null,
                });
              }}
              style={{ zIndex: 5 }}
            />
            <CitiesPicker
              disabled={!province}
              province={province}
              selectedOption={city}
              onChange={(selectedOption) => {
                this.setState({
                  city: selectedOption && selectedOption.value,
                  district: null,
                  subDistrict: null,
                });
              }}
              style={{ zIndex: 4 }}
            />
            <DistrictPicker
              disabled={!city}
              city={city}
              selectedOption={district}
              onChange={(selectedOption) => {
                this.setState({
                  district: selectedOption && selectedOption.value,
                  subDistrict: null,
                });
              }}
              style={{ zIndex: 3 }}
            />
            <SubDistrictPicker
              disabled={!district}
              district={district}
              selectedOption={subDistrict}
              onChange={(selectedOption) =>
                this._onValueChange(
                  selectedOption && selectedOption.value,
                  'subDistrict',
                )
              }
              style={{ zIndex: 2 }}
            />
            <TextField
              stretch
              labelHorizontal
              label="Kode Pos"
              placeholder="Kode Pos"
              value={postalCode}
              onChangeText={(value: string) =>
                this._onValueChange(sanitizeNumericInput(value), 'postalCode')
              }
            />
            <View style={styles.imageField}>
              <Text
                size="small"
                weight="reg"
                color={GRAY70}
                style={styles.imageFieldTitle}
              >
                Peta Lokasi
              </Text>
              <View style={styles.mapWrapper}>
                <View style={styles.mapBox}>
                  <GoogleMapReact
                    defaultCenter={{
                      lat,
                      lng,
                    }}
                    defaultZoom={13}
                  >
                    <Marker lat={lat} lng={lng} />
                  </GoogleMapReact>
                </View>
              </View>
            </View>
            <Separator secondary style={styles.separator} />
            <Header label="Segmentasi" />
            <TextForm label="Segmentasi" value={segment || '-'} />
            <TextForm label="Sub segmentasi" value={subSegment || '-'} />
            <TextForm label="Cluster" value={cluster || '-'} />
            <Query<PriceSegmentsWithoutProductsResult>
              query={PRICE_SEGMENTS_WITHOUT_PRODUCTS_QUERY}
            >
              {({ data: priceSegments }) => {
                const priceSegmentOptions = priceSegments!.priceSegments.map(
                  ({ title, priceSegmentCode }) => ({
                    label: title,
                    value: priceSegmentCode,
                  }),
                );
                return (
                  <Picker
                    label="Segmentasi Harga"
                    placeholder="Pilih Segmentasi Harga"
                    selectedOption={selectedPriceSegment}
                    options={priceSegmentOptions}
                    onChange={(selected) => {
                      this.setState({ selectedPriceSegment: selected!.value });
                    }}
                  />
                );
              }}
            </Query>
          </Card>
        </FormPage>
      )
    );
  }

  _handleDiscard = (user: User) => {
    this.setState(this._formatData(user));
  };

  _handleGoBack = () => {
    const { history } = this.props;
    history && history.goBack();
  };

  _handleSubmit = () => {
    const {
      name,
      telephone,
      email,
      address,
      storeName,
      storeAddress,
      storePhone,
      province,
      city,
      district,
      subDistrict,
      postalCode,
      photoFile,
      selectedPriceSegment,
    } = this.state;
    const { userQuery, updateUser, openToast } = this.props;
    if (validateEmail(email) && userQuery.user) {
      updateUser({
        variables: {
          id: userQuery.user.id,
          user: {
            data: {
              name,
              phone: telephone,
              email,
              address,
              storeName,
              storeAddress,
              storeTelephone: storePhone,
              priceSegment: selectedPriceSegment
                ? { connect: { priceSegmentCode: selectedPriceSegment } }
                : { disconnect: true },
              location: {
                update: {
                  ...(province
                    ? { province: { connect: { id: province } } }
                    : {}),
                  ...(city ? { city: { connect: { id: city } } } : {}),
                  ...(district
                    ? { district: { connect: { id: district } } }
                    : {}),
                  ...(subDistrict
                    ? { subDistrict: { connect: { id: subDistrict } } }
                    : {}),
                  postalCode,
                },
              },
            },
            storePhoto: photoFile,
          },
        },
      });
      openToast('success', 'Profil telah diubah!');
      this._handleGoBack();
    }
  };

  _onValueChange = <K extends keyof State>(value: State[K], field: K) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  _isFormDirty = () => {
    const { userQuery } = this.props;
    const { isLoading: _, ...userState } = this.state;
    if (userQuery.user) {
      return !isObjectEqual(this._formatData(userQuery.user), userState);
    }
    return false;
  };

  _isFormValid = () => {
    const {
      name,
      email,
      address,
      telephone,
      storeName,
      storePhone,
      province,
      city,
      district,
      subDistrict,
      storeAddress,
      postalCode,
      selectedPriceSegment,
    } = this.state;
    return (
      name !== '' &&
      email !== '' &&
      telephone !== '' &&
      storeName !== '' &&
      storePhone !== '' &&
      province != null &&
      city != null &&
      district != null &&
      subDistrict != null &&
      storeAddress !== '' &&
      postalCode !== '' &&
      address !== '' &&
      selectedPriceSegment != null
    );
  };

  _formatData = (user: User) => {
    const {
      szID,
      name,
      address,
      active,
      depot,
      email,
      phone,
      location: {
        province,
        city,
        district,
        subDistrict,
        latitude,
        longitude,
        postalCode,
      },
      segment,
      subSegment,
      storeName,
      storeAddress,
      storeTelephone,
      userUpload: { storePhoto },
      cluster,
      priceSegment,
    } = user;

    return {
      depoName: depot.title,
      id: szID,
      name: convertPascalCase(name),
      status: active ? 'Aktif' : 'Tidak Aktif',
      telephone: phone,
      email,
      address,
      storeName,
      storeAddress,
      storePhoto,
      storePhone: storeTelephone,
      province: province.id,
      city: city.id,
      district: district.id,
      subDistrict: subDistrict.id,
      postalCode,
      location: {
        lat: Number(latitude) || DEFAULT_LOCATION.lat,
        lng: Number(longitude) || DEFAULT_LOCATION.lng,
      },
      segment: segment.title,
      subSegment: subSegment.title,
      cluster: cluster && cluster.currentCluster,
      selectedPriceSegment: priceSegment.priceSegmentCode,
    };
  };

  _saveData = (user: User) => {
    this.setState(this._formatData(user));
  };
}

function TextForm(props: { label: string; value: string }) {
  const { label, value } = props;
  return (
    <View style={styles.textField}>
      <View style={styles.imageFieldTitle}>
        <Text size="small" weight="reg" style={styles.textFieldTitle}>
          {label}
        </Text>
      </View>
      <View style={styles.textFieldValue}>
        <Text size="small" weight="reg">
          {value}
        </Text>
      </View>
    </View>
  );
}

function Marker(_: any) {
  return <Icon name="room" color={RED_MARKER} />;
}

function Header(props: { label: string }) {
  const { label } = props;
  return (
    <Text size="small" weight="bold" style={{ marginBottom: 32 }}>
      {label}
    </Text>
  );
}

const styles = StyleSheet.create({
  emptyScene: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageField: {
    flexDirection: 'row',
    marginBottom: 32,
  },
  imageFieldTitle: {
    flex: 1,
    paddingVertical: 8,
  },
  dropzone: {
    flex: 2,
  },
  separator: {
    marginBottom: 32,
  },
  mapBox: {
    width: 200,
    height: 200,
  },
  mapWrapper: {
    flex: 2,
  },
  textField: {
    alignItems: 'flex-start',
    marginBottom: 32,
    flexDirection: 'row',
    width: '100%',
  },
  textFieldValue: {
    flex: 2,
    paddingVertical: 8,
  },
  textFieldTitle: {
    color: INPUT.LABEL,
  },
});

export default compose(
  graphql<RouteProps, UpdateUserProps, UpdateUserVariable, UpdateUserProps>(
    UPDATE_USER,
    { name: 'updateUser' },
  ),
  graphql<RouteProps, GetUserProps, GetUserVariable, GetUserProps>(
    GET_USER_BY_ID,
    {
      name: 'userQuery',
      options: (props) => ({
        variables: { id: props.match.params.id },
        fetchPolicy: 'network-only',
      }),
    },
  ),
  withToast,
  withRouter,
)(EditProfileScene);
