import { DataValue } from 'react-apollo';

type Params<T> = {
  query: string;
  variables: T;
  dataKey: string;
  rowsPerPage: number;
  page: number;
};

export default <
  TData extends ObjectKey = {},
  TVariables extends ObjectKey = {}
>(
  fetchMoreFn: (args: any) => void,
  { query, variables, dataKey, rowsPerPage, page }: Params<TVariables>,
) => {
  fetchMoreFn({
    query,
    variables,
    updateQuery: (
      prev: DataValue<TData>,
      { fetchMoreResult }: { fetchMoreResult: DataValue<TData, {}> },
    ) => {
      if (!fetchMoreResult) {
        return prev;
      }

      const items = (prev[dataKey] || []) as Array<ObjectKey>;
      const prevItems = items.slice(0, page * rowsPerPage);

      const nextItems = (fetchMoreResult[dataKey] || []) as Array<ObjectKey>;

      return {
        ...prev,
        [dataKey]: [...prevItems, ...nextItems],
        count: fetchMoreResult.count,
      };
    },
  });
};
