import gql from 'graphql-tag';

export type Rating = {
  rating_id: string;
  customer_code: string;
  customer_name: string;
  driver_name: string;
  store_name: string;
  point: number;
  review: string;
};

export type RatingsData = {
  ratingWithDriver: Array<Rating>;
};

export type GetRatingsResult = RatingsData & { count: number };
export type GetRatingsVariables = {
  skip: number;
  first: number;
};

export const GET_RATINGS = gql`
  query getRatings($skip: Int, $first: Int) {
    ratings(skip: $skip, first: $first) {
      id
      user {
        szID
        name
        storeName
      }
      point
      review
    }
    count: dataCount(node: RATING)
  }
`;

export const GET_RATINGS_WITHDRIVER = gql`
  query getRatingsWithDriver($skip: Int, $first: Int) {
    ratingWithDriver(skip: $skip, first: $first) {
      rating_id
      customer_code
      customer_name
      store_name
      driver_name
      point
      review
    }
    count: dataCount(node: RATING)
  }
`;
