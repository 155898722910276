import {
  ProgramDepotParams,
  ProgramParams,
  ProgramSegmentParams,
  ProgramSubSegmentParams,
  ProgramTermParams,
  ProgramUserParams,
} from 'graphql/mutations';
import getTimeFrom24HourFormat from 'helpers/getTimeFrom24HourFormat';
import { FormState } from '../NewProgramFormScene';

function dataOrUndefined<Data>(
  data: Data[],
  isDelete: true,
): { id_in: Data[] } | undefined;

function dataOrUndefined<Data>(
  data: Data[],
  isDelete?: false,
): Data[] | undefined;

function dataOrUndefined<Data>(
  data: Data[],
  isDelete?: boolean,
): { id_in: Data[] } | Data[] | undefined {
  if (data.length === 0) {
    return undefined;
  }

  if (isDelete) {
    return { id_in: data };
  } else {
    return data;
  }
}

export function getProgramData(
  props: FormState,
  previous: FormState,
): ProgramParams {
  const {
    title,
    description,
    programType,
    rewardType,
    giftType,
    startDate,
    startDateTime,
    endDate,
    endDateTime,
    claimType,
    claimLimit,
    giftName,
    giftPrice,
    selectedProducts,
    selectedDepots,
    selectedSegments,
    selectedSubSegments,
    selectedCustomers,
    selectedTerms,
    selectedLoyaltyTerms,
    selectedBundleProducts,
    selectedFlashSaleTerms,
    titleNotification,
    bodyNotification,
    maximalQuantity,
    programUsers,
    maximalQuantityLimit,
    maximalQuantityProgram,
  } = props;

  const {
    selectedDepots: previousSelectedDepots,
    selectedSegments: previousSelectedSegments,
    selectedSubSegments: previousselectedSubSegments,
    selectedCustomers: previousCustomers,
    selectedProducts: previousProducts,
    selectedTerms: previousSelectedTerms,
    selectedFlashSaleTerms: previousSelectedFlashSaleTerms,
    selectedBundleProducts: previousSelectedBundleProducts,
    selectedLoyaltyTerms: previousSelectedLoyaltyTerms,
  } = previous;

  const disconnectProducts = previousProducts
    .filter(
      (product) =>
        !selectedProducts.map((product) => product.id).includes(product.id),
    )
    .map((product) => ({ id: product.id }));
  let disconnectProduct = {};
  if (disconnectProducts.length) {
    disconnectProduct = { disconnect: disconnectProducts };
  }

  let products = [];
  if (programType === 'BUNDLE') {
    products = selectedBundleProducts.map((bundleProduct) => ({
      id: bundleProduct.product.id,
    }));
  } else {
    products = selectedProducts.map((product) => ({ id: product.id }));
  }

  const arrayFlashSaleTerms = Array.from(selectedFlashSaleTerms).map(
    ([productId, terms]) => ({
      productId,
      terms,
    }),
  );
  const flashSaleTerms: ProgramTermParams = {
    create: dataOrUndefined(
      arrayFlashSaleTerms
        .filter((data) => data.terms.every((term) => term.state === 'new'))
        .map((data) => ({
          programType,
          product: { connect: { id: data.productId } },
          terms: {
            create: data.terms.map(({ minimalPurchase, rewardQty }) => ({
              minimalPurchase,
              rewardQty,
            })),
          },
        })),
    ),
    update: dataOrUndefined(
      arrayFlashSaleTerms
        .filter((data) => !data.terms.every((term) => term.state === 'new'))
        .map((data) => ({
          where: {
            id: String(data.terms[0].programTermId),
          },
          data: {
            terms: {
              update: data.terms
                .filter(({ state }) => state === 'modified')
                .map(({ id, minimalPurchase, rewardQty }) => ({
                  where: { id },
                  data: { minimalPurchase, rewardQty },
                })),
              create: data.terms
                .filter(({ state }) => state === 'new')
                .map(({ minimalPurchase, rewardQty }) => ({
                  minimalPurchase,
                  rewardQty,
                })),
              deleteMany: {
                id_in: data.terms
                  .filter(({ state }) => state === 'deleted')
                  .map(({ id }) => id),
              },
            },
          },
        }))
        .filter(
          (data) =>
            data.data.terms.update.length > 0 ||
            data.data.terms.create.length > 0 ||
            data.data.terms.deleteMany.id_in.length > 0,
        ),
    ),
    deleteMany: dataOrUndefined(
      arrayFlashSaleTerms
        .filter(
          (flashSaleTerm) =>
            !previousProducts.some(
              (product) => product.id === flashSaleTerm.productId,
            ),
        )
        .map((flashSaleTerm) => flashSaleTerm.terms[0].programTermId as string),
      true,
    ),
  };

  const bundleTerms: ProgramTermParams = {
    create: dataOrUndefined(
      selectedBundleProducts
        .filter((data) => data.state === 'new')
        .map(({ product, minimalPurchase, rewardQty }) => ({
          programType,
          product: { connect: { id: product.id } },
          terms: { create: [{ minimalPurchase, rewardQty }] },
        })),
    ),
    update: dataOrUndefined(
      selectedBundleProducts
        .filter((data) => data.state === 'modified' && data.programTermId)
        .map(({ programTermId, minimalPurchase, rewardQty, id }) => ({
          where: {
            id: String(programTermId),
          },
          data: {
            terms: {
              update: [
                {
                  where: { id: String(id) },
                  data: { minimalPurchase, rewardQty },
                },
              ],
            },
          },
        })),
    ),
    deleteMany: dataOrUndefined(
      previousSelectedBundleProducts
        .filter(
          (bundle) =>
            !selectedBundleProducts
              .map((prev) => prev.programTermId)
              .includes(bundle.programTermId),
        )
        .map((data) => data.programTermId as string),
      true,
    ),
  };

  const tradePromoTerms: ProgramTermParams = {
    create: dataOrUndefined(
      selectedTerms
        .filter((data) => data.state === 'new')
        .map(({ minimalPurchase, rewardQty }) => ({
          programType,
          terms: { create: [{ minimalPurchase, rewardQty }] },
        })),
    ),
    update: dataOrUndefined(
      selectedTerms
        .filter((data) => data.state === 'modified')
        .map(({ minimalPurchase, rewardQty, programTermId, id }) => ({
          where: {
            id: String(programTermId),
          },
          data: {
            terms: {
              update: [
                {
                  where: {
                    id,
                  },
                  data: {
                    minimalPurchase,
                    rewardQty,
                  },
                },
              ],
            },
          },
        })),
    ),
    deleteMany: dataOrUndefined(
      previousSelectedTerms
        .filter(
          (term) =>
            !selectedTerms
              .map((prev) => prev.programTermId)
              .includes(term.programTermId) && term.state !== 'new',
        )
        .map((data) => data.programTermId as string),
      true,
    ),
  };

  const loyaltyTerms: ProgramTermParams = {
    create: dataOrUndefined(
      selectedLoyaltyTerms
        .filter((data) => data.state === 'new')
        .map(({ minimalPurchase, rewardQty, customer }) => ({
          programType,
          user: { connect: { id: customer.id } },
          terms: { create: [{ minimalPurchase, rewardQty }] },
        })),
    ),
    update: dataOrUndefined(
      selectedLoyaltyTerms
        .filter((data) => data.state === 'modified')
        .map(({ minimalPurchase, rewardQty, programTermId, id }) => ({
          where: {
            id: String(programTermId),
          },
          data: {
            terms: {
              update: [
                {
                  where: {
                    id,
                  },
                  data: {
                    minimalPurchase,
                    rewardQty,
                  },
                },
              ],
            },
          },
        })),
    ),
    deleteMany: dataOrUndefined(
      previousSelectedLoyaltyTerms
        .filter(
          (term) =>
            !selectedLoyaltyTerms
              .map((prev) => prev.programTermId)
              .includes(term.programTermId) && term.state !== 'new',
        )
        .map((data) => data.programTermId as string),
      true,
    ),
  };

  const terms = {
    FLASH_SALE: flashSaleTerms,
    BUNDLE: bundleTerms,
    LOYALTY: loyaltyTerms,
    TRADE_PROMO: tradePromoTerms,
  };

  const programDepotCreate = () => {
    const depots = [...selectedDepots];
    const previousSelectedDepotsId = previousSelectedDepots.map(
      (dep) => dep.depot.id,
    );
    return depots
      .filter(
        (newDepot) => !previousSelectedDepotsId.includes(newDepot.depot.id),
      )
      .map((dep) => ({
        depot: { connect: { id: dep.depot.id } },
        maxQty: dep.qty,
        limitType: maximalQuantityLimit,
      }));
  };

  const programDepotUpdate = () => {
    const prev = [...previousSelectedDepots];
    if (prev.length > 0) {
      for (const dep of prev) {
        dep.qty =
          selectedDepots.find(({ depot }) => depot.id === dep.depot.id)?.qty ??
          0;
      }
    }
    const updates = prev.map((dep) => ({
      where: { id: dep.id! },
      data: { maxQty: dep.qty, limitType: maximalQuantityLimit },
    }));

    if (updates.length > 0) {
      return updates;
    }
  };

  const programDepotDelete = () => {
    const prev = [...previousSelectedDepots];
    const newSelectedId = selectedDepots.map((dep) => dep.depot.id);
    const deleteIds = prev
      .filter((dep) => !newSelectedId.includes(dep.depot.id))
      .map((dep) => dep.id!);

    if (deleteIds.length > 0) {
      return {
        id_in: deleteIds,
      };
    }
  };

  const programDepot = (): ProgramDepotParams => {
    return {
      create: programDepotCreate(),
      updateMany: programDepotUpdate(),
      deleteMany: programDepotDelete(),
    };
  };

  const programSegmentCreate = () => {
    const segments = [...selectedSegments];
    const previousSelectedSegmentsId = previousSelectedSegments.map(
      (seg) => seg,
    );

    return segments
      .filter((newSegment) => !previousSelectedSegmentsId.includes(newSegment))
      .map((seg) => ({
        segment: { connect: { id: seg.segment.id } },
      }));
  };

  const programSegmentDelete = () => {
    const prev = [...previousSelectedSegments];
    const newSelectedId = selectedSegments.map((seg) => seg);
    const deleteIds = prev
      .filter((seg) => !newSelectedId.includes(seg))
      .map((seg) => seg.id!);

    if (deleteIds.length > 0) {
      return {
        id_in: deleteIds,
      };
    }
  };

  const programSegment = (): ProgramSegmentParams => {
    return {
      create: programSegmentCreate(),
      deleteMany: programSegmentDelete(),
    };
  };

  const programSubSegmentCreate = () => {
    const subSegments = [...selectedSubSegments];
    const previousselectedSubSegmentsId = previousselectedSubSegments.map(
      (seg) => seg,
    );

    return subSegments
      .filter((newSub) => !previousselectedSubSegmentsId.includes(newSub))
      .map((sub) => ({
        subSegment: { connect: { id: sub.subSegment.id } },
      }));
  };

  const programSubSegmentDelete = () => {
    const prev = [...previousselectedSubSegments];
    const newSelectedId = selectedSubSegments.map((seg) => seg);
    const deleteIds = prev
      .filter((seg) => !newSelectedId.includes(seg))
      .map((seg) => seg.id!);

    if (deleteIds.length > 0) {
      return {
        id_in: deleteIds,
      };
    }
  };

  const programSubSegment = (): ProgramSubSegmentParams => {
    return {
      create: programSubSegmentCreate(),
      deleteMany: programSubSegmentDelete(),
    };
  };

  const programUserCreate = () => {
    const customers = [...selectedCustomers];
    const previouseSelectedCustomersIds = previousCustomers.map(
      (programUser) => programUser.user.id,
    );

    return customers
      .filter(
        (newCustomer) =>
          !previouseSelectedCustomersIds.includes(newCustomer.user.id),
      )
      .map((customer) => ({
        user: { connect: { id: customer.user.id } },
        outletMaxQty: maximalQuantity,
        limitType: maximalQuantityProgram,
      }));
  };

  const programUserUpdate = () => {
    const prev = [...previousCustomers];
    const updates = prev.map((dep) => ({
      where: { id: dep.id! },
      data: {
        outletMaxQty: maximalQuantity,
        limitType: maximalQuantityProgram,
      },
    }));

    if (updates.length > 0) {
      return updates;
    }
  };

  const programUserDelete = () => {
    const prev = programUsers;
    const newSelectedId = selectedCustomers.map((customer) => customer.user.id);
    const deleteIds = prev
      .filter((programUser) => !newSelectedId.includes(programUser.user.id))
      .map((customer) => customer.id!);

    if (deleteIds.length > 0) {
      return {
        id_in: deleteIds,
      };
    }
  };

  const programUser: ProgramUserParams = {
    create: programUserCreate(),
    updateMany: programUserUpdate(),
    deleteMany: programUserDelete(),
  };

  // const getProgramFocProduct = ()=>{
  //   if(rewardType === 'TVIP_PRODUCT'){
  //     const result: Array<{name: string, quantity: number}>= []
  //     selectedTerms.forEach(({otherProducts})=> {
  //       otherProducts?.forEach(val => {
  //         result.push({name: val.productName, quantity: val.qty})
  //       })
  //     })
  //     return result
  //   }else{
  //     return []
  //   }
  // }

  return {
    title: title.toLowerCase(),
    description,
    programType,
    rewardType,
    giftType,
    startDate: getTimeFrom24HourFormat(startDateTime, startDate).toISOString(),
    endDate: getTimeFrom24HourFormat(endDateTime, endDate).toISOString(),
    programDepot: programDepot(),
    programSegment: programSegment(),
    programSubSegment: programSubSegment(),
    terms: terms[programType!],
    products: { connect: products, ...disconnectProduct },
    programUser,
    claimType,
    claimLimit: Number(claimLimit),
    giftName,
    giftPrice,
    titleNotification,
    bodyNotification,
    // programFocProduct: { create : getProgramFocProduct()}
  };
}
