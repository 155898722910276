import React from 'react';
import { graphql } from 'react-apollo';

import { GET_ROLES, RoleListData, ProviderRole } from '../../graphql/queries';

import { PickerProps, Picker } from '.';

type ProviderRoleListProps = { roleListQuery: RoleListData };
type OwnProps = Omit<
  PickerProps<ProviderRole>,
  'options' | 'label' | 'placeholder'
>;
type Props = ProviderRoleListProps & OwnProps;

function RolePicker(props: Props) {
  const { roleListQuery, style, ...otherProps } = props;
  const options = roleListQuery.roles
    ? roleListQuery.roles.map((role) => ({
        value: role,
        label: role
          .split('_')
          .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
          .join(' '),
      }))
    : [];
  return (
    <Picker
      label="Peran"
      placeholder="Peran"
      style={style}
      isLoading={roleListQuery.loading}
      options={options}
      {...otherProps}
    />
  );
}

export default graphql<OwnProps, RoleListData, {}, ProviderRoleListProps>(
  GET_ROLES,
  { name: 'roleListQuery' },
)(RolePicker);
