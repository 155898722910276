import { Box, createStyles, makeStyles } from '@material-ui/core';
import { PointTooltip, SliceTooltip } from '@nivo/line';
import { PaperBox, Query } from 'components';
import { BLUE, ZOMBIE_GREEN } from 'constants/colors';
import { Text } from 'core-ui';
import { addDays, differenceInDays, endOfMonth, format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import {
  GET_TREND_USER_REGISTERED_CUSTOMER_COUNT,
  GET_TREND_USER_TRANSACTION_REPETITIVE,
  GetTrendUserRegisteredCustomerCountParams,
  GetTrendUserRegisteredCustomerCountResult,
  GetTrendUserTransactionRepetitiveParams,
  GetTrendUserTransactionRepetitiveResult,
  TrendUserRegisteredCustomerCount,
  TrendUserRepetitiveTransactions,
} from 'graphql/queries/trendBusinessQuery';
import { formatThousandSeparator, pick } from 'helpers';
import { convertToDecimal } from 'helpers/formatNumber';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DEFAULT_FILTER_STATE,
  Filter,
  LineChart,
  LineChartProps,
} from '../components';
import { FilterValues } from '../context';
import { useDateValues } from '../hooks';

const useStyles = makeStyles(
  createStyles({
    gap: {
      gap: '4px',
    },
    chartBox: {
      gap: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
    },
  }),
);

export function CustomerData() {
  const [filter, setFilter] = useState<FilterValues>(DEFAULT_FILTER_STATE);

  const classNames = useStyles();

  const isSameDay = filter.startDate?.getTime() === filter.endDate?.getTime();

  const calculateIdealRangeDates = useMemo(() => {
    const rangeDate = differenceInDays(
      filter.endDate || 0,
      filter.startDate || 0,
    );

    if (rangeDate < 6) {
      return '1 day';
    }

    if (rangeDate >= 6 && rangeDate <= 14) {
      return '3 days';
    }

    if (rangeDate > 14 && rangeDate <= 30) {
      return '1 week';
    }

    if (rangeDate > 30 && rangeDate <= 60) {
      return '2 weeks';
    }

    return '3 weeks';
  }, [filter.startDate, filter.endDate]);

  const tickValues = useMemo(() => {
    if (filter.periodTime === 'daily') {
      return isSameDay ? 'every 3 hours' : `every ${calculateIdealRangeDates}`;
    }

    return `every ${calculateIdealRangeDates}`;
  }, [isSameDay, calculateIdealRangeDates, filter.periodTime]);

  const onFilterChange = useCallback((values: FilterValues) => {
    setFilter(values);
  }, []);

  const dateValues = useDateValues(
    pick(filter, ['startDate', 'endDate', 'periodTime']),
  );

  const formatChartDate = useCallback(
    (date: Date, isSameDayDisabled?: boolean) => {
      if (filter.periodTime === 'daily') {
        return isSameDay && !isSameDayDisabled
          ? new Date(date).toLocaleTimeString('id', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
          : new Date(date).toLocaleDateString('id', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            });
      } else if (filter.periodTime === 'weekly') {
        const startdate = new Date(date).toLocaleDateString('id', {
          day: 'numeric',
        });
        const enddate = new Date(addDays(date, 6)).toLocaleDateString('id', {
          day: 'numeric',
        });
        const startmonth = new Date(date).toLocaleDateString('id', {
          month: 'short',
        });
        const endmonth = new Date(addDays(date, 6)).toLocaleDateString('id', {
          month: 'short',
        });
        const year = new Date(date).getFullYear();
        const sameMonth = startmonth === endmonth;

        return `${startdate} ${
          sameMonth ? '' : startmonth
        } - ${enddate} ${endmonth} ${year}`;
      } else {
        return new Date(date).toLocaleDateString('id', {
          month: 'long',
          year: 'numeric',
        });
      }
    },
    [filter.periodTime, isSameDay],
  );

  const handleTrendUserTransactionData = useCallback(
    (data?: TrendUserRepetitiveTransactions[]) => (id: string) => {
      if (!data) {
        return [];
      }

      return [
        {
          id,
          color: BLUE,
          data: data.map((item) => {
            return {
              x: new Date(
                new Date(item.date).getTime() -
                  getTimezoneOffset('Asia/Jakarta'),
              ),
              y: item.user_repeat_transaction_count,
            };
          }),
        },
      ];
    },
    [],
  );

  const handleTrendUserCount = useCallback(
    (data?: TrendUserRegisteredCustomerCount[]) => (
      id: string,
      type: 'active_user_count' | 'registered_count',
    ) => {
      if (!data) {
        return [];
      }

      const mapColors = {
        active_user_count: ZOMBIE_GREEN,
        registered_count: BLUE,
      };

      return [
        {
          id,
          color: mapColors[type],
          data: data.map((item) => {
            return {
              x: new Date(
                new Date(item.date).getTime() -
                  getTimezoneOffset('Asia/Jakarta'),
              ),
              y: item[type],
            };
          }),
        },
      ];
    },
    [],
  );

  const SliceTooltip: (isSameDay: boolean) => SliceTooltip = (isSameDay) => ({
    slice,
  }) => {
    const registeredCount = slice.points[0].data.y;
    const activeCount = slice.points[1].data.y;
    const date = new Date(slice.points[0].data.x);
    const calculatedPercentage =
      (Number(activeCount) /
        (Number(registeredCount) === 0 ? 1 : Number(registeredCount))) *
      100;

    const percentage = convertToDecimal(calculatedPercentage);

    return (
      <PaperBox
        padding={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box>
          <Text size="small" weight="bold">
            {`${formatThousandSeparator(
              Number(registeredCount),
            )} Pelanggan Register`}
          </Text>
        </Box>
        <Box>
          <Text size="small" weight="bold">
            {`${formatThousandSeparator(
              Number(activeCount),
            )} Pelanggan Aktif (${percentage}%)`}
          </Text>
        </Box>
        <Box>
          <Text size="xsmall">{formatChartDate(date)}</Text>
        </Box>
      </PaperBox>
    );
  };

  const Tooltip: (isSameDay: boolean) => PointTooltip = (isSameDay) => ({
    point,
  }) => {
    const userCount = point.data.y;
    const date = new Date(point.data.x);

    return (
      <PaperBox
        padding={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box>
          <Text size="small" weight="bold">
            {`${formatThousandSeparator(Number(userCount))} User`}
          </Text>
        </Box>
        <Box>
          <Text size="xsmall">{formatChartDate(date, true)}</Text>
        </Box>
      </PaperBox>
    );
  };

  const commonLineChartProps: Omit<LineChartProps, 'data'> = {
    margin: {
      left: 50,
      right: 80,
      top: 50,
      bottom: 80,
    },
    legends: [
      {
        anchor: 'bottom',
        direction: 'row',
        itemWidth: 100,
        itemHeight: 25,
        translateY: 75,
        itemsSpacing: 80,
        symbolShape: 'circle',
      },
    ],
    xScale: {
      type: 'point',
    },
    axisBottom: {
      tickValues: 1,
      format: (date) => formatChartDate(date),
      tickRotation: 25,
    },
    axisLeft: {
      format: (value) => formatThousandSeparator(value),
    },
    sliceTooltip: SliceTooltip(isSameDay && filter.periodTime !== 'monthly'),
    tooltip: Tooltip(isSameDay && filter.periodTime !== 'monthly'),
  };

  const customLineChartProps: Omit<LineChartProps, 'data'> = {
    ...commonLineChartProps,
    tooltip: Tooltip(isSameDay && filter.periodTime !== 'monthly'),
    axisBottom: {
      tickValues: 1,
      format: (date) => formatChartDate(date, true),
      tickRotation: 25,
    },
  };

  const { startDateVariable, endDateVariable } = useMemo(() => {
    if (!filter.startDate || !filter.endDate) {
      return {
        startDateVariable: undefined,
        endDateVariable: undefined,
      };
    }
    const startDateVariable = format(filter.startDate, 'yyyy-MM-dd HH:mm:ss');
    let endDateVariable;

    if (filter.periodTime === 'monthly') {
      endDateVariable = format(
        endOfMonth(filter.endDate),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else if (filter.periodTime === 'weekly') {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    } else if (filter.periodTime === 'daily' && isSameDay) {
      endDateVariable = format(
        addDays(filter.endDate, 1),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    }

    return {
      startDateVariable,
      endDateVariable,
    };
  }, [filter.endDate, filter.startDate, filter.periodTime, isSameDay]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      style={{
        gap: '2rem',
      }}
    >
      <Filter withSegmentationSelection onFilter={onFilterChange} />
      {filter.startDate && filter.endDate && (
        <>
          <PaperBox className={classNames.chartBox} elevation={0}>
            <Text size="large" weight="bold">
              Total Pelanggan Register
            </Text>
            <Text size="small" weight="light">
              {dateValues}
            </Text>
            <Query<
              GetTrendUserRegisteredCustomerCountResult,
              GetTrendUserRegisteredCustomerCountParams
            >
              query={GET_TREND_USER_REGISTERED_CUSTOMER_COUNT}
              variables={{
                startDate: startDateVariable,
                endDate: endDateVariable,
                depot: filter.depot ? filter.depot : undefined,
                segment: filter.segmentation ? filter.segmentation : undefined,
                periodType:
                  filter.startDate?.getTime() === filter.endDate?.getTime() &&
                  filter.periodTime === 'daily'
                    ? 'hourly'
                    : filter.periodTime,
              }}
              skip={!filter.startDate || !filter.endDate}
            >
              {({ data }) => {
                const activeUser = handleTrendUserCount(
                  data?.trendUserRegisteredCustomerCount,
                );

                return (
                  <Box width="100%" height={400}>
                    <LineChart
                      data={[
                        activeUser(
                          'Total Pelanggan Aktif',
                          'active_user_count',
                        )[0],
                        activeUser(
                          'Total Pelanggan Register',
                          'registered_count',
                        )[0],
                      ]}
                      enableSlices={'x'}
                      {...commonLineChartProps}
                    />
                  </Box>
                );
              }}
            </Query>
          </PaperBox>
          <PaperBox className={classNames.chartBox} elevation={0}>
            <Text size="large" weight="bold">
              Total Pelanggan Repeat Order
            </Text>
            <Text size="small" weight="light">
              {dateValues}
            </Text>
            <Query<
              GetTrendUserTransactionRepetitiveResult,
              GetTrendUserTransactionRepetitiveParams
            >
              query={GET_TREND_USER_TRANSACTION_REPETITIVE}
              variables={{
                startDate: startDateVariable,
                endDate: endDateVariable,
                depot: filter.depot ? filter.depot : undefined,
                segment: filter.segmentation ? filter.segmentation : undefined,
                periodType:
                  filter.startDate?.getTime() === filter.endDate?.getTime() &&
                  filter.periodTime === 'daily'
                    ? 'hourly'
                    : filter.periodTime,
              }}
              skip={!filter.startDate || !filter.endDate}
            >
              {({ data }) => {
                const repeatOrderData = handleTrendUserTransactionData(
                  data?.trendUserRepeatTransactions,
                );

                return (
                  <Box width="100%" height={400}>
                    <LineChart
                      data={repeatOrderData('Total Pelanggan Repeat Order')}
                      {...customLineChartProps}
                    />
                  </Box>
                );
              }}
            </Query>
          </PaperBox>
        </>
      )}
    </Box>
  );
}
