import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { View, StyleSheet, Image } from 'react-native';
import { Mutation, compose } from 'react-apollo';

import { CustomField, AdvanceField } from '../program/components';

import { NotFoundPage } from '..';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import {
  Card,
  TextField,
  DatePickerField,
  Separator,
  Text,
} from '../../core-ui';
import { FormPage, SegmentPicker } from '../../components';
import { ProductSelectionModal } from '../../components/Modal';
import { gte } from '../../assets';
import { RED } from '../../constants/colors';
import {
  isObjectEqual,
  capitalize,
  formatThousandSeparator,
  parseInteger,
  graphqlErrorRemover,
} from '../../helpers';

import { Product, AccessProps, ClusterPart } from '../../graphql/queries';
import {
  ADD_CLUSTERS,
  UPDATE_CLUSTERS,
  AddClusterMutation,
} from '../../graphql/mutations';

type Props = AccessProps & ToastContextProps & RouteComponentProps;

type Cluster = {
  bronze: ClusterObject;
  silver: ClusterObject;
  gold: ClusterObject;
  diamond: ClusterObject;
};
type ClusterObject = {
  minRange: Nullable<number>;
  maxRange: Nullable<number>;
  unitMultiplier: Nullable<number>;
  pointMultiplier: Nullable<number>;
};

type State = {
  isEdit: boolean;
  id: Nullable<string>;
  segment: Nullable<string>;
  subSegment: Nullable<string>;
  selectedProducts: Array<Product>;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  isModalClicked: boolean;
  cluster: Cluster;
};

const defaultState = {
  id: null,
  segment: null,
  subSegment: null,
  selectedProducts: [],
  startDate: null,
  endDate: null,
  isModalClicked: false,
  cluster: {
    diamond: {
      minRange: 0,
      maxRange: 0,
      unitMultiplier: 0,
      pointMultiplier: 0,
    },
    gold: {
      minRange: 0,
      maxRange: 0,
      unitMultiplier: 0,
      pointMultiplier: 0,
    },
    silver: {
      minRange: 0,
      maxRange: 0,
      unitMultiplier: 0,
      pointMultiplier: 0,
    },
    bronze: {
      minRange: 0,
      maxRange: 0,
      unitMultiplier: 0,
      pointMultiplier: 0,
    },
  },
};

const rangeInputError =
  'Jumlah unit kurang tepat. Periksa kembali jumlah unit pada cluster lainnya.';

export class ClusterInformationScene extends Component<Props, State> {
  state: State = { ...defaultState, isEdit: false };

  componentDidMount() {
    this._initializeState();
  }

  _initializeState = () => {
    const { state, pathname } = this.props.location;
    const isEdit = pathname !== '/clusters/new';
    if (!isEdit) {
      this.setState({ ...this._getEmptyState(), isEdit });
    } else if (state && state.clusterData) {
      this.setState({
        ...this._reformatData(state.clusterData),
        isEdit: true,
      });
    }
  };

  render() {
    const { isEdit } = this.state;
    const { openToast, history, access } = this.props;
    if ((!access.create && !isEdit) || (!access.update && isEdit)) {
      return <NotFoundPage />;
    }
    return (
      <Mutation
        mutation={isEdit ? UPDATE_CLUSTERS : ADD_CLUSTERS}
        onCompleted={() => {
          openToast && openToast('success', 'Cluster telah diubah.');
          history.replace('/clusters');
        }}
        onError={(error) =>
          openToast && openToast('fail', graphqlErrorRemover(error.message))
        }
      >
        {(clusterMutation, { loading }) => (
          <FormPage
            showBackButton
            handleSubmit={() => this._handleSubmit(clusterMutation)}
            handleDiscard={this._initializeState}
            handleGoBack={this._handleBack}
            isDirty={this._isFormDirty()}
            isValid={this._isFormValid()}
            isSubmitLoading={loading}
            submitTitle={isEdit ? 'Simpan' : 'Tambah'}
          >
            <Card
              title={
                isEdit ? 'Ubah Informasi Cluster' : 'Tambah Informasi Cluster'
              }
            >
              {this._renderFields()}
            </Card>
          </FormPage>
        )}
      </Mutation>
    );
  }

  _reformatData = (clusterData: ClusterPart) => {
    const {
      clusterGroup: { diamond, gold, silver, bronze },
    } = clusterData;
    return {
      id: clusterData.id,
      segment: clusterData.segment.id,
      subSegment: clusterData.subSegment.id,
      selectedProducts: clusterData.products,
      startDate: new Date(clusterData.validDate),
      endDate: new Date(clusterData.expiredDate),
      isModalClicked: false,
      cluster: {
        diamond: {
          minRange: diamond.minRange,
          maxRange: diamond.maxRange,
          unitMultiplier: diamond.unitMultiplier,
          pointMultiplier: diamond.pointMultiplier,
        },
        gold: {
          minRange: gold.minRange,
          maxRange: gold.maxRange,
          unitMultiplier: gold.unitMultiplier,
          pointMultiplier: gold.pointMultiplier,
        },
        silver: {
          minRange: silver.minRange,
          maxRange: silver.maxRange,
          unitMultiplier: silver.unitMultiplier,
          pointMultiplier: silver.pointMultiplier,
        },
        bronze: {
          minRange: bronze.minRange,
          maxRange: bronze.maxRange,
          unitMultiplier: bronze.unitMultiplier,
          pointMultiplier: bronze.pointMultiplier,
        },
      },
    };
  };

  _isFormDirty() {
    const { isEdit, ...otherState } = this.state;
    return !isObjectEqual(
      isEdit ? this._getDefaultState() : this._getEmptyState(),
      otherState,
    );
  }

  _getDefaultState = () => {
    const { state } = this.props.location;
    const { isModalClicked } = this.state;

    return { ...this._reformatData(state.clusterData), isModalClicked };
  };

  _isFormValid() {
    const {
      segment,
      subSegment,
      selectedProducts,
      startDate,
      endDate,
      cluster,
    } = this.state;
    const { diamond, gold, silver, bronze } = cluster;

    return (
      segment !== null &&
      subSegment !== null &&
      selectedProducts.length >= 1 &&
      startDate !== null &&
      endDate !== null &&
      diamond.minRange !== null &&
      diamond.pointMultiplier !== null &&
      diamond.unitMultiplier !== null &&
      gold.minRange !== null &&
      gold.maxRange !== null &&
      diamond.minRange === gold.maxRange + 1 &&
      gold.maxRange > gold.minRange &&
      gold.pointMultiplier !== null &&
      gold.unitMultiplier !== null &&
      silver.minRange !== null &&
      silver.maxRange !== null &&
      gold.minRange === silver.maxRange + 1 &&
      silver.maxRange > silver.minRange &&
      silver.pointMultiplier !== null &&
      silver.unitMultiplier !== null &&
      bronze.maxRange !== null &&
      silver.minRange === bronze.maxRange + 1 &&
      bronze.pointMultiplier !== null &&
      bronze.unitMultiplier !== null
    );
  }

  _renderFields = () => {
    const { startDate, endDate, segment, subSegment, cluster } = this.state;
    return (
      <>
        <SegmentPicker
          selectedSegment={segment}
          selectedSubSegment={subSegment}
          onSegmentChange={(selected) =>
            this.setState({
              segment: selected ? selected.value : selected,
            })
          }
          onSubSegmentChange={(selected) =>
            this.setState({
              subSegment: selected ? selected.value : selected,
            })
          }
          containerStyle={{ zIndex: 4 }}
        />
        <DatePickerField
          label="Tanggal Berlaku"
          placeholder="Tanggal Berlaku"
          labelHorizontal
          selectedDate={startDate}
          onChange={(value: Date) => this._onValueChange(value, 'startDate')}
          style={{ zIndex: 3 }}
        />
        <DatePickerField
          label="Tanggal Berakhir"
          placeholder="Tanggal Berakhir"
          labelHorizontal
          selectedDate={endDate}
          minDate={startDate || undefined}
          onChange={(value: Date) => this._onValueChange(value, 'endDate')}
          style={{ zIndex: 2 }}
        />
        <Separator secondary />
        <CustomField
          headerText="Pilih Produk"
          bodyText="Tentukan Produk untuk cluster ini"
          buttonText="Pilih Produk"
          selectedText={this._getSelectedProductText()}
          onPress={this._onModalClick}
        />
        <Separator secondary />
        {ClusterArr.map((val, i) => {
          const { name, range } = val;
          const ccluster = cluster[name];
          const siblingClusters: Array<ClusterObject> = [];
          let minError;
          let maxError;
          switch (name) {
            case 'diamond': {
              siblingClusters.push(cluster.gold);
              break;
            }
            case 'gold': {
              siblingClusters.push(cluster.silver, cluster.diamond);
              break;
            }
            case 'silver': {
              siblingClusters.push(cluster.bronze, cluster.gold);
              break;
            }
            default: {
              siblingClusters[1] = cluster.silver;
            }
          }
          if (siblingClusters.length > 0) {
            const bellow = siblingClusters[0];
            const above = siblingClusters[1];
            if (
              above &&
              ccluster.maxRange &&
              above.minRange &&
              ccluster.maxRange !== above.minRange - 1
            ) {
              maxError = rangeInputError;
            }
            if (
              bellow &&
              ccluster.minRange &&
              bellow.maxRange &&
              ccluster.minRange !== bellow.maxRange + 1
            ) {
              minError = rangeInputError;
            }
          }
          if (
            ccluster.maxRange &&
            ccluster.minRange &&
            ccluster.maxRange < ccluster.minRange
          ) {
            minError = rangeInputError;
            maxError = rangeInputError;
          }
          return (
            <View key={i}>
              <AdvanceField label="Nama Cluster">
                <Text size="small">{capitalize(name)}</Text>
              </AdvanceField>
              <AdvanceField
                label="Range Pembelian"
                style={minError || maxError ? { marginBottom: 2 } : {}}
              >
                {range ? (
                  <>
                    <TextField
                      placeholder="Range Awal"
                      value={
                        ccluster.minRange
                          ? formatThousandSeparator(ccluster.minRange)
                          : ''
                      }
                      error={minError}
                      showErrorMessage={false}
                      onChangeText={(value) =>
                        this._onClusterRangeChange(
                          name,
                          'minRange',
                          parseInteger(value),
                        )
                      }
                      style={{
                        textField: styles.textFieldRange,
                      }}
                    />
                    <Text>-</Text>
                    <TextField
                      placeholder="Range Akhir"
                      onChangeText={(value) =>
                        this._onClusterRangeChange(
                          name,
                          'maxRange',
                          parseInteger(value),
                        )
                      }
                      value={
                        ccluster.maxRange
                          ? formatThousandSeparator(ccluster.maxRange)
                          : ''
                      }
                      error={maxError}
                      showErrorMessage={false}
                      style={{
                        textField: styles.textFieldRange,
                      }}
                    />
                  </>
                ) : (
                  this._renderSingleRangeInput(
                    name,
                    ccluster,
                    maxError || minError,
                  )
                )}
              </AdvanceField>
              <View style={{ width: '100%', flex: 0, flexDirection: 'row' }}>
                <View style={{ flex: 1 }} />
                <View style={{ flex: 2 }}>
                  <Text color={RED} size="xsmall">
                    {minError || maxError}
                  </Text>
                </View>
              </View>
              <AdvanceField label="Kelipatan Pembelian - Poin">
                <TextField
                  placeholder="Kelipatan"
                  onChangeText={(value) =>
                    this._onClusterRangeChange(
                      name,
                      'unitMultiplier',
                      parseInteger(value),
                    )
                  }
                  value={
                    ccluster.unitMultiplier
                      ? formatThousandSeparator(ccluster.unitMultiplier)
                      : ''
                  }
                  style={{
                    textField: styles.textFieldRange,
                  }}
                />
                <Text>-</Text>
                <TextField
                  placeholder="Poin"
                  onChangeText={(value) =>
                    this._onClusterRangeChange(
                      name,
                      'pointMultiplier',
                      parseInteger(value),
                    )
                  }
                  value={
                    ccluster.pointMultiplier
                      ? formatThousandSeparator(ccluster.pointMultiplier)
                      : ''
                  }
                  style={{
                    textField: styles.textFieldRange,
                  }}
                />
              </AdvanceField>
              <Separator />
            </View>
          );
        })}
        {this._renderModal()}
      </>
    );
  };

  _getSelectedProductText = () => {
    const { selectedProducts } = this.state;
    return selectedProducts.length > 0
      ? `${selectedProducts.length} produk terpilih`
      : null;
  };

  _renderModal = () => {
    const { isModalClicked, selectedProducts } = this.state;

    return (
      isModalClicked && (
        <ProductSelectionModal
          onClose={this._onModalClick}
          selectedProducts={selectedProducts}
          onChangeSelected={(selectedArray) =>
            this.setState({ selectedProducts: selectedArray })
          }
          bodyText="Tentukan produk untuk cluster ini"
        />
      )
    );
  };

  _getEmptyState = () => defaultState;

  _onModalClick = () => {
    const { isModalClicked } = this.state;
    this.setState({ isModalClicked: !isModalClicked });
  };

  _onClusterRangeChange = (
    clusterName: keyof Cluster,
    field: keyof ClusterObject,
    range: number,
  ) => {
    let { cluster } = this.state;

    let currentCluster = cluster[clusterName];
    currentCluster = { ...currentCluster, [field]: range };
    cluster = { ...cluster, [clusterName]: currentCluster };
    this.setState({ cluster });
  };

  _onValueChange = <K extends keyof State>(value: State[K], field: K) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  _handleBack = () => {
    const { history } = this.props;
    history && history.goBack();
  };

  _handleSubmit = (clusterMutation: AddClusterMutation) => {
    const {
      startDate,
      endDate,
      cluster: { diamond, gold, silver, bronze },
      segment,
      subSegment,
      selectedProducts,
      isEdit,
    } = this.state;
    const id = isEdit ? { id: this.state.id || '' } : {};
    if (startDate && endDate && segment && subSegment) {
      clusterMutation({
        variables: {
          ...id,
          validDate: startDate.toISOString(),
          expiredDate: endDate.toISOString(),
          segmentID: segment,
          subSegmentID: subSegment,
          products: selectedProducts.map((product) => ({ id: product.id })),
          diamond: {
            maxRange: diamond.maxRange || 0,
            minRange: diamond.minRange || 0,
            pointMultiplier: diamond.pointMultiplier || 0,
            unitMultiplier: diamond.unitMultiplier || 0,
            name: 'DIAMOND',
          },
          gold: {
            maxRange: gold.maxRange || 0,
            minRange: gold.minRange || 0,
            pointMultiplier: gold.pointMultiplier || 0,
            unitMultiplier: gold.unitMultiplier || 0,
            name: 'GOLD',
          },
          silver: {
            maxRange: silver.maxRange || 0,
            minRange: silver.minRange || 0,
            pointMultiplier: silver.pointMultiplier || 0,
            unitMultiplier: silver.unitMultiplier || 0,
            name: 'SILVER',
          },
          bronze: {
            maxRange: bronze.maxRange || 0,
            minRange: bronze.minRange || 0,
            pointMultiplier: bronze.pointMultiplier || 0,
            unitMultiplier: bronze.unitMultiplier || 0,
            name: 'BRONZE',
          },
        },
      });
    }
  };

  _renderSingleRangeInput(
    name: keyof Cluster,
    cluster: ClusterObject,
    error?: string,
  ) {
    const field: keyof ClusterObject =
      name === 'diamond' ? 'minRange' : 'maxRange';
    const rotateY = name === 'diamond' ? '0deg' : '180deg';
    return (
      <TextField
        stretch
        onChangeText={(value) =>
          this._onClusterRangeChange(name, field, parseInteger(value))
        }
        childrenPosition="left"
        value={
          cluster[field] ? formatThousandSeparator(Number(cluster[field])) : ''
        }
        error={error}
        showErrorMessage={false}
        placeholder="Range Pembelian"
        style={{ container: styles.flex }}
      >
        <Image
          resizeMode="contain"
          source={{ uri: gte }}
          style={{ width: 12, height: 12, transform: [{ rotateY }] }}
        />
      </TextField>
    );
  }
}

const ClusterArr: Array<{ name: keyof Cluster; range: boolean }> = [
  {
    name: 'diamond',
    range: false,
  },
  {
    name: 'gold',
    range: true,
  },
  {
    name: 'silver',
    range: true,
  },
  {
    name: 'bronze',
    range: false,
  },
];

let styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  textFieldRange: {
    maxWidth: 160,
  },
});

export default compose(withToast)(ClusterInformationScene);
