import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PaperBox } from 'components';
import { Text } from 'core-ui';
import { formatDecimal, formatThousandSeparator } from 'helpers/formatNumber';
import React from 'react';
import { BLUE_SOFT } from '../../../constants/colors';
import { LinearProgressbar } from './LinearProgressbar';

type StatisticsBoxProps = {
  targetTotal: number;
  programClaimCountTotal: number;
  achievementTotal: number;
  gapTotal: number;
  runningDays: string;
  percentage: number;
  startDate: string;
  endDate: string;
  currentRemainingDays: number;
  isLoading: boolean;
};

const useStyles = makeStyles(
  createStyles({
    paperBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      padding: 16,
      justifyContent: 'center',
    },
  }),
);

export function StatisticsBox({
  targetTotal,
  programClaimCountTotal,
  achievementTotal,
  gapTotal,
  runningDays,
  percentage,
  startDate,
  endDate,
  currentRemainingDays,
  isLoading,
}: StatisticsBoxProps) {
  const classNames = useStyles();

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(4, 1fr) 2fr"
      gridTemplateRows="1fr"
      gridGap={10}
      mb={4}
    >
      <PaperBox elevation={0} className={classNames.paperBox}>
        <Text size="small" weight="light">
          Kuota/Target
        </Text>
        <Text size="xlarge" weight="bold">
          {isLoading ? <Skeleton /> : formatThousandSeparator(targetTotal)}
        </Text>
      </PaperBox>
      <PaperBox elevation={0} className={classNames.paperBox}>
        <Text size="small" weight="light">
          Realisasi
        </Text>
        <Text size="xlarge" weight="bold">
          {isLoading ? (
            <Skeleton />
          ) : (
            formatThousandSeparator(programClaimCountTotal)
          )}
        </Text>
      </PaperBox>
      <PaperBox elevation={0} className={classNames.paperBox}>
        <Text size="small" weight="light">
          Achievement (%)
        </Text>
        <Text size="xlarge" weight="bold">
          {isLoading ? (
            <Skeleton />
          ) : (
            formatDecimal(achievementTotal > 100 ? 100 : achievementTotal, 1)
          )}
        </Text>
      </PaperBox>
      <PaperBox elevation={0} className={classNames.paperBox}>
        <Text size="small" weight="light">
          Gap
        </Text>
        <Text size="xlarge" weight="bold">
          {isLoading ? <Skeleton /> : formatThousandSeparator(gapTotal)}
        </Text>
      </PaperBox>
      <PaperBox elevation={0} className={classNames.paperBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text size="small" weight="light">
            Timegone
          </Text>
          {isLoading ? (
            <Skeleton width={100} height={50} />
          ) : (
            <Box padding={1} bgcolor={BLUE_SOFT} borderRadius={12}>
              <Text size="xsmall">{`${runningDays} hari`}</Text>
            </Box>
          )}
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="2fr 1fr"
          gridGap={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="xlarge" weight="bold">
            {isLoading ? <Skeleton /> : `${percentage}%`}
          </Text>
          <Box width="100%">
            {isLoading ? (
              <Skeleton width="100%" height={20} />
            ) : (
              <LinearProgressbar
                value={percentage}
                color={BLUE_SOFT}
                size="small"
              />
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text size="xsmall" weight="light">
            {isLoading ? (
              <Skeleton width="150px" height={20} />
            ) : (
              `Periode ${startDate} - ${endDate}`
            )}
          </Text>
          <Text size="xsmall" weight="light">
            {isLoading ? (
              <Skeleton width="150px" height={20} />
            ) : (
              `${currentRemainingDays} days remaining`
            )}
          </Text>
        </Box>
      </PaperBox>
    </Box>
  );
}
