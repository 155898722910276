import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import { DARK_GRAY, LIGHTER_GRAY } from '../../../constants/colors';
import {
  Button,
  IncrementField,
  Modal,
  Separator,
  Text,
} from '../../../core-ui';
import { CouponProduct, RewardType, Term } from '../../../graphql/queries';

import { TermInput } from '../components';
import { OtherProduct, ProductSelectionModal } from './ProductSelectionModal';

type Props = {
  selectedTerms: Array<Term>;
  onClose: () => void;
  onChangeSelected: (selectedTerms: Array<Term>) => void;
  rewardType: Nullable<RewardType>;
  isVisible: boolean;
};

type State = {
  terms: Array<{
    id: string;
    minimalPurchase: number;
    rewardQty: number;
    products?: CouponProduct[];
    otherProducts?: OtherProduct[];
    state?: Term['state'];
    __typename: 'Term';
  }>;
  productSelectionModalVisible: boolean;
  selectedTerm?: number;
};

class TermsModal extends Component<Props> {
  state: State = {
    terms:
      this.props.selectedTerms.length > 0
        ? this.props.selectedTerms
        : [
            {
              id: '',
              minimalPurchase: 0,
              rewardQty: 0,
              products: [],
              otherProducts: [],
              state: 'new',
              __typename: 'Term',
            },
          ],
    productSelectionModalVisible: false,
  };

  render() {
    const { onClose, isVisible } = this.props;
    return (
      <Modal
        maxHeight
        title="Tentukan Syarat"
        isVisible={isVisible}
        onClose={onClose}
        buttonText="Simpan"
        onSubmit={() => this._onSubmit()}
      >
        <View>
          <Text color={DARK_GRAY} style={styles.bottomPadding}>
            Tentukan syarat-syarat yang berlaku untuk mendapatkan program ini.
          </Text>
          {this._renderTerms()}
        </View>
      </Modal>
    );
  }

  _renderTerms = () => {
    const { terms, productSelectionModalVisible, selectedTerm } = this.state;
    const { rewardType } = this.props;

    return (
      <>
        <Separator />
        {rewardType === 'TVIP_PRODUCT' && typeof selectedTerm === 'number' ? (
          <ProductSelectionModal
            isVisible={productSelectionModalVisible}
            onClose={this._toggleProductSelectionModal}
            onSelect={({ products, otherProducts }) => {
              this.setState((prevState: State) => ({
                terms: prevState.terms.map((term, index) => {
                  if (index === selectedTerm) {
                    return {
                      ...term,
                      products,
                      otherProducts,
                    };
                  }

                  return term;
                }),
              }));
            }}
            selectedProducts={[...(terms[selectedTerm]?.products || [])]}
            selectedOtherProducts={[
              ...(terms[selectedTerm]?.otherProducts || []),
            ]}
          />
        ) : null}
        {terms.map((term, index) => {
          const totalProduct = term.products ? term.products.length : 0;
          const totalOtherProduct = term.otherProducts
            ? term.otherProducts.length
            : 0;

          if (rewardType === 'TVIP_PRODUCT') {
            return (
              <View style={styles.termInput}>
                <IncrementField
                  stretch
                  label="Minimal Pembelian (Unit)"
                  value={term.minimalPurchase}
                  onChangeValue={(value) =>
                    this._onMinimumOrderChange(value, index)
                  }
                  onButtonPress={(value) =>
                    this._onMinimumOrderChange(value, index)
                  }
                />
                <View style={styles.rightColumn}>
                  <Button
                    inverted
                    onPress={() => {
                      this.setState(
                        {
                          selectedTerm: index,
                        },
                        () => {
                          this._toggleProductSelectionModal();
                        },
                      );
                    }}
                  >
                    Pilih Bonus Produk
                  </Button>
                </View>
                <View style={{ marginVertical: 8, paddingLeft: 6 }}>
                  <Text
                    color={LIGHTER_GRAY}
                    size="medium"
                    key={`bonus-${index}`}
                  >
                    {totalProduct + totalOtherProduct} Produk dipilih
                  </Text>
                </View>
              </View>
            );
          } else {
            return (
              <TermInput
                key={`${index}-${term.minimalPurchase}-${term.rewardQty}`}
                initialValue={term}
                onUpdate={(newTermValue) =>
                  this._updateTerm(index, newTermValue)
                }
                onDelete={() => this._deleteTerm(index)}
              />
            );
          }
        })}
        <Button
          text="Tambah Syarat"
          icon="add"
          inverted
          style={styles.addButton}
          onPress={this._addTerm}
        />
      </>
    );
  };

  _onMinimumOrderChange = (value: number, index: number) => {
    this.setState((prevState: State) => ({
      terms: prevState.terms.map((term, idx) => {
        if (index === idx) {
          return {
            ...term,
            state: term.state === 'unchanged' ? 'modified' : term.state,
            minimalPurchase: value,
          };
        }
        return term;
      }),
    }));
  };

  _toggleProductSelectionModal = () => {
    this.setState((prevState: State) => ({
      productSelectionModalVisible: !prevState.productSelectionModalVisible,
    }));
  };

  _addTerm = () => {
    this.setState((prevState: State) => ({
      terms: [
        ...prevState.terms,
        {
          state: 'new',
          minimalPurchase: 0,
          rewardQty: 0,
          price: 0,
        },
      ],
    }));
  };

  _updateTerm = (selectedIndex: number, newTermValue: Term) => {
    this.setState((prevState: State) => {
      const { terms } = prevState;
      const newTerms = terms.map((term, index) =>
        index === selectedIndex
          ? {
              ...newTermValue,
              state: term.state === 'unchanged' ? 'modified' : term.state,
            }
          : term,
      );
      return {
        terms: newTerms,
      };
    });
  };

  _deleteTerm = (selectedIndex: number) => {
    this.setState((prevState: State) => {
      const { terms } = prevState;
      const newTerms = terms.filter((_, index) => index !== selectedIndex);
      return {
        terms: newTerms,
      };
    });
  };

  _onSubmit = () => {
    const { onChangeSelected, onClose } = this.props;
    const { terms } = this.state;
    onChangeSelected(terms);
    onClose();
  };
}

const styles = StyleSheet.create({
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomPadding: { paddingBottom: 20 },
  padding: { padding: 40 },
  dropdown: {
    marginBottom: 0,
    zIndex: 2,
  },
  button: {
    marginTop: 50,
    alignSelf: 'flex-end',
  },
  addButton: {
    alignSelf: 'flex-start',
    borderWidth: 0,
  },
  flexOne: { flex: 1 },
  halfFlex: { flex: 0.5 },
  leftColumn: {
    flex: 1,
    paddingRight: 20,
  },
  rightColumn: {
    paddingLeft: 10,
  },
  termInput: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 20,
  },
});

export default TermsModal;
