import React, { useState, Fragment, Dispatch } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import ImagePlaceholder from './ImagePlaceholder';
import { ModalAction } from '../helpers/modalHelper';
import { Text, Button } from '../../../core-ui';
import { Table, RowsPerPage, Query } from '../../../components';
import { User, GetUsersParams } from '../../../graphql/queries';
import { GET_USER_KTP_PENDING } from '../queries/getUsersWithPendingKtp';
import { fetchMoreItems, refetchItems } from '../../../helpers';
import { AccessProps } from '../../user/UserScene';

type UserWithPendingKTP = Omit<User, 'cluster'>;
type GetUsersWithPendingKTPResult = {
  users: Array<UserWithPendingKTP>;
  count: number;
};

type Props = {
  searchContent: string;
  modalDispatch: Dispatch<ModalAction>;
} & AccessProps;

export default function KtpTable(props: Props) {
  const dataKey = 'users';
  const [resetPage, setResetPage] = useState(false);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {
    searchContent,
    modalDispatch,
    access: { create },
  } = props;
  if (searchText !== searchContent) {
    setSearchText(searchContent);
    setResetPage(true);
  }
  const searchValue = searchText.toLowerCase();
  const searchWhere = {
    OR: [
      { name_contains: searchValue },
      { phone_contains: searchValue },
      { ktpNumber_contains: searchValue },
    ],
  };
  return (
    <Query<GetUsersWithPendingKTPResult, GetUsersParams>
      query={GET_USER_KTP_PENDING}
      variables={{
        where: searchWhere,
        first: rowsPerPage,
        skip: 0,
      }}
      fetchPolicy="network-only"
      keyData={dataKey}
      notifyOnNetworkStatusChange
    >
      {({ data, loading, fetchMore }) => {
        if (data) {
          const { users, count } = data;
          return (
            <Table
              resetPage={resetPage}
              setResetPage={setResetPage}
              page={page}
              onChangePage={setPage}
              isLoading={loading}
              onChangeRowsPerPage={(num) => {
                setRowsPerPage(num);
                setPage(0);
              }}
              data={users}
              loadMore={(params) => {
                fetchMoreItems<GetUsersWithPendingKTPResult, GetUsersParams>(
                  fetchMore,
                  {
                    query: GET_USER_KTP_PENDING,
                    variables: {
                      ...params,
                      where: searchWhere,
                    },
                    dataKey,
                  },
                );
              }}
              structure={{
                photo: {
                  headerTitle: 'FOTO KTP',
                  render: ({
                    name,
                    ktpNumber,
                    birthdate,
                    birthplace,
                    storeAddress,
                    userUpload,
                  }: {
                    [key: string]: any;
                  }) => {
                    const ktpPhoto = userUpload ? userUpload.ktpPhoto : '';
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          modalDispatch({
                            type: 'OPEN_KTP',
                            ktpData: {
                              name,
                              ktpNumber,
                              birthdate,
                              birthplace,
                              storeAddress,
                              ktpUrl: ktpPhoto,
                            },
                          })
                        }
                      >
                        <ImagePlaceholder size="small" imageUrl={ktpPhoto} />
                      </TouchableOpacity>
                    );
                  },
                },
                name: { headerTitle: 'NAMA', isOrder: true },
                ktpNumber: {
                  headerTitle: 'NO. KTP',
                  render: ({
                    depot,
                    id,
                    name,
                    address,
                    email,
                    active,
                    ktpNumber,
                    userUpload,
                    storeName,
                    storeAddress,
                    storeTelephone,
                    phone,
                    location,
                  }) => {
                    const province = location ? location.province.name : '';
                    const city = location ? location.city.name : '';
                    const district = location ? location.district.name : '';
                    const subDistrict = location
                      ? location.subDistrict.name
                      : '';
                    const postalCode = location ? location.postalCode : '';
                    const storePhoto = userUpload ? userUpload.storePhoto : '';
                    const latitude = location ? Number(location.latitude) : 0;
                    const longitude = location ? Number(location.longitude) : 0;
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          modalDispatch({
                            type: 'OPEN_DETAIL',
                            userDetail: {
                              id,
                              depotName: depot && depot.title,
                              name,
                              address,
                              phone,
                              email,
                              status: active ? 'Aktif' : 'Tidak Aktif',
                              storeName,
                              storeAddress,
                              storeTelephone,
                              storePhoto,
                              province,
                              city,
                              district,
                              subDistrict,
                              postalCode,
                              map: { latitude, longitude },
                            },
                          })
                        }
                      >
                        <Text size="small" primary>
                          {ktpNumber}
                        </Text>
                      </TouchableOpacity>
                    );
                  },
                },
                documentActionLogs: {
                  headerTitle: 'STATUS',
                  render: ({ id, documentActionLogs }) => {
                    const revision = documentActionLogs.length > 0;
                    return revision ? (
                      <TouchableOpacity
                        onPress={() =>
                          modalDispatch({ type: 'OPEN_REVISION', userId: id })
                        }
                      >
                        <Text size="small" primary>
                          REVISI
                        </Text>
                      </TouchableOpacity>
                    ) : (
                      <Text size="small">BARU</Text>
                    );
                  },
                },
                phone: { headerTitle: 'KONTAK' },
                action: {
                  noHeaderName: true,
                  render: ({ id }) =>
                    create && (
                      <View style={styles.flexRow}>
                        <Button
                          inverted
                          text="Tolak"
                          color="secondary"
                          onPress={() =>
                            modalDispatch({
                              type: 'OPEN_REJECT',
                              userId: id,
                              onSuccess: () => {
                                refetchItems<
                                  GetUsersWithPendingKTPResult,
                                  GetUsersParams
                                >(fetchMore, {
                                  query: GET_USER_KTP_PENDING,
                                  variables: {
                                    where: searchWhere,
                                    first: rowsPerPage,
                                    skip: page * rowsPerPage,
                                  },
                                  dataKey,
                                  rowsPerPage,
                                  page,
                                });
                              },
                            })
                          }
                          style={styles.rejectButton}
                        />
                        <Button
                          text="Terima"
                          onPress={() =>
                            modalDispatch({
                              type: 'OPEN_APPROVE',
                              userId: id,
                              onSuccess: () => {
                                refetchItems<
                                  GetUsersWithPendingKTPResult,
                                  GetUsersParams
                                >(fetchMore, {
                                  query: GET_USER_KTP_PENDING,
                                  variables: {
                                    where: searchWhere,
                                    first: rowsPerPage,
                                    skip: page * rowsPerPage,
                                  },
                                  dataKey,
                                  rowsPerPage,
                                  page,
                                });
                              },
                            })
                          }
                        />
                      </View>
                    ),
                },
              }}
              rowsPerPage={rowsPerPage as RowsPerPage}
              dataCount={count}
            />
          );
        }
        return null;
      }}
    </Query>
  );
}

let styles = StyleSheet.create({
  flexRow: { flexDirection: 'row' },
  flexEnd: { justifyContent: 'flex-end' },
  rejectButton: { marginRight: 20 },
});
