import React, { Children, Component, ReactElement } from 'react';

import {
  Tab as MTab,
  Tabs as MTabs,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab';
import { TabsProps as MTabsProps } from '@material-ui/core/Tabs';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { TABS } from '../constants/colors';
import { FONT_SIZE } from '../constants/textPresets';

type TabsProps = WithStyles<typeof styles> &
  Omit<MTabsProps, 'onChange'> & {
    selectedIndex: number;
    headerWrapperStyle?: StyleProp<ViewStyle>;
    noGreyBar?: boolean;
    onChange: (event: React.ChangeEvent<{}>, value: number) => void;
  };

export function Tab(_props: TabProps) {
  return null;
}

class Tabs extends Component<TabsProps> {
  static defaultProps = {
    value: 0,
    selectedIndex: 0,
  };

  render() {
    const {
      children,
      classes,
      noGreyBar,
      headerWrapperStyle,
      selectedIndex,
      value,
      ...otherTabsProps
    } = this.props;

    let content = null;

    const tabsStyle = {
      root: classes.tabsRoot,
      indicator: classes.tabsIndicatorDefault,
    };
    const tabStyle = {
      root: classes.tabRootDefault,
      label: classes.label,
      selected: classes.tabSelectedDefault,
    };

    const headerChildren = Children.map(children, (child, i: number) => {
      if (child) {
        const { type, props: childProps } = child as ReactElement<TabProps>;
        if (type === Tab) {
          const {
            label,
            disabled,
            children: grandChildren,
            ...otherTabProps
          } = childProps;
          const selected = i === selectedIndex;
          if (selected) {
            content = grandChildren;
          }
          return (
            <MTab
              label={label}
              disabled={disabled}
              classes={tabStyle}
              {...otherTabProps}
            />
          );
        }
      }
      return null;
    });
    const header = (
      <View style={headerWrapperStyle}>
        <MTabs
          classes={tabsStyle}
          value={selectedIndex || value}
          {...otherTabsProps}
        >
          {headerChildren}
        </MTabs>
        {noGreyBar ? null : <View style={nativeStyles.greyBar} />}
      </View>
    );
    return (
      <View style={nativeStyles.flex}>
        {header}
        <View style={nativeStyles.flex}>{content}</View>
      </View>
    );
  }
}

const nativeStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  greyBar: {
    marginTop: -1,
    zIndex: -1,
    borderBottomWidth: 1,
    borderBottomColor: TABS.SEPARATOR,
  },
});

const styles = createStyles({
  tabsRoot: {
    minHeight: 40,
  },
  label: {
    fontFamily: 'Rubik-Regular',
    fontSize: FONT_SIZE.small,
    fontWeight: 'bold',
  },
  tabRootDefault: {
    color: TABS.DEFAULT,
    textTransform: 'none',
    minWidth: 0,
    '&:hover': {
      color: TABS.ACTIVE,
    },
  },
  tabsIndicatorDefault: {
    backgroundColor: TABS.ACTIVE,
  },
  tabSelectedDefault: {
    color: TABS.ACTIVE,
    fontFamily: 'Rubik-Regular',
  },
});

export default withStyles(styles)(Tabs);
