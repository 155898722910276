import React, { Component, Fragment } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import {
  compose,
  graphql,
  MutationFunc,
  DataValue,
  Mutation,
} from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { SearchField, Query } from '../../components';
import { Text, Tabs, Tab, Button, Modal, CurrencyField } from '../../core-ui';
import {
  UPDATE_SEARCH_STATE,
  GET_SEARCH_STATE,
  GET_GALLON_PRICE_LIST,
  GallonPriceListResult,
  AccessProps,
  PageAccess,
} from '../../graphql/queries';
import { SearchState } from '../../graphql/localState';
import { AssignmentList } from '.';
import {
  UPDATE_GALLON_PRICE,
  UpdateGallonPriceResult,
  UpdateGallonPriceVars,
  ORDER_SYNC,
} from '../../graphql/mutations';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import { DARK_GRAY, WHITE } from '../../constants/colors';

export type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type Props = AccessProps & { bottleAccess: PageAccess } & RouteComponentProps &
  SearchStateProps &
  UpdateSearchData &
  ToastContextProps;
type State = {
  selectedTab: number;
  isModalVisible: boolean;
  aquaPrice: number;
  vitPrice: number;
  pricesFetched: boolean;
  isUpdateVisible: boolean;
  resetPage: boolean;
};

const TAB_HASH_URI = ['#assignment'];

export class AssigmentScene extends Component<Props, State> {
  state: State = {
    selectedTab: 0,
    isModalVisible: false,
    aquaPrice: 0,
    vitPrice: 0,
    pricesFetched: false,
    isUpdateVisible: false,
    resetPage: false,
  };

  componentDidMount() {
    this._updateSelectedTab();
  }

  componentWillUnmount() {
    this._clearSearch();
  }

  componentDidUpdate() {
    this._updateSelectedTab();
  }

  _clearSearch = () => {
    const { updateSearch } = this.props;
    updateSearch({ variables: { searchedString: '' } });
  };

  _updateSelectedTab = () => {
    const { location, access, bottleAccess } = this.props;
    const { selectedTab } = this.state;
    let newSelectedTab = selectedTab;
    switch (location.hash) {
      case TAB_HASH_URI[1]: {
        newSelectedTab = access.read ? 1 : 0;
        break;
      }
      default: {
        // TODO: if the user writes random hash like /purchase-orders#foo, should we display the first tab or a not found page?
        newSelectedTab = 0;
      }
    }
    if (selectedTab !== newSelectedTab) {
      this.setState({ selectedTab: newSelectedTab });
    }
  };

  _setResetPage = (reset: boolean) => {
    this.setState({ resetPage: reset });
  };

  _closeUpdate = () => this.setState({ isUpdateVisible: false });

  _onActivateError = () => {
    const { openToast } = this.props;
    openToast && openToast('fail', 'Data gagal diperbarui.');
  };

  _renderSyncModal = (refetchFn: () => void) => {
    const { isUpdateVisible } = this.state;
    return (
      <Mutation
        mutation={ORDER_SYNC}
        onCompleted={() => {
          const { openToast } = this.props;
          openToast && openToast('success', 'Pemesanan berhasil diperbarui.');
          refetchFn();
          this.setState({ isUpdateVisible: false });
        }}
        onError={this._onActivateError}
      >
        {(updateDatabase, { loading }) => (
          <Modal
            isVisible={isUpdateVisible}
            title="Perbarui Data Pemesanan"
            submitLoading={loading}
            description={
              <Text size="small" color={DARK_GRAY}>
                Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                yakin ingin meng-update/memperbarui data?{' '}
              </Text>
            }
            children={
              <View style={styles.modalView}>
                <Button
                  style={{ marginRight: 15 }}
                  inverted
                  color="secondary"
                  text="Batal"
                  onPress={this._closeUpdate}
                  loadingColor={WHITE}
                />
                <Button
                  color="primary"
                  text="Ya"
                  onPress={() => updateDatabase()}
                  loadingColor={WHITE}
                  isLoading={loading}
                />
              </View>
            }
            onClose={this._closeUpdate}
          />
        )}
      </Mutation>
    );
  };

  render() {
    return (
      <View style={styles.root}>
        {this._renderHeader()}
        {this._renderTabs()}
        {this._renderModal()}
      </View>
    );
  }

  _renderHeader = () => {
    const {
      searchStateQuery: { searchState },
      location,
      bottleAccess,
    } = this.props;
    return (
      <View style={styles.header}>
        <Text size="xlarge" style={styles.flex}>
          Assignment
        </Text>
        <SearchField
          value={searchState ? searchState.searchedString : ''}
          onChangeText={this._onSearchTextChange}
        />
        <Button onPress={() => this.setState({ isUpdateVisible: true })}>
          Update Data
        </Button>
        {bottleAccess.update && (
          <View
            style={
              ({
                transitionDuration: '0.5s',
                transitionTimingFunction: 'ease',
                transitionProperty: 'width',
                width: location.hash === TAB_HASH_URI[4] ? 230 : 0,
                overflow: 'hidden',
              } as unknown) as ViewStyle
            }
          >
            <Button
              style={{ marginLeft: 16 }}
              icon="settings"
              text="Pengaturan Harga Botol"
              onPress={this._openModal}
            />
          </View>
        )}
      </View>
    );
  };

  _renderTabs = () => {
    const { selectedTab, resetPage } = this.state;
    const {
      searchStateQuery: { searchState },
      access,
      bottleAccess,
    } = this.props;

    const tabs = [];
    if (access.read) {
      tabs.push(
        <Tab label="Assignment" key="assignment">
          <AssignmentList
            searchContent={searchState ? searchState.searchedString : ''}
            renderSyncModal={this._renderSyncModal}
            resetPage={resetPage}
            setResetPage={this._setResetPage}
            access={access}
          />
        </Tab>,
      );
    }

    return (
      <Tabs selectedIndex={selectedTab} onChange={this._onTabChange}>
        {tabs}
      </Tabs>
    );
  };

  _renderModal = () => {
    const { openToast } = this.props;
    const { isModalVisible, aquaPrice, vitPrice } = this.state;
    return (
      <Query<GallonPriceListResult>
        query={GET_GALLON_PRICE_LIST}
        onCompleted={this._onGallonPriceFetched}
        fetchPolicy="network-only"
      >
        {({ data, refetch }) => (
          <Mutation<UpdateGallonPriceResult, UpdateGallonPriceVars>
            mutation={UPDATE_GALLON_PRICE}
            onCompleted={refetch}
            onError={this._onFailUpdateGallon}
          >
            {(updateGallon, { loading }) => (
              <Modal
                hideHeaderClose
                title="Pengaturan Harga Botol"
                closeButtonText="Batal"
                buttonText="Simpan"
                submitLoading={loading}
                onSubmit={async () => {
                  if (data) {
                    const result = await Promise.all(
                      data.gallonPrices.map(({ id, gallonType }) => {
                        const gallonKey = `${gallonType.toLowerCase()}Price`;
                        const price = this.state[
                          gallonKey as keyof State
                        ] as number;
                        const updateData: any = { price };
                        return updateGallon({
                          variables: { id, data: updateData },
                        });
                      }),
                    );
                    if (result[0] && result[1]) {
                      openToast('success', 'Harga galon telah diubah.');
                      this._closeModal();
                    }
                  }
                }}
                description="Masukkan harga botol sesuai dengan merk yang tertera."
                isVisible={isModalVisible}
                onClose={this._closeModal}
              >
                <>
                  <CurrencyField
                    stretch
                    labelHorizontal
                    label="Aqua"
                    currencyValue={aquaPrice}
                    onCurrencyChange={this._onAquaPriceChange}
                  />
                  <CurrencyField
                    stretch
                    labelHorizontal
                    label="Vit"
                    currencyValue={vitPrice}
                    onCurrencyChange={this._onVitPriceChange}
                  />
                </>
              </Modal>
            )}
          </Mutation>
        )}
      </Query>
    );
  };

  _openModal = () => this.setState({ isModalVisible: true });

  _closeModal = () =>
    this.setState({ isModalVisible: false, pricesFetched: false });

  _onTabChange = (_e: React.ChangeEvent<{}>, index: number) => {
    const { history, location } = this.props;
    const { selectedTab } = this.state;
    this._clearSearch();
    if (selectedTab !== index) {
      this.setState({ selectedTab: index });
      history.push(`${location.pathname}${TAB_HASH_URI[index]}`);
    }
  };

  _onGallonPriceFetched = (data: GallonPriceListResult | {}) => {
    const { pricesFetched } = this.state;
    const gallonData: ObjectKey = {};
    if (!pricesFetched && data.hasOwnProperty('gallonPrices')) {
      const castData = data as GallonPriceListResult;
      for (const { gallonType, price } of castData.gallonPrices) {
        const gallonKey = `${gallonType.toLowerCase()}Price`;
        gallonData[gallonKey] = price;
      }
      this.setState({ pricesFetched: true, ...gallonData });
    }
  };

  _onSearchTextChange = (text: string) =>
    this.props.updateSearch({ variables: { searchedString: text } });

  _onAquaPriceChange = (newPrice: number) =>
    this.setState({ aquaPrice: newPrice });

  _onVitPriceChange = (newPrice: number) =>
    this.setState({ vitPrice: newPrice });

  _onFailUpdateGallon = () =>
    this.props.openToast('fail', 'Harga galon gagal diubah.');
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  root: { flex: 1, paddingVertical: 40, paddingHorizontal: 80 },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  tabContent: { paddingTop: 20 },
  modalView: { flexDirection: 'row', justifyContent: 'flex-end' },
});

export default compose(
  withRouter,
  withToast,
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, UpdateSearchVariables, UpdateSearchData>(
    UPDATE_SEARCH_STATE,
    { name: 'updateSearch' },
  ),
)(AssigmentScene);
