import React, { Component, FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';

import { Button, Text, TextField, Modal } from '../../../core-ui';
import { BLACK, DARK_GRAY } from '../../../constants/colors';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import { graphqlErrorRemover } from '../../../helpers';
import { Order } from '../../../graphql/queries';
import {
  REJECT_ORDER,
  RejectOrderMutationResult,
} from '../../../graphql/mutations';

type OwnProps = {
  data?: Order;
  onClose: () => void;
  refetchFn: () => any;
};

type Props = OwnProps & ToastContextProps;

type State = {
  message: string;
};

class RejectModal extends Component<Props, State> {
  state = {
    message: '',
  };

  render() {
    const { data } = this.props;
    const { message } = this.state;
    return (
      <Mutation
        mutation={REJECT_ORDER}
        onCompleted={this._onRejectComplete}
        onError={this._onRejectError}
      >
        {(rejectOrder, { loading }) => (
          <Modal
            hideHeaderClose
            title="Tolak Pesanan"
            closeButtonText="Tidak"
            buttonText="Tolak"
            submitLoading={loading}
            onSubmit={() => {
              rejectOrder({
                variables: {
                  id: data && data.id,
                  role: 'admin',
                  cancelReason: message,
                },
              });
            }}
            submitDisabled={!message}
            isVisible
            onClose={this._onClose}
          >
            <View style={styles.rejectModalTextfield}>
              <Text
                size="small"
                color={DARK_GRAY}
                style={styles.rejectDescription}
              >
                Apakah Anda yakin ingin menolak pesanan dari{' '}
                <Text size="small" weight="bold" color={BLACK}>
                  INVOICE {data && data.invoiceNumber}
                </Text>{' '}
                ?
              </Text>
              <TextField
                stretch
                multiline
                numberOfLines={5}
                label="Alasan Penolakan"
                placeholder="Alasan Penolakan"
                value={message}
                onChangeText={this._onChangeText}
                style={styles.bottomMargin}
              />
            </View>
          </Modal>
        )}
      </Mutation>
    );
  }

  _onRejectComplete = (result: RejectOrderMutationResult) => {
    if (result && result.rejectOrder && result.rejectOrder.id) {
      this._onClose();

      const { openToast, refetchFn } = this.props;
      refetchFn();
      openToast && openToast('success', 'Order telah ditolak.');
    }
  };

  _onRejectError = (error: ApolloError) => {
    const { openToast } = this.props;
    openToast && openToast('fail', graphqlErrorRemover(String(error)));
  };

  _onChangeText = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      message: value,
    }));
  };

  _onClose = () => {
    this.setState(
      {
        message: '',
      },
      () => this.props.onClose(),
    );
  };
}

let styles = StyleSheet.create({
  bottomMargin: {
    marginBottom: 8,
  },
  rejectModalTextfield: {
    flex: 1,
    paddingBottom: 30,
  },
  rejectDescription: {
    marginBottom: 15,
  },
  modalFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 30,
  },
  infoWrapper: { height: 40, justifyContent: 'flex-end' },
  infoText: { height: 22 },
  infoSeparator: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  infoValue: { flex: 1 },
});

export default withToast(RejectModal) as FC<OwnProps>;
