import { COMPANY_INFO } from '../constants/companyInfo';

export function getNameCompanyDepo(depo: string): string {
  const kodeDepo: string = depo.split('-')[0];

  return COMPANY_INFO[kodeDepo].name || '';
}

export function getAddressDepo(depo: string): string {
  const kodeDepo: string = depo.split('-')[0];

  return COMPANY_INFO[kodeDepo].address || '';
}
