import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  compose,
  graphql,
  MutationFunc,
  DataValue,
  Mutation,
} from 'react-apollo';

import { Text, Separator, Modal, Button } from '../../core-ui';
import { SearchField } from '../../components';
import { SearchState } from '../../graphql/localState';
import {
  UPDATE_SEARCH_STATE,
  GET_SEARCH_STATE,
  AccessProps,
} from '../../graphql/queries';

import ProductList from './ProductList';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import { PRODUCT_SYNC } from '../../graphql/mutations';
import { DARK_GRAY, WHITE } from '../../constants/colors';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<null, UpdateSearchVariables>;
};

type Props = SearchStateProps &
  UpdateSearchData &
  AccessProps &
  ToastContextProps;

type State = {
  isUpdateVisible: boolean;
};

export class ProductScene extends Component<Props, State> {
  state: State = {
    isUpdateVisible: false,
  };

  componentWillUnmount() {
    const { updateSearch } = this.props;
    updateSearch({ variables: { searchedString: '' } });
  }

  _closeUpdate = () => this.setState({ isUpdateVisible: false });

  _onActivateError = () => {
    const { openToast } = this.props;
    openToast && openToast('fail', 'Data gagal diperbarui.');
  };

  _renderSyncModal = (refetchFn: () => void) => {
    const { isUpdateVisible } = this.state;
    return (
      <Mutation
        mutation={PRODUCT_SYNC}
        onCompleted={() => {
          const { openToast } = this.props;
          openToast && openToast('success', 'Produk berhasil diperbarui.');
          refetchFn();
          this.setState({ isUpdateVisible: false });
        }}
        onError={this._onActivateError}
      >
        {(updateDatabase, { loading }) => (
          <Modal
            isVisible={isUpdateVisible}
            title="Perbarui Data Produk"
            submitLoading={loading}
            description={
              <Text size="small" color={DARK_GRAY}>
                Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                yakin ingin meng-update/memperbarui data?{' '}
              </Text>
            }
            children={
              <View style={styles.modalView}>
                <Button
                  style={{ marginRight: 15 }}
                  inverted
                  color="secondary"
                  text="Batal"
                  onPress={this._closeUpdate}
                  loadingColor={WHITE}
                />
                <Button
                  color="primary"
                  text="Ya"
                  onPress={() => updateDatabase()}
                  loadingColor={WHITE}
                  isLoading={loading}
                />
              </View>
            }
            onClose={this._closeUpdate}
          />
        )}
      </Mutation>
    );
  };

  render() {
    const {
      searchStateQuery: { searchState },
      updateSearch,
    } = this.props;
    return (
      <View style={styles.root}>
        <View style={styles.header}>
          <Text size="xlarge">Daftar Produk</Text>
          <View style={styles.toolsWrapper}>
            <View style={styles.searchFieldWrapper}>
              <SearchField
                value={searchState ? searchState.searchedString : ''}
                onChangeText={(text) =>
                  updateSearch({ variables: { searchedString: text } })
                }
              />
            </View>
            <Button onPress={() => this.setState({ isUpdateVisible: true })}>
              Update Data
            </Button>
          </View>
        </View>
        <Separator />
        {this._renderTable()}
      </View>
    );
  }

  _renderTable = () => {
    const {
      searchStateQuery: { searchState },
      access,
    } = this.props;
    return (
      <ProductList
        access={access}
        searchContent={searchState ? searchState.searchedString : ''}
        renderSyncModal={this._renderSyncModal}
      />
    );
  };
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  searchFieldWrapper: {
    marginRight: 10,
  },
  toolsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  modalView: { flexDirection: 'row', justifyContent: 'flex-end' },
});

export default compose(
  withToast,
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, UpdateSearchVariables, UpdateSearchData>(
    UPDATE_SEARCH_STATE,
    { name: 'updateSearch' },
  ),
)(ProductScene);
