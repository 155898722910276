import React, { Component } from 'react';

import { ChosenProps, Picker, Option } from '.';

const currentYear = new Date().getFullYear();
const startYear = 2019;
const defaultState = [{ value: startYear, label: String(startYear) }];

type Props = { isFilter?: boolean } & ChosenProps<number>;
type State = {
  years: Array<Option<number>>;
};

export class YearPicker extends Component<Props, State> {
  state: State = {
    years: defaultState,
  };

  componentDidMount() {
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push({ value: year, label: String(year) });
    }
    this.setState({ years });
  }

  render() {
    const { style, ...otherProps } = this.props;
    const { years } = this.state;
    return (
      <Picker<number>
        label="Tahun"
        placeholder="Tahun"
        style={style}
        options={years}
        {...otherProps}
      />
    );
  }
}

export default YearPicker;
