import React, { useState } from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import { Text, Icon, Separator } from '../core-ui';
import { GRAY, DARK_GRAY, GRAY5 } from '../constants/colors';
import { graphqlErrorRemover } from '../helpers';

type Props = {
  detailMessage: Nullable<string>;
};

export default function ErrorScreen(props: Props) {
  const { detailMessage } = props;
  const [isDetailErrorShow, changeStatus] = useState(false);

  return (
    <View style={styles.container}>
      <View style={{ alignItems: 'center' }}>
        <Icon
          size="xlarge"
          name="refresh"
          color={GRAY}
          containerStyle={styles.primaryIcon}
        />
        <Text size="small" color={DARK_GRAY}>
          Terjadi kesalahan, silakan muat ulang halaman.
        </Text>
        <TouchableWithoutFeedback
          onPress={() => changeStatus(!isDetailErrorShow)}
        >
          <View style={styles.detailWrapper}>
            <Icon
              size="small"
              name={isDetailErrorShow ? 'arrow_drop_up' : 'arrow_drop_down'}
              color={GRAY}
              containerStyle={
                isDetailErrorShow ? styles.iconOpened : styles.iconClosed
              }
            />
            <Text size="xsmall" color={DARK_GRAY}>
              Detail
            </Text>
            <Separator
              style={{ flex: 1, marginLeft: 10, borderColor: DARK_GRAY }}
            />
          </View>
        </TouchableWithoutFeedback>
        <View style={[styles.errorBox, { opacity: isDetailErrorShow ? 1 : 0 }]}>
          <Text size="xsmall">
            {detailMessage
              ? graphqlErrorRemover(detailMessage)
              : 'Tidak ada pesan detail'}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  primaryIcon: {
    paddingBottom: 30,
  },
  detailWrapper: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '100%',
  },
  errorBox: {
    alignSelf: 'flex-start',
    backgroundColor: GRAY5,
    borderColor: GRAY,
    borderWidth: 0.5,
    padding: 10,
    width: '100%',
    borderRadius: 2,
  },
  iconOpened: {
    marginLeft: -5,
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    transform: [{ rotate: '180deg' }],
    transitionProperty: 'transform',
  },
  iconClosed: {
    marginLeft: -5,
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease',
    transform: [{ rotate: '0deg' }],
    transitionProperty: 'transform',
  },
});
