import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { GRAY, GRAY70 } from '../../../constants/colors';
import { CurrencyField, Icon, IncrementField } from '../../../core-ui';
import { Term } from '../../../graphql/queries';

type Props = {
  initialValue: Term;
  onUpdate: (newTermValue: Term) => void;
  onDelete: () => void;
};

type State = Term;

export class TermInput extends Component<Props, State> {
  state: State = {
    __typename: 'Term',
    programTermId: this.props.initialValue.programTermId || '',
    id: this.props.initialValue.id || '',
    minimalPurchase: this.props.initialValue.minimalPurchase || 0,
    rewardQty: this.props.initialValue.rewardQty || 0,
    state: this.props.initialValue.state || 'new',
  };

  render() {
    const { onDelete } = this.props;
    const { minimalPurchase, rewardQty } = this.state;
    return (
      <View style={styles.root}>
        <View style={styles.leftColumn}>
          <IncrementField
            stretch
            label="Minimal Pembelian (Unit)"
            value={minimalPurchase}
            onChangeValue={(value) =>
              this._onChangeText('minimalPurchase', value)
            }
            onButtonPress={(value) =>
              this.setState({ minimalPurchase: value }, () => this._onBlur())
            }
            onBlur={this._onBlur}
          />
        </View>
        <View style={styles.rightColumn}>
          <CurrencyField
            stretch
            label="Potongan Harga/Unit"
            currencyValue={rewardQty}
            onCurrencyChange={(value) => this._onChangeText('rewardQty', value)}
            onBlur={this._onBlur}
          />
        </View>
        <View style={styles.deleteButtonContainer}>
          <TouchableOpacity onPress={() => onDelete()}>
            <View style={styles.deleteButton}>
              <Icon name="delete" size="small" color={GRAY} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  _onChangeText = <T extends keyof State>(key: T, value: State[T]) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _onBlur = () => {
    const {
      minimalPurchase,
      rewardQty,
      __typename,
      id,
      programTermId,
    } = this.state;
    const { onUpdate } = this.props;
    onUpdate({ __typename, id, minimalPurchase, rewardQty, programTermId });
  };
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  label: {
    color: GRAY70,
    paddingBottom: 10,
  },
  text: {
    paddingHorizontal: 10,
  },
  discountContainer: {
    flex: 2,
    marginRight: 20,
  },
  deleteButtonContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  deleteButton: {
    borderWidth: 1,
    width: 30,
    height: 30,
    borderRadius: 4,
    borderColor: GRAY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftColumn: {
    flex: 2,
    paddingRight: 10,
  },
  rightColumn: {
    flex: 2,
    paddingLeft: 10,
  },
});
