import {
  Box,
  Tooltip as CustomTooltip,
  FormControlLabel,
  FormGroup,
  Switch,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { PointTooltip, SliceTooltip } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import { PaperBox, Query } from 'components';
import { BLUE, GUARDSMAN_RED, YELLOW, ZOMBIE_GREEN } from 'constants/colors';
import { Icon, Loading, Text } from 'core-ui';
import { addDays, endOfMonth, format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import {
  GET_SALES_PERCENTAGE_BASED_ON_SEGMENTATION,
  GET_TOTAL_CANCELED_TRANSACTION,
  GET_TREND_ORDER_COUNTS,
  GET_TREND_USER_TRANSACTION,
  GetSalesPercentageBasedOnSegmentation,
  GetSalesPercentageBasedOnSegmentationParams,
  GetTotalCanceledTransactionResult,
  GetTrendOrderCountsParams,
  GetTrendOrderCountsResult,
  GetTrendUserTransactionParams,
  GetTrendUserTransactionResult,
  SalesPercentageBasedOnSegmentation,
  TrendOrderCount,
  TrendUserTransaction,
  TrendUserTransactionItem,
} from 'graphql/queries';
import { capitalize, formatThousandSeparator, omit, pick } from 'helpers';
import { convertToDecimal, formatDecimal } from 'helpers/formatNumber';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  BarChart,
  DEFAULT_FILTER_STATE,
  Filter,
  LineChart,
  LineChartProps,
  PieChart,
} from '../components';
import { FilterValues } from '../context';
import { useDateValues } from '../hooks';

type TrendOrderCountChartData = {
  id: string;
  label: string;
  value: number | boolean;
  color: string;
};

const TREND_ORDER_COUNTS_MAP_DATA = {
  new_count: ['Pesanan Baru', BLUE],
  in_process_count: ['Pesanan Diproses', YELLOW],
  received_count: ['Pesanan Sampai', ZOMBIE_GREEN],
  canceled_count: ['Pesanan Dibatalkan', GUARDSMAN_RED],
};

const mapColors = {
  offline: GUARDSMAN_RED,
  online: ZOMBIE_GREEN,
  total: BLUE,
};

const useStyles = makeStyles(
  createStyles({
    gap: {
      gap: '4px',
    },
    chartBox: {
      gap: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
    },
  }),
);

const TotalSwitch = withStyles({
  switchBase: {
    color: mapColors.total,
    '&$checked': {
      color: mapColors.total,
    },
    '&$checked + $track': {
      backgroundColor: mapColors.total,
    },
  },
  checked: {},
  track: {},
})(Switch);
const OfflineSwitch = withStyles({
  switchBase: {
    color: mapColors.offline,
    '&$checked': {
      color: mapColors.offline,
    },
    '&$checked + $track': {
      backgroundColor: mapColors.offline,
    },
  },
  checked: {},
  track: {},
})(Switch);
const OnlineSwitch = withStyles({
  switchBase: {
    color: mapColors.online,
    '&$checked': {
      color: mapColors.online,
    },
    '&$checked + $track': {
      backgroundColor: mapColors.online,
    },
  },
  checked: {},
  track: {},
})(Switch);

export function SalesTrend() {
  const classNames = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(810, 1200));

  const [filter, setFilter] = useState<FilterValues>(DEFAULT_FILTER_STATE);
  const [showedTransactionData, setShowedTransactionData] = useState<
    Record<
      keyof Omit<TrendUserTransactionItem, 'date'>,
      ('offline' | 'online' | 'total')[]
    >
  >({
    qty_transaction: ['offline', 'online', 'total'],
    transaction_price: ['offline', 'online', 'total'],
    qty_transaction_avg: ['offline', 'online', 'total'],
    transaction_price_avg: ['offline', 'online', 'total'],
  });

  const dateValues = useDateValues(
    pick(filter, ['startDate', 'endDate', 'periodTime']),
  );

  const isSameDay = filter.startDate?.getTime() === filter.endDate?.getTime();

  const formatChartDate = useCallback(
    (date) => {
      if (filter.periodTime === 'daily') {
        return isSameDay
          ? new Date(date).toLocaleTimeString('id', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
          : new Date(date).toLocaleDateString('id', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            });
      } else if (filter.periodTime === 'weekly') {
        const startdate = new Date(date).toLocaleDateString('id', {
          day: 'numeric',
        });
        const enddate = new Date(addDays(date, 6)).toLocaleDateString('id', {
          day: 'numeric',
        });
        const startmonth = new Date(date).toLocaleDateString('id', {
          month: 'short',
        });
        const endmonth = new Date(addDays(date, 6)).toLocaleDateString('id', {
          month: 'short',
        });
        const year = new Date(date).getFullYear();
        const sameMonth = startmonth === endmonth;

        return `${startdate} ${
          sameMonth ? '' : startmonth
        } - ${enddate} ${endmonth} ${year}`;
      } else {
        return new Date(date).toLocaleDateString('id', {
          month: 'long',
          year: 'numeric',
        });
      }
    },
    [filter.periodTime, isSameDay],
  );

  const Tooltip: (isSameDay: boolean) => PointTooltip = () => ({ point }) => {
    return (
      <BasicTooltip
        id={formatChartDate(new Date(point.data.x))}
        color={point.borderColor}
        value={convertToDecimal(Number(point.data.y), 1)}
      />
    );
  };

  const SliceTooltip: (
    chart: keyof typeof showedTransactionData,
  ) => SliceTooltip = (chart) => ({ slice }) => {
    const data = slice.points.filter((data) =>
      showedTransactionData[chart].includes(
        String(data.serieId).toLowerCase() as keyof typeof mapColors,
      ),
    );
    const date = new Date(slice.points[0].data.x);

    return (
      <PaperBox padding={1} display="flex" flexDirection="column">
        {data.map((point) => {
          const serieId = String(point.serieId) as keyof typeof mapColors;

          return (
            <Box display="flex" alignItems="center" style={{ gap: 4 }}>
              <Box
                width={10}
                height={10}
                style={{
                  backgroundColor:
                    mapColors[serieId.toLowerCase() as typeof serieId],
                }}
              />
              <Text size="small" weight="bold">
                {convertToDecimal(Number(point.data.y), 1)}
              </Text>
            </Box>
          );
        })}
        <Box alignSelf="center">
          <Text size="xsmall">{formatChartDate(date)}</Text>
        </Box>
      </PaperBox>
    );
  };

  const commonLineChartProps: Omit<LineChartProps, 'data'> = {
    margin: {
      left: 100,
      right: 100,
      top: 50,
      bottom: 80,
    },
    legends: [
      {
        anchor: 'bottom',
        direction: 'row',
        itemWidth: 100,
        itemHeight: 25,
        translateY: 75,
        symbolShape: 'circle',
      },
    ],
    xScale: {
      type: 'point',
    },
    axisLeft: {
      format: (value) => formatThousandSeparator(value),
    },
    axisBottom: {
      format: (date) => formatChartDate(date),
      tickRotation: filter.periodTime === 'weekly' ? 15 : 45,
    },
    tooltip: Tooltip(isSameDay && filter.periodTime !== 'monthly'),
  };

  const onFilter = useCallback((values: FilterValues) => {
    setFilter(values);
  }, []);

  const handleTrendOrderCountsData = useCallback(
    (data?: TrendOrderCount): TrendOrderCountChartData[] => {
      if (!data) {
        return [
          {
            id: 'Fill the filter first',
            label: 'Fill the filter first',
            value: true,
            color: BLUE,
          },
        ];
      }

      const fixedData = {
        new_count: data.new_count,
        in_process_count: data.in_process_count,
        received_count: data.received_count,
        canceled_count: data.canceled_count,
        __typename: data.__typename,
      };

      return Object.entries(omit(fixedData, ['__typename'])).map(
        ([key, value]) => {
          const dataKey = key as keyof Omit<TrendOrderCount, '__typename'>;

          return {
            id: TREND_ORDER_COUNTS_MAP_DATA[dataKey][0],
            label: TREND_ORDER_COUNTS_MAP_DATA[dataKey][0],
            value,
            color: TREND_ORDER_COUNTS_MAP_DATA[dataKey][1],
          };
        },
      );
    },
    [],
  );

  const handleTrendUserTransactionData = (data?: TrendUserTransaction) => (
    type: keyof Omit<TrendUserTransactionItem, 'date'>,
  ) => {
    if (!data) {
      return [];
    }

    return Object.entries(omit(data, ['__typename']))
      .filter(([key]) => {
        return showedTransactionData[type].includes(
          key as 'offline' | 'online' | 'total',
        );
      })
      .map(([key, value]) => {
        return {
          id: capitalize(key),
          color: mapColors[key as keyof typeof mapColors],
          data: value
            .map((item) => pick(item, ['date', type]))
            .map((item) => {
              return {
                x: new Date(
                  new Date(item.date).getTime() -
                    getTimezoneOffset('Asia/Jakarta'),
                ),
                y: item[type],
              };
            }),
        };
      });
  };

  const { startDateVariable, endDateVariable } = useMemo(() => {
    if (!filter.startDate || !filter.endDate) {
      return {
        startDateVariable: undefined,
        endDateVariable: undefined,
      };
    }
    const startDateVariable = format(filter.startDate, 'yyyy-MM-dd HH:mm:ss');
    let endDateVariable;

    if (filter.periodTime === 'monthly') {
      endDateVariable = format(
        endOfMonth(filter.endDate),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else if (filter.periodTime === 'weekly') {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    } else if (filter.periodTime === 'daily') {
      endDateVariable = format(
        addDays(filter.endDate, 1),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    }

    return {
      startDateVariable,
      endDateVariable,
    };
  }, [filter.endDate, filter.startDate, filter.periodTime]);

  const generateLabel = (percentage: number, amount: number) => {
    return `${convertToDecimal(
      percentage,
    )}% • Qty Total ${formatThousandSeparator(amount)}`;
  };

  const generateYAxisLabel = (value: string) => {
    const minWord = 3;
    const words = value.split(' ');

    if (words.length <= minWord) {
      return (
        <tspan x="0" dy={4}>
          {value}
        </tspan>
      );
    }

    let firstWord: string = '';
    let secondWord: string = '';

    words.forEach((value, i) => {
      if (i < minWord) {
        firstWord += ' ' + value;
      } else {
        secondWord += ' ' + value;
      }
    });

    const allWords = [firstWord, secondWord];

    return (
      <>
        {allWords.map((value, i) => {
          return (
            <tspan x="0" dy={i === 0 ? 0 : 12}>
              {value}
            </tspan>
          );
        })}
      </>
    );
  };

  const onToggle = useCallback(
    (
      key: keyof Omit<TrendUserTransactionItem, 'date'>,
      type: 'offline' | 'online' | 'total',
    ) => (_event: ChangeEvent<HTMLInputElement>) => {
      setShowedTransactionData((prev) => {
        return {
          ...prev,
          [key]: prev[key].includes(type)
            ? prev[key].filter((item) => item !== type)
            : [...prev[key], type],
        };
      });
    },
    [],
  );

  const renderToggles = (
    type: keyof Omit<TrendUserTransactionItem, 'date'>,
  ) => {
    return (
      <FormGroup style={{ marginTop: '1rem', justifyContent: 'center' }} row>
        <FormControlLabel
          value="top"
          control={
            <OfflineSwitch
              color="primary"
              checked={showedTransactionData[type].includes('offline')}
              onChange={onToggle(type, 'offline')}
            />
          }
          label="Offline"
        />
        <FormControlLabel
          value="top"
          control={
            <OnlineSwitch
              color="primary"
              checked={showedTransactionData[type].includes('online')}
              onChange={onToggle(type, 'online')}
            />
          }
          label="Online"
        />
        <FormControlLabel
          value="top"
          control={
            <TotalSwitch
              color="primary"
              checked={showedTransactionData[type].includes('total')}
              onChange={onToggle(type, 'total')}
            />
          }
          label="Total"
        />
      </FormGroup>
    );
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      style={{
        gap: '2rem',
      }}
    >
      <Filter withSegmentationSelection withSKUSelection onFilter={onFilter} />
      {filter.startDate && filter.endDate ? (
        <>
          <PaperBox padding={4} elevation={0}>
            <Text weight="bold">Jumlah Pesanan</Text>
            <Query<GetTrendOrderCountsResult, GetTrendOrderCountsParams>
              query={GET_TREND_ORDER_COUNTS}
              variables={{
                startDate: startDateVariable,
                endDate: endDateVariable,
                depot: filter.depot ? filter.depot : undefined,
                segment: filter.segmentation ? filter.segmentation : undefined,
                periodType:
                  filter.startDate?.getTime() === filter.endDate?.getTime() &&
                  filter.periodTime === 'daily'
                    ? 'hourly'
                    : filter.periodTime,
                sku:
                  [...(filter.productIds || [])].length > 0
                    ? filter.productIds
                    : undefined,
              }}
              skip={!filter.startDate || !filter.endDate}
            >
              {({ data }) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={matches ? 'column' : 'row'}
                  style={{
                    gap: '32px',
                  }}
                  mt={4}
                >
                  <Box
                    width={matches ? '100%' : '50%'}
                    height={300}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {handleTrendOrderCountsData(data?.trendOrderCounts).every(
                      (item) => item.value === 0,
                    ) ? (
                      <>
                        <Icon isCustomSVG name="emptyData" size="xxlarge" />
                        <Text size="small">
                          Tidak ada data untuk ditampilkan
                        </Text>
                      </>
                    ) : (
                      <PieChart
                        data={handleTrendOrderCountsData(
                          data?.trendOrderCounts,
                        )}
                        margin={{
                          right: 140,
                        }}
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            itemWidth: 100,
                            itemHeight: 25,
                            translateX: 90,
                          },
                        ]}
                      />
                    )}
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={
                      data?.trendOrderCounts ? '1fr 1fr' : '1fr'
                    }
                    gridTemplateRows={
                      data?.trendOrderCounts ? '1fr 1fr' : '1fr'
                    }
                    gridGap={10}
                    flex={1}
                  >
                    {handleTrendOrderCountsData(data?.trendOrderCounts).map(
                      (data) => (
                        <Box
                          border={`2px solid ${data.color}`}
                          padding={4}
                          borderRadius={4}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                          className={classNames.gap}
                        >
                          <Text weight="light" size="small">
                            {data.label}
                          </Text>
                          <Text weight="bold">{data.value}</Text>
                        </Box>
                      ),
                    )}
                  </Box>
                </Box>
              )}
            </Query>
          </PaperBox>
          <Query<GetTrendUserTransactionResult, GetTrendUserTransactionParams>
            query={GET_TREND_USER_TRANSACTION}
            variables={{
              startDate: startDateVariable,
              endDate: endDateVariable,
              depot: filter.depot ? filter.depot : undefined,
              segment: filter.segmentation ? filter.segmentation : undefined,
              periodType:
                filter.startDate?.getTime() === filter.endDate?.getTime() &&
                filter.periodTime === 'daily'
                  ? 'hourly'
                  : filter.periodTime,
              sku:
                [...(filter.productIds || [])].length > 0
                  ? filter.productIds
                  : undefined,
            }}
            fetchPolicy="cache-and-network"
          >
            {({ data }) => {
              const getTrendUser = handleTrendUserTransactionData(
                data?.trendUserTransactions,
              );

              return (
                <Box
                  display="grid"
                  gridTemplateColumns={matches ? '100%' : '49% 49%'}
                  gridTemplateRows="1fr 1fr"
                  gridGap={'1.4em'}
                >
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Total Penjualan Berdasarkan Rupiah
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={500}>
                      <LineChart
                        data={getTrendUser('transaction_price')}
                        {...commonLineChartProps}
                        legends={undefined}
                        enableSlices="x"
                        sliceTooltip={SliceTooltip('transaction_price')}
                      />
                    </Box>
                    {renderToggles('transaction_price')}
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Total Penjualan Berdasarkan Kuantitas
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={500}>
                      <LineChart
                        data={getTrendUser('qty_transaction')}
                        {...commonLineChartProps}
                        legends={undefined}
                        enableSlices="x"
                        sliceTooltip={SliceTooltip('qty_transaction')}
                      />
                    </Box>
                    {renderToggles('qty_transaction')}
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Rata-rata Nilai Transaksi per pesanan berdasarkan Rupiah
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={500}>
                      <LineChart
                        data={getTrendUser('transaction_price_avg')}
                        {...commonLineChartProps}
                        legends={undefined}
                        enableSlices="x"
                        sliceTooltip={SliceTooltip('transaction_price_avg')}
                      />
                    </Box>
                    {renderToggles('transaction_price_avg')}
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Rata-rata Nilai Transaksi per pesanan berdasarkan
                      Kuantitas
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={500}>
                      <LineChart
                        data={getTrendUser('qty_transaction_avg')}
                        {...commonLineChartProps}
                        legends={undefined}
                        enableSlices="x"
                        sliceTooltip={SliceTooltip('qty_transaction_avg')}
                      />
                    </Box>
                    {renderToggles('qty_transaction_avg')}
                  </PaperBox>
                </Box>
              );
            }}
          </Query>

          <PaperBox className={classNames.chartBox} elevation={0}>
            <Text size="large" weight="bold">
              Persentase Penjualan berdasarkan Segmentasi
            </Text>
            <Text size="small" weight="light">
              {dateValues}
            </Text>
            <Box width="100%" height={500}>
              <Query<
                GetSalesPercentageBasedOnSegmentation,
                GetSalesPercentageBasedOnSegmentationParams
              >
                query={GET_SALES_PERCENTAGE_BASED_ON_SEGMENTATION}
                variables={{
                  startDate: startDateVariable,
                  endDate: endDateVariable,
                  depot: filter.depot ? filter.depot : undefined,
                  segment: filter.segmentation
                    ? filter.segmentation
                    : undefined,
                  periodType:
                    filter.startDate?.getTime() === filter.endDate?.getTime() &&
                    filter.periodTime === 'daily'
                      ? 'hourly'
                      : filter.periodTime,
                  products:
                    [...(filter.products || [])].length > 0
                      ? filter.products || []
                      : [],
                }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading }) => {
                  const offlineData: SalesPercentageBasedOnSegmentation[] = [
                    ...(data?.salesPercentageBasedOnSegmentation?.offline ||
                      []),
                  ];

                  const processedData: SalesPercentageBasedOnSegmentation[] =
                    data?.salesPercentageBasedOnSegmentation?.online &&
                    data?.salesPercentageBasedOnSegmentation?.online?.length > 0
                      ? data.salesPercentageBasedOnSegmentation.online?.map(
                          (data) => ({
                            ...data,
                            offline_sales_amount:
                              offlineData.find(
                                (offline) =>
                                  offline.productID === data.productID,
                              )?.sales_amount || 0,
                            offline_percentage:
                              offlineData.find(
                                (offline) =>
                                  offline.productID === data.productID,
                              )?.percentage || 0,
                            total_sales_amount:
                              (offlineData.find(
                                (offline) =>
                                  offline.productID === data.productID,
                              )?.sales_amount || 0) + data.sales_amount,
                            total_percentage: 0,
                          }),
                        )
                      : [
                          {
                            productID: '',
                            title: 'Not Found Data',
                            sales_amount: 0,
                            percentage: 0,
                            offline_sales_amount: 0,
                            offline_percentage: 0,
                            total_percentage: 0,
                            total_sales_amount: 0,
                          },
                        ];

                  const totalSalesAmount: number = processedData.reduce(
                    (accumulator, object) => {
                      return accumulator + object.total_sales_amount;
                    },
                    0,
                  );

                  const salesPercentageData: SalesPercentageBasedOnSegmentation[] = processedData
                    .map((val) => {
                      return {
                        ...val,
                        total_percentage:
                          (val.total_sales_amount / totalSalesAmount) * 100 ||
                          0,
                      };
                    })
                    .sort((a, b) => a.total_percentage - b.total_percentage);

                  return loading ? (
                    <Loading />
                  ) : (
                    <BarChart
                      data={salesPercentageData}
                      indexBy="title"
                      margin={{ top: 50, bottom: 50, left: 150, right: 150 }}
                      padding={0.3}
                      layout="horizontal"
                      layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        'annotations',
                        ({ bars }) => {
                          return (
                            <g>
                              {bars.map(({ width, height, y, data }) => {
                                return (
                                  <text
                                    transform={`translate(${width + 10}, ${y +
                                      height / 2})`}
                                    text-anchor="left"
                                    dominant-baseline="central"
                                    style={{
                                      fontFamily: 'sans-serif',
                                      fontSize: 14,
                                    }}
                                  >{`${generateLabel(
                                    data.data.total_percentage || 0,
                                    data.data.total_sales_amount || 0,
                                  ) ?? ''}`}</text>
                                );
                              })}
                            </g>
                          );
                        },
                      ]}
                      keys={['total_percentage']}
                      colors={[BLUE]}
                      label={() => ''}
                      labelTextColor="#000"
                      axisLeft={{
                        tickSize: 0,
                        tickPadding: 15,
                        renderTick: (tick) => {
                          return (
                            <g
                              key={tick.value}
                              transform={`translate(${tick.x},${tick.y})`}
                            >
                              <CustomTooltip
                                title={
                                  <PaperBox
                                    padding={1}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    style={{
                                      margin: -10,
                                    }}
                                  >
                                    <Box>
                                      <Text size="small" weight="bold">
                                        {`Qty Offline: ${formatThousandSeparator(
                                          salesPercentageData.find(
                                            (val) => val.title === tick.value,
                                          )?.offline_sales_amount || 0,
                                        )} (${formatDecimal(
                                          salesPercentageData.find(
                                            (val) => val.title === tick.value,
                                          )?.offline_percentage || 0,
                                          2,
                                        )}%)`}
                                      </Text>
                                    </Box>
                                    <Box>
                                      <Text size="small" weight="bold">
                                        {`Qty Online: ${formatThousandSeparator(
                                          salesPercentageData.find(
                                            (val) => val.title === tick.value,
                                          )?.sales_amount || 0,
                                        )} (${formatDecimal(
                                          salesPercentageData.find(
                                            (val) => val.title === tick.value,
                                          )?.percentage || 0,
                                          2,
                                        )}%)`}
                                      </Text>
                                    </Box>
                                  </PaperBox>
                                }
                              >
                                <text
                                  id="detailSku"
                                  alignmentBaseline="auto"
                                  textAnchor={tick.textAnchor}
                                  transform={`translate(${tick.textX},${tick.textY})`}
                                  style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: 10,
                                  }}
                                >
                                  {generateYAxisLabel(tick.value)}
                                </text>
                              </CustomTooltip>
                            </g>
                          );
                        },
                      }}
                      minValue={0}
                      maxValue={100}
                      axisBottom={{
                        format: (value) =>
                          formatThousandSeparator(Number(value)),
                      }}
                      theme={{
                        fontSize: 12,
                      }}
                      enableGridX
                      labelSkipHeight={10}
                      labelSkipWidth={120}
                      tooltip={({ data }) => (
                        <PaperBox
                          padding={1}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <Box>
                            <Text size="small" weight="bold">
                              {`Qty Offline: ${formatThousandSeparator(
                                data.offline_sales_amount || 0,
                              )} (${formatDecimal(
                                data.offline_percentage || 0,
                                2,
                              )}%)`}
                            </Text>
                          </Box>
                          <Box>
                            <Text size="small" weight="bold">
                              {`Qty Online: ${formatThousandSeparator(
                                data.sales_amount || 0,
                              )} (${formatDecimal(data.percentage || 0, 2)}%)`}
                            </Text>
                          </Box>
                        </PaperBox>
                      )}
                    />
                  );
                }}
              </Query>
            </Box>
          </PaperBox>
          <Query<
            GetTotalCanceledTransactionResult,
            GetTrendUserTransactionParams
          >
            query={GET_TOTAL_CANCELED_TRANSACTION}
            variables={{
              startDate: startDateVariable,
              endDate: endDateVariable,
              depot: filter.depot ? filter.depot : undefined,
              segment: filter.segmentation ? filter.segmentation : undefined,
              sku:
                [...(filter.products || [])].length > 0
                  ? filter.products
                  : undefined,
              periodType:
                filter.startDate?.getTime() === filter.endDate?.getTime() &&
                filter.periodTime === 'daily'
                  ? 'hourly'
                  : filter.periodTime,
            }}
          >
            {({ data }) => (
              <Box
                display="grid"
                gridTemplateColumns={matches ? '100%' : '49% 49%'}
                gridGap="2%"
              >
                <PaperBox className={classNames.chartBox} elevation={0}>
                  <Text size="large" weight="bold">
                    Nilai Pembatalan Berdasarkan Rupiah
                  </Text>
                  <Text size="small" weight="light">
                    {dateValues}
                  </Text>
                  <Box width="100%" height={400}>
                    <LineChart
                      data={[
                        {
                          id: 'Online',
                          color: ZOMBIE_GREEN,
                          data: [
                            ...(data?.totalPriceOfTheCanceledTransaction || []),
                          ].map((data) => ({
                            x: new Date(
                              new Date(data.transaction_date).getTime() -
                                getTimezoneOffset('Asia/Jakarta'),
                            ),
                            y: data.final_price,
                          })),
                        },
                      ]}
                      {...commonLineChartProps}
                    />
                  </Box>
                </PaperBox>
                <PaperBox className={classNames.chartBox} elevation={0}>
                  <Text size="large" weight="bold">
                    Nilai Pembatalan Berdasarkan Kuantitas
                  </Text>
                  <Text size="small" weight="light">
                    {dateValues}
                  </Text>
                  <Box width="100%" height={400}>
                    <LineChart
                      data={[
                        {
                          id: 'Online',
                          color: ZOMBIE_GREEN,
                          data: [
                            ...(data?.totalProductQuantityOfTheCanceledTransaction ||
                              []),
                          ].map((data) => ({
                            x: new Date(
                              new Date(data.transaction_date).getTime() -
                                getTimezoneOffset('Asia/Jakarta'),
                            ),
                            y: data.total_product_quantity,
                          })),
                        },
                      ]}
                      {...commonLineChartProps}
                    />
                  </Box>
                </PaperBox>
              </Box>
            )}
          </Query>
        </>
      ) : null}
    </Box>
  );
}
