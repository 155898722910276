import React, { Component } from 'react';
import { StyleProp, ViewStyle, TextStyle, StyleSheet } from 'react-native';
import { Text, TextField } from '.';
import { TextFieldProps } from './TextField';
import { DARK_GRAY } from '../constants/colors';
import { formatThousandSeparator, parseInteger } from '../helpers';

type Props = TextFieldProps & {
  currencyValue: number;
  onCurrencyChange?: (value: number) => void;
  style?: StyleProp<ViewStyle>;
  textFieldStyle?: StyleProp<TextStyle>;
  color?: string;
};

const styles = StyleSheet.create({
  children: {
    paddingLeft: 0,
    marginHorizontal: 0,
  },
  rp: { paddingLeft: 10, cursor: 'default' },
});

export default class CurrencyField extends Component<Props> {
  render() {
    const {
      currencyValue,
      onCurrencyChange,
      style: containerStyle,
      textFieldStyle,
      color,
      editable,
      ...otherProps
    } = this.props;
    return (
      <TextField
        keyboardType="numbers-and-punctuation"
        onChangeText={this._onChangeText}
        childrenPosition="left"
        style={{
          textField: textFieldStyle,
          container: containerStyle,
          children: styles.children,
        }}
        value={formatThousandSeparator(currencyValue)}
        editable={editable}
        {...otherProps}
      >
        <Text size="small" color={color || DARK_GRAY} style={styles.rp}>
          {'Rp. '}
        </Text>
      </TextField>
    );
  }

  _onChangeText = (updatedValue: string) => {
    const { onCurrencyChange } = this.props;
    const newCurrencyValue = parseInteger(updatedValue);
    onCurrencyChange && onCurrencyChange(newCurrencyValue);
  };
}
