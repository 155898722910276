import React, { Component, CSSProperties } from 'react';
import ReactDropzone, {
  DropzoneInputProps,
  DropzoneProps,
} from 'react-dropzone';

import { Image, ImageSourcePropType, StyleSheet } from 'react-native';

import { Icon, Loading, Text } from '.';
import { DROPZONE } from '../constants/colors';

export type FileWithPreview = { file: File; preview: string };

type Props = DropzoneProps & {
  source?: ImageSourcePropType;
  uploadText?: string;
  getPreview?: (withPreview: Array<FileWithPreview>) => void;
  loading?: boolean;
  inputProps?: DropzoneInputProps;
};

type State = { files: Array<FileWithPreview> };

export default class Dropzone extends Component<Props, State> {
  state: State = { files: [] };

  render() {
    const {
      source,
      uploadText,
      getPreview,
      loading,
      inputProps,
      ...dropzoneProps
    } = this.props;
    return (
      <ReactDropzone
        accept="image/*"
        preventDropOnDocument
        onDrop={(acceptedFiles) => {
          const files = acceptedFiles.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
          }));
          getPreview && getPreview(files);
          this.setState({ files });
        }}
        {...dropzoneProps}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isFileDialogActive,
        }) => {
          let content;
          if (loading) {
            content = <Loading />;
          } else if (source) {
            content = (
              <Image
                source={source}
                style={styles.preview}
                resizeMode="contain"
              />
            );
          } else {
            content = (
              <>
                <Icon
                  name="file_upload"
                  color={
                    isDragActive || isFileDialogActive
                      ? DROPZONE.ACTIVE
                      : DROPZONE.DEFAULT
                  }
                  size="small"
                />
                <Text
                  size="small"
                  color={
                    isDragActive || isFileDialogActive
                      ? DROPZONE.ACTIVE
                      : DROPZONE.DEFAULT
                  }
                  style={styles.text}
                >
                  {uploadText || 'Unggah Foto'}
                </Text>
              </>
            );
          }
          return (
            <div
              {...(getRootProps({
                style: StyleSheet.flatten([
                  styles.root,
                  isDragActive || isFileDialogActive ? styles.active : {},
                ]) as CSSProperties,
              }) as any)}
            >
              <input {...(getInputProps(inputProps) as any)} />
              {content}
            </div>
          );
        }}
      </ReactDropzone>
    );
  }

  componentWillUnmount() {
    this.state.files.map(({ preview }) => URL.revokeObjectURL(preview));
  }
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    borderColor: DROPZONE.BORDER,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 150,
    width: 150,
  },
  preview: {
    borderRadius: 4,
    height: 150,
    width: 150,
  },
  text: { paddingTop: 5, cursor: 'pointer' },
  active: { borderColor: DROPZONE.ACTIVE },
});
