export type SelectedStateContent = {
  __typename: string;
  selectedArray: Array<string>;
};
export type SelectedState = { selectedState: SelectedStateContent };

export type ModalStateContent<T = any> = {
  __typename: string;
  selectedArray: Array<T>;
};
export type ModalState<T = any> = { modalState: ModalStateContent<T> };

export type SearchStateContent = {
  __typename: string;
  searchedString: string;
};
export type SearchState = { searchState: SearchStateContent };

export type UserContent = {
  userID: string;
  email: string;
  name: string;
  role: string;
  token: string;
};

type UserState = { userState: { __typename: string } & UserContent };

export type RootState = SelectedState & ModalState & SearchState & UserState;

export const defaultState: RootState = {
  selectedState: {
    __typename: 'SelectedState',
    selectedArray: [],
  },
  modalState: {
    __typename: 'ModalState',
    selectedArray: [],
  },
  searchState: {
    __typename: 'SearchState',
    searchedString: '',
  },
  userState: {
    __typename: 'UserState',
    userID: '',
    name: '',
    email: '',
    token: '',
    role: '',
  },
};
