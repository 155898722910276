import React from 'react';
import { StyleSheet } from 'react-native';

import { Dropdown, DropdownProps } from '../../core-ui';
import { WHITE } from '../../constants/colors';

export type ChosenProps<T = string> = Pick<
  DropdownProps<T>,
  'onChange' | 'selectedOption' | 'style'
>;
export type Option<T = string> = { value: T; label: string };

export type PickerProps<T = string> = ChosenProps<T> & {
  isFilter?: boolean;
  isLoading?: boolean;
  options: Array<Option<T>>;
  label: string;
  placeholder: string;
};

export function Picker<T = string>(props: PickerProps<T>) {
  const { isFilter, label, placeholder, options, style, ...otherProps } = props;
  const extendedProps = isFilter
    ? {
        backgroundColor: WHITE,
        style: [styles.filterDropdown, style],
      }
    : {
        labelHorizontal: true,
        style,
      };
  return (
    <Dropdown
      label={label}
      searchable
      placeholder={placeholder}
      options={options}
      {...extendedProps}
      {...otherProps}
    />
  );
}

const styles = StyleSheet.create({
  filterDropdown: { width: 230 },
});
