import React from 'react';
import { Query } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  ClusterDetail,
  HistoryPointsPrice,
  PercentageCreditPointModal,
} from './components';

import {
  DEFAULT_ROWS_PER_PAGE,
  ErrorScreen,
  RowsPerPage,
  Table,
} from '../../components';
import { BLUE_SAPPHIRE, GRAY, PRIMARY } from '../../constants/colors';
import { Button, Icon, Loading, Modal, Separator, Text } from '../../core-ui';
import {
  fetchMoreItems,
  formatFullDate,
  graphqlErrorRemover,
} from '../../helpers';

import withToast, { ToastContextProps } from 'helpers/withToast';
import {
  GET_CLUSTERS,
  GET_POINT_PRICE_CLUSTERS,
  GetClustersResult,
  GetClustersVariables,
} from '../../graphql/queries';
import { AccessProps } from '../../graphql/queries/roleQuery';

type State = {
  isHistoryPoinVisible: boolean;
  isClusterDetailVisible: boolean;
  actionData: Nullable<any>;
  rowsPerPage: RowsPerPage;
  page: number;
  isCreditPoinSettingVisible: boolean;
};

type Props = RouteComponentProps & AccessProps & ToastContextProps;

class ClusterScene extends React.Component<Props, State> {
  state: State = {
    isHistoryPoinVisible: false,
    isClusterDetailVisible: false,
    actionData: null,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    page: 0,
    isCreditPoinSettingVisible: false,
  };

  render() {
    return (
      <View style={styles.root}>
        {this._renderHeader()}
        <Separator />
        <View style={styles.bodyWrapper}>
          {this._renderTable()}
          {this._renderClusterDetail()}
          {this._renderPoinPriceForm()}
        </View>
      </View>
    );
  }

  _renderPoinPriceForm = () => {
    const { isHistoryPoinVisible } = this.state;
    return (
      <Modal
        title="Pengaturan Cluster"
        isVisible={isHistoryPoinVisible}
        onClose={() =>
          this.setState({ isHistoryPoinVisible: !isHistoryPoinVisible })
        }
      >
        <HistoryPointsPrice />
      </Modal>
    );
  };

  _renderClusterDetail = () => {
    const { isClusterDetailVisible, actionData } = this.state;
    return (
      <Modal
        title="Informasi Cluster"
        isVisible={isClusterDetailVisible}
        onClose={() =>
          this.setState({ isClusterDetailVisible: !isClusterDetailVisible })
        }
      >
        {/* TODO : Do refetch when handleBack from ClusterSceneForm */}
        {actionData && (
          <Query
            query={GET_POINT_PRICE_CLUSTERS}
            variables={{
              where: {
                active: true,
              },
            }}
          >
            {({ data, loading }) => {
              if (loading) {
                return (
                  <View style={styles.emptyContainer}>
                    <Loading />
                  </View>
                );
              }
              const pointPrice = data
                ? data.pointPriceClusters[0].pointPrice
                : 0;
              return (
                <ClusterDetail
                  clusterDetail={actionData}
                  pointPrice={pointPrice}
                />
              );
            }}
          </Query>
        )}
      </Modal>
    );
  };

  _renderHeader = () => {
    const { access } = this.props;
    return (
      <View style={styles.header}>
        <Text size="xlarge">Cluster</Text>
        {access.create && (
          <View style={styles.flexRow}>
            <Button
              inverted
              text="Pengaturan Kredit Poin"
              style={{
                marginRight: 16,
                padding: '0px 14px 0px 8px',
              }}
              onPress={() =>
                this.setState({
                  isCreditPoinSettingVisible: true,
                })
              }
            >
              <Icon
                size="xsmall"
                name="settings"
                color={BLUE_SAPPHIRE}
                style={{ marginRight: 6 }}
              />
              <Text size="small">Pengaturan Kredit Poin</Text>
            </Button>
            <Button
              icon="add"
              iconPosition="left"
              text="Tambah Cluster"
              onPress={this._addCluster}
            />
            <View style={styles.historyWrapper}>
              <Icon
                size="medium"
                name="settings"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => this.setState({ isHistoryPoinVisible: true })}
              />
            </View>
          </View>
        )}
        <PercentageCreditPointModal
          openToast={this.props.openToast}
          closeToast={this.props.closeToast}
          isCreditPoinSettingVisible={this.state.isCreditPoinSettingVisible}
          setIsCreditPoinSettingVisible={(visible) =>
            this.setState({
              isCreditPoinSettingVisible: visible,
            })
          }
        />
      </View>
    );
  };

  _renderTable = () => {
    const { access } = this.props;
    const { rowsPerPage: firstCount, page } = this.state;
    return (
      <Query
        query={GET_CLUSTERS}
        variables={{ first: firstCount, skip: 0 }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({ data, loading, error, fetchMore }) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen
                  detailMessage={graphqlErrorRemover(error.message)}
                />
              </View>
            );
          }
          if (data) {
            const { clusters } = data;
            return (
              <Table
                data={clusters}
                dataCount={data.count}
                page={page}
                onChangePage={(nextPage) => this.setState({ page: nextPage })}
                isLoading={loading}
                rowsPerPage={this.state.rowsPerPage}
                loadMore={({ first, skip }) => {
                  fetchMoreItems<GetClustersResult, GetClustersVariables>(
                    fetchMore,
                    {
                      query: GET_CLUSTERS,
                      variables: { first, skip },
                      dataKey: 'clusters',
                    },
                  );
                }}
                structure={{
                  segment: {
                    headerTitle: 'Segmentasi',
                    alias: 'segment.title',
                  },
                  validDate: {
                    headerTitle: 'Tanggal Mulai',
                    render: ({ validDate }) => (
                      <Text
                        size="small"
                        weight="reg"
                        style={{ letterSpacing: 1.5 }}
                      >
                        {formatFullDate(new Date(validDate))}
                      </Text>
                    ),
                  },
                  expiredDate: {
                    headerTitle: 'Tanggal Berakhir',
                    render: ({ expiredDate }) => (
                      <Text
                        size="small"
                        weight="reg"
                        style={{ letterSpacing: 1.5 }}
                      >
                        {formatFullDate(new Date(expiredDate))}
                      </Text>
                    ),
                  },
                  actions: {
                    render: (cluster) => (
                      <View style={styles.actionTableWrapper}>
                        <Icon
                          size="small"
                          name="description"
                          color={GRAY}
                          hoverColor={PRIMARY}
                          onPress={() =>
                            this.setState({
                              isClusterDetailVisible: true,
                              actionData: cluster,
                            })
                          }
                        />
                        {access.update && (
                          <Link
                            to={{
                              pathname: `clusters/${cluster.id}`,
                              state: { clusterData: cluster },
                            }}
                          >
                            <Icon
                              size="small"
                              name="edit"
                              color={GRAY}
                              hoverColor={PRIMARY}
                            />
                          </Link>
                        )}
                      </View>
                    ),
                    noHeaderName: true,
                  },
                }}
                onChangeRowsPerPage={(rowsPerPage) =>
                  this.setState({ rowsPerPage, page: 0 })
                }
              />
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _addCluster = () => {
    const { history } = this.props;
    history && history.push('/clusters/new');
  };
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  historyWrapper: {
    justifyContent: 'center',
    paddingLeft: 15,
    marginLeft: 15,
  },
  bodyWrapper: {
    flex: 1,
    paddingTop: 20,
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default withToast(ClusterScene);
