import gql from 'graphql-tag';
import { DataValue, MutationFn } from 'react-apollo';
import { ProviderRole } from './providerQuery';

export type AccessProps = {
  access: PageAccess;
};

export type PageAccess = {
  id: string;
  create: boolean;
  delete: boolean;
  page: string;
  role: string;
  read: boolean;
  update: boolean;
};

export type RoleListResult = { roles: Array<ProviderRole> };
export type RoleListData = DataValue<RoleListResult, {}>;

export type UpdatePageAccessResult = { id: string };
export type UpdatePageAccessVariable = {
  id: string;
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
};

export type UpdatePageAccessMutationFn = MutationFn<
  UpdatePageAccessResult,
  UpdatePageAccessVariable
>;

export const UPDATE_PAGE_ACCESS = gql`
  mutation(
    $id: ID!
    $create: Boolean
    $read: Boolean
    $update: Boolean
    $delete: Boolean
  ) {
    updatePageAccess(
      where: { id: $id }
      data: { create: $create, read: $read, update: $update, delete: $delete }
    ) {
      id
    }
  }
`;

export const GET_ROLES = gql`
  query {
    roles: providerRoles
  }
`;

export type PageAccessResult = { pageAccesses: Array<PageAccess> };
export type PageAccessData = DataValue<PageAccessResult, {}>;

export const GET_ACCESS_PRIVILEGES = gql`
  query($role: ProviderRole!) {
    pageAccesses(where: { role: $role }) {
      id
      role
      page
      create
      read
      update
      delete
    }
  }
`;

export const GET_ACCESS_PRIVILEGE_PER_PAGE = gql`
  query($role: ProviderRole!, $page: String!) {
    pageAccesses(where: { AND: [{ role: $role }, { page: $page }] }) {
      id
      role
      page
      create
      read
      update
      delete
    }
  }
`;
