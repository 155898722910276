import gql from 'graphql-tag';

import {
  segment,
  subSegment,
  products,
  clusterObject,
} from '../relationalFragments';
import { Segment, SubSegment, Product } from '.';

export type ClusterName = 'BRONZE' | 'SILVER' | 'GOLD' | 'DIAMOND';

export type Cluster = {
  id: string;
  maxRange: number;
  minRange: number;
  pointMultiplier: number;
  unitMultiplier: number;
  name: ClusterName;
};

export type ClusterGroup = {
  bronze: Cluster;
  silver: Cluster;
  gold: Cluster;
  diamond: Cluster;
};

export type ClusterPart = {
  id: string;
  validDate: string;
  expiredDate: string;
  segment: Segment;
  subSegment: SubSegment;
  products: Array<Product>;
  clusterGroup: ClusterGroup;
  active: boolean;
};

export type PointPriceCluster = {
  pointDate: string;
  pointPrice: number;
  active: boolean;
};

export type ClusterCycle = {
  id: string;
  cycleTime: number;
  active: boolean;
};

export type GetPointPriceResult = {
  pointPriceClusters: Array<PointPriceCluster>;
};

export type GetClusterCycleResult = {
  clusterCycles: Array<ClusterCycle>;
};

export type GetClustersResult = { clusters: Array<ClusterPart>; count: number };
export type GetClustersVariables = {
  skip: number;
  first: number;
};

export const clusterData = `
{
  id
  validDate
  expiredDate
  ${segment}
  ${subSegment}
  ${products}
  clusterGroup {
    diamond ${clusterObject}
    gold ${clusterObject}
    silver ${clusterObject}
    bronze ${clusterObject}
  }
}
`;

export const GET_CLUSTERS = gql`
query clusters($skip: Int, $first: Int) {
  clusters(skip: $skip, first: $first) ${clusterData}
  count: dataCount(node: CLUSTER)
}
`;

export const GET_POINT_PRICE_CLUSTERS = gql`
  query pointPriceClusters($where: PointPriceClusterWhereInput) {
    pointPriceClusters(where: $where) {
      id
      pointDate
      pointPrice
      active
    }
  }
`;

export const GET_CLUSTER_CYCLE = gql`
  query ClusterCycle {
    clusterCycles {
      id
      cycleTime
      active
    }
  }
`;
