import React from 'react';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from 'react-calendar';

import { createStyles, withStyles, WithStyles } from '@material-ui/core';

import boxShadow from '../constants/boxShadow';
import './Calendar.css';

import { PRIMARY, BLACK } from '../constants/colors';
import { DAYS } from '../constants/calendar';

type Props = WithStyles<typeof styles> & ReactCalendarProps;

export function DatePicker(props: Props) {
  const { classes, ...otherProps } = props;
  return (
    <ReactCalendar
      className={classes.root}
      formatShortWeekday={(_locale, date) => DAYS[date.getDay()].substr(0, 1)}
      minDate={new Date()}
      showNeighboringMonth={false}
      tileClassName={classes.dateTile}
      {...otherProps}
    />
  );
}

const styles = createStyles({
  root: {
    borderColor: PRIMARY,
    borderRadius: 5,
    borderWidth: 1,
    boxShadow,
    padding: 5,
  },
  dateTile: { color: BLACK },
});

export default withStyles(styles)(DatePicker);
