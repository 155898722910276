import React, { Component, Fragment } from 'react';
import generatePassword from 'password-generator';
import { compose, graphql } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { View, StyleSheet, Clipboard } from 'react-native';

import withToast, { ToastContextProps } from '../../helpers/withToast';

import { FormPage, RolePicker, DepotPicker } from '../../components';
import { Card, TextField, Text, Modal } from '../../core-ui';
import { isObjectEqual, convertPascalCase } from '../../helpers';
import { DARK_GRAY, BLACK, GRAY5, GRAY } from '../../constants/colors';
import {
  GET_PROVIDER_BY_ID,
  EDIT_PROVIDER,
  CREATE_PROVIDER,
  ProviderRole,
  ProviderResult,
  CreateProviderResult,
  CreateProviderMutation,
  EditProviderResult,
  EditProviderMutation,
  ProviderData,
  Provider,
  AccessProps,
} from '../../graphql/queries';
import { NotFoundPage } from '..';
import { sanitizeNumericInput } from '../../helpers/formatNumber';

type ProviderProps = { providerQuery: ProviderData };
type CreateProviderProps = { createProvider: CreateProviderMutation };
type EditProviderProps = { editProvider: EditProviderMutation };

type OwnProps = RouteComponentProps<{ id: string }>;
type Props = OwnProps &
  ToastContextProps &
  AccessProps &
  ProviderProps &
  CreateProviderProps &
  EditProviderProps;

type State = {
  isEdit: boolean;
  isModalVisible: boolean;
  id: string;
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  selectedRole: Nullable<ProviderRole>;
  selectedDepo: Nullable<string>;
  isSubmitLoading: boolean;
};

export class AdminFormScene extends Component<Props, State> {
  state: State = {
    isEdit: false,
    isModalVisible: false,
    id: '',
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    selectedRole: null,
    selectedDepo: null,
    isSubmitLoading: false,
  };

  componentDidMount() {
    this._initializeState();
  }

  _initializeState = () => {
    const { match, providerQuery, access } = this.props;
    const { id } = match.params;
    const isEdit = id !== 'new';

    if (isEdit && access.update) {
      if (providerQuery.provider) {
        this.setState(this._getDefaultState(providerQuery.provider));
      }
      this.setState({ isEdit });
    } else {
      this.setState(this._getEmptyState());
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { providerQuery: prevQuery } = prevProps;
    const { providerQuery: currQuery } = this.props;
    if (prevQuery.loading && !currQuery.loading && currQuery.provider) {
      this.setState(this._getDefaultState(currQuery.provider));
    }
  }

  render() {
    const {
      isEdit,
      selectedRole,
      selectedDepo,
      name,
      email,
      phoneNumber,
      isSubmitLoading,
    } = this.state;
    const { providerQuery, access } = this.props;
    if ((isEdit && !access.update) || (!isEdit && !access.create)) {
      return <NotFoundPage />;
    }
    return (
      <>
        {this._renderModal()}
        <FormPage
          showBackButton
          handleSubmit={this._handleSubmit}
          handleDiscard={this._initializeState}
          handleGoBack={this._handleBack}
          isDirty={this._isFormDirty()}
          isValid={this._isFormValid()}
          isLoading={providerQuery.loading}
          isSubmitLoading={isSubmitLoading}
          submitTitle={isEdit ? 'Simpan' : 'Tambah'}
        >
          <Card
            title={isEdit ? 'Ubah Informasi Admin' : 'Tambah Informasi Admin'}
          >
            <>
              <TextField
                stretch
                labelHorizontal
                childrenPosition="right"
                label="Nama"
                placeholder="Nama"
                value={name}
                onChangeText={(value) => this.setState({ name: value })}
              />
              <TextField
                stretch
                labelHorizontal
                childrenPosition="right"
                label="Email"
                placeholder="Email"
                value={email}
                onChangeText={(value) => this.setState({ email: value })}
              />
              <TextField
                stretch
                labelHorizontal
                childrenPosition="right"
                label="Nomor Telepon"
                placeholder="Nomor Telepon"
                value={phoneNumber}
                onChangeText={(value) =>
                  this.setState({ phoneNumber: sanitizeNumericInput(value) })
                }
              />
              <RolePicker
                style={{ zIndex: 2 }}
                selectedOption={selectedRole}
                onChange={(selected) =>
                  this.setState({
                    selectedRole: selected ? selected.value : null,
                  })
                }
              />
              {selectedRole !== 'SUPER_ADMIN' && (
                <DepotPicker
                  selectedOption={selectedDepo}
                  onChange={(selected) =>
                    this.setState({
                      selectedDepo: selected ? selected.value : null,
                    })
                  }
                />
              )}
            </>
          </Card>
        </FormPage>
      </>
    );
  }

  _renderModal = () => {
    const { isModalVisible, email, password } = this.state;
    // Temp : this data will get from backend when succes added
    return (
      <Modal
        isVisible={isModalVisible}
        title="Admin Berhasil Dibuat"
        buttonText="Salin Kata Kunci"
        onClose={this._closeModal}
        onSubmit={() => this._onModalSubmit()}
      >
        <>
          <Text size="small" color={DARK_GRAY}>
            Admin yang Anda buat sudah dapat menggunakan CMS TVIP.
          </Text>
          <View style={styles.modalContent}>
            <Text size="xsmall" color={DARK_GRAY}>
              Email
            </Text>
            <Text size="small" color={BLACK} style={styles.modalTextContent}>
              {email}
            </Text>
            <Text
              size="xsmall"
              color={DARK_GRAY}
              style={styles.modalTextHeader}
            >
              Kata Kunci Sementara
            </Text>
            <Text size="small" color={BLACK} style={styles.modalTextContent}>
              {password}
            </Text>
          </View>
        </>
      </Modal>
    );
  };

  _onModalSubmit = () => {
    const { history } = this.props;
    const { password } = this.state;
    Clipboard.setString(password);
    this.setState({ isModalVisible: false });
    history.replace('/admin-list');
  };

  _isFormDirty() {
    const { isEdit } = this.state;
    const { provider } = this.props.providerQuery;
    return !isObjectEqual(
      isEdit && provider
        ? this._getDefaultState(provider)
        : this._getEmptyState(),
      this.state,
    );
  }

  _getDefaultState = (data: Provider): State => {
    const { id, name, email, telephone, role, depot } = data;
    const depo = depot ? depot.id : null;
    return {
      ...this.state,
      id,
      name: convertPascalCase(name),
      email,
      phoneNumber: telephone,
      selectedRole: role,
      selectedDepo: depo,
    };
  };

  _isFormValid() {
    const { name, email, phoneNumber, selectedRole, selectedDepo } = this.state;
    return (
      name !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      selectedRole != null &&
      (selectedRole === 'ADMIN_DEPO' ? selectedDepo != null : true)
    );
  }

  _onValueChange = <K extends keyof State>(value: State[K], field: K) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  _getEmptyState(): State {
    return {
      ...this.state,
      name: '',
      email: '',
      phoneNumber: '',
      selectedRole: null,
      selectedDepo: null,
    };
  }

  _handleBack = () => {
    const { history } = this.props;
    history && history.goBack();
  };

  _handleSubmit = async () => {
    const {
      id,
      isEdit,
      name,
      email,
      phoneNumber,
      selectedRole,
      selectedDepo,
    } = this.state;
    const { createProvider, editProvider, openToast, history } = this.props;
    if (
      selectedRole != null &&
      (selectedRole !== 'SUPER_ADMIN' ? selectedDepo != null : true)
    ) {
      this.setState({ isSubmitLoading: true });
      const password = generatePassword(8, false);
      const connectDepo =
        selectedRole !== 'SUPER_ADMIN' && selectedDepo
          ? { depot: { connect: { id: selectedDepo } } }
          : {};
      const data = {
        name,
        email,
        password,
        telephone: phoneNumber,
        role: selectedRole,
        ...connectDepo,
      };
      if (isEdit) {
        const result = await editProvider({ variables: { id, data } });
        if (result && result.data) {
          openToast('success', 'Admin telah diubah.');
          history.goBack();
        } else {
          openToast('fail', 'Admin gagal diubah.');
        }
      } else {
        const result = await createProvider({ variables: { data } });
        if (result && result.data) {
          openToast('success', 'Admin telah dibuat.');
          this.setState({ isModalVisible: true, password });
        } else {
          openToast('fail', 'Admin gagal dibuat.');
        }
      }
      this.setState({ isSubmitLoading: false });
    }
  };

  _closeModal = () => this.setState({ isModalVisible: false });
}

const styles = StyleSheet.create({
  modalContent: {
    padding: 20,
    backgroundColor: GRAY5,
    borderColor: GRAY,
    borderWidth: 1,
    borderRadius: 5,
    marginVertical: 20,
  },
  modalTextContent: {
    marginTop: 5,
  },
  modalTextHeader: {
    marginTop: 15,
  },
});

export default compose(
  withToast,
  graphql<OwnProps, ProviderResult, {}, ProviderProps>(GET_PROVIDER_BY_ID, {
    name: 'providerQuery',
    options: (props) => ({ variables: { id: props.match.params.id } }),
  }),
  graphql<OwnProps, CreateProviderResult, {}, CreateProviderProps>(
    CREATE_PROVIDER,
    { name: 'createProvider' },
  ),
  graphql<OwnProps, EditProviderResult, {}, EditProviderProps>(EDIT_PROVIDER, {
    name: 'editProvider',
  }),
)(AdminFormScene);
