import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Icon, Text } from '.';
import { PRIMARY, BLACK } from '../constants/colors';

type Props<T = string> = {
  selected: boolean;
  label: string;
  value: T;
  onSelect: (value: T) => void;
};

export default function RadioButton<T = string>(props: Props<T>) {
  const { selected, label, value, onSelect } = props;
  return (
    <View style={styles.row}>
      <Icon
        name={selected ? 'radio_button_checked' : 'radio_button_unchecked'}
        size="small"
        color={PRIMARY}
        onPress={() => onSelect(value)}
      />
      <Text size="small" color={BLACK} style={styles.label}>
        {label}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  row: { flexDirection: 'row', alignItems: 'center' },
  label: { paddingLeft: 10 },
});
