import React, { Component, ComponentType, Fragment } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import withToast, { ToastContextProps } from '../../helpers/withToast';
import { Accordion, Button, Separator, Text, Icon, Modal } from '../../core-ui';
import { Query, NoDataPlaceholder } from '../../components';
import { GRAY, BLACK } from '../../constants/colors';
import { DELETE_FAQ, DeleteFaqParams } from '../../graphql/mutations';
import {
  GET_FAQS,
  GetFaqsData,
  Faq,
  AccessProps,
  PageAccess,
} from '../../graphql/queries';

type Props = RouteComponentProps & ToastContextProps & AccessProps;
type State = { selectedItem: Nullable<Faq>; isDeleteVisible: boolean };

class FrequentlyAskedScene extends Component<Props, State> {
  state: State = { selectedItem: null, isDeleteVisible: false };

  render() {
    const { access, openToast, history } = this.props;
    const { selectedItem, isDeleteVisible } = this.state;
    return (
      <View style={styles.root}>
        <View style={styles.header}>
          <Text size="xlarge">Bantuan</Text>
          {access.create && (
            <Button
              icon="add"
              text="Tambah Pertanyaan"
              onPress={() => history.push('/faqs/new')}
            />
          )}
        </View>
        <Separator />
        <Query<GetFaqsData> query={GET_FAQS} fetchPolicy="network-only">
          {({ data, refetch }) => (
            <Mutation<{}, DeleteFaqParams>
              mutation={DELETE_FAQ}
              onCompleted={() => {
                this.setState({ isDeleteVisible: false });
                openToast('success', 'Pertanyaan telah dihapus');
                refetch();
              }}
              onError={() => openToast('fail', 'Pertanyaan gagal dihapus')}
            >
              {(deleteFaq, { loading }) => {
                if (data) {
                  if (data.faqs.length === 0) {
                    return <NoDataPlaceholder text="Tidak ada data" />;
                  }
                  return (
                    <>
                      <Modal
                        hideHeaderClose
                        isVisible={isDeleteVisible}
                        title="Hapus Pertanyaan"
                        closeButtonText="Tidak"
                        onClose={() =>
                          this.setState({ isDeleteVisible: false })
                        }
                        buttonText="Hapus"
                        submitLoading={loading}
                        onSubmit={() =>
                          selectedItem &&
                          deleteFaq({ variables: { id: selectedItem.id } })
                        }
                        description={
                          selectedItem ? (
                            <Text>
                              Apakah Anda yakin ingin menghapus pertanyaan{' '}
                              <Text weight="bold" color={BLACK}>
                                {selectedItem.title}
                              </Text>{' '}
                              dari daftar pertanyaan?
                            </Text>
                          ) : (
                            ''
                          )
                        }
                      />
                      <Accordion
                        items={[...(data.faqs || [])].map((item) => ({
                          id: item.id,
                          title: item.title,
                          children: this._createChildren(item.content),
                          headerRight: (
                            <HeaderRight
                              onEditPress={() =>
                                history.push(`/faqs/${item.id}`)
                              }
                              access={access}
                              onDeletePress={() =>
                                this.setState({
                                  selectedItem: item,
                                  isDeleteVisible: true,
                                })
                              }
                            />
                          ),
                        }))}
                      />
                    </>
                  );
                }
                return null;
              }}
            </Mutation>
          )}
        </Query>
      </View>
    );
  }

  _createChildren = (content: string) => (
    <Text weight="reg" size="medium">
      {content}
    </Text>
  );
}

type HeaderRightProps = {
  onEditPress: () => void;
  onDeletePress: () => void;
  access: PageAccess;
};

function HeaderRight(props: HeaderRightProps) {
  const { onEditPress, onDeletePress, access } = props;
  return (
    <View style={styles.containerCrud}>
      {access.update && (
        <TouchableOpacity style={{ marginRight: 24 }}>
          <View style={styles.crudButon}>
            <Icon
              name="edit"
              size="small"
              color={GRAY}
              style={{ height: 30 }}
              onPress={() => onEditPress()}
            />
          </View>
        </TouchableOpacity>
      )}
      {access.delete && (
        <TouchableOpacity style={{ marginRight: 24 }}>
          <View style={styles.crudButon}>
            <Icon
              name="delete"
              size="small"
              color={GRAY}
              style={{ height: 30 }}
              onPress={onDeletePress}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  containerCrud: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  crudButon: {
    width: 30,
    height: 30,
  },
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
});

export default withToast(FrequentlyAskedScene) as ComponentType<{}>;
