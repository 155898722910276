import React, { Component, CSSProperties } from 'react';
import * as MaterialIcon from 'react-icons/md';
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import { SVG } from '../assets/svg';
import { convertIconName } from '../helpers';

export type IconSize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

type Props = {
  name: IconKey;
  color?: string;
  hoverColor?: string;
  size?: IconSize;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: (e: GestureResponderEvent) => void;
  isCustomSVG?: boolean;
  disabled?: boolean;
  title?: string;
};

type State = { isHovered: boolean };

const ICON_SIZE = {
  xsmall: 17,
  small: 25,
  medium: 30,
  large: 40,
  xlarge: 64,
  xxlarge: 120,
};

export default class Icon extends Component<Props, State> {
  state: State = { isHovered: false };

  render() {
    const {
      name,
      color,
      hoverColor,
      size,
      style,
      onPress,
      isCustomSVG,
      disabled,
      containerStyle,
      title,
      ...otherProps
    } = this.props;
    const { isHovered } = this.state;
    const iconName = convertIconName(name);
    const iconSize = ICON_SIZE[size || 'medium'];
    const iconColor = (hoverColor && isHovered ? hoverColor : color) || '';
    let recomp;
    if (isCustomSVG) {
      recomp = React.createElement(SVG[name as SVGKeys], {
        fill: iconColor,
        size: iconSize,
      });
    } else {
      recomp = React.createElement(MaterialIcon[iconName as MDKey], {
        color: iconColor,
        size: iconSize,
        style: style ? (StyleSheet.flatten(style) as CSSProperties) : {},
      });
    }

    const iconComponent = (
      <View
        style={[
          containerStyle || styles.wrapper,
          disabled && (({ cursor: 'not-allowed' } as unknown) as ViewStyle),
        ]}
        onMouseOver={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {recomp}
      </View>
    );

    if (onPress) {
      return (
        <TouchableOpacity
          disabled={disabled}
          onPress={onPress}
          accessibilityRole="button"
          accessibilityLabel={title}
          {...otherProps}
        >
          {iconComponent}
        </TouchableOpacity>
      );
    }
    return <View {...otherProps}>{iconComponent}</View>;
  }
}

let styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
});
