import { ProgramType } from '../../../graphql/queries';

export function getProgramTypeString(input: ProgramType) {
  switch (input) {
    case 'FLASH_SALE':
      return 'FLASH SALE';
    case 'TRADE_PROMO':
      return 'TRADE PROMO';
    default:
      return input;
  }
}
