import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';
import { SUB_SEGMENT_FRAGMENT, MEMBERS_FRAGMENT } from '../relationalFragments';

export type SubSegment = {
  id: string;
  subSegmentCode: string;
  title: string;
};
export type SubSegmentList = { subSegments: Array<SubSegment> };
export type SubSegmentListData = DataValue<SubSegmentList>;
export const GET_SUB_SEGMENTS = gql`
  query getSubSegments {
    subSegments {
      ...SubSegmentData
    }
  }
  ${SUB_SEGMENT_FRAGMENT}
`;

export type SubSegmentWithMembers = SubSegment & { members: Array<ObjectKey> };
export type SubSegmentWithMembersList = {
  subSegments: Array<SubSegmentWithMembers>;
};
export type SubSegmentWithMembersListData = DataValue<
  SubSegmentWithMembersList
>;
export const GET_SUB_SEGMENTS_WITH_MEMBERS = gql`
  query getSubSegmentsWithMembers {
    subSegments {
      ...SubSegmentData
      members {
        ...MemberData
      }
    }
  }
  ${SUB_SEGMENT_FRAGMENT}
  ${MEMBERS_FRAGMENT}
`;

export type SubSegmentWithMembersParams = { segmentID: string };
export type SubSegmentWithMembersData = DataValue<
  SubSegmentWithMembers,
  SubSegmentWithMembersParams
>;

export const GET_SUB_SEGMENT_MEMBERS = gql`
  query getSubSegmentMembers($segmentID: ID!) {
    subSegment(id: $segmentID) {
      ...SubSegmentData
      members {
        ...MemberData
      }
    }
  }
  ${SUB_SEGMENT_FRAGMENT}
  ${MEMBERS_FRAGMENT}
`;
