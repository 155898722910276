import React, { useReducer, Fragment } from 'react';

import KtpModal from './components/KtpModal';
import { modalReducer } from './helpers/modalHelper';
import KtpTable from './components/KtpTable';
import { AccessProps } from '../user/UserScene';

type Props = {
  searchContent: string;
} & AccessProps;

export default function KtpApprovalScene(props: Props) {
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    modalType: 'KTP',
    ktpData: null,
    userDetail: null,
    userId: null,
    isOpen: false,
    onSuccess: () => {},
  });

  return (
    <>
      <KtpModal
        {...modalState}
        onClose={() => modalDispatch({ type: 'CLOSE' })}
      />
      <KtpTable {...props} modalDispatch={modalDispatch} />
    </>
  );
}
