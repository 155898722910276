import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';

import { User, USER_DATA } from './userQuery';

export type TopPaymentPeriod =
  | 'DAY0'
  | 'DAY7'
  | 'DAY10'
  | 'DAY15'
  | 'DAY20'
  | 'DAY30'
  | 'DAY40'
  | 'DAY45';
export type TopInvoiceType = 'AGENT' | 'DAILY' | 'MONTHLY' | 'INSTANT';
export type TOP = {
  id: string;
  userId: string;
  contractNumber: string;
  contractStart: Date;
  details: string;
  paymentPeriod: TopPaymentPeriod;
  isCredit: boolean;
  creditLimit: number;
  invoiceType: TopInvoiceType;
  active: boolean;
};

export type AssignTopVars = {
  id: string;
  data: {
    contractNumber: string;
    contractStart: Date;
    details: string;
    paymentPeriod: TopPaymentPeriod;
    isCredit: boolean;
    creditLimit: number;
    invoiceType: TopInvoiceType;
  };
};
export const ASSIGN_TOP = gql`
  mutation assignTop($id: ID!, $data: TOPDetailCreateInput!) {
    assignTOP(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export type RemoveTopVars = { id: string };
export const REMOVE_TOP = gql`
  mutation removeTop($id: ID!) {
    deleteTOP(where: { id: $id }) {
      id
    }
  }
`;

export type UsersWithTOPResult = { usersWithTOP: Array<User> } & {
  count: number;
};
export type UsersWithTOPVars = {
  first: number;
  skip: number;
  where: ObjectKey;
};
export type UsersWithTOPData = DataValue<UsersWithTOPResult, UsersWithTOPVars>;
export const GET_USERS_WITH_TOP = gql`
  query getUsersWithTOP($first: Int, $skip: Int, $where: UserWhereInput) {
    usersWithTOP(first: $first, skip: $skip, where: $where) {
      ${USER_DATA}
    }
    count: dataCount(node: USER, userWhere: $where)
  }
`;

const TOP_STATUS_DATA = `
  user {
    ${USER_DATA}
  }
  totalPurchase
  status
`;

export type TOPStatus = 'ACTIVE' | 'BLOCKED';
export type UserWithTOP = {
  user: User;
  totalPurchase: number;
  status: TOPStatus;
};
export type TOPStatusResult = { userTOPStatus: UserWithTOP };
export type TOPStatusVars = { id: string };
export type TOPStatusData = DataValue<TOPStatusResult, TOPStatusVars>;
export const GET_TOP_STATUS = gql`
  query getTOPStatus($where: UserWhereUniqueInput) {
    userTOPStatus(where: $where) {
      ${TOP_STATUS_DATA}
    }
  }
`;

export type TOPStatusListResult = {
  userTOPStatuses: Array<UserWithTOP>;
  count: number;
};
export type TOPStatusListVars = {
  where: ObjectKey;
  first: number;
  skip: number;
};
export type TOPStatusListData = DataValue<
  TOPStatusListResult,
  TOPStatusListVars
>;
export const GET_TOP_STATUS_LIST = gql`
  query getTOPStatusList($where: UserWhereInput, $skip: Int, $first: Int) {
    count: dataCount(node: TOP_STATUS, userWhere: $where)
    userTOPStatuses(where: $where, skip: $skip, first: $first) {
      ${TOP_STATUS_DATA}
    }
  }
`;
