import React from 'react';
import { Mutation, compose } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { Query } from '../../../components';
import { BLACK, DARK_GRAY, PRIMARY } from '../../../constants/colors';
import {
  Button,
  CurrencyField,
  Icon,
  Separator,
  Tab,
  Tabs,
  Text,
  TextField,
} from '../../../core-ui';
import {
  CREATE_CLUSTER_CYCLE,
  CREATE_POINT_PRICE_CLUSTERS,
} from '../../../graphql/mutations';
import {
  GET_CLUSTER_CYCLE,
  GET_POINT_PRICE_CLUSTERS,
  GetClusterCycleResult,
  GetPointPriceResult,
} from '../../../graphql/queries';
import {
  formatShortMonth,
  formatThousandSeparator,
  graphqlErrorRemover,
  parseInteger,
} from '../../../helpers';
import withToast, { ToastContextProps } from '../../../helpers/withToast';

type Props = ToastContextProps;

type State = {
  beingEdit: boolean;
  pointPrice: string;
  clusterCycle: string;
  defaultClusterCycle: string;
  selectedDetailTab: number;
};

export class HistoryPointPrice extends React.Component<Props, State> {
  state = {
    beingEdit: false,
    pointPrice: '-',
    clusterCycle: '-',
    defaultClusterCycle: '-',
    selectedDetailTab: 0,
  };

  render() {
    const { selectedDetailTab } = this.state;

    return (
      <Tabs
        selectedIndex={selectedDetailTab}
        onChange={(_e, index) =>
          this.setState({
            selectedDetailTab: index,
            clusterCycle: this.state.defaultClusterCycle,
          })
        }
      >
        <Tab label="Harga Poin">{this._pointPrice()}</Tab>
        <Tab label="Siklus Penurunan Cluster">{this._clusterCycle()}</Tab>
      </Tabs>
    );
  }

  _clusterCycle = () => {
    const { clusterCycle } = this.state;
    const { openToast } = this.props;

    return (
      <Query
        query={GET_CLUSTER_CYCLE}
        onCompleted={(data) => {
          const { clusterCycles } = data as GetClusterCycleResult;
          const activeClusterCycle = clusterCycles.find((item) => item.active);
          if (clusterCycle === '-') {
            const cycleTime = String(
              activeClusterCycle ? activeClusterCycle.cycleTime : 0,
            );
            this.setState({
              defaultClusterCycle: cycleTime,
              clusterCycle: cycleTime,
            });
          }
        }}
        style={styles.loading}
      >
        {({ data, refetch: refetchClusterCycle }) => {
          if (data) {
            return (
              <Mutation
                mutation={CREATE_CLUSTER_CYCLE}
                onCompleted={(afterComplete) => {
                  const { cycleTime } = afterComplete.createClusterCycle;
                  this.setState(() => {
                    refetchClusterCycle();
                    openToast &&
                      openToast(
                        'success',
                        'Siklus penurunan cluster berhasil diperbarui',
                      );

                    return {
                      defaultClusterCycle: cycleTime,
                      clusterCycle: cycleTime,
                    };
                  });
                  this.setState({});
                }}
                onError={(error) =>
                  openToast &&
                  openToast('fail', graphqlErrorRemover(error.message))
                }
              >
                {(addClusterCycle) => (
                  <View style={styles.pointPriceContainer}>
                    <View style={styles.pointForm}>
                      <View style={styles.flex}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          Siklus Penurunan Cluster
                        </Text>
                      </View>
                      <View style={styles.separatorForm}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          :
                        </Text>
                      </View>
                      <View style={styles.flexTwo}>
                        <TextField
                          stretch
                          onChangeText={(value) =>
                            this.setState({ clusterCycle: value })
                          }
                          value={clusterCycle}
                          style={{ container: { marginHorizontal: 10 } }}
                          keyboardType="numeric"
                        />
                      </View>
                      <Text size="small" weight="reg" color={DARK_GRAY}>
                        Bulan
                      </Text>
                    </View>
                    <View style={styles.buttonWrapper}>
                      <Button
                        color="secondary"
                        inverted
                        text="Batal"
                        onPress={this._onClusterCycleCanceled}
                      />
                      <Button
                        style={styles.saveButton}
                        text="Simpan"
                        onPress={() => {
                          addClusterCycle({
                            variables: {
                              cycleTime: Number(clusterCycle),
                            },
                          });
                        }}
                      />
                    </View>
                  </View>
                )}
              </Mutation>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _pointPrice = () => {
    const { pointPrice, beingEdit } = this.state;
    const { openToast } = this.props;

    return (
      <Query<GetPointPriceResult>
        query={GET_POINT_PRICE_CLUSTERS}
        onCompleted={(data) => {
          const { pointPriceClusters } = data as GetPointPriceResult;
          const activePointPrice = pointPriceClusters.find(
            (item) => item.active,
          );
          if (pointPrice === '-') {
            this.setState({
              pointPrice: String(
                activePointPrice ? activePointPrice.pointPrice : 0,
              ),
            });
          }
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, refetch: refetchPointPrices }) => {
          if (data) {
            const { pointPriceClusters } = data;
            const activePointPrice = pointPriceClusters.find(
              (item) => item.active,
            );

            return (
              <Mutation
                mutation={CREATE_POINT_PRICE_CLUSTERS}
                onCompleted={({
                  createPointPriceCluster: { pointPrice: newPointPrice },
                }: {
                  createPointPriceCluster: { pointPrice: number };
                }) => {
                  this.setState(() => {
                    openToast &&
                      openToast('success', 'Harga point berhasil diperbarui');
                    refetchPointPrices();
                    return {
                      beingEdit: false,
                      pointPrice: newPointPrice.toString(),
                    };
                  });
                }}
                onError={(error) =>
                  openToast &&
                  openToast('fail', graphqlErrorRemover(error.message))
                }
              >
                {(addPointPrice) => (
                  <View style={styles.pointPriceContainer}>
                    <View style={styles.pointForm}>
                      <View style={styles.flex}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          1 Poin
                        </Text>
                      </View>
                      <View style={styles.separatorForm}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          :
                        </Text>
                      </View>
                      <View style={styles.flexTwo}>
                        {beingEdit ? (
                          <CurrencyField
                            stretch
                            currencyValue={Number(pointPrice)}
                            onCurrencyChange={(value) =>
                              this.setState({ pointPrice: value.toString() })
                            }
                          />
                        ) : (
                          <Text size="small" weight="reg" color={BLACK}>
                            Rp.{' '}
                            {formatThousandSeparator(parseInteger(pointPrice))}
                          </Text>
                        )}
                      </View>
                      {!beingEdit && (
                        <Icon
                          size="xsmall"
                          name="edit"
                          color={DARK_GRAY}
                          hoverColor={PRIMARY}
                          onPress={() => this.setState({ beingEdit: true })}
                        />
                      )}
                    </View>
                    {beingEdit && (
                      <View style={styles.buttonWrapper}>
                        <Button
                          color="secondary"
                          inverted
                          text="Batal"
                          onPress={() =>
                            this.setState({
                              beingEdit: false,
                              pointPrice: String(
                                activePointPrice
                                  ? activePointPrice.pointPrice
                                  : 0,
                              ),
                            })
                          }
                        />
                        <Button
                          disabled={
                            activePointPrice
                              ? Number(pointPrice) ===
                                activePointPrice.pointPrice
                              : false
                          }
                          style={styles.saveButton}
                          text="Simpan"
                          onPress={() => {
                            addPointPrice({
                              variables: {
                                pointPrice: Number(pointPrice),
                                pointDate: new Date().toISOString(),
                              },
                            });
                          }}
                        />
                      </View>
                    )}
                    <Separator secondary />
                    <Text size="small" weight="bold">
                      Riwayat Perubahan Harga Poin
                    </Text>
                    <View style={styles.historyPointBox}>
                      <View style={styles.historyLabelWrapper}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          Tanggal
                        </Text>
                      </View>
                      <View style={styles.flexTwo}>
                        <Text size="small" weight="reg" color={DARK_GRAY}>
                          Harga Poin
                        </Text>
                      </View>
                    </View>
                    <Separator />
                    {pointPriceClusters.map((item, index) => {
                      const itemDate = new Date(item.pointDate);
                      return (
                        <View key={index} style={styles.poinPriceBox}>
                          <View style={styles.historyLabelWrapper}>
                            <Text size="small" weight="reg" color={BLACK}>
                              {formatShortMonth(itemDate)}
                            </Text>
                          </View>
                          <View style={styles.flexTwo}>
                            <Text size="small" weight="reg" color={BLACK}>
                              Rp. {formatThousandSeparator(item.pointPrice)}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </Mutation>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _onClusterCycleCanceled = () =>
    this.setState({
      clusterCycle: this.state.defaultClusterCycle,
    });
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  pointPriceContainer: {
    flex: 1,
    marginTop: 30,
  },
  flexTwo: {
    flex: 2,
  },
  pointForm: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: 10,
  },
  separatorForm: {
    marginHorizontal: 10,
  },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  saveButton: {
    marginLeft: 10,
  },
  historyPointBox: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginTop: 25,
  },
  historyLabelWrapper: {
    flex: 1,
    marginRight: 10,
  },
  poinPriceBox: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: 10,
  },
  emptyContainer: {
    flex: 1,
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    marginTop: 30,
  },
});

export default compose(withToast)(HistoryPointPrice);
