import React, { Component, Fragment } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import { Icon, Text } from '../../../core-ui';
import { formatThousandSeparator, convertPascalCase } from '../../../helpers';

import TablePaginationAction from '../../../components/Table/TablePagination';
import {
  PRIMARY,
  GRAY,
  GHOST_WHITE,
  TRANSPARENT,
} from '../../../constants/colors';

const DEFAULT_ROWS_PER_PAGE = 10;

type LoadMoreFunction = {
  skip: number;
  first: number;
  searchInput?: string;
};

type OwnProps = {
  data: Array<ObjectKey>;
  keyTitle: ObjectKey;
  dataCount: number;
  selectedProduct?: Nullable<ObjectKey>;
  onChangeSelected?: (selectedProduct: Nullable<ObjectKey>) => void;
  searchKey?: string;
  loadMore: (params: LoadMoreFunction) => void;
  disableSelect?: boolean;
  hidePagination?: boolean;
};

type Props = WithStyles<typeof styles> & OwnProps;

type State = {
  page: number;
};

class ModalTable extends Component<Props, State> {
  state = {
    page: 0,
  };

  render() {
    const { classes, data, hidePagination } = this.props;
    const { page } = this.state;
    return (
      <Paper square elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {this._renderHeader()}
              <TableCell className={classes.cell} />
            </TableRow>
          </TableHead>
          <TableBody>{this._renderBody()}</TableBody>
          {!hidePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={8}
                  count={data.length}
                  rowsPerPage={DEFAULT_ROWS_PER_PAGE}
                  page={page}
                  onChangePage={this._handleChangePage}
                  ActionsComponent={TablePaginationAction}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    );
  }

  _renderHeader = () => {
    const { classes, keyTitle } = this.props;
    return (
      <>
        {Object.keys(keyTitle).map((key) => (
          <TableCell className={classes.cell}>
            <Text size="xsmall" weight="bold">
              {keyTitle[key]}
            </Text>
          </TableCell>
        ))}
      </>
    );
  };

  _renderBody = () => {
    const {
      classes,
      selectedProduct,
      hidePagination,
      data,
      disableSelect,
      keyTitle,
    } = this.props;
    const displayData = hidePagination ? data : this._getSortedData();
    return (
      <>
        {displayData.map((datum) => (
          <TableRow
            key={datum.id}
            onClick={(_) => !disableSelect && this._handleSelected(datum)}
          >
            {Object.keys(keyTitle).map((item: any) => (
              <TableCell
                className={classNames(
                  classes.cell,
                  selectedProduct &&
                    selectedProduct.id === datum.id &&
                    classes.selectedCell,
                )}
              >
                <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
                  {this._getValue(item, datum)}
                </Text>
              </TableCell>
            ))}
            <TableCell
              className={classNames(
                classes.cell,
                selectedProduct &&
                  selectedProduct.id === datum.id &&
                  classes.selectedCell,
              )}
            >
              <Icon
                name="check"
                size="small"
                color={
                  selectedProduct && selectedProduct.id === datum.id
                    ? PRIMARY
                    : TRANSPARENT
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  _getValue = (key: string, datum: ObjectKey) => {
    const pascalCase = ['name'];
    if (pascalCase.includes(key)) {
      return convertPascalCase(datum[key]);
    }

    switch (key) {
      case 'rewardQty':
        return `Rp. ${formatThousandSeparator(datum[key])}`;
      default:
        return datum[key];
    }
  };

  _getSortedData() {
    const { data } = this.props;
    const { page } = this.state;

    const beginIndex = page * DEFAULT_ROWS_PER_PAGE;
    const endIndex = page * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE;

    const dataToDisplay = data.slice(beginIndex, endIndex);
    return dataToDisplay;
  }

  _handleSelected = (selectedProduct: ObjectKey) => {
    const { onChangeSelected } = this.props;
    onChangeSelected && onChangeSelected(selectedProduct);
  };

  _handleChangePage = (_: CustomMouseEvent, nextPage: number) => {
    const { loadMore, searchKey, data, dataCount } = this.props;
    const { page } = this.state;
    const isNextPagePressed = nextPage === page + 1;
    const totalPage = Math.ceil(dataCount / DEFAULT_ROWS_PER_PAGE);
    const nextPageIsLastPage = nextPage + 1 === totalPage;
    if (nextPageIsLastPage && data.length < dataCount) {
      loadMore({
        skip: data.length,
        first: dataCount - data.length,
        searchInput: searchKey,
      });
    } else if (
      !nextPageIsLastPage &&
      isNextPagePressed &&
      data.length < DEFAULT_ROWS_PER_PAGE * (nextPage + 1)
    ) {
      loadMore({
        skip: data.length,
        first: DEFAULT_ROWS_PER_PAGE,
        searchInput: searchKey,
      });
    }
    this.setState({ page: nextPage });
  };
}

const styles = createStyles({
  cell: {
    padding: '4px 18px 4px 16px',
    borderBottom: `1px dashed ${GRAY}`,
  },
  selectedCell: {
    backgroundColor: GHOST_WHITE,
  },
});

export default withStyles(styles)(ModalTable);
