import React, { Component } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, TextField } from '.';
import { TextFieldProps } from './TextField';
import { DARK_GRAY } from '../constants/colors';
import { parseFloat } from '../helpers';

type Props = TextFieldProps & {
  percentValue: number;
  onValueChange: (value: number) => void;
  style?: StyleProp<ViewStyle>;
  maxValue?: number;
};

type State = {
  value: string;
};

export default class PercentField extends Component<Props, State> {
  state = {
    value: String(this.props.percentValue),
  };

  render() {
    const {
      percentValue,
      onValueChange,
      style: containerStyle,
      ...otherProps
    } = this.props;
    return (
      <TextField
        keyboardType="numbers-and-punctuation"
        onChangeText={this._onChangeText}
        childrenPosition="right"
        style={{
          container: containerStyle,
          children: {
            paddingLeft: 0,
            marginHorizontal: 0,
          },
        }}
        value={this.state.value}
        onBlur={this._onBlur}
        {...otherProps}
      >
        <Text size="small" color={DARK_GRAY} style={{ paddingLeft: 10 }}>
          {' %'}
        </Text>
      </TextField>
    );
  }

  _onChangeText = (updatedValue: string) => {
    this.setState({ value: updatedValue });
  };

  _onBlur = () => {
    const { onValueChange, maxValue } = this.props;
    const maximum = maxValue || 100;
    const numberValue = parseFloat(this.state.value);
    const newValue = numberValue > maximum ? maximum : numberValue;
    this.setState(
      { value: String(newValue) },
      () => onValueChange && onValueChange(newValue),
    );
  };
}
