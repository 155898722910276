import React, { Component } from 'react';
import { graphql } from 'react-apollo';

import {
  GET_SUB_DISTRICT_LIST,
  SubDistrictListData,
  SubDistrictListParams,
} from '../../graphql/queries';

import { ChosenProps, Picker } from '.';

type LocationParams = {
  district?: Nullable<string>;
};

type SubDistrictListProps = { subDistrictQuery: SubDistrictListData };
type OwnProps = { isFilter?: boolean; disabled?: boolean } & ChosenProps &
  LocationParams;
type Props = SubDistrictListProps & OwnProps;

export class SubDistrictPicker extends Component<Props> {
  render() {
    const { disabled, subDistrictQuery, style, ...otherProps } = this.props;
    return (
      <Picker
        label="Kelurahan"
        placeholder="Kelurahan"
        style={style}
        isLoading={subDistrictQuery.loading}
        options={
          disabled
            ? []
            : subDistrictQuery && subDistrictQuery.subDistricts
            ? subDistrictQuery.subDistricts.map(({ id, name }) => ({
                value: id,
                label: name.toUpperCase(),
              }))
            : []
        }
        {...otherProps}
      />
    );
  }
}

export default graphql<
  OwnProps,
  SubDistrictListData,
  SubDistrictListParams,
  SubDistrictListProps
>(GET_SUB_DISTRICT_LIST, {
  name: 'subDistrictQuery',
  options: ({ district }) => ({
    variables: district ? { district } : {},
  }),
})(SubDistrictPicker);
